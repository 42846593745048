'use strict';
define('tagpacker-angular/services/userService',['tagpackerModule', 'classes/User'], function(tagpackerModule, User) {

	tagpackerModule.factory('userService', userService);
	
	userService.$inject = ['$http'];
	
	function userService($http) {
		var service = {};
		var callbacks = [];
		
		service.getUser = function(username) {
			return $http.get('/api/users', { 
				params: { username: username } 
			}).then(function(response) {
				var user = response.data[0] ? new User(response.data[0]) : null;
				angular.forEach(callbacks, function(callback){
			        callback(user);
			    });
				return user;
			});
		};
		
		service.update = function(userId, fields) {
			return $http({
				method: 'PUT',
				url: '/api/users/' + userId,
				data: fields
			});
		};
		
		service.onUserChanged = function(callback){
			callbacks.push(callback);
		};
		
		return service;
	}

});

