define('tagpacker-angular/services/pageService',['classes/User', 'classes/Facet', 'classes/Tag', 'classes/TagPack',
		'classes/Znippet', 'tagpackerModule'],
    function(User, Facet, Tag, TagPack, Znippet, tagpackerModule)
{
	tagpackerModule.factory('pageService', pageService);

	pageService.$inject = ['primaryDataService', 'pageLoader'];

	function pageService(primaryDataService, pageLoader) {
		
		var pageService = {};
		
		pageService.loadPage = function(ownerId, tagNames, contentParameters) {	
			var result = pageLoader.get('/getPage', 
			{ 
				serializedTagNames: angular.toJson(tagNames),
				ownerId: ownerId,
				contentParameters: contentParameters
			}).then(function(data) {
				data.page.znippets = Znippet.constructArray(data.page.znippets);
				data.facetList.facets = Facet.constructArray(data.facetList.facets);
				data.page.filter.user = new User(data.page.filter.user);
				data.page.filter.tags = Tag.constructArray(data.page.filter.tags);
				data.page.filter.excludedTags = Tag.constructArray(data.page.filter.excludedTags);
				data.page.filter.combPack = data.page.filter.combPack == null
					? null
					: new TagPack(data.page.filter.combPack); 
				data.page.adZnippets = Znippet.constructArray(data.page.adZnippets);
				primaryDataService.update($.parseJSON(data.initialData));
				return data;
			});
			return result;
		};
		
		return pageService;
		
	}

});

