define('tagpacker-angular/directives/onEnterPressDirective',[], function(){

	return function factory() {
		return {
			link: function postLink(scope, element, attributes) {
				element.keypress(function(event) {
					if (typeof attributes.onEnterPress !== 'undefined' && event.which == $.ui.keyCode.ENTER) {
			            event.preventDefault();
			            scope.$eval(attributes.onEnterPress);
			        }
			    });
			}
		};
	};

});
