define('tagpacker-angular/directives/otherTagsFacet/otherTagsFacet',[], function () {

    var otherTagsFacet = function factory(filterService, tagService, mainListService, analytics, authService) {

        return {
            templateUrl: '/assets/javascripts/tagpacker-angular/directives/otherTagsFacet/otherTagsFacet.html',
            scope: {
                facet: '=',
                reload: '&',
                mayEdit: '&',
                index: '&'
            },
            replace: true,
            restrict: 'E',
            controller: ['$scope', function ($scope) {
                $scope.showMore = function () {
                    mainListService.expandOtherTags = true;
                    $scope.reload();
                };

                $scope.getTitle = function () {
                    return $scope.index() > 0 ? 'Other tags' : 'Tags';
                };

                $scope.getTitleTooltip = function () {
                    return 'Tags not assigned to any tagpack';
                };

                $scope.getTags = function () {
                    var result = [];
                    for (var i = 0; i < $scope.facet.options.length; i++) {
                        result.push($scope.facet.options[i].tag);
                    }
                    return result;
                };

                $scope.toggleSort = function () {
                    if ($scope.isSortedAlphabetically()) {
                        mainListService.otherTagsFacetSort = null;
                        analytics.sendTagPackEvent('sort frequency', 'sort');
                    } else {
                        mainListService.otherTagsFacetSort = 'alphabetically';
                        analytics.sendTagPackEvent('sort alphabetically', 'sort');
                    }
                    $scope.reload();
                };

                $scope.isSortedAlphabetically = function () {
                    return $scope.facet.sort == 'alphabetically';
                };

                $scope.addTag = function () {
                    var tag = tagService.newTag('', authService.getSessionUserId());
                    tagService.openTagEditDialog(tag)
                        .then(function (tag) {
                            return tagService.saveNew(tag);
                        })
                        .then(function () {
                            analytics.sendTagEvent('facet option create', 'create');
                            $scope.showMore();
                        });
                };

                $scope.handleOptionClick = function (option) {
                	var tag = option.tag;
                	if (filterService.getNumberOfTags() == 0) {
                        tagService.openDragAndDropInfoDialog();
                    } else {
                        if (filterService.containsFilter(tag)) {
                            filterService.removeTag(tag);
                            analytics.sendTagEvent('facet option deselected', 'unfilter');
                        }
                        else {
                            filterService.addTag(tag);
                            analytics.sendTagEvent('facet option selected', 'filter');
                        }
                    }
                };

                $scope.isComplete = function () {
                    return $scope.facet.options.length == $scope.facet.totalSize;
                };

            }],
            link: function postLink(scope, element, attributes) {
                var droppableInitialized = false;
                scope.$on('dragStart', function () {
                    if (!droppableInitialized) {
                        element.find('.facet-head .title').droppable({
                            hoverClass: 'droppable-hover',
                            tolerance: 'pointer',
                            accept: function (draggable) {
                                return draggable.data('tag') ? true : false;
                            },
                            drop: function (event, ui) {
                                scope.$apply(function () {
                                    var updatedTag = ui.draggable.data('tag').clone();
                                    updatedTag.pack = null;
                                    tagService.update(updatedTag).then(function () {
                                        scope.reload();
                                    });
                                    analytics.sendTagEvent('facet option dropped on other facet', 'move');
                                });
                            }
                        });
                        droppableInitialized = true;
                    }
                });
            }
        };

    };

    otherTagsFacet.$inject = ['filterService', 'tagService', 'mainListService', 'analytics', 'authService'];

    return otherTagsFacet;

});

