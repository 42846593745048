define('tagpacker-angular/directives/tagInput/facetBasedTagInput',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.directive('facetBasedTagInput', facetBasedTagInput);

	facetBasedTagInput.$inject = ['scrollService', '$timeout'];

	function facetBasedTagInput(scrollService, $timeout) {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/tagInput/facetBasedTagInput.html',
			replace: true,
			restrict: 'E',
			scope: {
				userId: '&',
				tags: '=',
                onFacetsLoaded: '&'
			},
			controller: Controller,
			link: function (scope, element) {
				scope.scrollToFacet = function (index) {
					// let the browser render the facets first
					$timeout(function () {
						var facet = element.find('> li').eq(index);
						scrollService.scrollElementToTop(facet, undefined, 41);
					});
				}
			}
		};
	}

	Controller.$inject = ['$scope', 'facetService', 'scrollService'];

	function Controller($scope, facetService, scrollService) {
		$scope.facets = null;
		$scope.openIndex = -1;
		var nextOpenIndex = 0;

		$scope.$watch('tags', loadFacets, true);

        function loadFacets() {
			facetService.getFacets($scope.userId(), $scope.tags).then(function(facets) {
				applyNewFacets(facets);
				if (nextOpenIndex !== null) {
					$scope.openIndex = nextOpenIndex < facets.length
						? nextOpenIndex
						: facets.length-1;
					nextOpenIndex = null;
				}
				scrollToOpenFacet();
				if($scope.onFacetsLoaded) {
					$scope.onFacetsLoaded({facets: facets})
				}
			});
		}

		function scrollToOpenFacet() {
			if ($scope.openIndex >= 0) {
				$scope.scrollToFacet($scope.openIndex);
			}
		}

		$scope.headerClick = function(index) {
			$scope.openIndex = $scope.openIndex == index ? -1 : index;
			scrollToOpenFacet();
		};

		$scope.handleQuickSelect = function () {
			nextOpenIndex = $scope.openIndex+1;
		};

		$scope.$on('goToFacet', function (event, params) {
			var packId = params.pack != null ? params.pack.id : null;
			for (var i=0; i<$scope.facets.length ;i++) {
				if ($scope.facets[i].isPack()) {
					if( $scope.facets[i].pack.id == packId) {
						$scope.openIndex = i;
						scrollToOpenFacet();
					}
				}
				else if(packId == null && $scope.facets[i].isOther()) {
					$scope.openIndex = i;
					scrollToOpenFacet();
				}
			}
		});

		function applyNewFacets(newFacets) {
			$scope.facets = $scope.facets || [];
			while ($scope.facets.length > ($scope.openIndex+1)) {
				$scope.facets.pop();
			}
			var missingFacets = newFacets.filter(function(f) {
				if ($scope.facets.some(function(x) { return f.equals(x) })) return false;
				// this condition is required so a new users does not see the empty other facet
				if (newFacets.length == 1 && f.type == 'other' && f.totalSize == 0) return false;
				return true;
			})
			Array.prototype.push.apply($scope.facets, missingFacets);
		}
	}

});

