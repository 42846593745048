define('tagpacker-angular/directives/inputFocusDirective',[], function(){	

	var inputFocus = function factory(utilService) {
		return {
			replace: false,
			link: function postLink(scope, element, attributes) {
				utilService.focusElement(element);
			}
		};
	};
	
	inputFocus.$inject=['utilService'];
	
	return inputFocus;
	
});
