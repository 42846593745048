define('tagpacker-angular/controllers/znippetPage/ZnippetPageController',['tagpackerUtils'], function(tagpackerUtils){

	var ZnippetPageController = function($scope, znippetService, analytics, windowLocation,
			titleService, alertService, authService, $stateParams, userListOverlayService) {
		
		$scope.linkRecommendations = [];
		
		function loadLink() {
			$scope.isLoading = true;
			znippetService.loadLink($stateParams.znippetId).then(function(link) {
				$scope.znippet = link
				$scope.isLoading = false;
				titleService.setTitle(link.title);
			});
		}
			
		$scope.handleChange = function(link) {
			if (link == null) {
				alertService.addSuccessAlert('Your link has been deleted.');	
				analytics.sendLinkEvent('link deleted in link page', 'delete');
				windowLocation.assign($scope.znippet.owner.url);
			} else {
				alertService.addSuccessAlert('Changes to your link have been saved.');
				analytics.sendLinkEvent('link edited in link page', 'edit');
			}
			loadLink();
		};
		
		$scope.handleRepack = function(link) {
			analytics.sendLinkEvent('repacked link in link page', 'repack');
			alertService.addSuccessAlert('Link successfully repacked to '+
					'<a href="' + authService.getSessionUser().url + '">your collection</a>.');
			loadLink();
		};
	
		$scope.handleUserClick = function () {
			windowLocation.assign($scope.znippet.owner.url);
		};
		
		$scope.showOtherPackers = function() {
			userListOverlayService.openOtherPackersOverlay($scope.znippet);
		}
		
		znippetService.loadLinkRecommendationsForLink($stateParams.znippetId, 4)
			.then(function(response) {
				$scope.linkRecommendations = response;
			});
		
		loadLink();
				
	};
	
	ZnippetPageController.$inject=['$scope', 'znippetService', 'analytics', 'windowLocation',
	    'titleService', 'alertService', 'authService', '$stateParams', 'userListOverlayService'];
	
	return ZnippetPageController;
	
});
