define('tagpacker-angular/directives/challenge/challengeDirective',[], function() {

	var directive = function factory(challengeService, analytics, alertService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/challenge/challengeDirective.html',
			restrict: 'E',
			replace: true,
			scope: {
				challenge: '='
			},
			controller: ['$scope', function($scope) {
				$scope.$watch('challenge.progress()', function(newValue, oldValue){
					if(oldValue != newValue && oldValue < 100 && newValue >= 100 && !challengeService.completed($scope.challenge)) {
						challengeService.complete($scope.challenge);
					}
				});
				
				$scope.handleClick = function() {
					analytics.sendChallengeEvent($scope.challenge.key, 'click');
				};
			}]
		};
		
	};
	
	directive.$inject=['challengeService', 'analytics', 'alertService'];
	
	return directive;

});

