define('tagpacker-angular/services/dialogService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('dialogService', dialogService);

	dialogService.$inject = ['modalService'];

	function dialogService(modalService) {

		var service = {
			showDeleteDialog: showDeleteDialog,
			showQuestionDialog: showQuestionDialog,
			showInfoDialog: showInfoDialog,
			showCancelDialog: showCancelDialog,
			showContinueDialog: showContinueDialog
		};

		return service;

		function getTemplate(header, body, footer) {
			return '<div>'
				+     (header ? '<div class="modal-header">' + header + '</div>' : '')
				+     '<div class="modal-body">' + body + '</div>'
				+     '<div class="modal-footer">' + footer + '</div>'
			    +  '</div>';
		}

		function getCancelConfirmFooter(cancelLabel, confirmLabel) {
			return '<button ng-click="cancel()" class="btn btn-link">'
				+ cancelLabel + '</button>'
				+ '<button autofocus ng-click="confirm()" class="btn btn-primary">'
				+ confirmLabel + '</button>';
		}

		function showConfirmationDialog(header, body, addFieldsToScope) {
			var footer = getCancelConfirmFooter('Cancel', 'Ok');
			
			var modalInstance = modalService.openBootstrapModal({
			    backdrop: 'static',
			    template: getTemplate(header, body, footer),
			    controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
					if (addFieldsToScope)
						addFieldsToScope($scope);
			    	$scope.confirm = function () { $uibModalInstance.close() };
					$scope.cancel = function () { $uibModalInstance.dismiss('cancel') };
			    }]
			});
			
			return modalInstance.result;
		}
		
		function showDeleteDialog(name, note) {
			var header = '<h1>Delete \'{{ name | shorten:30 }}\'</h1>';
			var optionalNote = note ? '<p><strong>{{ note }}</strong></p>' : '';
			var body = optionalNote	+ '<p>Really delete?</p>';
			
			return showConfirmationDialog(header, body, function($scope) {
		    	$scope.name = name;
		    	$scope.note = note;
			});
		}
		
		function showCancelDialog() {
			var header = '<h1>Really close?</h1>';
			var body = '<p>Changes will not be saved.</p>';

			return showConfirmationDialog(header, body);
		}

		function showContinueDialog(message) {
			var header = '<h1>Continue?</h1>';
			var body = '<p>' + message + '</p>';
			
			return showConfirmationDialog(header, body);
		}

		function showQuestionDialog(header, question, placeholder, submitLabel) {
			var head = '<h1>{{ header }}</h1>';
			var body = '<p><strong>{{ question }}</strong></p>'
				+ '<p><form novalidate="novalidate">'
				+ '<textarea msd-elastic tagpacker-textarea style="resize:none" is-focused="true" '
				+ 'ng-model="answer" placeholder="{{ placeholder }}" required '
				+ 'class="delete-account-reason form-control"'
				+ '></textarea></form></p>';
			var footer = getCancelConfirmFooter('Cancel', submitLabel);
			
			var modalInstance = modalService.openBootstrapModal({
			    backdrop: 'static',
			    template: getTemplate(head, body, footer),
			    controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
			    	$scope.header = header;
					$scope.question = question;
					$scope.placeholder = placeholder;
			    	$scope.confirm = function () { $uibModalInstance.close($scope.answer) };
					$scope.cancel = function () { $uibModalInstance.dismiss('cancel') };
			    }]
			});
			
			return modalInstance.result;
		}

		function showInfoDialog(info) {
			var body = '<p class="info">' + info + '</p>';
			var header = null;
			var footer = '<button autofocus ng-click="confirm()" class="btn btn-primary">Ok</button>';
			
			var modalInstance = modalService.openBootstrapModal({
			    backdrop: 'static',
			    template: getTemplate(header, body, footer),
			    controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
			    	$scope.confirm = function () { $uibModalInstance.close() };
			    }]
			});
			
			return modalInstance.result;			
		}
		
	}
	
});

