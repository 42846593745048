define('classes/User',['classes/Tag'], function (Tag) {

    function User(state) {
        this.update(state);
    }

    User.prototype.update = function (otherUserOrState) {
        this.id = otherUserOrState.id;
        this.username = otherUserOrState.username;
        this.name = otherUserOrState.name;
        this.url = otherUserOrState.url;
        this.photoUrl = otherUserOrState.photoUrl;
        this.description = otherUserOrState.description;
        this.email = otherUserOrState.email;
        this.numberOfAccounts = otherUserOrState.numberOfAccounts;
        this.linkPrivateByDefault = otherUserOrState.linkPrivateByDefault;
        this.linkAppearance = otherUserOrState.linkAppearance;
        this.repackNotificationEnabled = otherUserOrState.repackNotificationEnabled;
        this.likeNotificationEnabled = otherUserOrState.likeNotificationEnabled;
        this.followNotificationEnabled = otherUserOrState.followNotificationEnabled;
        this.inviteConfirmedNotificationEnabled = otherUserOrState.inviteConfirmedNotificationEnabled;
        this.linkTipReceivedNotificationEnabled = otherUserOrState.linkTipReceivedNotificationEnabled;
        this.linkTipHandledNotificationEnabled = otherUserOrState.linkTipHandledNotificationEnabled;
        this.summaryNotificationEnabled = otherUserOrState.summaryNotificationEnabled;
        this.channelNotificationEnabled = otherUserOrState.channelNotificationEnabled;
        this.betaFeaturesEnabled = otherUserOrState.betaFeaturesEnabled;
        this.numberOfFollowers = otherUserOrState.numberOfFollowers;
        this.numberOfFollowing = otherUserOrState.numberOfFollowing;
        this.numberOfLinks = otherUserOrState.numberOfLinks;
        this.hasLocalAccount = otherUserOrState.hasLocalAccount;
        this.hasApiTokenPassword = otherUserOrState.hasApiTokenPassword;
        this.followingIds = otherUserOrState.followingIds;
        this.blockedIds = otherUserOrState.blockedIds;
        this.isEmailValidated = otherUserOrState.isEmailValidated;
        this.isQuality = otherUserOrState.isQuality;
        this.numberOfUnseenLinks = otherUserOrState.numberOfUnseenLinks;
        this.numberOfProfileViews = otherUserOrState.numberOfProfileViews;
        this.isDropboxConnected = otherUserOrState.isDropboxConnected;
        this.lastDropboxBackup = otherUserOrState.lastDropboxBackup;
        this.dropboxBackupError = otherUserOrState.dropboxBackupError;
        this.usesCustomTagPackOrder = otherUserOrState.usesCustomTagPackOrder;
        this.isDonor = otherUserOrState.isDonor;
        if (otherUserOrState.mostFrequentTags) {
            this.mostFrequentTags = otherUserOrState.mostFrequentTags;
            for (var i = 0; i < this.mostFrequentTags.length; i++) {
                if (!(this.mostFrequentTags[i].tag instanceof Tag)) {
                    this.mostFrequentTags[i].tag = new Tag(this.mostFrequentTags[i].tag);
                }
            }
        }
        else {
            this.mostFrequentTags = [];
        }
    };

    User.prototype.getPhotoUrl = function () {
        return this.photoUrl;
    };

    User.prototype.isAdmin = function () {
        return this.id == '51c1bb61137ebad815e98a2f';
    };

    User.prototype.isAlphaUser = function () {
        return this.isAdmin() || this.id == '53ea20d6b909cf352527ccf7'
            || this.id == '559cd630b9090e3ca1b38a81'; // tagpacker || Michele Brocco 
    };

    User.prototype.isFollowing = function (userId) {
        return this.followingIds.indexOf(userId) >= 0;
    };

    User.prototype.withImage = function () {
        return this.linkAppearance && this.linkAppearance.withImage;
    };

    User.prototype.withDescription = function () {
        return this.linkAppearance && this.linkAppearance.withDescription;
    };

    User.prototype.withTags = function () {
        return this.linkAppearance && this.linkAppearance.withTags;
    };

    User.prototype.isCompactLinkView = function () {
        return this.linkAppearance && (!this.linkAppearance.withImage || !this.linkAppearance.withDescription || !this.linkAppearance.withTags);
    };

    User.constructArray = function (users) {
        return users.map(function (x) {
            return new User(x);
        });
    };

    return User;

});

