define('tagpacker-angular/controllers/account/AccountTabController',[], function () {

    return ['$scope', '$http', 'alertService', 'dialogService', 'authService', 'analytics', 'loginService', '$window', 'modalService', 'znippetService', 'windowLocation', 'loadingService', 'userService', 'primaryDataService',
        function ($scope, $http, alertService, dialogService, authService, analytics, loginService, $window, modalService, znippetService, windowLocation, loadingService, userService, primaryDataService) {

            $scope.$watch('user', function (newValue, oldValue) {
                if (newValue != null) {
                    $scope.session = {
                        email: $scope.user.email,
                        linkPrivateByDefault: $scope.user.linkPrivateByDefault,
                        linkAppearance: $scope.user.linkAppearance,
                        betaFeaturesEnabled: $scope.user.betaFeaturesEnabled
                    };
                }
            });

            $scope.isApp = function () {
                return primaryDataService.isApp();
            };

            $scope.sendVerifyMail = function () {
                $http({
                    method: 'POST',
                    url: '/sendVerifyMail'
                }).success(function (data, status, headers, config) {
                    alertService.addSuccessAlert("An email with verification instructions has been sent.", true);
                });
            };

            $scope.submitProfile = function (form) {
                userService.update(authService.getSessionUser().id, $scope.session).then(function () {
                    alertService.addSuccessAlert("Saved!", true);
                    $scope.$emit('refreshAccount');
                });
            };

            $scope.openConfirmDeleteDialog = function () {
                var note = 'Your complete collection including all '
                    + 'links, tags etc. will be irreversibly deleted.';

                return dialogService.showDeleteDialog($scope.user.username, note)
                    .then(function () {
                        var header = "Confirm it again";
                        var question = "Would you please tell us why are you leaving?";
                        var placeholder = "Enter a reason";
                        var submitLabel = "Delete my account permanently";
                        return dialogService.showQuestionDialog(header, question, placeholder,
                            submitLabel);
                    })
                    .then(function (reason) {
                        return $http({
                            method: 'DELETE',
                            url: '/api/users/' + authService.getSessionUser().id + '?' + $.param({'reason': reason})
                        }).then(function () {
                            analytics.sendUserEvent('account settings', 'delete');
                        });
                    })
                    .then(function () {
                        windowLocation.assign('/');
                    });
            };

            $scope.handleClick = function (provider) {
                if (!provider.isLinked) {
                    authenticate(provider.key);
                } else {
                    unlinkAccount(provider);
                }
            };

            function authenticate(providerKey) {
                loginService.authenticate(providerKey, 'account', $window.location.href);
            };

            var unlinkAccount = function (provider) {
                $http({
                    method: 'POST',
                    url: '/unlinkAccount',
                    params: {
                        providerKey: provider.key
                    }
                }).success(function (data, status, headers, config) {
                    alertService.addSuccessAlert('Successfully disconnected.');
                    $scope.$emit('refreshAccount');
                });
            };

            $scope.title = function (provider) {
                if (provider.isLinked) {
                    return 'Disconnect from ' + provider.key;
                } else {
                    return 'Connect to ' + provider.key;
                }
            };

            $scope.createPassword = function () {
                modalService.openBootstrapModal({
                    backdrop: 'static',
                    templateUrl: '/assets/javascripts/tagpacker-angular/controllers/account/createPasswordDialog.html',
                    controller: ['$scope', '$uibModalInstance', function CreatePasswordDialogController($scope, $uibModalInstance) {

                        $scope.session = {
                            password: null
                        };

                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };

                        $scope.saveIfValid = function () {
                            if ($scope.form.$valid) {
                                $http({
                                    method: 'POST',
                                    url: '/createPassword',
                                    params: {
                                        password: $scope.session.password
                                    }
                                }).then(function () {
                                    $uibModalInstance.close();
                                }).finally(function () {
                                    $scope.session.password = null;
                                    $scope.form.$setPristine();
                                });
                            }
                        };

                    }]
                }).result.then(function () {
                    $scope.$emit('refreshAccount');
                    alertService.addSuccessAlert('Your password has been created.');
                });
            };

            $scope.changePassword = function () {
                modalService.openBootstrapModal({
                    backdrop: 'static',
                    templateUrl: '/assets/javascripts/tagpacker-angular/controllers/account/changePasswordDialog.html',
                    controller: ['$scope', '$uibModalInstance', function ChangePasswordDialogController($scope, $uibModalInstance) {

                        $scope.session = {
                            oldPassword: null,
                            password: null
                        };

                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };

                        $scope.saveIfValid = function () {
                            if ($scope.form.$valid) {
                                $http({
                                    method: 'POST',
                                    url: '/changePassword',
                                    params: {
                                        oldPassword: $scope.session.oldPassword,
                                        newPassword: $scope.session.password
                                    }
                                }).then(function () {
                                    $uibModalInstance.close();
                                }).finally(function () {
                                    $scope.session.oldPassword = null;
                                    $scope.session.password = null;
                                    $scope.form.$setPristine();
                                });
                            }
                        };

                    }]
                }).result.then(function () {
                    $scope.$emit('refreshAccount');
                    alertService.addSuccessAlert('Your password has been changed.');
                });
            };

            $scope.deleteApiTokenPassword = function () {
                dialogService.showDeleteDialog('API key', 'Third-party services (like IFTTT or Zapier) will not be able to access your account anymore.').then(function () {
                    $http({
                        method: 'DELETE',
                        url: '/api/users/' + authService.getSessionUser().id + '/authToken'
                    }).then(function () {
                        $scope.$emit('refreshAccount');
                        alertService.addSuccessAlert('API key deleted.');
                    });
                });
            };

            $scope.refreshApiTokenPassword = function () {
                var message = 'This invalidates your current key and requires you to update keys you have passed to third-party services (like IFTTT or Zapier).';
                dialogService.showContinueDialog(message).then(function () {
                    return $scope.createApiTokenPassword();
                });
            };

            $scope.createApiTokenPassword = function () {
                return $http({
                    method: 'GET',
                    url: '/api/users/' + authService.getSessionUser().id + '/authToken'
                }).then(function (response) {
                    modalService.openBootstrapModal({
                        backdrop: 'static',
                        templateUrl: '/assets/javascripts/tagpacker-angular/controllers/account/createApiTokenDialog.html',
                        controller: ['$scope', '$uibModalInstance', function CreateApiTokenDialogController($scope, $uibModalInstance) {
                            $scope.close = function (form) {
                                $uibModalInstance.close();
                            };

                            $scope.token = response.data;
                        }]
                    }).result.then(function () {
                        $scope.$emit('refreshAccount');
                    });
                });
            };

            $scope.import = function () {
                znippetService.openImportDialog().then(function () {

                });
            };

            $scope.connectDropbox = function () {
                modalService.openBootstrapModal({
                    backdrop: 'static',
                    templateUrl: '/assets/javascripts/tagpacker-angular/controllers/account/connectDropboxDialog.html',
                    controller: ['$scope', '$uibModalInstance', function connectDropboxDialogController($scope, $uibModalInstance) {
                        $scope.close = function () {
                            $uibModalInstance.close();
                        };
                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }]
                }).result.then(function () {
                    windowLocation.assign('/dropbox/authStart');
                });

            };

            $scope.disconnectDropbox = function () {
                return $http({
                    method: 'GET',
                    url: '/dropbox/disconnect'
                }).then(function (response) {
                    alertService.addSuccessAlert('Dropbox backup disabled.');
                    $scope.$emit('refreshAccount');
                });
            };

            $scope.backupToDropbox = function () {
                loadingService.showModalLoadingAnimation();
                return $http({
                    method: 'GET',
                    url: '/dropbox/backup?format=html'
                }).then(function (response) {
                    alertService.addSuccessAlert('Backup created.');
                }).finally(function () {
                    loadingService.hideModalLoadingAnimation();
                    $scope.$emit('refreshAccount');
                });
            };

        }];

});

