define('tagpacker-angular/controllers/welcome/InterestsController',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.controller('InterestsController', InterestsController);

	InterestsController.$inject=['$scope', '$http'];
	
	function InterestsController($scope, $http) {
		
		$scope.all = [];
		$scope.selected = [];
		$scope.numberOfInterests = 3;

		var saving = false;
		
		function init() {
			$http({
				method: 'GET',
				url: '/interests'		
			}).success(function(data, status, headers, config) {
				$scope.all = data.all;
				$scope.selected = data.selected;
			});
		};
		
		$scope.$on('trigger-next-step', function(event) {
			if (!saving) {
				$http({
					method: 'POST',
					url: '/interests',
					params: {
						'selected': angular.toJson($scope.selected)
					}
				}).then(function () {
					saving = false;
					$scope.$emit('next-step');
				});
				saving = true;
			}
		});

		$scope.isSelected = function (interest) {
			return $scope.selected.indexOf(interest) > -1;
		};
		
		$scope.select = function (interest) {
			var index = $scope.selected.indexOf(interest);
			if(index == -1) {
				$scope.selected.push(interest);
			} else {
				$scope.selected.splice(index, 1);
			}
		};
		
		init();
		
	}
	
});

