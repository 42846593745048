define('tagpacker-angular/directives/ad/blockAdsWhenActive',[], function(){

	var blockAdsWhenActive = function factory(recommendationService, $timeout) {
		
		return {
			restrict: 'A',
			link: function postLink(scope, element, attributes) {
				var timeout = null;
				$(element).on('show.bs.dropdown', function (e) {
					if (timeout) {
						$timeout.cancel(timeout);
					}
					scope.$apply(function(){
						recommendationService.block();
					});
					
				});
				$(element).on('hide.bs.dropdown', function (e) {
					timeout = $timeout(function(){
						scope.$apply(function(){
							recommendationService.unblock();
						});	
					}, 400);
				});
			}
		}
		
	};
	
	blockAdsWhenActive.$inject=['recommendationService', '$timeout'];
	
	return blockAdsWhenActive;
	
});
