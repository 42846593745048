define('tagpacker-angular/directives/thumbnail/thumbnailSourceSelector',['tagpackerModule', 'tagpackerUtils'], function(tagpackerModule, tagpackerUtils){

	tagpackerModule.directive('thumbnailSourceSelector', thumbnailSourceSelector);

	thumbnailSourceSelector.$inject = [];
	
	function thumbnailSourceSelector() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/thumbnail/thumbnailSourceSelector.html',
			replace: true,
			restrict: 'E',
			scope: {
				link: '='
			},
			controller: Controller
		};	
	}

	Controller.$inject = ['$scope'];

	function Controller($scope) {
		$scope.uploadedImagePreviewUrl = $scope.link.thumbnailSource == 'UPLOAD'
			? $scope.link.thumbnailUrl : '/assets/images/default-link-thumbnail.png';

		$scope.removeUploadedImage = function() {
			$scope.uploadedImage = null;
			$scope.link.thumbnailId = null;
		};

		$scope.previewImageUploadValidators = [
			tagpackerUtils.createFileTypeFilter(['png','jpg','jpeg','gif']),
			tagpackerUtils.createFileSizeFilter(4 * 1000 * 1000)
		];
		
		$scope.previewImageUploadFinished = function (file) {
			$scope.uploadedImage = file;
			$scope.link.thumbnailId = file.id;
			$scope.uploadedImagePreviewUrl = file.url;
		};

		var statesPerSource = {};
		
		function getState() {
			return {
				thumbnailId: $scope.link.thumbnailId,
				thumbnailSourceUrl: $scope.link.thumbnailSourceUrl
			};
		}
		
		function applyState(state) {
			$scope.link.thumbnailId = state.thumbnailId;
			$scope.link.thumbnailSourceUrl = state.thumbnailSourceUrl;
		}

		$scope.$watch('link.thumbnailSource', function (newSource, oldSource) {
			statesPerSource[oldSource] = getState();
		
			if (statesPerSource[newSource]) {
				applyState(statesPerSource[newSource]);
			}
			else {
				$scope.link.thumbnailId = null;
				$scope.link.thumbnailSourceUrl = null;
			}
		});
	}

});
