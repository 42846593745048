define('tagpacker-angular/controllers/SearchController',['classes/Collection'], function(Collection){

	return ['$scope', '$state', 'listLoader', function($scope, $state, listLoader) {
		
		function loadList(query) {
			var url = '/api/collections?query=' + encodeURIComponent(query);
			$scope.collectionList = listLoader.create(url, {
				chunkSize: 8,
				transformElement: function(element) {
					return new Collection(element);
				}
			});
			$scope.collectionList.load();
		}
		
		$scope.$watch(function() {
			return $state.params.q;
		}, function(value) {
			$scope.query = value;
			loadList(value);
		});
		
	}];

});

