define('tagpacker-angular/services/tagpackService',['classes/TagPack', 'tagpackerModule'], function(TagPack, tagpackerModule) {

	tagpackerModule.factory('tagpackService', tagpackService);

	tagpackService.$inject = ['$http', 'modalService', 'authService'];

	function tagpackService($http, modalService, authService) {
		
		function submitTagPack(tagPack) {
			var ownerId = authService.getSessionUser().id;
			if(tagPack.isNew()){
				return $http({
					method: 'POST',
					url: '/api/users/'+ ownerId + '/tagpacks',
					data: tagPack
				}).then(function(response) {
					return new TagPack(response.data);
				});
			} else {
				return $http({
					method: 'PUT',
					url: '/api/users/'+ ownerId + '/tagpacks/' + tagPack.id,
					data: tagPack
				}).then(function(response) {
					return new TagPack(response.data);
				});
			}
		};
		
		return {
			
			setSuperTag: function(pack, tag) {
				return $http.put('/api/users/' + pack.ownerId + '/tagpacks/' + pack.id, {
					superTagId: tag == null ? null : tag.id
				}).then(function(response) {
					return new TagPack(response.data);
				});
			}, 
			
			setSort: function(pack, sort) {
				return $http.put('/api/users/' + pack.ownerId + '/tagpacks/' + pack.id, {
					sort: sort
				}).then(function(response) {
					return new TagPack(response.data);
				});
			}, 
			
			removeTagPack: function(pack) {
				var url = '/api/users/'+ pack.ownerId + '/tagpacks/' + pack.id;
				return $http({
					method: 'DELETE',
					url: url
				});	
			},
			
			openTagPackEditDialog: function(tagPack) {
				return modalService.openBootstrapModal({
			    	backdrop: 'static',
			    	templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/tagPackEditDialog.html',
			    	controller: ['$scope', '$uibModalInstance', function TagPackEditDialogController($scope, $uibModalInstance) {
			    		$scope.tagPack = tagPack;
			    		$scope.save = function (form) {
			    			if(form.$valid) {
			    				$uibModalInstance.close($scope.tagPack);
			    			}
						};
						$scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
			    	}]
				}).result.then(function (tagPack) {
			    	return submitTagPack(tagPack);
			    });
			}, 
			
			openChangeFacetColorDialog: function(tagPack) {
				return modalService.openBootstrapModal({
			    	backdrop: 'static',
			    	templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/changeFacetColorDialog.html',
			    	controller: ['$scope', '$uibModalInstance', function TagPackEditDialogController($scope, $uibModalInstance) {
			    		$scope.tagPack = tagPack;
			    		$scope.colors = [1,2,3,4,5,6,7,8];
			    		$scope.save = function () {
		    				$uibModalInstance.close($scope.tagPack);
						};
						$scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
			    	}]
				}).result.then(function (tagPack) {
					return submitTagPack(tagPack);
			    });
			}, 
			
			setFacetOrder: function(ownerId, tagPacks) {
				return $http({
					method: 'PUT',
					url: '/api/users/'+ ownerId + '/tagpacks',
					data: tagPacks
				}).then(function(response) {
					var result = [];
					response.data.forEach(function(tagPack){
						result.push(new TagPack(tagPack));
					});
					
					return result;
				});
			}
			
		};
	}
	
});


