define('tagpacker-angular/directives/tagpackerTextareaDirective',['tagpackerUtils'], function(tagpackerUtils){

	var tagpackerTextarea = function factory($timeout) {
		return {
			scope: {
				'isFocused': '&'
			},
			link: function postLink(scope, element, attributes) {
				setTimeout(function() {
			    	if (scope.isFocused()) {
						$timeout(function(){
							element.focus();
							tagpackerUtils.setCaretToPos(element.get(0), element.val().length);
			    		});
					}
				});
			}
		};
	};
	
	tagpackerTextarea.$inject=['$timeout'];
	
	return tagpackerTextarea;

});
