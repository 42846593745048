define('tagpacker-angular/filters/highlightQueryFilter',['tagpackerUtils'], function(tagpackerUtils){

	return ['$sce', '$filter', function ($sce) {
	  	return function (text, query) {
	  		text = tagpackerUtils.encodeHtmlSpecialChars(text);
	    	if (query) {
	    		query = tagpackerUtils.encodeHtmlSpecialChars(query);
	    		escapedQuery = tagpackerUtils.escapeRegexp(query);
      			text = text.replace(new RegExp('(^|\\W)('+escapedQuery+')', 'gi'),
      					'$1<span class="highlighted-text">$2</span>');
      			
	    	}
	    	text = tagpackerUtils.createHtmlLinebreaks(text);
	  		return $sce.trustAsHtml(text);
	  	};
	}];
	
});
