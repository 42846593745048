define('classes/TagPack',[], function(){

	function TagPack(pack) {
		if (pack) {
			this.update(pack);
		}
		else {
			this.id = null;
			this.name = '';
			this.color = null;
			this.superTagId = null;
		}
	}

	TagPack.prototype.update = function(otherPack) {
		this.id = otherPack.id;
		this.name = otherPack.name;
		this.ownerId = otherPack.ownerId;
		this.color = otherPack.color;
		this.superTagId = otherPack.superTagId;
	};

	TagPack.prototype.isNew = function() {
		return this.id == null;
	};
	
	TagPack.prototype.equals = function(otherPack) {
		if (otherPack == null)
			return false;
		
		if (this.id !== null && otherPack.id !== null)
			return this.id === otherPack.id;
		else
			return this.name === otherPack.name;
	};
	
	TagPack.constructArray = function(packs) {
		return packs.map(function(x) { return new TagPack(x); });
	}
	
	return TagPack;
	
});

