/*
 * There is a Java version of this file that has to be consistent with this one.
 */

define('tagpacker-angular/services/tagNameInUrlEncoder',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('tagNameInUrlEncoder', tagNameInUrlEncoder);

	tagNameInUrlEncoder.$inject = [];

	function tagNameInUrlEncoder() {
		
		return {
			
			encode: function(name) {
				var result = name;
				result = result.replace(/%/g, "%25");
				result = result.replace(/,/g, "%2C");
				result = result.replace(/_/g, "%5F");
				result = result.replace(/\//g, "%2F");
				result = result.replace(/ /g, "_");
				return result;
			},
		
			decode: function(encodedName) {
				var result = encodedName;
				result = result.replace(/_/g, " ");
				result = result.replace(/%5F/g, "_");
				result = result.replace(/%2C/g, ",");
				result = result.replace(/%2F/g, '/');
				result = result.replace(/%25/g, '%');
				return result;
			}
			
		};
		
	};
	
});

