'use strict';
define('tagpacker-angular/services/authService',['tagpackerModule', 'tagpackerUtils'], function(tagpackerModule, tagpackerUtils) {

	tagpackerModule.factory('authService', authService);

	authService.$inject = ['primaryDataService', '$window', '$rootScope'];

	function authService(primaryDataService, $window, $rootScope) {
		var service = {};
		
		// TODO: avoid delegating to primaryDataService
		service.getSessionUser = function() {
			return primaryDataService.getSessionUser();
		};
		
		service.getSessionUserId = function() {
			return service.getSessionUser().id;
		};
		
		service.setSessionUser = function(user) {
			return primaryDataService.setSessionUser(user);
		};
		
		service.getSessionUserUsername = function() {
			return service.getSessionUser() != null ? service.getSessionUser().username : null;
		};
		
		service.isSessionUserUsername = function(username) {
			return service.isLoggedIn() 
				&& username == service.getSessionUserUsername();
		};
		
		service.isSessionUserId = function(userId) {
			return service.isLoggedIn() 
				&& userId == service.getSessionUser().id;
		};
		
		service.isLoggedIn = function() {
			return service.getSessionUser() !== null;
		};
		
		service.areAdminFeaturesEnabled = function() {
			return service.isLoggedIn() && service.getSessionUser().isAdmin();
		};
		
		service.areAlphaFeaturesEnabled = function() {
			return service.isLoggedIn() && service.getSessionUser().isAlphaUser();
		};
		
		service.areBetaFeaturesEnabled = function() {
			return service.isLoggedIn() ? service.getSessionUser().betaFeaturesEnabled : false;
		};
		
		service.monitorSessionEventsForPlugin = function(scope) {
			$rootScope.$watch(function() { return service.getSessionUser() == null ? null : service.getSessionUser().id; }, function(newValue, oldValue){
				if(newValue){
					if(tagpackerUtils.inIframe()) {
						$window.parent.postMessage('tp-login', '*');
					} else {
						$window.postMessage('tp-login', '*');
					}
				} else {
					if(tagpackerUtils.inIframe()) {
						$window.parent.postMessage('tp-logout', '*');
					} else {
						$window.postMessage('tp-logout', '*');
					}
				}
				if(primaryDataService.isApp()) {
					window.cordova.plugins.CookieManagementPlugin.flush();
				}
			}, true);
		};
		
		return service;
	}

});

