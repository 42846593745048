define('tagpacker-angular/directives/login/passwordDirective',[], function(){	

	var password = function factory() {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/passwordDirective.html',
			scope: {
				hideForgotPasswordHint: '&',
				onPasswordForgot: '&',
				form: '=',
				session: '=',
				label: '&',
				hint: '&'
			},
			replace: true,
			restrict: "E",
			link: function postLink(scope, element, attrs) {
				scope.hidePasswordHint = scope.hideForgotPasswordHint();
				
				scope.getPlaceholder = function() {
					if(scope.inputFocused) {
						return "";
					}
					return scope.getLabel();
				};
				
				scope.getLabel = function () {
					if (scope.label()) {
						return scope.label();
					} else {
						return "Password";
					}
				};
			}
		};
		
	};
	
	return password;

});
