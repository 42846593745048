define('tagpacker-angular/services/userListOverlayService',['classes/User', 'tagpackerUtils', 'tagpackerModule'],
	   function(User, tagpackerUtils, tagpackerModule)
{
	tagpackerModule.factory('userListOverlayService', userListOverlayService);

	userListOverlayService.$inject = ['modalService', '$http', 'listLoader', 'authService'];
	
	function userListOverlayService(modalService, $http, listLoader, authService)
	{
		return {
			openFollowersOverlay: function(user) {
				return openOverlay({
	    			title: 'Followers of ' + user.name,
	    			listUrl: '/api/users/' + user.id + '/followers',
	    			emptyInfo: 'Nobody is following ' + (authService.isSessionUserId(user.id) ? 'you' : user.name) + ' yet.'
	    		});
			},
			
			openFollowingOverlay: function(user) {
				return openOverlay({
	    			title: "Users " + user.name + " is following",
	    			listUrl: '/api/users/' + user.id + '/following',
	    			emptyInfo: user.name + " isn't following anyone yet."
	    		});
			},
			
			openRepackersOverlay: function(link) {
				return openOverlay({
	    			title: "Repacked by",
	    			listUrl: '/api/links/' + link.id + '/repackers',
	    			emptyInfo: "This link has not been repacked yet.",
	    			complete: true
	    		});
			},
			
			openLikersOverlay: function(link) {
				return openOverlay({
	    			title: "Liked by",
	    			listUrl: '/api/links/' + link.id + '/likers',
	    			emptyInfo: "This link has not been liked yet.",
	    			complete: false
	    		});
			},
			
			openSuggestedUsersOverlay: function() {
				return openOverlay({
	    			title: "Users you might know",
	    			listUrl: '/api/socialNetworkFriends',
	    			emptyInfo: "Currently we cannot suggest any users here.",
	    			complete: false
	    		});
			},
			
			openSimilarUsersOverlay: function(id) {
				return openOverlay({
	    			title: "Users with similar interests",
	    			listUrl: '/api/userRecommendationsForUser?id=' + id,
	    			emptyInfo: "Currently we cannot suggest any users here.",
	    			complete: false
	    		});
			},
			
			openChannelFollowSuggestionsOverlay: function(channelName) {
				return openOverlay({
	    			title: "Users posting to channel '" + channelName + "'",
	    			listUrl: '/api/channelFollowSuggestions?channelName=' + encodeURIComponent(channelName),
	    			emptyInfo: "Currently we cannot suggest any users here.",
	    			complete: false
	    		});
			},
			
			openOtherPackersOverlay: function(link) {
				return openOverlay({
	    			title: "Users also packed this link",
	    			listUrl: '/api/links/' + link.id + '/otherPackers',
	    			emptyInfo: "This link has not been packed by others yet.",
	    			complete: false
	    		});
			}
			
		};
		
		function openOverlay(options) {
			modalService.openBootstrapModal({
		    	backdrop: 'static',
		    	templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/userListOverlay.html',
		    	controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
		    		initOverlay($scope, $uibModalInstance, options);
		    	}]
			}).result;
		}
		
		function initOverlay($scope, $uibModalInstance, options) {
			$scope.title = options.title;
			$scope.emptyInfo = options.emptyInfo;
			
			// closed flag is a preliminary fix required because of this bug
			// https://github.com/angular-ui/bootstrap/issues/1643
			var closed = false;
			$scope.close = function () {
				if (!closed) {
					$uibModalInstance.close();
					closed = true;
				}
			};
			
			var listOptions = {
				morphArray: function(oldArray, newArray) {
					newArray = User.constructArray(newArray);
					tagpackerUtils.morphUserArray(oldArray, newArray);
				}
			};
			if (!options.complete) {
				listOptions.chunkSize = 12;
			}
			
			$scope.userList = listLoader.create(options.listUrl, listOptions);
			$scope.userList.load();		
					
			$scope.$on('$stateChangeSuccess', function() {
				$scope.close();
			});
		}
		
	}
	
});

