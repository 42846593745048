define('classes/Hint',['bootstrap', 'jquery-ui'], function(){

	function Hint($rootScope, $compile, hintService, key, selector, message, dependencies) {
		this.$rootScope = $rootScope;
		this.$compile = $compile;
		this.hintService = hintService;
		this.key = key;
		this.selector = selector;
		this.message = message;
		this.dependencies = dependencies ? dependencies : [];
		this.element = null;
		this.confirmed = false;
	}

	Hint.prototype.close = function() {
		if (this.element) {
			this.element.popover('hide');
		}
	};

	Hint.prototype.confirm = function() {
		this.confirmed = true;
		this.close();
		this.hintService.confirm(this);
	};				

	Hint.prototype.openPopover = function() {
		//avoid 'nullpointer' if popover is not there for any reason
		if (this.isInitialized() && !this.isConfirmed()) {
			this.element.popover('show');
			//because popover might be appended to body set z-index of popover (popover.$tip) to z-index of the 'positioning' element
			this.getPopover().tip().zIndex(this.element.zIndex());
			this.offset = this.element.offset();
		}
	};

	Hint.prototype.isConfirmed = function () {
		return this.confirmed || this.hintService.confirmed(this.key);
	};	

	Hint.prototype.schedulePositionChecks = function() {
		var self = this;
		
		$(window).resize(function(){
			self.repositionOrHideIfRequired();
		});

		//if element position has changed (e.g., due to scrolling) redraw popover; $('body').scroll is not sufficient because hint could be in a scrollable container (e.g., overlay)
		//TODO: fix flickering while scrolling
		doReqularlyNotConfirmed(function() {
			self.repositionOrHideIfRequired();
		}, 300);
		
		function doReqularlyNotConfirmed(task, delay) {
			setTimeout(function() {
				task();
				if (!self.isConfirmed()) {
					doReqularlyNotConfirmed(task, delay);
				}
			}, delay);
		}
	};

	Hint.prototype.repositionOrHideIfRequired = function() {
		if (this.element && this.element.is(":visible")) {
			var newOffset = this.element.offset();
			if (newOffset.top != this.offset.top || newOffset.left != this.offset.left) {
				this.reposition();
			}
		} else {
			this.element.popover('hide');	
		}
	}

	Hint.prototype.reposition = function() {
		var popover = $(".popover");
		popover.addClass("no-transition");
		this.openPopover();
		popover.removeClass("no-transition");
	}

	Hint.prototype.isDisplayed = function () {
		if (this.isInitialized()) {
			return this.getPopover().tip().hasClass('in');
		}
		return false;
	};
		
	Hint.prototype.isInitialized = function () {
		return this.getPopover() != null;
	};

	Hint.prototype.getPopover = function () {
		if (this.element && this.element.length > 0) {
			return this.element.data('bs.popover');
		}
		return null;
	};

	Hint.prototype.show = function () {
		var thisHint = this;
		var element = $('body').find(thisHint.selector);
		if (element && $(element).is(':visible')) {
			var popover = thisHint.getPopover();
			
			//popover not initialized yet for element
			if (!popover) {
				//if hint's element changes (cf. next line "thisHint.element = element") close popover at previous element; 
				//element change can happen e.g., if repack hint is displayed, link is opened in view mode, repack button is clicked in overlay, and overlay is closed; 
				//then the hint appears at the next repack button in the list; 
				if(thisHint.isInitialized()) {
					thisHint.close();
				}
				
				thisHint.element = element;
				thisHint.schedulePositionChecks();
				thisHint.initPopover();
				
				//hint is confirmed if element is clicked directly
				thisHint.element.click(function(){
					thisHint.confirm();
				});
				
				//destroy popover if element gets detached from DOM
				thisHint.element.on("remove", function () {
					thisHint.element.popover('destroy');
				});
				
				thisHint.openPopover();
			}
			
			//popover is initialized but not displayed currently
			else if (popover && !popover.tip().hasClass('in')) {
				thisHint.openPopover();
			}
		} 
	};

	Hint.prototype.initPopover = function() {
		var self = this;
		
		this.element.popover({
			content: self.getRenderedAndCompiledHtml(), 
			html: true,
			placement: 'bottom', 
			trigger: 'manual', 
			container: 'body'
		});
	};

	Hint.prototype.getRenderedAndCompiledHtml = function() {
		var self = this;
		
		var scope = self.$rootScope.$new();
		scope.confirmHint = function () {
			self.confirm();
		};
		scope.message = self.message;
		
		var template = 
			'<div style="padding:10px; font-size:20px; font-weight:bold; color:grey">' + 
				'<div class="hint-content" style="margin-bottom:10px">{{ message }}</div>' + 
				'<div class="hint-footer" style="text-align:right"><button class="btn btn-primary" ng-click="confirmHint()">Got it</button></div>' + 
			'</div>'; 
		
		var result = self.$compile(template)(scope);
		// digest once to actually render the template 
		scope.$digest();
		return result;
	};
	
	return Hint;

});
