define('tagpacker-angular/controllers/account/NotificationTabController',[], function(){

	return ['$scope', 'alertService', 'authService', 'userService',
			function($scope, alertService, authService, userService) {
		
		$scope.session = {
			notificationEnabled: false,
			repackNotificationEnabled: false,
			likeNotificationEnabled: false,
			followNotificationEnabled: false,
			inviteConfirmedNotificationEnabled: false,
			linkTipReceivedNotificationEnabled: false,
			linkTipHandledNotificationEnabled: false,
			summaryNotificationEnabled: false,
			channelNotificationEnabled: false
		};
		
		$scope.$watch('user', function(newValue, oldValue) {
			if (newValue != null) {
				$scope.session = {
					notificationEnabled: newValue.repackNotificationEnabled || 
										 newValue.likeNotificationEnabled || 
										 newValue.followNotificationEnabled || 
										 newValue.inviteConfirmedNotificationEnabled || 
										 newValue.linkTipReceivedNotificationEnabled || 
										 newValue.linkTipHandledNotificationEnabled || 
										 newValue.summaryNotificationEnabled ||
										 newValue.channelNotificationEnabled,
					repackNotificationEnabled: newValue.repackNotificationEnabled,
					likeNotificationEnabled: newValue.likeNotificationEnabled,
					followNotificationEnabled: newValue.followNotificationEnabled,
					inviteConfirmedNotificationEnabled: newValue.inviteConfirmedNotificationEnabled,
					linkTipReceivedNotificationEnabled: newValue.linkTipReceivedNotificationEnabled,
					linkTipHandledNotificationEnabled: newValue.linkTipHandledNotificationEnabled,
					summaryNotificationEnabled: newValue.summaryNotificationEnabled,
					channelNotificationEnabled: newValue.channelNotificationEnabled
				};
			}
		});
		
		$scope.onGlobalNotificationSettingChanged = function(){
			$scope.session.repackNotificationEnabled = $scope.session.notificationEnabled;
			$scope.session.likeNotificationEnabled = $scope.session.notificationEnabled;
			$scope.session.followNotificationEnabled = $scope.session.notificationEnabled;
			$scope.session.inviteConfirmedNotificationEnabled = $scope.session.notificationEnabled;
			$scope.session.linkTipReceivedNotificationEnabled = $scope.session.notificationEnabled;
			$scope.session.linkTipHandledNotificationEnabled = $scope.session.notificationEnabled;
			$scope.session.summaryNotificationEnabled = $scope.session.notificationEnabled;
			$scope.session.channelNotificationEnabled = $scope.session.notificationEnabled;
		}
		
		$scope.submitProfile = function(form) {
			userService.update(authService.getSessionUser().id, $scope.session).then(function(){
            	alertService.addSuccessAlert("Saved!", true);
                $scope.$emit('refreshAccount');
            });
		};
		
	}];
	
});

