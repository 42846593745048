define('tagpacker-angular/controllers/SignupController',[], function(){
	
	var SignupController = function($scope, $http, analytics) {
		
		$scope.session = {};
		
		var loadAuthUser = function () {
			$http({
				method: 'GET',
				url: '/authUser'
			}).success(function(data, status, headers, config) {
				$scope.session.name = data.name;
				$scope.session.email = data.email;
			});
		}
		
		$scope.handleCompleteClick = function () {
			analytics.sendUserEvent('complete button', 'register');
		};
		
		loadAuthUser();
		
	};
	
	SignupController.$inject=['$scope', '$http', 'analytics'];
	
	return SignupController;

});

