define('tagpacker-angular/services/mouseService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('mouseService', mouseService);

	mouseService.$inject = ['$rootScope'];

	function mouseService($rootScope) {
		
		var numberOfPressedButtons = 0;
		
		var nextMouseUpCallbacks = [];
		
		$(document).mousedown(function() {
			numberOfPressedButtons++;
		});
		
		$(document).mouseup(function() {
			numberOfPressedButtons--;
			
			$rootScope.$apply(function() {
				for (var i=0 ; i<nextMouseUpCallbacks.length ; i++) {
					nextMouseUpCallbacks[i]();
				}
			});
			
			nextMouseUpCallbacks = [];
		});
	
		var service = {};
		
		service.isButtonPressed = function() {
			return numberOfPressedButtons > 0;
		};
		
		service.onNextMouseUp = function(callback) {
			nextMouseUpCallbacks.push(callback);
		};
		
		return service;
	}
	
});

