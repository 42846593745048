define('tagpacker-angular/directives/followButton/followButton',[], function(){

	var followButton = function factory(analytics, $rootScope, followService, alertService, loginService, cookieService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/followButton/followButton.html',
			scope: {
				user: '=' /* may not have been initialized (this should be fixed in the future) */
			},
			restrict: 'E',
			replace: true,
			controller: ['$scope', 'authService', function($scope, authService) {
				
				$scope.isLoading = false;
				
				$scope.isFollowing = function() {
					return authService.isLoggedIn() && $scope.user
						&& authService.getSessionUser().followingIds.indexOf($scope.user.id) !== -1;
				};
			
				var follow = function () {
					followService.follow($scope.user.id).then(function() {
						authService.getSessionUser().followingIds.push($scope.user.id);
						analytics.sendUserFollowEvent('follow (followButton)', authService.getSessionUser(), $scope.user);
						$scope.$emit('follow', { user: $scope.user });
					}).finally(function() {
						$rootScope.$broadcast('refreshPage');
						$scope.isLoading = false;
					});
					$scope.isLoading = true;
			  	};
			  	
			  	var unfollow = function () {
			  		followService.unfollow($scope.user.id).then(function() {
			  			var index = authService.getSessionUser().followingIds.indexOf($scope.user.id);
						if (index > -1) {
							authService.getSessionUser().followingIds.splice(index, 1);
						}
						analytics.sendUserUnfollowEvent('unfollow (followButton)', authService.getSessionUser(), $scope.user);
						$scope.$emit('unfollow', { user: $scope.user });
					}).finally(function() {
						$rootScope.$broadcast('refreshPage');
						$scope.isLoading = false;
					});
					$scope.isLoading = true;
			  	};
				
				$scope.toggleFollow = function () {
					if (authService.isLoggedIn()) {
						$scope.isFollowing() ? unfollow() : follow();
					} else {
						var userId = $scope.user.id;
						
						//follow is triggered at server-side when the user has registered
						followService.followRequest(userId);
						
						//used to show follow suggestions (e.g., in welcome wizard / follow step)
						var additionalUser = {"userId": userId};
  						cookieService.setCookie("additionalUser", JSON.stringify(additionalUser), 1);
						
						loginService.openRegisterDialog('follow button');
					}
				};
				
				$scope.isVisible = function () {
					return $scope.user && 
						(!authService.isLoggedIn() || authService.getSessionUser().id !== $scope.user.id);
				};
				
			}]
		};
		
	};
	
	followButton.$inject=['analytics', '$rootScope', 'followService', 'alertService', 'loginService', 'cookieService'];
	
	return followButton;
	
});
