define('tagpacker-angular/services/keyEventService',['tagpackerModule'], function(tagpackerModule){
	
	tagpackerModule.factory('keyEventService', keyEventService);
	
	keyEventService.$inject = [];
	
	function keyEventService() {

		var service = {
			add: add,
			remove: remove,
			isActive: isActive
		};

		var ids = [];
		
		function add(id) {
			if($.inArray(id, ids) == -1)
				ids.push(id);
		}

		function remove(id) {
			for(var i = ids.length - 1; i >= 0; i--) {
			    if(ids[i] === id) {
			    	ids.splice(i, 1);
			    }
			}
		}
		
		function isActive(id) {
			return ids[ids.length - 1] === id;
		}
		
		return service;

	}
	
});

