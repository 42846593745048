define('tagpacker-angular/directives/affiliateAnchor/affiliateAnchor',[], function(){

	var affiliateAnchor = function (znippetService, $http, cookieService, utilService, authService) {
		return {
			scope: {
	  			context: '&'
	  		},

			link: function(scope, elem, attributes) {
	      		scope.link = scope.$parent.$eval(attributes['link']);

	      		scope.$watch('link.sourceUrl', function() {
	      			elem.attr('href', scope.link.affiliateUrl ? scope.link.affiliateUrl : scope.link.sourceUrl);
	      		});

				elem.attr('target', '_blank');
				elem.attr('rel', 'nofollow');
				elem.click(function(event) {
	      			var href = elem.attr('href');
	      			var lastClickThrough = {"id": scope.link.id, "sourceUrl": href};
  					  cookieService.setCookie("lastClickThrough", JSON.stringify(lastClickThrough), 2);
	      			znippetService.handleClick(scope.link, 'clickThrough', scope.context());
	      			if (!scope.link.affiliateUrl && href) {
	      				var url = utilService.isLocalhost() || (authService.isLoggedIn() && authService.getSessionUser().isDonor) ? href
		      					: 'https://go.redirectingat.com/?id=70622X1519194&url=' + encodeURIComponent(href);
		      			window.open(url, '_blank');
		      			event.preventDefault();
	      			}
	      		});
	  		}
		};
	};

	affiliateAnchor.$inject=['znippetService', '$http', 'cookieService', 'utilService', 'authService'];

	return affiliateAnchor;

});

