define('tagpacker-angular/services/shareService',['tagpackerModule'], function(tagpackerModule) {
	'use strict';

	// The name was changed from 'adService' so it's not blocked by Adblock plus
	tagpackerModule.factory('shareService', shareService);
	
	shareService.$inject = ['$http'];
	
	function shareService($http) {

		var service = {};
	  
		service.twitterShare = function (url, text) {
			return 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(text) + '&url=' + url + '&via=tagpacker';
		};
		
		service.facebookShare = function (url) {
			return 'http://www.facebook.com/share.php?u=' + url;
		};
		
		service.googleShare = function (url) {
			return 'https://plus.google.com/share?url=' + url;
		};
		
		service.emailShare = function (url, subject) {
			return 'mailto:?subject=' + subject + '&body=' + url;
		};
		
		service.tumblrShare = function (url) {
			return 'https://www.tumblr.com/widgets/share/tool?canonicalUrl=' + url;
		};
		
		return service;
	
	}
	
});

