define('tagpacker-angular/directives/tagInput/singleTagInput',[], function(){

	return [function() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/tagInput/singleTagInput.html',
			replace: true,
			restrict: 'E',
			scope: {
				tag: '=',
				userId: '&',
				placeholder: '&'
			},
			controller: ['$scope', function($scope) {
				
				$scope.tags = [];
				
				$scope.reset = function() {
					$scope.tag = null;
				}
				
				$scope.$watch('tags.length', function(newValue) {
					if (newValue > 0) {
						$scope.tag = $scope.tags[0];
						while ($scope.tags.length > 0) {
							$scope.tags.pop();
						}
					}
				});
				
			}]
		};
	}];
	
});
