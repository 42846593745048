define('tagpacker-angular/services/loginService',['tagpackerUtils', 'tagpackerModule'], function (tagpackerUtils, tagpackerModule) {

    tagpackerModule.factory('loginService', loginService);

    loginService.$inject = ['$window', 'modalService', 'analytics', 'recaptcha'];

    function loginService($window, modalService, analytics, recaptcha) {
        var loginService = {};

        loginService.openLoginDialog = function (context) {
            modalService.openBootstrapModal({
                templateUrl: '/assets/javascripts/tagpacker-angular/controllers/login/loginOverlay.html',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.session = {};
                    $scope.close = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onRegister = function () {
                        $scope.close();
                    };
                    $scope.onForgotPassword = function () {
                        $scope.close();
                    };
                }],
                windowClass: 'login-overlay'
            });
            analytics.sendUserEvent(context, 'openLoginForm');
        };

        loginService.openRegisterDialog = function (context) {
            modalService.openBootstrapModal({
                templateUrl: '/assets/javascripts/tagpacker-angular/controllers/login/registerOverlay.html',
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.session = {};
                    $scope.close = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.onLogin = function () {
                        $scope.close();
                    };
                }],
                windowClass: 'register-overlay'
            });
            analytics.sendUserEvent(context, 'openRegistrationForm');
        };

        loginService.authenticate = function (providerKey, label, referrer) {
            // we store the timestamp of the authentication attempt locally so we can suppress the referrer when the user returns (prevent new session)
            $window.localStorage.providerAuthTimestamp = Date.now();
            analytics.sendUserEvent(providerKey + ' user registered/logged-in ' + label, 'register-login');
            recaptcha.execute(label).then(function (token) {
                var url = "/authStart/" + providerKey + "?g-recaptcha-response=" + token;
                if (referrer) {
                    url += "&referrer=" + referrer;
                }
                if (tagpackerUtils.inIframe()) {
                    $window.open(url, '_blank');
                    $window.parent.postMessage('closePackItDialog', '*');
                } else {
                    $window.location = url;
                }
            });
        };

        return loginService;

    }

});

