define('routing',[], function(){

	var routing = function($locationProvider, $httpProvider, $stateProvider, $urlRouterProvider, primaryDataServiceProvider) {
		$locationProvider.html5Mode(true);
		$httpProvider.interceptors.push('tagpackerHttpInterceptor');
		
		//required to disable IE ajax request caching
		if (!$httpProvider.defaults.headers.get) {
			$httpProvider.defaults.headers.get = {};
		}
		$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
		
		$urlRouterProvider.when('', '/'); // required for IE9
		$urlRouterProvider.when('/account', '/account/profile');
		$urlRouterProvider.when('/welcome', function(){
			if(primaryDataServiceProvider.isApp()) {
				return '/welcome/interests';
			} else {
				return '/welcome/packit';
			}
		});
		 
		$stateProvider
			.state('index', {
	            url: '/',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/index.html',
	            controller: 'IndexController',
	            anonymousOnly: true
	        })
		 	.state('login', {
	            url: '/login',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/login.html',
	            title: 'Login',
	            anonymousOnly: true
	        })
		 	.state('register', {
	            url: '/register',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/register.html',
	            title: 'Sign up',
	            anonymousOnly: true
	        })
	        .state('forgotPassword', {
	            url: '/forgotPassword',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/forgotPassword.html',
	            title: 'Forgot password'
	        })
	        .state('resetPassword', {
	            url: '/resetPassword/:token',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/resetPassword.html',
	            controller: 'ResetPasswordController',
	            title: 'Reset password'
	        })
	        .state('unsubscribe', {
	            url: '/unsubscribe',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/unsubscribe.html',
	            title: 'Block mails'
	        })
	        .state('znippet', {
	            url: '/link/:znippetId',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/znippetPage/znippetPage.html',
	            controller: 'ZnippetPageController'
	        })
		 	.state('search', {
		    	url: '/search',
		    	'abstract': true,
		    	templateUrl: '/assets/javascripts/tagpacker-angular/partials/search.html',
		    	controller: 'SearchController'
		    })
		    // Lex: this sub-state is required to prevent a complete reload of the search controller when only the query changes
		    // The problem with the option 'reloadOnSearch' is that it does not update the URL when params change
		    // I also didn't test how it affects the browser history
		    .state('search.main', {
		    	url: '?q'
		    })
		    .state('user', {
		      'abstract': true,
		      url: '/user/:username?e',
		      templateUrl: function (stateParams) {
		      	return '/assets/javascripts/tagpacker-angular/partials/user.html';
		      },
		      controller: 'UserController'
		    })
		 	.state('user.main', {
		 	  'abstract': true,
		      url: '',
		      views: {
		      	'filter': {
		      		templateUrl: '/assets/javascripts/tagpacker-angular/controllers/znippetsList/filterWithToolbar.html',
		      	},
		        'sidebar': { 
		        	templateUrl: '/assets/javascripts/tagpacker-angular/controllers/znippetsList/sidebar.html',
		        },
		        'content': { 
		        	templateUrl: '/assets/javascripts/tagpacker-angular/controllers/znippetsList/linkList.html', 
		        	controller: 'LinkListController'
		        }, 
		        'ads': {
		        	templateUrl: '/assets/javascripts/tagpacker-angular/controllers/znippetsList/znippetsAds.html',
		        	controller: 'ZnippetsAdsController'
		        }
		      }
		    })
		    .state('user.main.filter', {
		    	url: '?nt&t&comb&q&v&r&c&sf&so&{a:json}'
		    })
		    .state('network', {
		      'abstract': true,
		      url: '',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/page.html'
		    })
		    .state('network.latestLinks', {
		    	url: '/latestLinks',
		    	views: {
			        'content': { 
			        	templateUrl: '/assets/javascripts/tagpacker-angular/controllers/activitiesList/latestLinksTab.html',
					    controller: 'LatestLinksController'
			        }
		    	},
		    	title: 'Latest links'
		    })
		    .state('network.main', {
		    	url: '/network',
		    	views: {
		    		'sidebar': {
		    			templateUrl: '/assets/javascripts/tagpacker-angular/controllers/activitiesList/activitiesSidebar.html',
						controller: 'ActivitiesSidebarController'
		    		},
		    		'content': { 
			        	templateUrl: '/assets/javascripts/tagpacker-angular/controllers/activitiesList/activitiesList.html',
			        	controller: 'ActivitiesListController'
		    		},
		    		'ads': {
			        	templateUrl: '/assets/javascripts/tagpacker-angular/controllers/znippetsList/znippetsAds.html',
			        	controller: 'ZnippetsAdsController'
			        }
		        },
		        title: 'Network'
		    })
		    .state('network.main.channel', {
		    	url: '/:channel',
		    	title: 'Channel'
		    })
		    .state('home', {
		      url: '/home',
		      anonymousOnly: true
		    })
		    .state('account', {
	            'abstract': true,
	            url: '/account',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/account.html',
	            controller: 'AccountController'
	        })
		    .state('account.profile', {
		      url: '/profile',
		      templateUrl: '/assets/javascripts/tagpacker-angular/controllers/account/profileTab.html',
		      controller: 'ProfileTabController',
		      title: 'Profile settings'
		    })
		    .state('account.account', {
		      url: '/account',
		      templateUrl: '/assets/javascripts/tagpacker-angular/controllers/account/accountTab.html',
		      controller: 'AccountTabController',
		      title: 'Basic account settings'
		    })
		    .state('account.notification', {
		      url: '/notification',
		      templateUrl: '/assets/javascripts/tagpacker-angular/controllers/account/notificationTab.html',
		      controller: 'NotificationTabController',
		      title: 'Notification settings'
		    })
		    .state('confirmSignup', {
		      url: '/confirmSignup',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/confirmSignup.html',
		      controller: 'SignupController',
		      title: 'Complete registration'
		    })
		    .state('legalNotice', {
	            url: '/legalNotice',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/legalNotice.html',
	            title: 'Legal Notice'
	        })
	        .state('api', {
	            url: '/api',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/api.html',
	            title: 'API'
	        })
		    .state('imprint', {
	            url: '/imprint',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/legalNotice.html',
	            title: 'Legal Notice'
	        })
		    .state('termsOfService', {
		      url: '/termsOfService',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/termsOfService.html',
		      title: 'Terms of Service'
		    })
		    .state('privacyPolicy', {
		      url: '/privacyPolicy',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/privacyPolicy.html',
		      title: 'Privacy Policy'
		    })
		    .state('about', {
		      url: '/about',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/about.html',
		      title: 'About'
		    })
		    .state('howto', {
		      url: '/howto',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/howto.html',
		      title: 'Howto'
		    })
		    .state('features', {
		      url: '/features',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/features.html',
		      title: 'Features',
		      controller: 'FeaturesController'
		    })
		    .state('faq', {
		      url: '/faq',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/faq.html',
		      title: 'FAQ'
		    })
		    .state('help', {
		      url: '/help',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/help.html',
		      title: 'Help'
		    })
		    .state('bookmarklet', {
		      url: '/bookmarklet',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/bookmarklet.html',
		      title: 'Bookmarklet'
		    })
		    .state('jobs', {
		      url: '/jobs',
		      templateUrl: '/assets/javascripts/tagpacker-angular/partials/jobs.html',
		      title: 'Jobs'
		    })
		    .state('error', {
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/error.html',
	            title: 'Error'
	        })
	        .state('badRequest', {
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/notFound.html',
	            title: 'Page not found'
	        })
	        .state('welcome', {
		    	'abstract': true,
	            url: '/welcome',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/welcome/welcome.html',
	            controller: 'WelcomeController',
	        })
	        .state('welcome.verify', {
		    	url: '/verify',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/welcome/verify.html',
	            title: 'Verify Your Email Address',
				controller: 'VerifyController'
	        })
	        .state('welcome.bookmarklet', {
	            url: '/packit',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/welcome/bookmarklet.html',
	            title: 'Add the "Pack it" Button',
				controller: 'WelcomePackitController'
	        })
	        .state('welcome.interests', {
	            url: '/interests',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/welcome/interests.html',
	        	controller: 'InterestsController',
	        	title: 'Choose Your Interests'
	        })
	        .state('welcome.follow', {
	            url: '/follow',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/welcome/follow.html',
	            controller: 'FollowController',
	            title: 'Pick People to Follow'
	        })
	        .state('bookmark', {
	        	url: '/bookmark',
	        	title: false
	        })
	        .state('packit', {
	            url: '/packit',
	            title: false
	        })
	        .state('plugin', {
	            url: '/plugin',
	            title: false
	        })
	        .state('kpis', {
		    	url: '/kpis',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/kpis/kpis.html',
	            controller: 'KPIsController',
	            title: 'KPIs'
	        })
		    .state('lockedUsers', {
		    	url: '/lockedUsers',
	            templateUrl: '/assets/javascripts/tagpacker-angular/controllers/lockedUsers/lockedUsers.html',
	            controller: 'LockedUsersController',
	            title: 'Locked Users'
	        })
	        .state('donate', {
	            url: '/donate',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/donate.html',
	            title: 'Donate'
	        })
	        .state('app', {
	            url: '/app',
	            title: 'Update App'
	        })
		    .state('notFound', {
		    	url: '{path:.*}',
	            templateUrl: '/assets/javascripts/tagpacker-angular/partials/notFound.html',
	            title: 'Page not found'
	        });
	};
	
	routing.$inject=['$locationProvider', '$httpProvider', '$stateProvider', '$urlRouterProvider', 'primaryDataServiceProvider'];
	
	return routing;

});

