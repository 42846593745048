define('tagpacker-angular/directives/userVoice/userVoice',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.directive('userVoice', userVoice);

	userVoice.$inject=['modalService', 'cookieService', 'analytics', 'viewService'];

    function userVoice(modalService, cookieService, analytics, viewService) {
		var key = 'M99ILOjYgRMirh55mrlqtg';
		var disableStr = "uv-disable-" + key;
		var init = function () {
			UserVoice = window.UserVoice || [];
			if (UserVoice.length == 0) {
				(function(){var uv=document.createElement('script');uv.type='text/javascript';uv.async=true;uv.src='//widget.uservoice.com/'+ key + '.js';var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(uv,s)})()	
			}
		};
		
		var openUserVoiceDialog = function () {
			UserVoice.push(['show', {
				target: '#feedback-button-overlay-anchor',
				mode: 'smartvote',
				menu_enabled: true,
				contact_enabled: true
			}]);
		};
		
		return {
			restrict: 'E',
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/userVoice/userVoice.html',
			replace: true,
			controller: ['$scope', function($scope) {
				init();
				$scope.viewService = viewService;
				$scope.showFeedbackDialog = function () {
					analytics.sendEvent('feedback', 'click', 'feedback button clicked on ' + window.location.href);
					if (cookieService.hasCookie(disableStr)) {
						openUserVoiceDialog();
					} else {
						modalService.openBootstrapModal({
					    	backdrop: 'static',
					    	templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/confirmUserVoiceDialog.html',
					    	controller: ['$scope', '$uibModalInstance', function UserDescriptionEditDialogController($scope, $uibModalInstance) {
					    		$scope.save = function () {
					    			$uibModalInstance.close();
								};
								$scope.cancel = function () {
									$uibModalInstance.dismiss('cancel');
								};
					    	}]
						}).result.then(function () {
							cookieService.setCookie(disableStr);
							openUserVoiceDialog();
					    });
					}
				}
			}]
		};
		
	};
	
	return userVoice;
	
});
