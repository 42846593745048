define('tagpacker-angular/directives/facetOption/facetOption',['tagpackerUtils', 'classes/Filter'], function(tagpackerUtils, Filter){

	return ['znippetService', 'filterService', 'authService', 'draggingService', 'analytics',
			'$rootScope', 'alertService', 'tagService', 'dialogService', 'mainListService', '$q', 'loadingService',
			function (znippetService, filterService, authService, draggingService, analytics,
					  $rootScope, alertService, tagService, dialogService, mainListService, $q, loadingService) {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/facetOption/facetOption.html',
			scope: {
				option: '=',
				onClick: '&'
			},
			restrict: 'E',
			replace: true,
			controller: [ '$scope', function($scope) {
				
				$scope.handleClick = function() {
					$scope.onClick({ $option: $scope.option });
					mainListService.setFocusedTagId($scope.option.tag.id);
				};
				
				$scope.mayEdit = function() {
				    return authService.isSessionUserUsername(filterService.getUsername());
				};
				
				$scope.excludeOptionVisible = function() {
					return filterService.containsFilter($scope.option.tag);
				};
				
                $scope.toggleExclude = function() {
					filterService.toggleExclude($scope.option.tag);
					analytics.sendTagEvent('facet option excluded', 'excluded');
				};
				
				$scope.remove = function() {
					analytics.sendTagEvent('facet option delete', 'delete');
					dialogService.showDeleteDialog($scope.option.tag.name)
						.then(function() {
							loadingService.showModalLoadingAnimation();
						    return tagService.removeTag($scope.option.tag).finally(function(){
						    	loadingService.hideModalLoadingAnimation();
						    });
						})
						.then(function() {
							filterService.removeTag($scope.option.tag);
							$rootScope.$broadcast('refreshPage');							
						});
				};
				
				$scope.move = function() {
					analytics.sendTagEvent('facet option move', 'move');
					znippetService.openMoveTagDialog($scope.option.tag).then(function(tagPack) {
						if (tagPack != null) {
							alertService.addSuccessAlert(
								"Moved tag '" + $scope.option.tag.name + "' into tagpack '" + tagPack.name + "'");
						}
						else {
							alertService.addSuccessAlert(
								"Moved tag '" + $scope.option.tag.name + "' out of its tagpack");
						}
						
						$rootScope.$broadcast('refreshPage');
					});
				};
				
				function mergeTags(sourceTag, targetTag) {
					return dialogService.showContinueDialog("Really merge tag '" + sourceTag.name + "' with tag '" + targetTag.name + "'?").then(function(){
						var filter = new Filter(authService.getSessionUser()).withTag(sourceTag);
						loadingService.showModalLoadingAnimation();
						return znippetService.tagAll([targetTag], filter).then(function(){
							return tagService.removeTag(sourceTag).then(function(){
								filterService.clear();
								filterService.addTag(targetTag);
								alertService.addSuccessAlert("Merged tag '" + sourceTag.name + "' with tag '" + targetTag.name + "'");
								$rootScope.$broadcast('refreshPage');
								return $q.reject();
							});
						}).finally(function(){
							loadingService.hideModalLoadingAnimation();
						});
					});
				}
				
				$scope.rename = function() {
					tagService.openTagEditDialog($scope.option.tag.clone(), true)
						.then(function(tag) {
							return tagService.update(tag);
						}, function(tag){
							//merge; would be better to have an explicit reason
							if(tag && tag.name) {
								return tagService.getTagsForName(tag.ownerId, tag.name).then(function(tags){
									if(tags && tags.length > 0) {
										return mergeTags($scope.option.tag, tags[0]);	
									}
									return $q.reject();
								});
							}
							return $q.reject();
						})
						.then(function(tag) {
							if(filterService.containsFilter($scope.option.tag)) {
								filterService.removeTag($scope.option.tag);
								filterService.addTag(tag);
							}
							analytics.sendTagEvent('facet option rename', 'rename');
							$rootScope.$broadcast('refreshPage');							
						});
				};
				
			}],
			link: function postLink(scope, element, iAttrs) {
				element.addClass('tag' + scope.option.tag.id);
				
				var droppableInitialized = false;
				scope.$on('dragStart', function() {
					if (!droppableInitialized) {
						element.droppable({
							hoverClass: 'droppable-hover',
							tolerance: 'pointer',
							accept: function(draggable) {
								return draggable.data('znippet') ? true : false;
							},
							drop: function(event, ui) {
								// don't do this at once, otherwise the draggable will fail (stop function/event)
								scope.$apply(function() {
									var draggedZnippet = ui.draggable.data('znippet');
									var newTags = draggedZnippet.tags.concat([scope.option.tag]);
									znippetService.updateZnippet(draggedZnippet.id, { tags: newTags })
										.then(function() {
											$rootScope.$broadcast('refreshPage');
										});
									analytics.sendLinkEvent('link dropped on facet option', 'edit');
								});
							}
						});
					}
					droppableInitialized = true;
				});
				
				var isDraggable = false;
				element.bind('mouseenter', function() {
					if (!ResponsiveBootstrapToolkit.is('xs') && !isDraggable && scope.mayEdit()) {
						element.data('tag', scope.option.tag);
						element.draggable({
		    		  		revert: 'invalid',
		    		  		distance: 10,
		    		  		appendTo: 'body',
		    		  		start: function() {
		    		  			draggingService.startDragging();
		    		  		},
							stop: function() {
								draggingService.stopDragging();
							},
		    		  		helper: function() {
		    		  	  		draggingHelper = $(this).clone();
		    		  	  		draggingHelper.addClass('dragging-helper');
		    		  	  		draggingHelper.css('width', $(this).outerWidth());
		    		  	  		draggingHelper.css('height', $(this).outerHeight());
		    		  	  		draggingHelper.css('list-style-type', 'none');
		    		      		return draggingHelper;
		    		    	}
		    		  	});
		    		  	isDraggable = true;
					}
				});
				
				scope.$watch('option.frequency', function(newValue, oldValue) {
					element.find('a.main-link').attr('rel', 
							scope.option.frequency == 0 ? 'nofollow' : null);
				});
				
			}
		};
	}];

});

