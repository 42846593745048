define('tagpacker-angular/directives/user/changeUserPhotoDirective',['tagpackerUtils'], function(tagpackerUtils){

	var changeUserPhoto = function factory(authService, $timeout, $rootScope) {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/user/changeUserPhotoDirective.html',
			replace: true,
			restrict: 'E', 
			scope: {
				user: '='
			}, 
			link: function postLink(scope, element, iAttrs) {
	    		scope.photoChangeFinished = function () {
	    			// reload is required to force the browser to request the photo again.
	    			// Adding a cache breaker to the url does not clear the browser cache
	    			// for the original url but you will often encounter the original url as you browse 
	    			// the platform.
					window.location.reload();
			    };
			    scope.fileValidators = [
			    	tagpackerUtils.createFileTypeFilter(['png','jpg','jpeg','gif']),
					tagpackerUtils.createFileSizeFilter(4 * 1000 * 1000)
				]
		    }
		};
	};
	
	changeUserPhoto.$inject=['authService', '$timeout', '$rootScope'];
	
	return changeUserPhoto;

});
