define('tagpacker-angular/services/loadingService',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.factory('loadingService', loadingService);

	loadingService.$inject = ['$timeout'];

	function loadingService($timeout) {
	
		var DELAY = 250;
		
		var isLoadingAnimationVisible = false;
		var loadingTimeout = null;
		
		var nextTaskNumber = 1;
		var loadingTasks = [];
		
		// always append the (invisible) div so the animation-gif is already loaded. It won't load in Chrome 
		// if appended after a form submit
		var modalDiv = angular.element('<div class="fullscreen-loading-div">&nbsp;</div>');
		$('body').append(modalDiv);
		
		return {
			
			showModalLoadingAnimation: function() {
				$('body').addClass('modal-loading')
			},
			
			hideModalLoadingAnimation: function() {
				$('body').removeClass('modal-loading');
			},
			
			startNewTask: function(startImmediately) {
				var newTask = nextTaskNumber;
				nextTaskNumber++;
				loadingTasks.push(newTask);
				startLoadingAnimation(startImmediately);
				return newTask;
			},
			
			stopTask: function(task) {
				for(var i = loadingTasks.length - 1; i >= 0; i--) {
				    if(loadingTasks[i] === task) {
				    	loadingTasks.splice(i, 1);
				    }
				}
				if (loadingTasks.length == 0) {
					stopLoadingAnimation();
				}
			},
			
			// refers only to non-modal loading tasks
			isLoading: function() {
				return loadingTasks.length > 0;
			},
			
			isLoadingAnimationVisible: function() {
				return isLoadingAnimationVisible;
			}
			
		};
		
		function startLoadingAnimation(startImmediately) {
			if (loadingTimeout == null) {
				loadingTimeout = $timeout(function() {
					isLoadingAnimationVisible = true;
				}, startImmediately ? 0 : DELAY);
			}
		};
		
		function stopLoadingAnimation() {
			isLoadingAnimationVisible = false;
			if (loadingTimeout) {
				$timeout.cancel(loadingTimeout);
				loadingTimeout = null;
			}
		};
		
	}
	
});

