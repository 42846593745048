define('tagpacker-angular/directives/recommendation/linkRecommendationDirective',[], function(){

	var linkRecommendation = function factory(znippetService, userListOverlayService) {
		
		return {
			replace: true,
			scope: {
				links: '=',
				getUser: '&'
			},
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/recommendation/linkRecommendationDirective.html',
			controller: ['$scope', function($scope) {
				$scope.trackClick = function(link) {
					znippetService.handleClick(link, 'clickThroughRecommendation');
				};
				$scope.showOtherPackers = function(link) {
					userListOverlayService.openOtherPackersOverlay(link);
				}
			}]
		};
		
	};

	linkRecommendation.$inject=['znippetService', 'userListOverlayService'];

	return linkRecommendation;

});
