define('tagpacker-angular/services/facetService',['tagpackerUtils', 'classes/Facet', 'tagpackerModule'], function (tagpackerUtils, Facet, tagpackerModule) {

    tagpackerModule.factory('facetService', facetService);

    facetService.$inject = ['$http'];

    function facetService($http) {

        var totalContentLength = 0;
        var service = {};

        var morphConfig = {
            checkIdentity: function (oldFacet, newFacet) {
                if (oldFacet.type == newFacet.type) {
                    if (oldFacet.type == "pack") {
                        return oldFacet.pack.id == newFacet.pack.id;
                    }
                    else {
                        return true;
                    }
                }
                return false;
            },
            update: function (oldFacet, newFacet) {
                oldFacet.update(newFacet);
            }
        };

        service.getTotalContentLength = function () {
            return totalContentLength;
        };

        service.setTotalContentLength = function (length) {
            totalContentLength = length;
        };

        service.morphFacets = function (oldFacets, newFacets) {
            tagpackerUtils.morphArray(oldFacets, newFacets, morphConfig);
        };

        service.getFacets = function (userId, tags) {
            var url = '/api/users/' + userId + '/facets';

            if (tags && tags.length > 0) {
                var tagIdParams = tags
                    .filter(function (t) {
                        return !!t.id
                    })
                    .map(function (t) {
                        return 't=' + t.id
                    });
                if (tagIdParams.length > 0) {
                    url += '?' + tagIdParams.join('&');
                }
            }

            return $http.get(url).then(function (response) {
                return Facet.constructArray(response.data);
            });
        };

        return service;

    }

});

