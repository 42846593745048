'use strict';
define('tagpacker-angular/services/sidebarService',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.factory('sidebarService', sidebarService);

	sidebarService.$inject = [];

	function sidebarService() {
		var service = {};
		
		service.open = false;
		
		service.toggle = function() {
			service.open = !service.open;
		};
		
		service.close = function() {
			service.open = false;
		};
		
		service.isOpen = function() {
			return service.open;
		};
		
		return service;
	}

});

