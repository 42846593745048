define('tagpacker-angular/filters/linebreakFilter',['tagpackerUtils'], function(tagpackerUtils){

	return ['$sce', function ($sce) {
	  	return function (text) {
	  		text = tagpackerUtils.encodeHtmlSpecialChars(text);
	    	text = tagpackerUtils.createHtmlLinebreaks(text);
	  		return $sce.trustAsHtml(text);
	  	};
	}];
	
});
