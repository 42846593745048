define('tagpacker-angular/directives/editView/editView',[], function () {

    return [function factory() {

        return {
            templateUrl: '/assets/javascripts/tagpacker-angular/directives/editView/editView.html',
            scope: {
                znippet: '=link',
                showLogo: '&',
                hideHeader: '&',
                onCancel: '&',
                onSave: '&',
                onDelete: '&'
            },
            replace: true,
            restrict: 'E',
            controller: ['$scope', 'znippetService', 'authService', '$document', 'loadingService', 'dialogService', 'scrollService', '$location',
                function ($scope, znippetService, authService, $document, loadingService, dialogService, scrollService, $location) {
                    $scope.betaFeaturesEnabled = authService.areBetaFeaturesEnabled();
                    $scope.sessionUser = authService.getSessionUser();
                    $scope.saving = false;
                    $scope.showAdvancedSettings = false;
                    $scope.showImageChooser = false;

                    $scope.getCurrentImageUrl = function () {
                        return $scope.znippet.thumbnailUrl != null ? $scope.znippet.thumbnailUrl : '/assets/images/default-link-thumbnail.png';
                    };

                    $scope.togglePrivacy = function () {
                        $scope.znippet.isPrivate = !$scope.znippet.isPrivate;
                    };

                    $scope.isFallback = function () {
                        return $location.search().fallback === 'true';
                    };

                    $scope.cancel = function () {
                        if ($scope.formDataChanged) {
                            var elementToReceiveFocus = $document[0].activeElement;
                            dialogService.showCancelDialog()
                                .then(function () {
                                    $scope.onCancel()
                                })
                                .finally(function () {
                                    elementToReceiveFocus.focus()
                                });
                        } else {
                            $scope.onCancel();
                        }
                    };

                    // 'delete' is a reserved word...
                    $scope.deleteLink = function () {
                        dialogService.showDeleteDialog($scope.znippet.title)
                            .then(function () {
                                return znippetService.remove($scope.znippet);
                            })
                            .then(function () {
                                $scope.onDelete();
                            });
                    };

                    $scope.save = function () {
                        if (!$scope.saving) {
                            $scope.saving = true;
                            loadingService.showModalLoadingAnimation();

                            znippetService.submitEditForm($scope.znippet.id, getFormData())
                                .then(function (link) {
                                    $scope.onSave({$link: link});
                                }, function () {
                                    $scope.saving = false;
                                }).finally(function () {
                                loadingService.hideModalLoadingAnimation();
                            });
                        }
                    };

                    function getFormData() {
                        var result = {
                            title: $scope.znippet.title,
                            sourceUrl: $scope.znippet.sourceUrl,
                            description: $scope.znippet.description,
                            tags: $scope.znippet.tags,
                            isPrivate: $scope.znippet.isPrivate,
                            thumbnailSource: $scope.znippet.thumbnailSource,
                            thumbnailId: $scope.znippet.thumbnailId,
                            thumbnailSourceUrl: $scope.znippet.thumbnailSourceUrl
                        };
                        if ($scope.znippet.isNew()) {
                            result.sourceZnippetId =
                                typeof $scope.znippet.sourceZnippetId != 'undefined' ?
                                    $scope.znippet.sourceZnippetId : null;
                        }
                        return result;
                    };

                    $scope.znippetCopy = angular.copy($scope.znippet);
                    $scope.formDataChanged = false;
                    $scope.$watch('znippet', function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            $scope.formDataChanged = !angular.equals($scope.znippetCopy, $scope.znippet);
                        }
                    }, true);

                    $scope.facetsExpanded = false;

                    $scope.onFacetToggle = function (facetsExpanded) {
                        $scope.facetsExpanded = facetsExpanded;
                    };

                    $scope.$on('tagClick', function (event, params) {
                        $scope.$broadcast('goToFacet', {pack: params.tag.pack});
                    });

                    $scope.saveIfValid = function () {
                        if ($scope.form.$valid) {
                            $scope.save();
                        }
                    };
                }],
            link: function postLink(scope, element, attributes) {

                scope.focusUrlInput = function () {
                    setTimeout(function () {
                        element.find('.thumbnail-url-input').focus();
                    });
                };

                scope.$on('tagCreate', function () {
                    setTimeout(function () {
                        element.find('.simple-tag-input .autocomplete-input').focus();
                    });
                });

                element.bind('keydown', function (event) {
                    if (event.which === $.ui.keyCode.ESCAPE) {
                        scope.cancel();
                    }
                });

                setTimeout(adjustBodyMaxHeight);
                $(window).resize(adjustBodyMaxHeight);
                setTimeout(function () {
                    if (element.find('.link-title').val().length == 0) {
                        element.find('.link-title').focus();
                    } else {
                        element.find('.autocomplete-input').focus();
                    }
                },500);

                function adjustBodyMaxHeight() {
                    $modalBody = element.find('.modal-body');
                    var maxBodyHeight = $(window).height() - 2 * $modalBody[0].getBoundingClientRect().top - 10;
                    maxBodyHeight = Math.max(maxBodyHeight, 0);
                    element.find('.modal-body').css('max-height', maxBodyHeight);
                }

                scope.scrollToTags = function (scrollService) {
                    setTimeout(function () {
                        var $tagInput = element.find('.simple-tag-input');
                        var $container = element.find('.modal-body');
                        scrollService.scrollElementToTop($tagInput, $container, 10);
                    });
                }

                scope.scrollToAdvancedSettings = function (scrollService) {
                    setTimeout(function () {
                        var $tagInput = element.find('.advanced-settings');
                        var $container = element.find('.modal-body');
                        scrollService.scrollElementToTop($tagInput, $container, 10);
                    });
                }
            }
        };

    }];

});
				

