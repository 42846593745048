define('tagpacker-angular/directives/navbar/navbarDirective',['classes/User'], function(User){

	var znippetNavbar = function factory(filterService, modalService, authService, $state, 
			loadingService, alertService, $window, $rootScope, loginService,
			znippetService, analytics, $timeout, navigationService, notificationService, primaryDataService) {
		
		var PACK_IT_BUTTON = 'PACK_IT_BUTTON';
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/navbar/navbarDirective.html',
			replace: true,
			restrict: 'EA',
			transclude: true,
			scope: {
				stubMode: '&',
				logoDisabled: '&'			
			},
			controller: ['$scope', function($scope) {
				var sessionUser = authService.getSessionUser();
				
				$scope.authService = authService;
				$scope.numberOfUnseenLinks = 0;
				$scope.numberOfUnseenLinkTips = 0;
				$scope.$state = $state;
				$scope.sessionUserId = sessionUser == null ? "" : sessionUser.id;
				
				$scope.isLoading = function() {
					return loadingService.isLoadingAnimationVisible();
				};
				
				$scope.isLoggedIn = function() {
					return authService.isLoggedIn();
				};
				
				$scope.isApp = function() {
					return primaryDataService.isApp();
				}
				
				$scope.isOwnCollection = function() {
					return authService.isSessionUserUsername(filterService.getUsername());
				};
				
				$scope.addLink = function() {
					znippetService.addLink().then(function() {
						$rootScope.$broadcast('refreshPage');
						analytics.sendLinkEvent('new link button (navbar)', 'create');
						if(!$scope.isOwnCollection()) {
							alertService.addSuccessAlert('Link successfully saved to <a href="' + $scope.getHomeUrl() + '">your collection</a>.');
						}
					});
				};
				
				$scope.openLoginDialog = function() {
			    	loginService.openLoginDialog('navbar');
			    };
			    
			    $scope.openRegisterDialog = function() {
			    	loginService.openRegisterDialog('navbar');
			    };
			    
			    $scope.$watch(function() { return notificationService.getBadgeNumbers() }, function(newValue, oldValue) {
				 	if(oldValue != newValue) {
				 		$scope.numberOfUnseenLinks = newValue.numberOfUnseenLinks;
				 		$scope.numberOfUnseenLinkTips = newValue.numberOfUnseenLinkTips;
				 	}
				}, true);
				
				$scope.goProfile = function() {
					navigationService.goProfile();
				};
				
				$scope.getHomeUrl = function() {
					return navigationService.getHomeUrl();
				};
				
				$scope.handleLogoClick = function() {
					if(authService.isLoggedIn()) {
						navigationService.goHome();
					} else {
						navigationService.goIndex();
					}
				};
				
				$scope.goHome = function() {
					navigationService.goHome();
				};
				
				$scope.isHome = function() {
					return navigationService.isHome();
				};
				
				$scope.goNetwork = function(element) {
					var label = 'network tab element ' + element + ' ' + $scope.numberOfUnseenLinks  + ' ' + $scope.numberOfUnseenLinkTips + ' logged in: ' + authService.isLoggedIn();
					analytics.sendUserEvent(label, 'click');
					if ($state.is('network.main')) {
						// this is a separate event because it is handled a bit differently (immediate loading animation)
						$rootScope.$broadcast('forceActivitiesRefresh');
					}
					else {
						navigationService.goNetwork();
					}
				};
				
				$scope.logout = function() {
					analytics.sendUserEvent('navbar menu', 'logout');
					window.location = "/logout";
				};
				
				$scope.openBookmarkletDialog = znippetService.openBookmarkletDialog;
				
				$scope.openImportDialog = function() {				
					znippetService.openImportDialog().then(function () {
						$rootScope.$broadcast('refreshPage');
				    });
				};
				
				$scope.hasSidebarToggle = function() {
					return $state.includes('network.main') || $state.includes('user.main.filter') || $state.includes('search');
				};
				
			}],
			link: function postLink(scope, element, attributes) {
				var collapseNavbar = function() {
				 	var $navbar = element.find('.navbar-collapse');
					if ($navbar.hasClass('in')) {
						element.find('.navbar-toggle').click();
					}
				};
				
				element.find('a').click(function(event) {
					collapseNavbar();
				});
			
				$($window).resize(function() {
					// close the menu when it's open
					// This is required to avoid broken state when menu was expanded in mobile mode
					// before screen is made bigger (e.g. tablet is rotated 90 degrees)
					collapseNavbar();
				});
			}
		};
		
	};
	
	znippetNavbar.$inject=['filterService', 'modalService', 'authService', '$state',
	    'loadingService', 'alertService', '$window', '$rootScope', 'loginService',
	    'znippetService', 'analytics', '$timeout', 'navigationService', 'notificationService', 'primaryDataService'];
	
	return znippetNavbar;

});

