define('tagpacker-angular/services/pullToRefresh',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('pullToRefresh', pullToRefresh);

	function pullToRefresh() {
		
				
		var pStart = {x: 0, y:0};
		var pStop = {x:0, y:0};
		var scrollYStart = 0;
		var onRefresh = function() {};
		
		function swipeStart(e) {
			if(typeof e['targetTouches'] !== "undefined"){
		        var touch = e.targetTouches[0];
		        pStart.x = touch.screenX;
		        pStart.y = touch.screenY;
		    } else {
		        pStart.x = e.screenX;
		        pStart.y = e.screenY;
		    }
		    scrollYStart = window.scrollY;
		};
		
		function swipeEnd(e){
		    if(typeof e['changedTouches'] !== "undefined"){
		        var touch = e.changedTouches[0];
		        pStop.x = touch.screenX;
		        pStop.y = touch.screenY;
		    } else {
		        pStop.x = e.screenX;
		        pStop.y = e.screenY;
		    }
		    swipeCheck();
		};
		
		function swipeCheck() {
		    var changeY = pStart.y - pStop.y;
		    var changeX = pStart.x - pStop.x;
		    if(isPullDown(changeY, changeX) && scrollingStartedOnTop()) {
		        onRefresh();
		    }
		}
		
		function scrollingStartedOnTop() {
			return scrollYStart == 0;
		};
		
		function isPullDown(dY, dX) {
		    return dY < 0 && Math.abs(dY) >= 150;
		};
		
		
		var service = {
			init: function(config) {
				onRefresh = config.onRefresh;
				document.addEventListener('touchstart', function(e){ swipeStart(e); }, false);
				document.addEventListener('touchend', function(e){ swipeEnd(e); }, false);
			}
		};
			
		return service;
	
	}

});

