define('tagpacker-angular/controllers/account/ProfileTabController',[], function(){

	return ['$scope', '$http', 'alertService', 'authService', 'userService',
			function($scope, $http, alertService, authService, userService) {
		
		$scope.$watch('user', function(newValue, oldValue) {
			if (newValue != null) {
				$scope.session = {
					name: $scope.user.name,
					description: $scope.user.description,
					username: $scope.user.username
				};
			}
		});
		
		$scope.submitProfile = function(form) {
			userService.update(authService.getSessionUser().id, $scope.session).then(function(){
            	alertService.addSuccessAlert("Saved!", true);
                $scope.$emit('refreshAccount');
            });
		};
		
		$scope.resetUserPhoto = function () {
			$http({
				method: 'POST',
				url: '/resetUserPhoto'
			}).success(function(data, status, headers, config) {
				window.location.reload();
			});
		};
		
	}];
	
});

