define('tagpacker-angular/services/hintService',['classes/Hint', 'tagpackerModule'], function(Hint, tagpackerModule) {

	tagpackerModule.factory('hintService', hintService);

	hintService.$inject = ['$timeout', '$rootScope', '$compile', 'authService', '$http', 'analytics'];
	function hintService($timeout, $rootScope, $compile, authService, $http, analytics) {

		var service = {};
		var welcomeHints = [];
		var otherHints = [];
		var HINT_KEY_TAGPACK_INFO = 'TAGPACK_INFO';

		var remove = function(hint) {
			for(var i=0;i<welcomeHints.length;i++){
				if(welcomeHints[i].key == hint.key){
					 welcomeHints.splice(i, 1);
				}
			}
			for(var i=0;i<otherHints.length;i++){
				if(otherHints[i].key == hint.key){
					otherHints.splice(i, 1);
				}
			}
		}

		var allHintsConfirmed = function() {
			if(welcomeHints.length == 0) {
				return false;
			}
			for (var i=0;i<welcomeHints.length;i++) {
				if(!welcomeHints[i].isConfirmed()) {
					return false;
				}
			}
			return true;
		}

		function areDependenciesMet(hint) {
			var welcomeHintKeys = [];
			for (var i=0;i<welcomeHints.length;i++) {
				welcomeHintKeys.push(welcomeHints[i].key);
			}
			for (var i=0;i<hint.dependencies.length;i++) {
				if (welcomeHintKeys.indexOf(hint.dependencies[i].key) >= 0) {
					return false;
				}
			}
			return true;
		};

		var getNextHints = function() {
			var hintsToBeShown = [];
			for(var i=0;i<welcomeHints.length;i++) {
				var hint = welcomeHints[i];
				if (!hint.isConfirmed() && areDependenciesMet(hint)) {
					hintsToBeShown.push(hint);
				}
			}
			return hintsToBeShown;
		}

		var checkHints = function() {
			var nextHints = getNextHints();
			for (var i=0;i<nextHints.length;i++) {
				nextHints[i].show();
			}
			if(!allHintsConfirmed()){
				$timeout(function(){
					checkHints();
				}, 2000);
			}
		};

		var makeHints = function(data) {
			var result = [];
			for (var i=0;i<data.length;i++) {
				var after = data[i].after ? [data[i].after] : [];
				result.push(new Hint($rootScope, $compile, service, data[i].key, data[i].selector, data[i].message, after));
			}
			return result;
		}

		var loadWelcomeHints = function() {
			return $http({
				method: 'GET',
				url: '/api/users/' + authService.getSessionUser().id + '/hints?group=welcome'
			}).then(function(response) {
				return makeHints(response.data);
			});
		};

		var loadOtherHints = function() {
			return $http({
				method: 'GET',
				url: '/api/users/' + authService.getSessionUser().id + '/hints?group=other'
			}).then(function(response) {
				return makeHints(response.data);
			});
		}

		service.loadHints = function() {
			if (authService.isLoggedIn()) {
				loadWelcomeHints().then(function(result){
					welcomeHints = result;
					return loadOtherHints();
				}).then(function(result){
					otherHints = result;
				});
			}
		};

		service.run = function() {
			checkHints();
		};

		service.confirm = function(hint) {
			if(hint){
				hint.confirmed = true;
				analytics.sendHintEvent(hint.key, 'confirm');
				$http({
					method: 'POST',
					url: '/api/users/' + authService.getSessionUser().id + '/hints/' + hint.key,
					data: {confirmed: hint.confirmed}
				}).success(function(data) {
					remove(hint);
				});
			}
		};

		service.get = function(key) {
			for(var i=0;i<welcomeHints.length;i++){
				if(welcomeHints[i].key == key){
					return welcomeHints[i];
				}
			}
			for(var i=0;i<otherHints.length;i++){
				if(otherHints[i].key == key){
					return otherHints[i];
				}
			}
			return null;
		}

		service.confirmed = function(key) {
			return service.get(key) == null;
		};

		service.confirmTagPackInfo = function() {
			service.confirm(service.get(HINT_KEY_TAGPACK_INFO));
		};

		service.tagPackInfoConfirmed = function() {
			return service.confirmed(HINT_KEY_TAGPACK_INFO);
		};

		return service;

	}

});

