define('tagpacker-angular/directives/linkTip/linkTip',['tagpackerUtils'], function(tagpackerUtils){

	var linkTip = function factory($http, analytics, $rootScope, alertService, authService, znippetService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/linkTip/linkTip.html',
			replace: true,
			restrict: 'E',
			scope: {
				linkTip: '='
			},
			link: function postLink(scope, element, attributes) {
				scope.accept = function () {
					var newLink = znippetService.getNewZnippet();
	    			newLink.title = scope.linkTip.title;
	    			newLink.sourceUrl = scope.linkTip.url;
	    			newLink.sourceZnippetId = scope.linkTip.sourceLinkId;
	    			newLink.description = '';
	    			znippetService.openEditOverlay(newLink).then(function() {
						$http.post('/api/linkTips/' + scope.linkTip.id, { status: "ACCEPTED" }).then(function(){
	    					alertService.addSuccessAlert('Link successfully repacked to '+ '<a href="' + authService.getSessionUser().url + '">your collection</a>.');
	    					analytics.sendLinkTipEvent('accepted ' + scope.linkTip.id, 'accept');
		    				$rootScope.$broadcast('refreshPage');
		    			});
					});
				};
				scope.like = function () {
					$http.post('/api/linkTips/' + scope.linkTip.id, { status: "LIKED" }).then(function(){
	    				alertService.addSuccessAlert('Thanks for your like!');
	    				analytics.sendLinkTipEvent('liked ' + scope.linkTip.id, 'like');
	    				$rootScope.$broadcast('refreshPage');
	    			});
				};
				scope.reject = function () {
					$http.post('/api/linkTips/' + scope.linkTip.id, { status: "REJECTED" }).then(function(){
	    				analytics.sendLinkTipEvent('rejected ' + scope.linkTip.id, 'reject');
	    				$rootScope.$broadcast('refreshPage');
	    			});
				};
		    }
		};
	};
	
	linkTip.$inject=['$http', 'analytics', '$rootScope', 'alertService', 'authService', 'znippetService'];
	
	return linkTip;
	
});
