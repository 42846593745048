define('tagpacker-angular/controllers/welcome/FollowController',['classes/User', 'tagpackerUtils', 'tagpackerModule'],
	   function(User, tagpackerUtils, tagpackerModule)
{
	tagpackerModule.controller('FollowController', FollowController);

	FollowController.$inject=['$scope', 'listLoader', 'authService', 'primaryDataService', 'cookieService', 'followService', 'analytics'];

	function FollowController($scope, listLoader, authService, primaryDataService, cookieService, followService, analytics) {
		
		$scope.primaryDataService = primaryDataService;
		$scope.numberOfFollowing = 3;

		$scope.$on('trigger-next-step', function () {
			$scope.$emit('next-step');
		});
		
		var getUrl = function() {
			var url = '/api/followSuggestions';
			if(cookieService.hasCookie('additionalUser')) {
				var userId = JSON.parse(cookieService.getCookie('additionalUser')).userId;
				url += '?additionalUser=' + userId;	
			}
			return url;
		}
		
		$scope.userList = listLoader.create(getUrl(), {
			chunkSize: 5,
			morphArray: function(oldArray, newArray) {
				newArray = User.constructArray(newArray);
				tagpackerUtils.morphUserArray(oldArray, newArray);
			}
		});
		$scope.userList.load();
		
		$scope.remaining = function () {
			if(authService.getSessionUser()) {
				return $scope.numberOfFollowing -
					authService.getSessionUser().followingIds.length;
			}
		};
		
		$scope.$on('refreshPage', function() {
			$scope.userList.refresh();
		});

	}
	
});

