define('tagpacker-angular/directives/jumpToTopOnInit/jumpToTopOnInit',[], function(){

	var jumpToTopOnInit = function factory(scrollService) {
		
		return {
			restrict: 'A',
			link: function postLink(scope, element, iAttrs) {
				scrollService.jumpToTop();
			}
		};
		
	};
	
	jumpToTopOnInit.$inject=['scrollService'];
	
	return jumpToTopOnInit;

});
