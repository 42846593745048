define('tagpacker-angular/services/scrollService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('scrollService', scrollService);

	scrollService.$inject = [];

	function scrollService() {
		var service = {};
		
		var sidebar = null;
		
		service.registerSidebar = function(s) {
			sidebar = s;
		};
		
		service.scrollToTop = function() {
			if ($('html').scrollTop() > 0 || $('body').scrollTop() > 0) {
				$('html, body').animate({
					scrollTop: 0
				}, 500);
			}
		};
		
		service.jumpToTop = function() {
			$('html, body').scrollTop(0);
		};
		
		service.scrollToFilter = function() {
			var filterOffset = $('.filter').offset();
			if (filterOffset) {
				// #TODO avoid hardcoding this value
				var filterPosition = 85;
				var targetScrollTop = filterOffset.top - filterPosition;
				if ($('html').scrollTop() != targetScrollTop || $('body').scrollTop() != targetScrollTop) {
					$('html, body').animate({
						scrollTop: targetScrollTop
					}, 500);
				}
			} 
		};
		
		function getWindowScrollTop() {
			result =  $('html').scrollTop();
			if ($('body').scrollTop() > result) {
				result = $('body').scrollTop();	
			}
			return result;
		}

		// container can be
		// - undefined for automatic detection via .scrollable-container class
		// - a specific element
		// - or 'body' 
		function getScrollEnvironment(element, container) {
			if (typeof container === 'undefined') {
				container = element.closest('.scrollable-container');
			}

			var r = {
				container: container,
				additionalTopPadding: 0,
				elementTopInScreen: element[0] ? element[0].getBoundingClientRect().top : 0
			}
			if (r.container === 'body' || r.container.length == 0) {
				r.container = $('html,body');
				r.scrollTop = getWindowScrollTop();
				r.containerHeight = $(window).height();
				r.additionalTopPadding = $('.navbar').height();
				r.containerTopInScreen = 0;
			}
			else {
				r.scrollTop = r.container.scrollTop();
				r.containerHeight = r.container.height();
				r.containerTopInScreen = r.container[0].getBoundingClientRect().top;
			}
			return r;
		}
		
		// see getScrollEnvironment for containerParam
		service.scrollToElement = function(element, speed, containerParam) {
			var bottomPadding = 30;
			var env = getScrollEnvironment(element, containerParam);
			var topPadding = 30 + env.additionalTopPadding;			
			var minimumElementTopInScreen = env.containerTopInScreen + topPadding;
			
			if (env.elementTopInScreen < minimumElementTopInScreen) {
				var scrollAdjustment = env.elementTopInScreen - minimumElementTopInScreen;
				env.container.animate({scrollTop : env.scrollTop + scrollAdjustment }, speed);
			}
			else {
				var maximumScrollAdjustment = env.elementTopInScreen - minimumElementTopInScreen;
				var elementBottomInScreen = env.elementTopInScreen + element.height();
				var containerBottomInScreen = env.containerTopInScreen + env.containerHeight;
				var maximumElementBottomInScreen = containerBottomInScreen - bottomPadding;
				if (elementBottomInScreen > maximumElementBottomInScreen) {
					var scrollAdjustment = elementBottomInScreen - maximumElementBottomInScreen;
					if (scrollAdjustment > maximumScrollAdjustment) {
						scrollAdjustment = maximumScrollAdjustment;
					}
					env.container.animate({scrollTop : env.scrollTop + scrollAdjustment }, speed);
				}
			}
		}

		// see getScrollEnvironment for containerParam
		service.scrollElementToTop = function(element, containerParam, topPadding) {
			var env = getScrollEnvironment(element, containerParam);
			var targetElementTopInScreen =
				env.containerTopInScreen + topPadding + env.additionalTopPadding;

			if (env.elementTopInScreen != targetElementTopInScreen) {
				var scrollAdjustment = env.elementTopInScreen - targetElementTopInScreen;
				env.container.animate({scrollTop : env.scrollTop + scrollAdjustment });
			}
		}
		
		service.scrollToPackInSidebar = function(packId) {
			// timeout is needed to delay scrolling until the page is rendered
			setTimeout(function() {
				if (sidebar != null) {
					sidebar.scrollToPack(packId);
				}
			});
		};

		service.scrollToTagInFixedSidebar = function(tagId) {
			// timeout is needed to delay scrolling until the page is rendered
			setTimeout(function() {
				if (sidebar != null) {
					sidebar.scrollToTagIfFixed(tagId);
				}
			});
		};
		
		return service;
		
	}
	
});

