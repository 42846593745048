define('tagpacker-angular/directives/sidebar/sidebarDirective',['tagpackerUtils'], function(tagpackerUtils) {

	var sidebar = function factory($window, scrollService, osDetector, sidebarService) {
		
		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/sidebar/sidebar.html',
			link: function postLink(scope, element, attributes) {
				var $sidebar = element.find('.sidebar');
				
				
				var $sidebarContent = $sidebar.find('.sidebar-content')
				var mc = new Hammer($sidebarContent[0]);
				mc.on("swipeleft", function(ev) {
					scope.$apply(function(){
						sidebarService.close();
					});
				});
				
				scrollService.registerSidebar({
					scrollToTagIfFixed: scrollToTagIfFixed,
					scrollToPack: scrollToPack
				});
				
				scope.isOpen = function() {
					return sidebarService.isOpen();
				};
				
				scope.$watch(function() {
					return sidebarService.isOpen();
				}, function(newValue) {
					//initialization might not be finished at this time
					adaptDimensions();
				});
				
				$(window).resize(function() {
	            	adaptDimensions();
	            	adjustPreserveHeightDivHeight();
	            });
				
				$(window).scroll(function(){
					adaptDimensions();
				});
				
				//wait for rendering
				setTimeout(function(){
					adaptDimensions();
				});
				
				function adjustPreserveHeightDivHeight() {
					var preserveHeightDiv = $sidebar.next('.affix-height-fix');
					if(ResponsiveBootstrapToolkit.is('xs')) {
						preserveHeightDiv.height(0);
					} else {
						preserveHeightDiv.height($sidebar.outerHeight(true));
					}
				}
				
				function getVisibleFooterHeight() {
					if ($('footer').length == 0)
						return 0;
					var windowBottom = $(window).scrollTop() + $(window).height();
					var visiblePortion = windowBottom - $('footer').offset().top; 					
					return visiblePortion > 0 ? visiblePortion : 0;
				}
				
				function adaptDimensions() {
					adaptHeight();
	            	adaptWidth();
	            	$sidebar.find('.sidebar-content').css('padding-bottom', ($(window).height() * 0.7) + 'px');
				}
				
				// not the perfect name. It means it is not in xs mode and snapping to 
				// the top on scrolling
				function isInline() {
					return !ResponsiveBootstrapToolkit.is('xs');
				}
				
				function adaptHeight() {
					if(isInline()) {
						$sidebar.css('height', 'auto');
					}
					else {
						var windowHeight = $(window).height();
						$sidebar.height(windowHeight);
					} 
				}
				
				function adaptWidth() {
	            	if(ResponsiveBootstrapToolkit.is('xs')) {
	            		$sidebar.width(280);
	            	} else {
	            		$sidebar.width($sidebar.parent().width());
	            	}
				}

				function scrollToTagIfFixed(tagId) {
					if ($sidebar.css('position') == 'fixed') {
						scrollToElement($sidebar.find('.tag' + tagId));
					}
				}
		        
				function scrollToPack(packId) {
					scrollToElement($sidebar.find('.pack' + packId));
				}

				function scrollToElement(e) {
					if (isInline()) {
						scrollService.scrollToElement(e, 400, 'body');
					}
					else if ($sidebar.css('position') == 'fixed') {
						scrollService.scrollToElement(e, 400, $sidebar);
					}					
				}
			}
		};
		
	};
	
	sidebar.$inject=['$window', 'scrollService', 'osDetector', 'sidebarService'];
	
	return sidebar;
	
});

