define('tagpacker-angular/controllers/welcome/WelcomePackitController',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.controller('WelcomePackitController', WelcomePackitController);

	WelcomePackitController.$inject=['$scope'];

	function WelcomePackitController($scope) {
		
		$scope.$on('trigger-next-step', function () {
			$scope.$emit('next-step');
		});
	}
	
});

