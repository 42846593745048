define('tagpacker-angular/controllers/PluginController',['tagpackerUtils'], function(tagpackerUtils){
	
	return ['$scope', '$window', 'primaryDataService', 'titleService', 'cookieService',
			'authService', 'analytics', 
		function($scope, $window, primaryDataService, titleService, cookieService,
			 authService, analytics)
	{
		$scope.closed = false;
		
		$scope.betaFeaturesEnabled = authService.areBetaFeaturesEnabled();
		$scope.version = primaryDataService.getActualBookmarkletVersion();
		$scope.isBookmarklet = typeof $scope.version === 'number';
		$scope.isPlugin = primaryDataService.isPlugin();
		$scope.isUserLoggedIn = authService.isLoggedIn();
		
		if($scope.isUserLoggedIn) {
			$scope.link = primaryDataService.getZnippet();
			titleService.setTitle("Save link '" + tagpackerUtils.shorten($scope.link.title, 20) + "'");
			
			if ($scope.link.isNew() && cookieService.hasCookie("lastClickThrough")) {
				var lastClickThrough = JSON.parse(cookieService.getCookie("lastClickThrough"));
				if (lastClickThrough.sourceUrl == $scope.link.sourceUrl) {
					$scope.link.sourceZnippetId = lastClickThrough.id;
				}
			}
		}
		
		$scope.getAnalyticsLabel = function() {
			return $scope.isPlugin ? 'plugin' : 'packit';
		};

		$scope.close = function() {
			// for new version (iframe)
			$window.parent.postMessage('closePackItDialog', '*');
			// for old version (extra browser window)
			$window.close();
		};
		
		$scope.onSave = function() {
			action = $scope.link.isNew() ? 'create' : 'edit';
			analytics.sendLinkEvent($scope.getAnalyticsLabel(), action);
			$scope.close();
		};
		
		$scope.onDelete = function() {
			analytics.sendLinkEvent($scope.getAnalyticsLabel(), 'delete');
			$scope.close();
		};
		
		authService.monitorSessionEventsForPlugin();
		
	}];
		
});

