define('tagpacker-angular/directives/uploadFile/uploadFileDirective',[], function(){

	var uploadFile = function factory(FileUploader, $window, alertService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/uploadFile/uploadFileDirective.html',
			replace: true,
			scope: {
				action: '@',
				buttonName: '@',
				fileValidators: '=',
				onFinish: '&',
				onStarted: '&',
				onFailure: '&'
			},
			controller: ['$scope', function($scope) {
				$scope.$watch('fileValidators', function(oldValue, newValue){
					if (newValue) {
						for(var i=0;i<newValue.length;i++) {
							$scope.uploader.filters.push(newValue[i]);
						}
					}
				});
				
				$scope.uploader = new FileUploader();
				
				$scope.uploader.url = $scope.action;
				$scope.uploader.removeAfterUpload = true;
				
				$scope.uploader.onSuccessItem = function(item, response) {
					if(response.files[0].errorMessage) {
						alertService.addErrorAlert(response.files[0].errorMessage, true);
					} else {
						$scope.onFinish({$file: response.files[0], $item: item});
					}
				};
				
				$scope.uploader.onAfterAddingFile = function (item) {
					item.upload();
				};
				
				$scope.uploader.onBeforeUploadItem = function (item) {
					$scope.onStarted();
				};
				
				$scope.uploader.onErrorItem = function(item) {
					$scope.onFailure(item.file);
					alertService.addErrorAlert('We cannot process your file, please contact us at mail@tagpacker.com', true);
				};
				
				$scope.uploader.onWhenAddingFileFailed = function (item, filter, options) {
					alertService.addErrorAlert(filter.message, true);
				};
				
			}], 
			link: function postLink(scope, element, attributes) {
				
				element.find('.select-file-button').click(function() {
					element.find('input').click();
				});
			}
		};
		
	};
	
	uploadFile.$inject=['FileUploader', '$window', 'alertService'];
	
	return uploadFile;
	
});
