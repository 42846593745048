define('tagpacker-angular/directives/facetList/facetList',['classes/TagPack', 'classes/Challenge', 'tagpackerModule'], function(TagPack, Challenge, tagpackerModule){

	tagpackerModule.directive('facetList', [function() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/facetList/facetList.html',
			replace: true,
			restrict: 'E',
			scope: {
				facets: '=',
				toolbarChallenge: '=',
				numberOfUnusedTags: '&',
				numberOfUsedTags: '&',
				totalFacetListLength: '&',
				owner: '='
			},
			controller: ['$scope', 'mainListService', 'authService', 'filterService', 'tagpackService', 'analytics', 'hintService', 'modalService','challengeService', 'tagService', 'dialogService', '$q', 'alertService', 'loadingService',
	   					 function($scope, mainListService, authService, filterService, tagpackService, analytics, hintService, modalService, challengeService, tagService, dialogService, $q, alertService, loadingService)
			{	
				$scope.alphaFeaturesEnabled = authService.areAlphaFeaturesEnabled();
				
				$scope.tagPackInfoAlreadyDisplayed = function () {
					if (authService.getSessionUser()) {
						return hintService.tagPackInfoConfirmed();
					}
					return false;
				};
				
				$scope.movingFacetsAllowed = function() {
					return mainListService.isFacetsCustomSort() && (mainListService.facetsExpanded() || $scope.totalFacetListLength() == $scope.numberOfPacks());
				};
				
				function array_move(arr, old_index, new_index) {
				    while (old_index < 0) {
				        old_index += arr.length;
				    }
				    while (new_index < 0) {
				        new_index += arr.length;
				    }
				    if (new_index >= arr.length) {
				        var k = new_index - arr.length + 1;
				        while (k--) {
				            arr.push(undefined);
				        }
				    }
				    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
				};
				
				$scope.moveUp = function(facet) {
					var tagPacks = $scope.facets.map(function(facet) { return facet.pack; }).filter(function(pack) { return pack != null; } );
					var oldIndex = tagPacks.indexOf(facet.pack);
					if(oldIndex > 0) {
						var newIndex = oldIndex-1;
						array_move(tagPacks, oldIndex, newIndex);
						tagpackService.setFacetOrder(authService.getSessionUser().id, tagPacks).then(function(){
							mainListService.refresh();
							analytics.sendTagPackEvent('facet moved up', 'move');
						});
					}
				};
				
				$scope.moveDown = function(facet) {
					var tagPacks = $scope.facets.map(function(facet) { return facet.pack; }).filter(function(pack) { return pack != null; } );
					var oldIndex = tagPacks.indexOf(facet.pack);
					if(oldIndex < tagPacks.length - 1) {
						var newIndex = oldIndex+1;
						array_move(tagPacks, oldIndex, newIndex);
						tagpackService.setFacetOrder(authService.getSessionUser().id, tagPacks).then(function(){
							mainListService.refresh();
							analytics.sendTagPackEvent('facet moved down', 'move');
						});
					}
				};
				
				var openNewTagpackDialog = function () {
					tagpackService.openTagPackEditDialog(new TagPack()).then(function(savedPack) {
						mainListService.requirePackMode(savedPack.id, 'complete');
						mainListService.setFocusedPackId(savedPack.id);
						mainListService.requireAllFacets();
						mainListService.refresh();
					}).finally(function() {
						analytics.sendTagPackEvent('facet new tag pack button', 'create');
					});
				};
				
				$scope.showTagPackInfo = function () {
					var alreadyDisplayed = $scope.tagPackInfoAlreadyDisplayed();
					modalService.openBootstrapModal({
						templateUrl:  '/assets/javascripts/tagpacker-angular/dialogs/tagPackInfo.html',
						controller: ['$scope', '$uibModalInstance', function InfoDialogController($scope, $uibModalInstance){
			  				$scope.alreadyDisplayed = alreadyDisplayed;
			  				$scope.close = function(result){
								hintService.confirmTagPackInfo();
								$uibModalInstance.close();
								openNewTagpackDialog();
							};
							$scope.cancel = function () {
								$uibModalInstance.dismiss('cancel');
							};
						}]
					});
				};
				
				$scope.newTagPack = function() {
					if(!$scope.tagPackInfoAlreadyDisplayed()) {
						$scope.showTagPackInfo();
					} else {
						openNewTagpackDialog();
					}
				};
				
				$scope.first = function(index) {
					return index == 0 ? 'first-facet' : '';
				};
				
				$scope.showAll = function() {
					mainListService.requireAllFacets();
					mainListService.refresh();
				};
				
				$scope.showLess = function() {
					mainListService.requireLessFacets();
					mainListService.refresh();
				};
				
				$scope.facetsExpanded = function() {
					return mainListService.facetsExpanded();
				};
				
				$scope.mayEdit = function() {
					return authService.isSessionUserUsername(filterService.getUsername());	
				};
				
				$scope.filterForVisibility = function(visibility) {
					if($scope.isSelected(visibility)) {
						filterService.setVisibility(null);
					} else {
						filterService.setVisibility(visibility)
					}
				};
				
				$scope.getNumberOfPrivateLinks = function() {
					return mainListService.getPrivateContentLength();
				};
				
				$scope.getNumberOfPublicLinks = function() {
					return mainListService.getTotalContentLength()
						- $scope.getNumberOfPrivateLinks();
				};
				
				$scope.isSelected = function(visibility) {
					return visibility === filterService.getVisibility();
				};
				
				$scope.reachability = function() {
					if(filterService.getReachability() == null) {
						return null;
					}
					return filterService.getReachability();
				};
				
				var setReachability = function(reachability) {
					if($scope.reachability() == null) {
						filterService.setReachability(reachability);
					} else {
						filterService.setReachability(null);
					}
				}
				
				$scope.applyBrokenLinksFilter = function() {
					setReachability(false);
				};
				
				$scope.toggleLiveLinksFilter = function() {
					setReachability(true);
				};
				
				$scope.numberOfTags = function() {
					return filterService.getNumberOfTags();
				};
				
				$scope.toggleNumberOfTags = function() {
					if(filterService.getNumberOfTags() == null) {
						filterService.setNumberOfTags(0);
						mainListService.requireAllFacets();
					} else {
						filterService.setNumberOfTags(null);
						mainListService.requireLessFacets();
					}
				};
				
				$scope.getNumberOfBrokenLinks = function() {
					return mainListService.getBrokenContentLength();
				};
				
				$scope.getNumberOfNotTaggedLinks = function() {
					return mainListService.getNotTaggedContentLength();
				};
				
				$scope.getNumberOfLiveLinks = function() {
					return mainListService.getTotalContentLength() - $scope.getNumberOfBrokenLinks();
				};
				
				$scope.hasPacks = function() {
					return $scope.numberOfPacks() > 0;
				};
				
				$scope.numberOfPacks = function() {
					var result = 0;
					$scope.facets.forEach(function(facet) {
						if(facet.isPack()) {
							result++;
						}
					});
					return result;
				};
				
				$scope.numberOfIrrelevantPacks = function() {
					var result = 0;
					$scope.facets.forEach(function(facet) {
						if(facet.isPack() && facet.coverage == 0 && filterService.getExcludedPackId() !== facet.pack.id) {
							result++;
						}
					});
					return result;
				};
				
				$scope.isCustomSort = function() {
					return mainListService.isFacetsCustomSort();
				};
				
				$scope.isAlphapeticallySort = function() {
					return mainListService.isFacetsAlphapeticallySort();
				};
				
				$scope.isFrequencySort = function() {
					return mainListService.isFacetsFrequencySort();
				};
				
				$scope.toggleSort = function () {
                    mainListService.toggleFacetsSort();
                };
				
				$scope.deleteUnusedTags = function() {
					tagService.getByFrequency(authService.getSessionUserId(), 0).then(function(tags) {
						var tagNames = tags.map(function(tag){ return tag.name; }).join(', ');
						dialogService.showDeleteDialog(tags.length + ' tags', 'Unused tags: ' + tagNames).then(function() {
							var promises = [];
							angular.forEach( tags, function(tag){
						        promises.push(tagService.removeTag(tag));
						    });
						    loadingService.showModalLoadingAnimation();
						    $q.all(promises).then(function(){
						    	mainListService.refresh();
						    	alertService.addSuccessAlert(tags.length + " tags deleted");
						    }).finally(function(){
						    	loadingService.hideModalLoadingAnimation();
						    });
						});
					});
				};
				
				$scope.challenge = null;
				var minimumNumberOfTags = 5;
				
				if($scope.mayEdit()) {
					$scope.challenge = new Challenge({
						key: 'TAGPACK_CHALLENGE',
						message: function(){
							return 'Assign ' + (minimumNumberOfTags - $scope.numberOfUsedTags()) + ' more tags to your links';
						},
						name: function(){
							return 'Create tagpacks';
						},
						progress: function(){
							return ($scope.numberOfUsedTags() / minimumNumberOfTags) * 100;
						},
						challengeService: challengeService
					});
				}
				
			}],
			link: function(scope, element, attributes) {
				
			}
		};
	}]);
	
});
	
