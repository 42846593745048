define('tagpacker-angular/directives/facet/facetDirective',['classes/TagPack'], function (TagPack) {

    return ['filterService', 'mainListService', 'znippetService', 'dialogService', 'tagpackService',
        'facetService', 'analytics', 'modalService', 'authService', 'tagService', 'superTagDialogService', 'loadingService',
        function factory(filterService, mainListService, znippetService, dialogService, tagpackService,
                         facetService, analytics, modalService, authService, tagService, superTagDialogService, loadingService) {

            return {
                templateUrl: '/assets/javascripts/tagpacker-angular/directives/facet/facetDirective.html',
                scope: {
                    facet: '=',
                    reload: '&',
                    mayEdit: '&',
                    onMoveUp: '&', 
                    onMoveDown: '&',
                    movingAllowed: '&'
                },
                replace: true,
                restrict: 'E',
                controller: ['$scope', 'adminService', function ($scope, adminService) {
                    $scope.authService = authService;
                    $scope.betaFeaturesEnabled = authService.areBetaFeaturesEnabled();
                    var getPackId = function () {
                        return $scope.facet.pack.id;
                    };
                    
                    $scope.isCombFacet = function () {
                        return filterService.getExcludedPackId() === getPackId();
                    };

                    $scope.isCombActive = function() {
                        return filterService.getExcludedPackId() != null;
                    };

                    $scope.handleOptionClick = function(option) {
                    	var tag = option.tag;
                    	if ($scope.isCombFacet() || filterService.getNumberOfTags() == 0) {
                            tagService.openDragAndDropInfoDialog();
                        } else {
                        	if (filterService.containsFilter(tag)) {
                                filterService.removeTag(tag);
                                mainListService.requirePackMode($scope.facet.pack.id, 'open');
                                analytics.sendTagEvent('facet option deselected', 'unfilter');
                            }
                            else {
                            	mainListService.requireLessFacets();
                                filterService.addTag(tag);
                                mainListService.resetPackMode($scope.facet.pack.id);
                                analytics.sendTagEvent('facet option selected', 'filter');
                            }
                        }
                    };
                    
                    $scope.filterIsEmpty = function() {
                    	return filterService.isEmpty();
                    };

                    $scope.toggleComb = function () {
                        if ($scope.isCombFacet()) {
                            filterService.setExcludedPack(null);
                            analytics.sendTagPackEvent('facet comb button deactivated', 'uncomb');
                        }
                        else {
                            filterService.setExcludedPack($scope.facet.pack);
                            analytics.sendTagPackEvent('facet comb button activated', 'comb');
                        }
                    };

                    $scope.getTotalContentLength = function () {
                        return facetService.getTotalContentLength();
                    };

                    function facetClosed() {
                        return $scope.facet.mode == 'collapsed';
                    }

                    $scope.isSortedByFrequency = function () {
                        return $scope.facet.sort == 'frequency';
                    };
                    
                    $scope.moveUp = function() {
                    	$scope.onMoveUp({$facet: $scope.facet});
                    	analytics.sendTagPackEvent('facet moved down', 'move');
                    };

                    $scope.moveDown = function() {
                    	$scope.onMoveDown({$facet: $scope.facet});
                    	analytics.sendTagPackEvent('facet moved down', 'move');
                    };
                    
                    function toggleSort() {
                    	var packId = getPackId();
                    	if ($scope.isSortedByFrequency()) {
                            mainListService.requireAlphapeticallySort(packId);
                            analytics.sendTagPackEvent('sort alphabetically', 'sort');
                        } else {
                            mainListService.requireFrequencySort(packId);
                            analytics.sendTagPackEvent('sort frequency', 'sort');
                        }
                        $scope.reload();
                    };
                    
                    function isOwnFacet() {
                    	return authService.isLoggedIn() && authService.getSessionUserId() == $scope.facet.pack.ownerId;
                    };
                    
                    $scope.toggleSort = function () {
                        if(isOwnFacet()) {
                        	var sort = $scope.isSortedByFrequency() ? 0 : 1;
                        	tagpackService.setSort($scope.facet.pack, sort).finally(function () {
                        		toggleSort();
                            });
                        } else {
                        	toggleSort();
                        }
                    };

                    $scope.toggle = function () {
                        var packId = getPackId();
                        if (facetClosed()) {
                            analytics.sendTagPackEvent('facet opened', 'open');
                            if ($scope.facet.coverage > 0) {
                                mainListService.requirePackMode(packId, 'open');
                            } else {
                                mainListService.requirePackMode(packId, 'complete');
                            }
                            mainListService.setFocusedPackId(packId);
                        } else {
                            analytics.sendTagPackEvent('facet closed', 'close');
                            mainListService.setFocusedPackId(packId);
                            mainListService.requirePackMode(packId, 'collapsed');
                        }
                        $scope.reload();
                    };

                    $scope.showAll = function () {
                        analytics.sendTagPackEvent('facet show all link', 'open-all');
                        mainListService.requirePackMode(getPackId(), 'complete');
                        $scope.reload();
                    };

                    $scope.showRelevant = function () {
                        analytics.sendTagPackEvent('facet show less link', 'close-less');
                        mainListService.requirePackMode(getPackId(), 'open');
                        mainListService.refresh();
                    };

                    $scope.getDegreeOfCoverage = function () {
                        return ($scope.facet.coverage / $scope.getTotalContentLength()) * 100 + '%';
                    };

                    $scope.getTitle = function () {
                        return $scope.facet.pack.name;
                    };

                    $scope.getTitleTooltip = function () {
                        return $scope.facet.coverage + " of " + $scope.getTotalContentLength()
                            + " links are tagged with '" + $scope.facet.pack.name + "'";
                    };

                    $scope.rename = function () {
                        analytics.sendTagPackEvent('facet rename', 'rename');
                        var copy = new TagPack($.extend(true, {}, $scope.facet.pack));
                        tagpackService.openTagPackEditDialog(copy).then(function () {
                            $scope.reload();
                        });
                    };

                    $scope.remove = function () {
                        var pack = $scope.facet.pack;

                        analytics.sendTagPackEvent('facet delete', 'delete');
                        var note = 'All tags in this pack will be deleted.';
                        dialogService.showDeleteDialog(pack.name, note)
                            .then(function () {
                                loadingService.showModalLoadingAnimation();
                                return tagpackService.removeTagPack(pack).finally(function () {
                                    loadingService.hideModalLoadingAnimation();
                                });
                            })
                            .then(function () {
                                var tags = $scope.facet.options.map(function (x) {
                                    return x.tag
                                });
                                filterService.removeTags(tags);
                                $scope.reload();
                            });
                    };

                    $scope.addTag = function () {
                        var tag = tagService.newTag('', $scope.facet.pack);
                        tagService.openTagEditDialog(tag)
                            .then(function (tag) {
                                return tagService.saveNew(tag);
                            })
                            .then(function () {
                                analytics.sendTagEvent('facet option create', 'create');
                                $scope.showAll();
                            });
                    };

                    $scope.changeColor = function () {
                        analytics.sendTagEvent('facet option color', 'color');
                        var copy = new TagPack($.extend(true, {}, $scope.facet.pack));
                        tagpackService.openChangeFacetColorDialog(copy).then(function () {
                            $scope.reload();
                        });
                    };

                    $scope.getTags = function () {
                        var result = [];
                        for (var i = 0; i < $scope.facet.options.length; i++) {
                            result.push($scope.facet.options[i].tag);
                        }
                        return result;
                    };

                    $scope.tagAll = function () {
                        adminService.openTagAllDialog(filterService.getFilter().withRequiredPack($scope.facet.pack)).then(function () {
                            analytics.sendTagEvent('tag all (facet)', 'tagAll');
                            $scope.reload();
                        });
                    };

                    $scope.setSuperTag = function () {
                        superTagDialogService.open($scope.facet.pack).then(function () {
                            $scope.reload();
                        });
                    };

                }],
                link: function postLink(scope, element, attributes) {
                    element.addClass('pack' + scope.facet.pack.id);

                    var droppableInitialized = false;
                    scope.$on('dragStart', function () {
                        if (!droppableInitialized) {
                            element.droppable({
                                hoverClass: 'droppable-hover',
                                tolerance: 'pointer',
                                accept: function (draggable) {
                                    return draggable.data('tag') ? true : false;
                                },
                                drop: function (event, ui) {
                                    scope.$apply(function () {
                                        var updatedTag = ui.draggable.data('tag').clone();
                                        updatedTag.pack = scope.facet.pack;
                                        tagService.update(updatedTag).then(function () {
                                            scope.reload();
                                        });
                                        analytics.sendTagEvent('facet option dropped on normal facet', 'move');
                                    });
                                }
                            });
                            droppableInitialized = true;
                        }
                    });
                }
            };

        }];

});
