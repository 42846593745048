define('tagpacker-angular/controllers/welcome/VerifyController',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.controller('VerifyController', VerifyController);

	VerifyController.$inject=['$scope', 'authService'];

	function VerifyController($scope, authService) {
			
		$scope.email = authService.getSessionUser().email;

		$scope.$on('trigger-next-step', function () {
			$scope.$emit('next-step');
		});

	}
	
});

