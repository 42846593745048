define('tagpacker-angular/controllers/IndexController',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.controller('IndexController', IndexController);

	IndexController.$inject = ['$scope', 'loginService', '$state'];
	
	function IndexController($scope, loginService, $state) {
		
		$scope.openLoginDialog = function() {
	    	loginService.openLoginDialog('IndexController');
	    };
	    
	    $scope.openRegisterDialog = function() {
	      	loginService.openRegisterDialog('IndexController');
	    };
	    
	    $scope.search = function () {
	    	if ($scope.query && $scope.query!='') {
				$state.go('search.main', {q: $scope.query});
			}
		};
		
		$scope.scrollToNextScreen = function() {
			$('body,html').animate({
				scrollTop: $('.known-from').offset().top
			}, 800, 'easeOutQuad');
		}
	}

});

