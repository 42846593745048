define('tagpacker-angular/services/footerService',['tagpackerModule'], function (tagpackerModule) {

    tagpackerModule.factory('footerService', footerService);

    footerService.$inject = ['$rootScope'];

    function footerService($rootScope) {

        var callbacks = [];

        $(window).scroll(function () {
            var fixed = $(document).scrollTop() > 1500;
            if (fixed) {
                $('body').addClass('with-small-footer');
            } else {
                $('body').removeClass('with-small-footer');
            }
            for (var i = 0; i < callbacks.length; i++) {
                callbacks[i](fixed);
            }
        });

        var service = {};

        service.register = function (callback) {
            callbacks.push(callback);
        };

        return service;

    }

});

