define('tagpacker-angular/controllers/login/LoginController',[], function () {

    var LoginController = function ($scope, loginService, analytics, primaryDataService, recaptcha) {

        $scope.authenticateWithProvider = function (providerKey) {
            loginService.authenticate(providerKey, 'login_provider_' + providerKey);
        };

        $scope.onValidSubmit = function ($event, form) {
            $event.preventDefault();
            recaptcha.execute('login_email').then(function (token) {
                document.getElementById('g-recaptcha-response').value = token;
                form.commit();
            });
            analytics.sendUserEvent('login button', 'login');
        };

        $scope.isApp = function () {
            return primaryDataService.isApp();
        };

    };

    LoginController.$inject = ['$scope', 'loginService', 'analytics', 'primaryDataService', 'recaptcha'];

    return LoginController;

});

