'use strict';
define('tagpacker-angular/directives/link/linkThumbnail',[], function(){
	
	return function factory() {
		return {
			restrict: 'A',
			link : function postLink(scope, element, attributes) {
				scope.$watch(attributes['link'] + '.thumbnailUrl', function() {
					var src;
					var link = scope.$eval(attributes['link']);
					if(link.thumbnailSource == 'AUTO' && link.sourceUrl){
						if(link.sourceUrl.endsWith('.pdf')) {
							src	= '/assets/images/file-extensions/pdf.png';
						} else if(link.sourceUrl.endsWith('.doc') || link.sourceUrl.endsWith('.docx')) {
							src	= '/assets/images/file-extensions/doc.png';	
						} else if(link.sourceUrl.endsWith('.xls') || link.sourceUrl.endsWith('.xlsx')) {
							src	= '/assets/images/file-extensions/xls.png';	
						} else if(link.sourceUrl.endsWith('.ppt') || link.sourceUrl.endsWith('.pptx')) {
							src	= '/assets/images/file-extensions/ppt.png';	
						}
					}
					if(!src){
						src = link.thumbnailUrl ? link.thumbnailUrl : '/assets/images/default-link-thumbnail.png';
					}
					if (element.prop('tagName') == 'IMG') {
						element.attr('src', src);
					}
					else {
						element.css('background-image', "url('" + src + "')");
					}
				});
			}
		};
	};
	
});
