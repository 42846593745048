define('tagpacker-angular/directives/activity/activity',[], function(){

	var activity = function factory(analytics, $rootScope, authService, alertService, navigationService) {
		return {
			scope: {
				link: '='
			},
			restrict: 'E',
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/activity/activity.html',
			link : function postLink(scope, element, iAttrs) {
				scope.handleChange = function(link) {
					$rootScope.$broadcast('refreshPage');
					if (link == null) {
						alertService.addSuccessAlert('Your link has been deleted.');
						analytics.sendLinkEvent('link deleted via repack button in activities', 'delete');
					}
					else {
						alertService.addSuccessAlert('Changes to your link have been saved.');
						analytics.sendLinkEvent('link edited via repack button in activities', 'edit');
					}
				};
				
				scope.handleRepack = function(link) {
					$rootScope.$broadcast('refreshPage');
					analytics.sendLinkEvent('repacked link from activity', 'repack');
					alertService.addSuccessAlert('Link was successfully repacked to '+
							'<a href="' + navigationService.getHomeUrl() + '">your collection</a>.');
				};
			}
		};
	};
	
	activity.$inject=['analytics', '$rootScope', 'authService', 'alertService', 'navigationService'];
	
	return activity;
	
});
