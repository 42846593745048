define('tagpacker-angular/services/analytics',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('analytics', analytics);

	analytics.$inject = ['$location', '$rootScope', 'primaryDataService', 'cookieService', '$window'];

	
	function analytics($location, $rootScope, primaryDataService, cookieService, $window)
	{
		var key = primaryDataService.getGoogleAnalyticsKey();
		var disableStr = primaryDataService.getGoogleAnalyticsDisableString();
		var hostname = window.location.hostname;
		
		var service = {};
	  	
	  	var isEnabled = function () {
	  		return key != null;
	  	};
	  	
	  	service.init = function() {
		  	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
			  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
			
			//optout
			if (cookieService.hasCookie(disableStr)) {
				window[disableStr] = true;
			}
	
			//initialize
			if (isEnabled()) {
				ga('create', key, {
					'cookieDomain': hostname.indexOf('localhost') > -1 ? 'none' : hostname,
					'cookieFlags': 'SameSite=None;Secure'
				});
				var sessionUser = primaryDataService.getSessionUser();
				if (sessionUser) {
					//clarify first: https://www.datenschutz-nord-gruppe.de/fileadmin/datenschutz-nord/media/Downloads/Blog/DUD_2014_03.pdf					
					//ga('set', '&uid', sessionUser.id);
				}
				ga('set', 'anonymizeIp', true);
				$rootScope.$watch(function() { return $location.url() }, function(url) {
				 	sendPageView(url);
				});
			}
		}
		
		service.gaOptout = function () {
			cookieService.setCookie(disableStr);
	  		window[disableStr] = true;
		};
		
		
		service.sendException = function (message) {
			if (isEnabled()) {
				ga('send', 'exception', {
					'exDescription': message,
			    	'exFatal': false
			    });
			}
		};
		
		//categories to use: ad, link, tagpack, tag, welcome, user, packit, feedback
		service.sendEvent = function (category, action, label) {
			if (isEnabled()) {
				if (!action) {
					action = 'click';
				}	
				ga('send', 'event', category, action, label);
			}
		};
		
		service.sendHintEvent = function (label, action) {
			service.sendEvent('hint', action, label);
		};
		
		service.sendChallengeEvent = function (label, action) {
			service.sendEvent('challenge', action, label);
		};
		
		service.sendAdEvent = function (label, action) {
			service.sendEvent('ad', action, label);
		};
		
		service.sendLinkEvent = function (label, action) {
			service.sendEvent('link', action, label);
		};
		
		service.sendLinkTipEvent = function (label, action) {
			service.sendEvent('link tip', action, label);
		};
		
		service.sendTagPackEvent = function (label, action) {
			service.sendEvent('tagpack', action, label);
		};
		
		service.sendTagEvent = function (label, action) {
			service.sendEvent('tag', action, label);
		};
		
		service.sendWelcomeEvent = function (label, action) {
			service.sendEvent('welcome', action, label);
		};
		
		service.sendUserEvent = function (label, action) {
			service.sendEvent('user', action, label);
		};
		
		service.sendChannelEvent = function (label, action) {
			service.sendEvent('channel', action, label);
		};
		
		service.sendUserFollowEvent = function (label, follower, leader) {
			service.sendUserEvent(label, 'follow');
			//clarify how we need to change our privacy policy before sending user ids to Google (http://cutroni.com/blog/2014/04/10/understanding-cross-device-measurement-and-the-user-id/)
			//service.sendUserEvent(label + ' ' + follower.id + ' --> ' + leader.id, 'follow');
		};
		
		service.sendUserUnfollowEvent = function (label, unfollower, leader) {
			service.sendUserEvent(label, 'unfollow');
			//clarify how we need to change our privacy policy before sending user ids to Google (http://cutroni.com/blog/2014/04/10/understanding-cross-device-measurement-and-the-user-id/)
			//service.sendUserEvent(label + ' ' + unfollower.id + ' -/-> ' + leader.id, 'unfollow');
		};
		
		service.sendIndexEvent = function (action, label) {
			service.sendEvent('index', action, label);
		};
		
		service.sendIndexClickEvent = function (label) {
			service.sendIndexEvent('click', label);
		};
		
		function mightBeAuthenticationReferral() {
		    var authTimestamp = $window.localStorage.providerAuthTimestamp;
			return (authTimestamp && (Date.now() - authTimestamp) < 1000 * 60 * 5); 
		}
		
		function sendPageView(url) {
			if (isEnabled()) {
				// using a timeout to give the page controller a chance to set an experiment variation
				// using the native timeout because its sufficient and more efficient
				setTimeout(function() {
					if (mightBeAuthenticationReferral()) {
					   // we don't want to start a new session when the user returns from 
					   // facebook/google authentication
					   ga('set', 'referrer', null);
					}
					ga('send', 'pageview', { 'page': url });
				});
		 	}
		}
	
		return service;
	}

});

