define('tagpacker-angular/controllers/UserController',['classes/User', 'classes/Challenge', 'tagpackerUtils'], function (User, Challenge, tagpackerUtils) {

    return ['$scope', 'filterService', 'znippetService', 'mainListService', 'scrollService',
        'adminService', 'authService', 'analytics', '$rootScope', 'titleService',
        'facetService', 'modalService', 'recommendationService', 'footerService', 'loginService',
        'challengeService', 'shareService', '$timeout', 'userService', 'sortService', 'viewService',
        'navigationService', 'dialogService', 'primaryDataService', 'hintService',
        function ($scope, filterService, znippetService, mainListService, scrollService,
                  adminService, authService, analytics, $rootScope, titleService,
                  facetService, modalService, recommendationService, footerService, loginService,
                  challengeService, shareService, $timeout, userService, sortService, viewService,
                  navigationService, dialogService, primaryDataService, hintService) {

            $scope.links = [];
            $scope.selectedLinks = [];
            $scope.facets = [];
            $scope.owner = null;
            $scope.toolbarChallenge = null;
            $scope.footerFixed = false;
            $scope.beforeFirstLoad = true;
            $scope.totalResultSize = 0;
            $scope.viewService = viewService;

            function getShareUrl() {
                return navigationService.getUserShareUrl();
            };

            function openLinks() {
                return znippetService.getLinks($scope.owner, filterService.getFilter()).then(function (links) {
                    znippetService.openAll(links);
                });
            };

            function getSelectedLinkIds() {
                var result = [];
                for (var i = 0; i < $scope.selectedLinks.length; i++) {
                    result.push($scope.selectedLinks[i].id);
                }
                return result;
            };

            if (authService.isSessionUserUsername(filterService.getUsername())) {
                if (authService.getSessionUser().isCompactLinkView() && !viewService.isCompactLinkView()) {
                    viewService.applyCompactLinkView();
                }
                hintService.loadHints();
            }

            recommendationService.update([]);
            filterService.setPrefixQuery(null);
            footerService.register(function (fixed) {
                $scope.$apply(function () {
                    $scope.footerFixed = fixed;
                });
            });


            function updateSelectedLinkIds(links) {
                var selectedLinkIds = getSelectedLinkIds();
                for (var i = 0; i < links.length; i++) {
                    if ($.inArray(links[i].id, selectedLinkIds) > -1) {
                        links[i].selected = true;
                    }
                }
            };

            mainListService.registerLoadPageCallback(function (data, reason) {
                $scope.owner = data.page.filter.user;
                if ($scope.mayEdit()) {
                    updateSelectedLinkIds(data.page.znippets);
                    analytics.sendUserEvent('viewed own profile ', 'view');
                }
                tagpackerUtils.morphZnippetArray($scope.links, data.page.znippets, !$scope.beforeFirstLoad, reason);
                //remove links with status removed (only required for CSS animation)
                for (var i = $scope.links.length - 1; i >= 0; i--) {
                    if ($scope.links[i].removed === true) {
                        $scope.links.splice(i, 1);
                    }
                }
                $scope.totalResultSize = data.page.totalContentLength;
                $scope.numberOfUnusedTags = data.page.numberOfUnusedTags;
                $scope.numberOfUsedTags = data.page.numberOfUsedTags;
                $scope.beforeFirstLoad = false;

                titleService.setProfileTitleFor(filterService.getFilter());

                // handle scrolling
                if (reason !== 'loadMore' && reason !== 'refresh') {
                    scrollService.scrollToFilter();
                }

                $scope.totalFacetListLength = data.facetList.totalFacetListLength;
                facetService.morphFacets($scope.facets, data.facetList.facets);
                facetService.setTotalContentLength(data.page.totalContentLength);
            });

            filterService.onFilterChange($scope, function (reason) {
                mainListService.handleFilterChange(reason);
            });

            $scope.isFilterEmpty = function () {
                return filterService.isEmpty()
            };

            $scope.getFilter = function () {
                return filterService.getFilter().asPlainObject();
            };

            $scope.getLinkIds = function () {
                return $scope.selectedLinks.map(function(x) { return x.id });
            };

            $scope.addLink = function () {
                znippetService.addLink(filterService.getTags()).then(function () {
                    analytics.sendLinkEvent('new link button ' + filterService.getTagIds(), 'create');
                    $rootScope.$broadcast('refreshPage');
                });
            };

            $scope.openBookmarkletDialog = znippetService.openBookmarkletDialog;

            $scope.registerBannerVisible = function () {
                return !authService.isLoggedIn() && $scope.footerFixed && !viewService.bannerHidden();
            };

            $scope.openRegisterDialog = function () {
                loginService.openRegisterDialog('UserController');
            };

            $scope.closeBanner = function () {
                $scope.openRegisterDialog();
                analytics.sendUserEvent('close banner requested', 'click');
            };

            $scope.openImportDialog = function () {
                znippetService.openImportDialog().then(function () {
                    $rootScope.$broadcast('refreshPage');
                });
            };

            $scope.addTagToAll = function () {
                adminService.openTagAllDialog(filterService.getFilter()).then(function () {
                    analytics.sendTagEvent('tag all', 'tagAll');
                    $rootScope.$broadcast('refreshPage', {reason: 'bulkTagging'});
                });
            };

            $scope.addTagToSelected = function () {
                adminService.openTagLinksDialog($scope.owner, $scope.selectedLinks).then(function () {
                    analytics.sendTagEvent('tag selected', 'tagSelected');
                    $rootScope.$broadcast('refreshPage', {reason: 'bulkTagging'});
                });
            };

            $scope.removeTagFromAll = function () {
                adminService.openUntagAllDialog(filterService.getFilter()).then(function () {
                    analytics.sendTagEvent('untag all', 'untagAll');
                    $rootScope.$broadcast('refreshPage', {reason: 'bulkTagging'});
                });
            };

            $scope.removeTagFromSelected = function () {
                adminService.openUntagLinksDialog($scope.owner, $scope.selectedLinks).then(function () {
                    analytics.sendTagEvent('untag selected', 'untagSelected');
                    $rootScope.$broadcast('refreshPage', {reason: 'bulkTagging'});
                });
            };

            $scope.deleteAll = function () {
                znippetService.removeAll(filterService.getFilter(), $scope.totalResultSize).then(function () {
                    analytics.sendLinkEvent('delete all', 'deleteAll');
                    $rootScope.$broadcast('refreshPage');
                });
            };

            $scope.isApp = function () {
                return primaryDataService.isApp();
            };

            $scope.deleteSelected = function () {
                dialogService.showDeleteDialog($scope.selectedLinks.length + ' Selected Links').then(function () {
                    znippetService.removeLinks($scope.selectedLinks).then(function () {
                        analytics.sendLinkEvent('delete selected', 'deleteSelected');
                        $rootScope.$broadcast('refreshPage', {reason: 'linkDeleted'});
                    });
                });
            };

            $scope.openLinks = function () {
                analytics.sendLinkEvent('open all', 'openAll');
                znippetService.openLinksWithWarning($scope.totalResultSize, openLinks);
            };

            $scope.openSelectedLinks = function () {
                analytics.sendLinkEvent('open selected', 'openSelected');
                znippetService.openLinksWithWarning($scope.selectedLinks.length, function () {
                    znippetService.openAll($scope.selectedLinks);
                });
            };

            $scope.makePublic = function () {
                znippetService.makePublic(filterService.getFilter()).then(function () {
                    analytics.sendLinkEvent('made all public', 'makeAllPublic');
                    $rootScope.$broadcast('refreshPage', {reason: 'linkPrivacyToggled'});
                });
            };

            $scope.makePrivate = function () {
                znippetService.makePrivate(filterService.getFilter()).then(function () {
                    analytics.sendLinkEvent('made all private', 'makeAllPrivate');
                    $rootScope.$broadcast('refreshPage', {reason: 'linkPrivacyToggled'});
                });
            };

            $scope.makeSelectedPublic = function () {
                znippetService.makeLinksPublic($scope.owner, $scope.selectedLinks).then(function () {
                    analytics.sendLinkEvent('made selected public', 'makeSelectedPublic');
                    $rootScope.$broadcast('refreshPage', {reason: 'linkPrivacyToggled'});
                });
            };

            $scope.makeSelectedPrivate = function () {
                znippetService.makeLinksPrivate($scope.owner, $scope.selectedLinks).then(function () {
                    analytics.sendLinkEvent('made selected private', 'makeSelectedPrivate');
                    $rootScope.$broadcast('refreshPage', {reason: 'linkPrivacyToggled'});
                });
            };

            $scope.linksSelected = function () {
                return $scope.selectedLinks.length > 0;
            };

            $scope.numberOfSelectedLinks = function () {
                return $scope.selectedLinks.length;
            };

            $scope.mayEdit = function () {
                return authService.isSessionUserUsername(filterService.getUsername());
            };

            $scope.showingAutocompleteResults = function () {
                return filterService.getPrefixQuery() ? true : false;
            };

            $scope.$on('windowFocus', function () {
                $rootScope.$broadcast('refreshPage', {reason: 'windowFocused'});
            });

            $scope.$on('refreshPage', function () {
                mainListService.refresh();
            });

            $scope.isReloadPossible = function () {
                return !mainListService.isComplete();
            };

            $scope.triggerReload = function () {
                mainListService.loadMore();
            };

            sortService.onSortChange($scope, function () {
                mainListService.handleSortChange();
            });

            $scope.sortByDate = function () {
                sortService.sortByDate();
            };

            $scope.sortByClicks = function () {
                sortService.sortByClicks();
            };

            $scope.sortByTitle = function () {
                sortService.sortByTitle();
            };

            $scope.isSortByDate = function () {
                return sortService.isSortByDate();
            };

            $scope.isSortByClicks = function () {
                return sortService.isSortByClicks();
            };

            $scope.isSortByTitle = function () {
                return sortService.isSortByTitle();
            };

            $scope.isSortASC = function () {
                return sortService.isSortASC();
            };

            $scope.challangeVisible = function () {
                return $scope.mayEdit() && $scope.toolbarChallenge && !$scope.toolbarChallenge.completed();
            };

            $scope.toggleLinkView = function () {
                viewService.toggleLinkView();
            };

            $scope.isCompactLinkView = function () {
                return viewService.isCompactLinkView();
            };

            if ($scope.mayEdit()) {
                var minimumNumberOfLinks = 4;
                $scope.toolbarChallenge = new Challenge({
                    key: 'TOOLBAR_CHALLENGE',
                    message: function () {
                        return $scope.owner ? 'Create or import ' + (minimumNumberOfLinks - $scope.owner.numberOfLinks) + ' more links' : '';
                    },
                    name: function () {
                        return 'Batch editing';
                    },
                    progress: function () {
                        return $scope.owner && ($scope.owner.numberOfLinks / minimumNumberOfLinks) * 100;
                    },
                    challengeService: challengeService
                });
            }

            $scope.showShareOptions = function () {
                if ($scope.isApp()) {
                    window.plugins.socialsharing.share(null, null, null, getShareUrl());
                } else {
                    $timeout(function () {
                        $('#share-submenu').trigger('click.bs.dropdown');
                    });
                }
            };

            $scope.twitterShare = function () {
                var text = titleService.getProfileTitleFor(filterService.getFilter());
                return shareService.twitterShare(getShareUrl(), text);
            };

            $scope.facebookShare = function () {
                return shareService.facebookShare(getShareUrl());
            };

            $scope.tumblrShare = function () {
                return shareService.tumblrShare(getShareUrl());
            };

            $scope.googleShare = function () {
                return shareService.googleShare(getShareUrl());
            };

            $scope.emailShare = function () {
                var subject = titleService.getProfileTitleFor(filterService.getFilter());
                return shareService.emailShare(getShareUrl(), subject);
            };


            $scope.trackShareClick = function (service) {
                analytics.sendLinkEvent(service + ' share link list button clicked ' + getShareUrl(), 'share');
            };

            $scope.openEmbedDialog = function () {
                modalService.openBootstrapModal({
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/embedDialog.html',
                    controller: ['$scope', '$uibModalInstance', '$sce', 'navigationService', 'filterService', 'viewService',
                        function ($scope, $uibModalInstance, $sce, navigationService, filterService, viewService) {
                            $scope.numbersOnly = new RegExp('^\d+$');
                            $scope.code = null;
                            $scope.safeCode = null;
                            $scope.width = 500;
                            $scope.height = 760;

                            $scope.url = function () {
                                return navigationService.getEmbedUrl(filterService.getUsername(), $scope.withImage, $scope.withDescription, $scope.withTags);
                            };

                            $scope.getCode = function () {
                                return '<iframe src="' + $scope.url() + '"' +
                                    ' width="' + $scope.width + 'px"' +
                                    ' height="' + $scope.height + 'px"' +
                                    ' scrolling="auto"' +
                                    ' frameborder="0">' +
                                    '</iframe>';
                            };

                            $scope.$watch('getCode()', function (newValue) {
                                if ($scope.form.$valid) {
                                    $scope.code = newValue;
                                    $scope.safeCode = $sce.trustAsHtml(newValue);
                                }
                            });

                            $scope.withImage = viewService.isLinkWithImage();
                            $scope.withDescription = viewService.isLinkWithDescription();
                            $scope.withTags = viewService.isLinkWithTags();

                            $scope.onTextClick = function ($event) {
                                $event.target.select();
                            };

                            $scope.cancel = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }],
                    windowClass: 'embed-overlay'
                });
                analytics.sendLinkEvent('embed link list button clicked ' + getShareUrl(), 'share');
            };

        }];

});

