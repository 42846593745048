define('tagpacker-angular/directives/analytics/optOutDirective',[], function(){

	var optOut = function factory(analytics, alertService) {
		
		return {
			restrict: 'E',
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/analytics/optOutDirective.html',
			replace: true,
			controller: ['$scope', function($scope) {
				$scope.disableAnalytics = function() {
					analytics.gaOptout();
					alertService.addSuccessAlert('Google Analytics is disabled now.');
				};
			}]
		};
		
	};

	optOut.$inject=['analytics', 'alertService'];
	
	return optOut;
	
});
