define('tagpacker-angular/controllers/MainController',[], function() {
	
	return ['$scope', 'authService', '$state', 'primaryDataService', '$window', 
	        function ($scope, authService, $state, primaryDataService, $window) {
		
		$scope.authService = authService;
		$scope.$state = $state;
		$scope.logline = primaryDataService.getLogline();
		$scope.chromeWebstoreItemUrl = primaryDataService.getChromeWebstoreItemUrl();
		$scope.firefoxWebstoreItemUrl = primaryDataService.getFirefoxWebstoreItemUrl();
		$scope.edgeWebstoreItemUrl = primaryDataService.getEdgeWebstoreItemUrl();
		
		authService.monitorSessionEventsForPlugin();
		
	}];

});

