define('tagpacker-angular/directives/activity/stub',[], function(){

	return function factory() {
		return {
			scope: {
				stub: '='
			},
			restrict: 'E',
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/activity/stub.html'
		};
	};
	
});
