define('tagpacker-angular/controllers/lockedUsers/LockedUsersController',[], function(){

	return ['$scope', '$http', '$state', function($scope, $http, $state) {
		
		$scope.users = [];
		
		$http.get('/api/users/locked').success(function(data) {
			$scope.users = data;
		});
		
		$scope.unlock = function(user) {
			$http.post('/api/users/'+ user.id +'/unlock').then(function() {
				$state.go('user.main.filter', {username:user.username});
			});
		}
	}];
	
});
