define('tagpackerUtils',['classes/Znippet'], function(Znippet){
	
	var utils = {};
	
	utils.isBlank = function (string) {
		return (!string || /^\s*$/.test(string));
	};
	
	utils.setCaretToPos = function (input, pos) {
		if (input.setSelectionRange) {
			input.focus();
			input.setSelectionRange(pos, pos);
			input.focus();
		}
		else if (input.createTextRange) {
		/* don't know which browser this is for. I think old IE */
		var range = input.createTextRange();
		range.collapse(true);
		range.moveEnd('character', pos);
		range.moveStart('character', pos);
			range.select();
		}
	};
	
	utils.isValidUrl = function(url) {
		try {
			new URL(url);
		    return true;
		} catch (_) {}
		return false;
	};
	
	utils.morphTagArray = function(oldArray, newArray) {
		var parameters = { 
			checkIdentity: function(oldTag, newTag) {
				return oldTag.id == newTag.id;
			},		
			update: function(oldTag, newTag) {
				oldTag.update(newTag);
			}
		};
		utils.morphArray(oldArray, newArray, parameters);
	};
	
	utils.morphFacetOptionArray = function(oldArray, newArray) {
		var parameters = { 
			checkIdentity: function(oldOption, newOption) {
				return oldOption.tag.id == newOption.tag.id;
			},		
			update: function(oldOption, newOption) {
				oldOption.update(newOption);
			}
		};
		utils.morphArray(oldArray, newArray, parameters);
	};
	
	utils.morphUserArray = function(oldArray, newArray) {
		var parameters = { 
			checkIdentity: function(oldUser, newUser) {
				return oldUser.id == newUser.id;
			},		
			update: function(oldUser, newUser) {
				oldUser.update(newUser);
			}
		};
		utils.morphArray(oldArray, newArray, parameters);
	};
	
	utils.morphZnippetArray = function(oldArray, newArray, twoStageMorph, mode) {
		var parameters = { 
			checkIdentity: function(oldZnippet, newZnippet) {
				return oldZnippet.id == newZnippet.id;
			},		
			update: function(oldZnippet, newZnippet) {
				oldZnippet.update(newZnippet);
			},
			mark: null,
			isMarked: null
		};
		
		if (twoStageMorph) {
			parameters.mark = function(znippet) {
				znippet.removed = true;
				znippet.added = false;
			};
			parameters.isMarked = function(znippet) {
				return znippet.removed === true;
			};
			parameters.mode = mode ? mode : 'widen';
			// do not animate new znippets at the bottom
			if (parameters.mode !== 'loadMore') {
				parameters.markNew = function(znippet) {
					znippet.added = true;
					znippet.removed = false;
				}
			}
		}
		
		utils.morphArray(oldArray, newArray, parameters);
	};
	
	var removeMarkedElements = function(array, isMarked) {
		var index = 0;
		while (index < array.length) {
			if (isMarked(array[index])) {
				array.splice(index, 1);
			}
			else {
				index++;
			}
		}
	};
	
	var getNextMatch = function(array1, offset1, array2, offset2, parameters) {
		var result = null;
		
		for (var i=offset1 ; i < array1.length && result == null ; i++) {
			if (!parameters.isMarked || !parameters.isMarked(array1[i])) {
				for (var j=offset2 ; j < array2.length && result == null ; j++) {
					if (parameters.checkIdentity(array1[i], array2[j])) {
						result = {
							index1: i,
							index2: j
						};
					}					
				}
			}
		}
		
		return result;
	};
	
	var insertNewElements = function(oldArray, oldOffset, newArray, newOffset, newLength, parameters) {
		for (var i=0 ; i<newLength ; i++) {
			var newElement = newArray[newOffset + i];
			if (parameters.markNew) {
				parameters.markNew(newElement);
			}
			oldArray.splice(oldOffset+i, 0, newElement);
		}
	};
	
	var exchangeArrayPortion = function(oldArray, oldOffset, oldLength,
			newArray, newOffset, newLength, parameters) {
		if (parameters.mode !== 'narrow') {
			insertNewElements(oldArray, oldOffset, newArray, newOffset, newLength, parameters);
			oldOffset+= newLength;
		}
			
		if (parameters.mark) {
			for (var i=0 ; i<oldLength ; i++) {
				parameters.mark(oldArray[oldOffset + i]);
			}
			oldOffset += oldLength;
		}
		else {
			oldArray.splice(oldOffset, oldLength);
		}
		
		if (parameters.mode === 'narrow') {
			insertNewElements(oldArray, oldOffset, newArray, newOffset, newLength, parameters);
		}
	}
	
	utils.morphArray = function(oldArray, newArray, parameters) {
		parameters = parameters ? parameters : {}
		parameters.isMarked = typeof parameters.isMarked !== 'undefined' ? 
			parameters.isMarked : null;
		parameters.mark = typeof parameters.mark !== 'undefined' ? 
			parameters.mark : null;
			
		if (parameters.isMarked) {
			removeMarkedElements(oldArray, parameters.isMarked);
		}	
	
		// offsets denote how many items have already been processed (not taken into account anymore) 
		var oldOffset = 0;
		var newOffset = 0;
		
		while (oldOffset < oldArray.length || newOffset < newArray.length) {
			var match = getNextMatch(oldArray, oldOffset, newArray, newOffset, parameters);
			
			if (match != null) {
				var oldDistance = match.index1 - oldOffset;
				var newDistance = match.index2 - newOffset;
				
				exchangeArrayPortion(oldArray, oldOffset, oldDistance,
					newArray, newOffset, newDistance, parameters);
					
				oldOffset += newDistance;
				if (parameters.mark) {
					oldOffset += oldDistance;
				}
				newOffset += newDistance;
				
				parameters.update(oldArray[oldOffset], newArray[newOffset]);
				oldOffset++;
				newOffset++;
			}
			else {
				exchangeArrayPortion(oldArray, oldOffset, oldArray.length - oldOffset,
					newArray, newOffset, newArray.length - newOffset, parameters);
				oldOffset = oldArray.length;
				newOffset = newArray.length;
			}
		}
	};
	
	// see http://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
	utils.escapeRegexp = function(string) {
		return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	};
	
	utils.getURLParameter = function(parameterName, url) {
		return decodeURI(
			(RegExp(parameterName + '=' + '(.+?)(&|$)').exec(url)||[,null])[1]
		);
	};

	utils.parseUrl = function (url) {
		var a = document.createElement('a');
		a.href = url;
		return a;
	};
	
	utils.shorten = function(input, length) {
		var result = input == null ? "" : input;
		
		if (result.length > length) {
			result = result.substr(0, length - 3) + "...";
		}
		
		return result;
	};

	utils.createFileSizeFilter = function (sizeInBytes) {
		return {
	        name: 'maxFileSize',
	        fn: function(item) {
	            return item.size < sizeInBytes;
	        },
	        message: "Max. " + sizeInBytes / 1000 + "KB allowed." 
	    };
	};
	
	utils.createFileTypeFilter = function (fileExtensionsAsArray) {
		return {
	    	name: 'fileType',
	        fn: function(item) {
	            var regExString = '(\.|\/)(' + fileExtensionsAsArray.join('|') + ')$';
	            return RegExp(regExString, 'i').exec(item.name) != null;
	        },
	        message: "Invalid file type. Your filename must end with: " + fileExtensionsAsArray.join(', ')
	    }
	};
	
	utils.encodeHtmlSpecialChars = function(text) {
		var el = document.createElement("div");
		el.textContent = text;
		return el.innerHTML;
	};
	
	utils.createHtmlLinebreaks = function(text) {
		return text.replace(/\r?\n/g, '<br />');
	};
	
	utils.fixSemicolons = function(url) {
		var a = document.createElement('a');
		a.href = url;
		if(a.search.length > 1) {
			var params = a.search.substring(1).split('&');
			for (var i=0; i<params.length; i++) {
				var param = params[i].split('=');
				if(param[1]) {
					params[i] = [param[0], param[1].split(';').join('%3B')].join('=');
				}
			}
			url = url.substring(0, url.lastIndexOf('?')) + '?' + params.join('&');
        }
        return url;
	};
	
	utils.inIframe = function() {
	    try {
	        return window.self !== window.top;
	    } catch (e) {
	        return true;
	    }
	};
	
	return utils;

});
