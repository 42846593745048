'use strict';
define('tagpacker-angular/services/channelService',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.factory('channelService', channelService);

	channelService.$inject = ['$state', 'authService', '$http'];

	function channelService($state, authService, $http) {
		var service = {};
				
		service.getChannelName = function() {
			return typeof $state.params.channel === 'undefined' ? null : $state.params.channel;
		};
		
		service.open = function(channel) {
			$state.go('network.main.channel', {channel: channel.name});
		};
		
		service.channelSelected = function() {
			return service.getChannelName() != null;
		};
		
		service.update = function(channel) {
			return $http.put('/api/users/' + authService.getSessionUser().id + '/channels/' + encodeURIComponent(channel.name), channel);
		};
		
		service.delete = function(channel) {
			return $http.delete('/api/users/' + authService.getSessionUser().id + '/channels/' + encodeURIComponent(channel.name));
		};
		
		service.getAll = function() {
			return $http.get('/api/users/' + authService.getSessionUser().id + '/channels').then(function(response){
				return response.data;
			});
		};
		
		return service;
	}

});
