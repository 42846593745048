define('tagpacker-angular/controllers/login/ResetPasswordController',[], function(){

	var ResetPasswordController = function($scope, $stateParams) {
		
		$scope.$stateParams = $stateParams;
	
	};
	
	ResetPasswordController.$inject=['$scope', '$stateParams'];
	
	return ResetPasswordController;
	
});
