define('classes/Tag',['classes/TagPack'], function(TagPack){ 

	function Tag(tag) {
		if (tag) {
			this.update(tag);
		}
		else {
			this.id = null;
			this.name = '';
			this.excluded = false;
			this.pack = null;
		}
	}

	Tag.prototype.clone = function() {
		return new Tag(this);
	};

	Tag.prototype.update = function(otherTag) {
		this.id = otherTag.id;
		this.name = otherTag.name;
		this.url = otherTag.url;
		this.ownerId = otherTag.ownerId;
		this.excluded = typeof otherTag.excluded === "boolean" ? otherTag.excluded : false;
		this.pack = otherTag.pack;
		if (this.pack && !(this.pack instanceof TagPack)) {
			this.pack = new TagPack(this.pack);
		}
	};

	function arePacksEqual(tag1, tag2) {
		if (tag1.pack !== null && tag2.pack !== null)
			return tag1.pack.equals(tag2.pack);
		else 
			return tag1.pack == null && tag2.pack == null;
	}
	
	Tag.prototype.equals = function(otherTag) {
		if (this.id !== null && otherTag.id !== null)
			return this.id === otherTag.id;
		else 
			return this.name === otherTag.name && arePacksEqual(this, otherTag);
	};

	Tag.prototype.isNew = function() {
		return this.id == null;
	};
	
	Tag.prototype.getColor = function() {
		return this.pack == null ? 0 : this.pack.color;
	};

	Tag.constructArray = function(tags) {
		return tags.map(function(x) { return new Tag(x); });
	}
	
	return Tag;

});

