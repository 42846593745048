define('tagpacker-angular/services/inviteFriendsDialogService',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.factory('inviteFriendsDialogService', inviteFriendsDialogService);

	inviteFriendsDialogService.$inject = ['modalService'];

	function inviteFriendsDialogService(modalService)
	{
		return {
			open: function(owner) {
				modalService.openBootstrapModal({
			    	templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/inviteFriendsDialog.html',
			    	controller: controller,
			    	resolve: {
			    		owner: function() { 
			    			return owner;
			    		}
			    	}
				});
			}
		};
	}

	controller.$inject = ['$scope', '$uibModalInstance', 'owner', 'alertService', '$http'];

	function controller($scope, $uibModalInstance, owner, alertService, $http) {
		$scope.messageMaxLength = 500;
		$scope.owner = owner;
		$scope.session =  {
			email: '',
			message: 'Hi,\n\n' + 
					'How are you today?\n' + 
					'I\'d like to invite you to Tagpacker, a free tool to organize, find and share links.\n' +
					'Let\'s pack links together!\n\n' +
					'Cheers,' + 
					'\n' + $scope.owner.name 
		};
		$scope.save = function (form) {
			return $http({
				method: 'POST',
				url: '/sendEmailInvitation',
				data: {
					email: $scope.session.email, 
					message: $scope.session.message
				}
			}).then(function() {
				alertService.addSuccessAlert('Invitation sent to \'' + $scope.session.email + '\'.', true);
				$scope.session.email =  "";
    			form.$setPristine();
			}, function(response) {
				if (response.data.code == 'INVITEE_ALREADY_REGISTERED') {
					alertService.addInfoAlert(response.data.message);
					response.preventDefault();
				}
			});
		};
		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};
	}

});

