define('tagpacker-angular/directives/scrollToTop/scrollToTopDirective',[], function(){

	return function factory() {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/scrollToTop/scrollToTopDirective.html',
			replace: true,
			scope: true,
			restrict: 'E',
			link: function postLink(scope, element, iAttrs) {
				scope.isVisible = shouldItBeVisible();
				
				function shouldItBeVisible() {
					return $(window).scrollTop() > 0;
				}
				
				var checkVisibility = function() {
					if (scope.isVisible != shouldItBeVisible()) {
						scope.$apply(function() {
							scope.isVisible = shouldItBeVisible();		
						});
					}
				};
				
				$(window).scroll(function(){
					checkVisibility();
			    });
				
				scope.scrollToTop = function() {
					$('html, body').animate({
						scrollTop: 0
					}, 500);
				};
			}
		};
		
	};

});
