define('classes/AutocompleteEntry',['classes/Znippet', 'classes/Tag', 'classes/TagPack', 'classes/User'], function(Znippet, Tag, TagPack, User){
	
	function AutocompleteEntry(entry) {
		this.name = entry.name;
		this.type = entry.type;
		this.disambiguation = entry.disambiguation;
		
		switch (this.type) {
			case 'znippet': 
				this.item = new Znippet(entry.item);
				break;
			case 'tag': 
				this.item = new Tag(entry.item);
				break;
			case 'tagPack': 
				this.item = new TagPack(entry.item);
				break;
			case 'user': 
				this.item = new User(entry.item);
				break;
		}
	}
	
	return AutocompleteEntry;

});
