define('tagpacker-angular/directives/filter/filterDirective',['classes/Tag', 'classes/TagPack', 'classes/Znippet', 'classes/AutocompleteEntry'],
		function(Tag, TagPack, Znippet, AutocompleteEntry) {

	var tagpackerFilter = function factory(filterService, mainListService, analytics, tagService, authService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/filter/filterDirective.html',
			replace: true,
			scope: {
				user: '='
			},
			link: function postLink(scope, element, iAttrs) {
				scope.hasFocus = false;
				scope.tags = [];
				scope.resultContainer = element.find('.result-container');
				scope.prefixQuery = filterService.getPrefixQuery();
				
				scope.$watch(function() { return filterService.getTags() }, function (newValue) {
					scope.tags = newValue;
					scope.prefixQuery = '';
				}, true);
				
				scope.fakeInput = element.find('.fake-input');
				
				function updatePlaceholder() {
					var input = element.find('.inline-autocomplete');
					input.attr('placeholder', getPlaceholder(mainListService.getTotalContentLength()));
				}
				
				function getPlaceholder(numberOfLinks) {
					if (!filterService.isEmpty()) return '';
					
					if (authService.isSessionUserUsername(filterService.getUsername()))
						return 'Search all your links';
					else
						return 'Search all links';
				}
				
				scope.$watch(function() { return filterService.isEmpty() }, function() {
					updatePlaceholder();
				});
				
				scope.$watch(function() {return mainListService.getTotalContentLength()}, function() {
					updatePlaceholder();
				});
				
				scope.removeExcludedPack = function() {
					filterService.setExcludedPack(null);
				};
				
				scope.removeXClicked = function(tag) {
					filterService.removeTag(tag);
					if (tag.pack) {
						mainListService.requirePackMode(tag.pack.id, 'open');
					}
					analytics.sendTagEvent('tag unselected in filter', 'unfilter');
				};
				
				scope.clearFilter = function() {
					filterService.clear();
					scope.prefixQuery = '';
					analytics.sendTagEvent('all tags unselected in filter', 'unfilter');
				};
				
				scope.handleSelect = function(tag) {
					filterService.addTag(tag);
					analytics.sendTagEvent('tag selected in filter', 'filter');
				};
				
				scope.setQuery = function(q) {
					filterService.setQuery(q);
				};
				
				scope.removeLastFilter = function() {
					if (scope.getNumberOfTags() != null) {
						scope.clearNumberOfTags();
					} else if (scope.getReachability() != null) {
						scope.clearReachability();
					} else if (scope.getVisibility()) {
						scope.clearVisibility();
					} else if (scope.getQuery() != null) {
						filterService.setQuery(null);
					} else if (scope.getExcludedPack() != null) {
						scope.removeExcludedPack();
					}  else if (scope.tags.length > 0) {
						filterService.removeTag(scope.tags[scope.tags.length-1]);
					}
				}; 
				
				scope.navigate = function(tag) {
					filterService.clear();
					filterService.setTags([tag]);
				};
				
				scope.toggleExclude = function(tag) {
					filterService.toggleExclude(tag);
					analytics.sendTagEvent('filter tag excluded', 'excluded');
				};
	
				scope.getExcludedPack = function() {
		    		return filterService.getExcludedPack();
		    	};
		    	
		    	scope.getQuery= function() {
		    		return filterService.getQuery();
		    	};
		    	
		    	scope.filterIsEmpty = function () {
		    		return filterService.isEmpty();
		    	};
		    	
		    	scope.$watch('prefixQuery', function(newValue){
					if(!newValue) {
						filterService.setPrefixQuery(null);
					}
				});
		    	
		    	function makeAutocompleteEntry(tag) {
		    		return new AutocompleteEntry({
	    				name: tag.name,
	    				type: 'tag',
	    				item: tag,
	    				disambiguation: ''
	    			});
		    	}
		    	
		    	scope.findTagsAndLinks = function(query) {
		    		filterService.setPrefixQuery(query);
		    		return tagService.getTagsForPrefix(query, scope.user.id, 4).then(function(tags) {
		    			return tags.map(function(t) { return makeAutocompleteEntry(t) });
		    		});
				};
				
				scope.getVisibility = function () {
					return filterService.getVisibility();
				};
				
				scope.clearVisibility = function () {
					filterService.setVisibility(null);
				};
				
				scope.getReachability = function () {
					return filterService.getReachability();
				};
				
				scope.clearReachability = function () {
					return filterService.setReachability(null);
				};
				
				scope.getNumberOfTags = function () {
					return filterService.getNumberOfTags();
				};
				
				scope.clearNumberOfTags = function () {
					return filterService.setNumberOfTags(null);
				};
				
				scope.isOwnCollection = function() {
					return authService.isSessionUserUsername(filterService.getUsername());
				};
				
				element.find('.tag-list').click(function(e) { 
					// we don't focus if a tag is removed or the filter is cleared; otherwise the keyboard is opened on mobile devices which is quite annoying 
					if(e.target == this) {
						element.find('input').focus();
					}
				});
				
			}
		};
		
	};
	
	tagpackerFilter.$inject=['filterService', 'mainListService', 'analytics', 'tagService', 'authService'];
	
	return tagpackerFilter;

});

