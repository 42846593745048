define('tagpacker-angular/services/browserDetector',['tagpackerModule'], function (tagpackerModule) {

    tagpackerModule.factory('browserDetector', browserDetector);

    browserDetector.$inject = ['$window', 'primaryDataService', '$q'];

    function browserDetector($window, primaryDataService, $q) {

        var browserName = getBrowserName();

        var isEdge = function () {
            return getBrowserName() == 'Edge';
        };

        var isChrome = function () {
            return getBrowserName() == 'Chrome';
        };

        var isFirefox = function () {
            return getBrowserName() == 'Firefox';
        };

        var isSafari = function () {
            return getBrowserName() == 'Safari';
        };

        return {
            getBrowserName: function () {
                return browserName;
            },
            isEdge: isEdge,
            isChrome: isChrome,
            isFirefox: isFirefox,
            isSafari: isSafari
        };

        function getBrowserName() {
            // check functions see http://stackoverflow.com/questions/9847580
            var browserChecks = {
                'Firefox': function () {
                    return typeof InstallTrigger !== 'undefined';
                },
                'Edge': function () {
                    return browserChecks['Chrome']() && (navigator.userAgent.indexOf("Edg") != -1);
                },
                'Chrome': function () {
                    return !!$window.chrome && !browserChecks['Opera']();
                },
                'Opera': function () {
                    return !!$window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                },
                'Safari': function () {
                    return /constructor/i.test($window.HTMLElement) || (function (p) {
                        return p.toString() === "[object SafariRemoteNotification]";
                    })(!$window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
                },
                'IE': function () {
                    return /*@cc_on!@*/false || !!document.documentMode;
                }
            };

            for (var name in browserChecks) {
                if (browserChecks[name]()) {
                    return name;
                }
            }

            return 'unknown';
        };
    }

});

