define('tagpacker-angular/services/tagService',['classes/Tag', 'classes/TagPack', 'tagpackerModule'], function(Tag, TagPack, tagpackerModule){

	tagpackerModule.factory('tagService', tagService);

	tagService.$inject = ['$http', 'modalService'];
	
	function tagService($http, modalService)
	{
		function tagsBaseUrlFor(userId) {
			return '/api/users/' + userId + '/tags';
		}

		function loadTags(url) {
			return $http.get(url).then(function(response) {
				return Tag.constructArray(response.data);
			});
		}
		
		var self = {
			
			newTag: function(name, ownerIdOrPack) {
				var result = new Tag();
				result.name = name;
				if (ownerIdOrPack instanceof TagPack) {
					var pack = ownerIdOrPack;
					result.pack = pack;
					result.ownerId = pack.ownerId;
				}
				else {
					result.pack = null;
					result.ownerId = ownerIdOrPack;
				}
				return result;
			},
		
			saveNew: function(tag) {
				var data = {
					name: tag.name,
				};
				data.pack = tag.pack ? { id: tag.pack.id } : undefined;
				return $http.post('/api/users/' + tag.ownerId + '/tags', data)
					.then(function(response) {
						return new Tag(response.data);
					});
			},

			update: function(tag) {
				var data = { name: tag.name	};
				data.pack = tag.pack ? { id: tag.pack.id } : null;
				return $http.put('/api/users/' + tag.ownerId + '/tags/' + tag.id, data)
					.then(function(response) {
						return new Tag(response.data);
					});
			},
			
			getTagsForPack: function(pack) {
				var url = tagsBaseUrlFor(pack.ownerId) + '?packId=' + pack.id + '&order=abc';
				return loadTags(url);
			},

			getTagsWithoutPack: function(userId) {
				var url = tagsBaseUrlFor(userId) + '?packId=&order=abc';
				return loadTags(url);
			},
			
			getTagsForName: function(userId, name) {
				var url = tagsBaseUrlFor(userId) +'?name=' + encodeURIComponent(name);
				return loadTags(url);
			},
			
			getTagsForPrefix: function(query, userId, limit) {
				var prefixes = [];
				query.trim().split(' ').forEach(function(prefix) {
					if (prefix.length > 0) {
						prefixes.push(prefix);
					}
				});
				return $http.get(tagsBaseUrlFor(userId), {
					params: { prefix: prefixes , limit: limit }
				}).then(function(response) {
					return Tag.constructArray(response.data);
				});
			},
			
			openTagEditDialog: function(tag, withMergeOption) {
				return modalService.openBootstrapModal({
			    	backdrop: 'static',
			    	templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/tagRenameDialog.html',
			    	controller: ['$scope', '$uibModalInstance', 'tag', 
			    	        function TagEditDialogController($scope, $uibModalInstance, tag) {
			    		$scope.tag = tag;
			    		$scope.withMergeOption = withMergeOption;
			    		$scope.save = function(form) {
							if (form.$valid) {
			    				$uibModalInstance.close(tag);
			    			}
						};
						$scope.merge = function(){
							$uibModalInstance.dismiss($scope.tag);
						};
						$scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
			    	}],
			    	resolve: {
			    		tag: function() { return tag }
			    	}
				}).result;
			},
			
			loadTag: function(userId, tagId) {
				var url = tagsBaseUrlFor(userId) + '/' + tagId;
				return $http.get(url).then(function(response) {
					return new Tag(response.data);
				});
			},
			
			getOrCreateTag: function(userId, tagName) {
				return self.getTagsForName(userId, tagName).then(function(tagArray) {
					if(tagArray[0]) {
						return tagArray[0];
					} else {
						return self.newTag(tagName, userId);
					}
				});
			},
		
			removeTag: function(tag) {
				return $http.delete(tagsBaseUrlFor(tag.ownerId) + '/' + tag.id);	
			},
			
			getByFrequency: function(userId, frequency) {
				return $http.get(tagsBaseUrlFor(userId) + '?frequency=' + frequency).then(function(response) {
					return Tag.constructArray(response.data);
				});	
			},
			openDragAndDropInfoDialog: function(){
				modalService.openBootstrapModal({
				  templateUrl:  '/assets/javascripts/tagpacker-angular/dialogs/dragAndDropInfo.html',
				  controller: ['$scope', '$uibModalInstance', function InfoDialogController($scope, $uibModalInstance){
				  	$scope.close = function(result){
						$uibModalInstance.close();
					};
				  }]
				});
			}
			
		};
		
		return self;

	}
	
});

