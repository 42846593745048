define('tagpacker-angular/controllers/register/RegisterController',[], function () {

    var RegisterController = function ($scope, loginService, analytics, recaptcha, primaryDataService) {

        //var recaptchaSolved = false;

        $scope.authenticateWithProvider = function (providerKey) {
            loginService.authenticate(providerKey, 'register_provider_' + providerKey);
        };

        $scope.onValidSubmit = function ($event, form) {
            $event.preventDefault();
            recaptcha.execute('register_email').then(function (token) {
                document.getElementById('g-recaptcha-response').value = token;
                form.commit();
            });
            analytics.sendUserEvent('register button', 'register');
        };

        $scope.isApp = function () {
            return primaryDataService.isApp();
        };

    };

    RegisterController.$inject = ['$scope', 'loginService', 'analytics', 'recaptcha', 'primaryDataService'];

    return RegisterController;

});

