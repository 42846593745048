define('tagpacker-angular/services/blockService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('blockService', blockService);

	blockService.$inject = ['$http'];

	function blockService($http) {
	
		return {
			block: function(blockerId, blockedId) {
				return $http.post('/block', { blockerId: blockerId, blockedId: blockedId});
			},
			unblock: function(unblockerId, unblockedId) {
				return $http.post('/unblock', { unblockerId: unblockerId, unblockedId: unblockedId});
			}
		};
	
	}
	
});

