define('tagpacker-angular/services/loaderService',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.factory('loaderService', loaderService);

	loaderService.$inject = ['$q'];

	function loaderService($q) {
	
		return {
			
			getMostRecentRequestLoader: function() {
				
				var latest = null;
				
				function successHandler(deferred) {
					return function(resolution) {
						if (latest == deferred) {
							deferred.resolve(resolution);
						}
					};
				}
				
				function errorHandler(deferred) {
					return function(reason) {
						if (latest == deferred) {
							deferred.reject(reason);
						}
					}
				}
				
				return {
					load: function(promise) {
						var deferred = $q.defer();
						
						if (latest != null) 
							latest.reject('obsolete');
						
						latest = deferred;

						promise
							.then(successHandler(deferred), errorHandler(deferred));
						
						return deferred.promise;
					},

					cancel: function() {
						if (latest !== null) {
							latest.reject('obsolete');
							latest = null;
						}
					}
				}
			}
			
		};
	
	}

});

