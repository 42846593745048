define('classes/Challenge',[], function(){

	function Challenge(otherChallenge) {
		this.key = otherChallenge.key;
		this.message = otherChallenge.message;
		this.name = otherChallenge.name;
		this.progress = otherChallenge.progress;
		this.challengeService = otherChallenge.challengeService;
	}
	
	Challenge.prototype.completed = function() {
		return this.progress() >= 100 || this.challengeService.completed(this);
	}
	
	return Challenge;

});
