define('tagpacker-angular/directives/autocomplete/autocompleteResults',[], function(){
	
	return function factory() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/autocomplete/autocompleteResults.html',
			scope: true,
			replace: true,
			restrict: 'E'
		};
	};

});
