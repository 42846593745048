define('tagpacker-angular/directives/debug/debug',[], function(){

	var debug = function($compile, $rootScope) {
	  return {
	  	scope: {},
	    restrict: 'A',
	    link: function postLink(scope, element, attributes) {
	    	var digestCounter = 0;
	    	
	    	var css= "position: fixed; top: 20px; left: 20px; z-index: 10000; "
	    	    + "background-color: white; border: 1px solid grey; padding: 20px;"
	    	    + "text-align: center; width: 200px;";
	        var template = '<div class="admin-function debug-window" style="'+css+'">'
	        	+ '<button ng-click="doIt()">$rootScope.digest()</button>'
	        	+ '<hr/><div>{{ output }}</div><hr/><div class="digest-counter"></div>'
	        	+ '</div>';
			
	        var debugWindow = angular.element($compile(template)(scope));
	        element.append(debugWindow);
	        
	        function countDigest() {
	        	digestCounter++;
	        	debugWindow.find('.digest-counter').html(digestCounter + ' digests');
	        }
	        
	        $rootScope.$watch(function() {countDigest(); }, function(newValue, oldValue) {});
			
			function profile(script) {
			    var start = new Date();
			    script();
			    return new Date() - start;
			}
			
			scope.output = "---";
			
			scope.doIt = function() {
				var n = 100;
				scope.output = 'Digesting ' + n + ' times...'; 
				
				setTimeout(function() {
					
					var time = profile(function() {
						for (var i=0 ; i<n ; i++) {
							$rootScope.$digest();				
						}
						digestCounter = 0;
					}); 
					scope.$apply(function() {
						scope.output = time + 'ms for ' + n + ' digest cycles'; 
					});
				});
			};
		}
	  }
	};

	debug.$inject=['$compile', '$rootScope'];
	
	return debug;
	
});
