define('tagpackerModule',[
        'angular',
        'angular-ui-router',
        'angular-ui-bootstrap',
        'angular-elastic',
        'angular-file-upload',
        'angular-youtube-embed'
    ], function (
    angular,
    angularUiRouter,
    angularUiBootstrap,
    angularElastic,
    angularFileUpload,
    angularYoutubeEmbed,
    angularEasyfb) {
        'use strict';
        if (typeof String.prototype.endsWith !== 'function') {
            String.prototype.endsWith = function (suffix) {
                return this.indexOf(suffix, this.length - suffix.length) !== -1;
            };
        }
        return angular.module('tagpacker', [
            'ui.router', 'ui.bootstrap', 'monospaced.elastic', 'angularFileUpload', 'youtube-embed']);
    }
);

