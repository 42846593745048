define('tagpacker-angular/directives/facet/editModeFacet',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.directive('editModeFacet', editModeFacet);

	editModeFacet.$inject = [];
	
	function editModeFacet() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/facet/editModeFacet.html',
			replace: true,
			restrict: 'E',
			scope: {
				facet: '=',
				selectedTags: '=',
				onQuickSelect: '&',
				open: '&',
				userId: '&',
				onHeaderClick: '&',
				totalNumberOfFacets: '&'
			},
			controller: Controller
		};	
	}

	Controller.$inject = ['$scope', 'tagService'];

	function Controller($scope, tagService) {

		$scope.tags = null;
		$scope.$watch(function() { return $scope.open() }, function(open) {
			if (open) loadTags();
		});
		$scope.loading = false;

		function loadTags() {
			var tagPromise = $scope.facet.isPack()
				? tagService.getTagsForPack($scope.facet.pack)
				: tagService.getTagsWithoutPack($scope.userId());
			$scope.loading = true;

			tagPromise.then(function(tags) {
				$scope.tags = tags;
				$scope.loading = false;
			});
		}
		
		$scope.handleClick = function(tag) {
			if ($scope.isSelected(tag)) {
				$scope.selectedTags.splice(getIndexOf(tag), 1);
			}
			else {
				$scope.selectedTags.push(tag);
				$scope.onQuickSelect();
			}
		};

		$scope.handleHeaderClick = function(event) {
			// clicks on buttons are not taken into account
			if (!$(event.target).is('button')) {
				$scope.onHeaderClick();
			}
		};
		
		$scope.isSelected = function(tag) {
			return getIndexOf(tag) >= 0;
		};
		
		$scope.toggle = function(tag) {
			if ($scope.isSelected(tag))
				$scope.selectedTags.splice(getIndexOf(tag), 1);
			else
				$scope.selectedTags.push(tag);
		}
		
		$scope.getTagClasses = function(tag) {
			var result = ['color_'+tag.getColor()];
			if ($scope.isSelected(tag)) {
				result.push('checked');
			}
			return result;
		};

		$scope.getName = function () {
			return $scope.facet.isPack()
				? $scope.facet.pack.name
				: 'Other tags';
		};

		function getIndexOf(tag) {
			for (var i=0 ; i<$scope.selectedTags.length ; i++) {
				if (tag.equals($scope.selectedTags[i])) return i; 
			}
			return -1;
		}
		
		$scope.openNewTagDialog = function() {
			var packOrOwnerId = $scope.facet.isPack() ? $scope.facet.pack : $scope.userId();
			var tag = tagService.newTag('', packOrOwnerId); 
			tagService.openTagEditDialog(tag).then(function(newTag) {
				$scope.$emit('tagCreate');
				$scope.selectedTags.push(newTag);
			});
		};

		function isInThisFacet(tag) {
			if ($scope.facet.isPack())
				return $scope.facet.pack.equals(tag.pack);
			else
				return tag.pack == null;
		}
		
		$scope.tagsIncludingNewTags = function() {
			if ($scope.tags == null) {
				return [];
			}
			var result = $scope.tags.concat([]);
			$scope.selectedTags.forEach(function(eachTag) {
				if (eachTag.isNew() && isInThisFacet(eachTag)) {
						insertInSortedTagArray(result, eachTag);
				}
			});
			return result;
		}
		
		function insertInSortedTagArray(arr, tag) {
			var index = 0;
			var lowercaseTagName = tag.name.toLowerCase();
			while (index < arr.length
				   && lowercaseTagName.localeCompare(arr[index].name.toLowerCase()) > 0 ) {
				index++;
			}
			arr.splice(index, 0, tag);
		}
		
	}

});

