(function (requirejs) {
    'use strict';
    requirejs.config({
        packages: [],
        shim: {
            'angular': {
                deps: ['jquery'],
                exports: 'angular'
            },
            'bootstrap': ['jquery'],
            'angular-ui-router': ['angular'],
            'angular-ui-bootstrap': ['angular'],
            'angular-elastic': ['angular']
        },
        paths: {
            'requirejs': './lib/require',
            'angular': './lib/angular',
            'jquery': './lib/jquery',
            'jquery-ui': './lib/jquery-ui',
            'angular-elastic': './lib/angular-elastic',
            'bootstrap': './lib/bootstrap',
            'angular-ui-bootstrap': './lib/ui-bootstrap-tpls',
            'angular-ui-router': './lib/angular-ui-router',
            'angular-file-upload': './lib/angular-file-upload',
            'hammer': './lib/hammer',
            'classes': './classes'
        }
    });

    requirejs.onError = function (err) {
        console.log(err);
    };

    require([
            'angular',
            'jquery',
            'jquery-ui',
            'bootstrap',
            'bootstrap-toolkit',
            './tagpacker',
            './urlSemicolonFix',
            'hammer'
        ],
        function (angular) {
            angular.bootstrap(document, ['tagpacker', 'urlSemicolonFix']);
        }
    );
})(requirejs);

define("main", function(){});

