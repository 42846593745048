define('tagpacker-angular/directives/clickTracker/clickTracker',[], function(){

	var clickTracker = function (analytics) {
		return {
			link: function(scope, elem, attributes, ngModel) {
	      		elem.click(function(event){
	      			var href = elem.attr('href');
	      			analytics.sendLinkEvent('clicked ' + href, 'clickTrackerClickThrough');
	      		});
	  		}
		};
	};
	
	clickTracker.$inject=['analytics'];
	
	return clickTracker;
	
});
