define('classes/Filter',[ 'classes/Tag', 'classes/TagPack' ], function(Tag, TagPack) {

	function Filter(user) {
		if (arguments.length != 1) {
			throw new Error('A filter needs a user!');
		}

		// since really immutable types are not easy to achieve in javascript,
		// the underscore serves as a reminder not to access these fields
		// outside this class
		this._user = user;
		this._tags = [];
		this._excludedPack = null;
		this._requiredPack = null;
		this._query = null;
		this._visibility = null;
		this._reachability = null;
		this._numberOfTags = null;
	}

	Filter.prototype._clone = function() {
		var result = new Filter(this._user);
		result._tags = [].concat(this._tags);
		result._excludedPack = this._excludedPack;
		result._requiredPack = this._requiredPack;
		result._query = this._query;
		result._visibility = this._visibility;
		result._reachability = this._reachability;
		result._numberOfTags = this._numberOfTags;
		return result;
	};

	Filter.prototype.getUser = function() {
		return this._user;
	};

	Filter.prototype.getUserId = function() {
		return this._user.id;
	};

	Filter.prototype.withTag = function(tag) {
		var result = this._clone();
		result._tags.push(tag)
		return result;
	};

	Filter.prototype.getTags = function() {
		return [].concat(this._tags);
	};

	Filter.prototype.getTagIds = function() {
		return this._tags.filter(function(x) { return x.excluded == false }).map(function(x) { return x.id });
	};

	Filter.prototype.getExcludedTagIds = function() {
		return this._tags.filter(function(x) { return x.excluded == true }).map(function(x) { return x.id });
	};
	
	Filter.prototype.withExcludedPack = function(pack) {
		var result = this._clone();
		result._excludedPack = pack;
		return result;
	};

	Filter.prototype.getExcludedPack = function() {
		return this._excludedPack;
	};

	Filter.prototype.getExcludedPackId = function() {
		return this._excludedPack ? this._excludedPack.id : null;
	};

	Filter.prototype.withRequiredPack = function(pack) {
		var result = this._clone();
		result._requiredPack = pack;
		return result;
	};

	Filter.prototype.getRequiredPack = function() {
		return this._requiredPack;
	};

	Filter.prototype.withQuery = function(query) {
		var result = this._clone();
		result._query = query;
		return result;
	};

	Filter.prototype.getQuery = function() {
		return this._query;
	};

	Filter.prototype.withVisibility = function(visibility) {
		var result = this._clone();
		result._visibility = visibility;
		return result;
	};

	Filter.prototype.getVisibility = function() {
		return this._visibility;
	};

	Filter.prototype.withReachability = function(reachability) {
		var result = this._clone();
		result._reachability = reachability;
		return result;
	};

	Filter.prototype.getReachability = function() {
		return this._reachability;
	};

	Filter.prototype.withNumberOfTags = function(numberOfTags) {
		var result = this._clone();
		result._numberOfTags = numberOfTags;
		return result;
	};

	Filter.prototype.getNumberOfTags = function() {
		return this._numberOfTags;
	};

	Filter.prototype.asPlainObject = function() {
		var result = { userId: this.getUserId() };
		if (this.getTagIds().length > 0) {
			result.tagIds = this.getTagIds();
		}
		if (this.getExcludedTagIds().length > 0) {
			result.excludedTagIds = this.getExcludedTagIds();
		}
		if (this.getExcludedPack()) {
			result.excludedPackId = this.getExcludedPack().id;
		}
		if (this.getRequiredPack()) {
			result.requiredPackId = this.getRequiredPack().id;
		}
		if (this.getQuery()) {
			result.query = this.getQuery();
		}
		if (this.getVisibility()) {
			result.visibility = this.getVisibility();
		}
		if (this.getReachability() != null) {
			result.reachability = this.getReachability();
		}
        if (this.getNumberOfTags() != null) {
            result.numberOfTags = this.getNumberOfTags();
        }
		return result;
	}

	return Filter;

});
