define('tagpacker-angular/directives/imageChooser/imageChooser',['tagpackerUtils'], function (tagpackerUtils) {

    var imageChooser = function () {
        return {
            replace: true,
        	scope: {
            	link: '='
            },
            templateUrl: '/assets/javascripts/tagpacker-angular/directives/imageChooser/imageChooser.html',
            controller: ['$scope', 'imageService', 'modalService', 'analytics', function ($scope, imageService, modalService, analytics) {
            	
            	$scope.loading = false;
            	$scope.uploadingUrl = false;
            	$scope.uploadingImage = false;
            	$scope.images = [];
            	$scope.excludedImageUrls = [];
            	$scope.noMoreLoadingResults = false;
            	$scope.currentImage = null;
            	$scope.uploadedImage = null;
            	
            	$scope.handleSelect = function(image) {
            		var images = [].concat($scope.images);
            		images.push($scope.defaultImage);
            		if($scope.currentImage != null) {
            			images.push($scope.currentImage);
            		}
            		if ($scope.uploadedImage != null) {
            			images.push($scope.uploadedImage);
            		}
            		images.forEach(function(i) {
            			if(i == image){
            				if(i.selected == true) {
            					i.selected = false;
            					$scope.link.thumbnailSource = 'AUTO';
            					if($scope.currentImage) {
            						$scope.link.thumbnailId = $scope.currentImage.id;
            					} else {
            						$scope.link.thumbnailId = null;
            					}
            				} else {
            					i.selected = true;
            					if(i.type == 'UPLOAD' || i.type == 'AUTO') {
            						$scope.link.thumbnailId = i.id;
            					} else if(i.type == 'URL'){
            						$scope.link.thumbnailSourceUrl = i.url;
            					}
                        		$scope.link.thumbnailSource = i.type;
            				}
            			} else {
            				i.selected = false;
            			}
        			});
            	};
            	
            	$scope.defaultImage = {
					type: 'NONE',
                	url: '/assets/images/default-link-thumbnail.png',
                	selected: $scope.link.thumbnailSource == 'NONE'
				};
				
            	$scope.removeUploadedImage = function() {
            		if($scope.uploadedImage.selected == true) {
            			$scope.link.thumbnailSource = 'AUTO';
            		}
            		$scope.uploadedImage = null;
            	};
            	
            	function isAnyImageSelected() {
            		var imageSelected = false;
            		$scope.images.forEach(function(image){
            			if(image.selected == true) {
            				imageSelected = true;
            			}
            		});
            		return 	imageSelected || 
            				($scope.currentImage != null && $scope.currentImage.selected == true) || 
            				($scope.uploadedImage != null && $scope.uploadedImage.selected == true) || 
            				$scope.defaultImage.selected == true;
            	};
            	
            	$scope.removeImage = function(image) {
            		if(image.selected == true) {
            			$scope.link.thumbnailSource = 'AUTO';
            		}
            		var index = $scope.images.indexOf(image);
            		if(index >= 0) {
            			$scope.images.splice(index, 1);
            		}
            	};
            	
            	$scope.imageUploadValidators = [
        			tagpackerUtils.createFileTypeFilter(['png','jpg','jpeg','gif']),
        			tagpackerUtils.createFileSizeFilter(4 * 1000 * 1000)
        		];
            	
            	if($scope.link.thumbnailUrl != null) {
					$scope.currentImage = {
						type: $scope.link.thumbnailSource,
						url: $scope.link.thumbnailUrl,
						id: $scope.link.thumbnailId,
						selected: true
					};
				}
				
				if($scope.link.thumbnailSource == 'URL' && $scope.link.thumbnailSourceUrl != null) {
            		$scope.excludedImageUrls.push($scope.link.thumbnailSourceUrl);
            	}
            	
            	
            	if($scope.link.isNew()) {
            		if($scope.link.sourceUrl) {
            			loadImages($scope.link.thumbnailUrl != null ? 4 : 5);
            		}
            	}
            	
            	function loadImages(limit) {
            		$scope.loading = true;
            		imageService.load($scope.link.sourceUrl, limit, $scope.excludedImageUrls).then(function(result) {
            			$scope.noMoreLoadingResults = result.length < limit;
            			result.forEach(function(image) {
            				$scope.images.push({
                            	type: 'URL',
                            	url: image.imageUrl,
                            	selected: false
                            });
            				$scope.excludedImageUrls.push(image.imageUrl);
            			});
            			if(!isAnyImageSelected()) {
            				if($scope.images.length > 0) {
            					$scope.handleSelect($scope.images[0]);
            				} else {
            					$scope.handleSelect($scope.defaultImage);
            				}
            			}
            			
                	}).finally(function(){
        				$scope.loading = false;
        			});
            	};
            	
            	$scope.$watch('link.sourceUrl', function(newValue, oldValue) {
					if(newValue != oldValue) {
						$scope.noMoreLoadingResults = false;
					}
				});
            	
            	$scope.uploadStarted = function() {
            		$scope.uploadingImage = true;
            	};
            	
            	$scope.uploadFailed = function() {
            		$scope.uploadingImage = false;
            	};
            	
            	$scope.uploadFinished = function (file) {
            		var uploadedImage = {
                    	type: 'UPLOAD',
                    	url: file.url,
                    	id: file.id
        			};
            		$scope.uploadedImage = uploadedImage;
            		$scope.handleSelect(uploadedImage);
            		$scope.uploadingImage = false;
            		analytics.sendLinkEvent('image file uploaded', 'upload');
        		};
        		
        		$scope.loadMore = function() {
        			if($scope.loading === false && $scope.noMoreLoadingResults === false) {
        				loadImages(6);
            			analytics.sendLinkEvent('images reload requested', 'reload');
        			}
        		};
        		
        		$scope.loadUrl = function() {
        			modalService.openBootstrapModal({
        				templateUrl:  '/assets/javascripts/tagpacker-angular/dialogs/loadUrl.html',
        				controller: ['$scope', '$uibModalInstance', function InfoDialogController($scope, $uibModalInstance){
        					$scope.save = function() {
        						if($scope.url) {
        							$uibModalInstance.close($scope.url);
        						} else {
        							$uibModalInstance.dismiss('cancel');
        						}
        					};
        					$scope.cancel = function() {
        						$uibModalInstance.dismiss('cancel');
        					};
        				}]
        			}).result.then(function(imageUrl){
        				$scope.uploadingUrl = true;
        				return imageService.create(imageUrl);
        			}).then(function(image){
        				var uploadedImage = {
    						type: 'UPLOAD',
                        	url: image.url,
                        	id: image.id
                        };
        				$scope.uploadedImage = uploadedImage;
        				$scope.handleSelect(uploadedImage);
        				analytics.sendLinkEvent('image url uploaded', 'upload');
        			}).finally(function(){
        				$scope.uploadingUrl = false;
        			});
        		};
            	
            }], link : function postLink(scope, element, iAttrs) {
            	scope.uploadImage = function() {
            		element.find('.select-file-button').click();
            	};
            }
        };
    };

    imageChooser.$inject = [];

    return imageChooser;

});
