define('tagpacker-angular/services/viewService',['tagpackerModule'], function (tagpackerModule) {
    'use strict';

    // The name was changed from 'adService' so it's not blocked by Adblock plus
    tagpackerModule.factory('viewService', viewService);

    viewService.$inject = ['$http', 'authService', 'analytics', '$stateParams', '$state', 'filterService'];

    function viewService($http, authService, analytics, $stateParams, $state, filterService) {

        var service = {};

        var EMBEDDED_ID = '1';
        var HOME_ID = '2';

        var APPEARANCE_SMALL = 's';
        var APPEARANCE_HIDDEN = 'h';

        var embeddedViewConfig = {
            p: EMBEDDED_ID
        }

        var homeViewConfig = {
            p: HOME_ID
        }

        service.getEmbeddedViewConfig = function (withImage, withDescription, withTags) {
            var result = angular.copy(embeddedViewConfig);
            result.l = makeLinkAppearance(withImage, withDescription, withTags);
            return result;
        };

        service.getHomeViewConfig = function () {
            return angular.copy(homeViewConfig);
        };

        service.isDefaultEmbeddedView = function () {
            return getViewConfig() && getViewConfig().p == EMBEDDED_ID;
        };

        service.isDefaultHomeView = function () {
            return getViewConfig() && getViewConfig().p == HOME_ID;
        };

        service.isCompactNavbar = function () {
            return getViewConfig() && (getViewConfig().p && getViewConfig().p.nb == APPEARANCE_SMALL) || service.isDefaultEmbeddedView();
        };

        service.headerHidden = function () {
            return getViewConfig() && (getViewConfig().p && getViewConfig().p.he == APPEARANCE_HIDDEN) || service.isDefaultEmbeddedView() || service.isDefaultHomeView();
        };

        service.footerHidden = function () {
            return getViewConfig() && (getViewConfig().p && getViewConfig().p.fo == APPEARANCE_HIDDEN) || service.isDefaultEmbeddedView();
        };

        service.feedbackHidden = function () {
            return getViewConfig() && (getViewConfig().p && getViewConfig().p.fb == APPEARANCE_HIDDEN) || service.isDefaultEmbeddedView();
        };

        service.bannerHidden = function () {
            return service.isDefaultEmbeddedView() || service.footerHidden();
        };

        service.isCompactLinkView = function () {
            return getViewConfig() && (typeof getViewConfig().l !== 'undefined');
        };

        service.isLinkWithImage = function () {
            return !service.isCompactLinkView() || (typeof getViewConfig().l.i !== 'undefined');
        };

        service.isLinkWithDescription = function () {
            return !service.isCompactLinkView() || (typeof getViewConfig().l.d !== 'undefined');
        };

        service.isLinkWithTags = function () {
            return !service.isCompactLinkView() || (typeof getViewConfig().l.t !== 'undefined');
        };

        function makeLinkAppearance(withImage, withDescription, withTags) {
            if (!withImage && !withDescription && !withTags) {
                return APPEARANCE_SMALL;
            }
            var result = {};
            if (withImage) {
                result.i = 1;
            }
            if (withDescription) {
                result.d = 1;
            }
            if (withTags) {
                result.t = 1;
            }
            return result;
        }

        service.applyCompactLinkView = function () {
            if (!getViewConfig()) {
                initViewConfig();
            }
            getViewConfig().l = APPEARANCE_SMALL;
            if (authService.isSessionUserUsername(filterService.getUsername())) {
                var sessionUser = authService.getSessionUser();
                if (sessionUser.isCompactLinkView()) {
                    getViewConfig().l = makeLinkAppearance(
                        sessionUser.linkAppearance.withImage,
                        sessionUser.linkAppearance.withDescription,
                        sessionUser.linkAppearance.withTags
                    );
                }
            }
        };

        service.toggleLinkView = function () {
            analytics.sendLinkEvent('toolbar', 'toggle-link-view');
            if (service.isCompactLinkView()) {
                delete getViewConfig().l;
                clearConfigIfEmpty();
            } else {
                service.applyCompactLinkView();
            }
            $state.go('user.main.filter', $stateParams);
        };

        function getViewConfig() {
            return $stateParams.a;
        };

        function initViewConfig() {
            $stateParams.a = {};
        };

        function clearConfigIfEmpty() {
            if (angular.equals({}, getViewConfig())) {
                delete $stateParams.a;
            }
        };

        return service;

    }

});

