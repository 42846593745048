define('tagpacker-angular/directives/sidebar/sidebarToggleDirective',['classes/User'], function(User){

	var sidebarToggle = function factory(sidebarService) {
				
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/sidebar/sidebarToggleDirective.html',
			replace: true,
			restrict: 'E',
			scope: {
				user: '='			
			},
			controller: ['$scope', function($scope) {
				
			    $scope.toggleSidebar = function() {
			    	sidebarService.toggle();
			    };
			    
			    $scope.isSidebarOpen = function() {
			    	return sidebarService.isOpen();
			    };
				
			}]
		};
		
	};
	
	sidebarToggle.$inject=['sidebarService'];
	
	return sidebarToggle;

});

