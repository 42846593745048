define('tagpacker-angular/directives/navbar/changelogDirective',['classes/User'], function(User){

	var changelogDirective = function factory(analytics) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/navbar/changelogDirective.html',
			replace: true,
			restrict: 'E',
			controller: ['$scope', function($scope) {
				
				$scope.handleChangelogClick = function() {
					analytics.sendUserEvent('changelog badge', 'click');
				};
				
				var HW_config = {
						selector: ".changelog",
						account: "yjobkx"
				};
				Headway.init(HW_config);
			}],
			link: function postLink(scope, element, attributes) {
				scope.hasBadge = false;
				
				var observer = new MutationObserver(function(mutations) {
					scope.$apply(function() {
						scope.hasBadge = element.find('.HW_hidden').length == 0;
					});
				});
	            observer.observe(element[0], {
	                childList: true,
	                subtree: true
	            });
								
				scope.getTitle = function() {
					if(scope.hasBadge){
						return "What's New";
					}
					return '';
				};
			}
		};
		
	};
	
	changelogDirective.$inject=['analytics'];
	
	return changelogDirective;

});

