define('tagpacker-angular/directives/bookmarklet/packItDirective',[], function () {

    return function factory() {

        return {
            scope: true,
            replace: true,
            templateUrl: '/assets/javascripts/tagpacker-angular/directives/bookmarklet/packItDirective.html',
            link: function postLink(scope, element, attributes) {

            },
            controller: ['$scope', 'analytics', 'primaryDataService', 'browserDetector', 'osDetector', '$timeout',
                function ($scope, analytics, primaryDataService, browserDetector, osDetector, $timeout) {

                    $scope.packItType = function () {
                        if (browserDetector.isEdge()) {
                            return 'edge';
                        } else if (browserDetector.isChrome()) {
                            return 'chrome';
                        } else if (browserDetector.isFirefox() && !osDetector.isAndroid()) {
                            return 'firefox'
                        }
                        return 'bookmarklet';
                    };

                    $scope.showFallback = false;

                    $scope.installEdge = function (event) {
                        event.preventDefault();
                        openExtensionStore(primaryDataService.getEdgeWebstoreItemUrl());
                    };

                    $scope.installChrome = function (event) {
                        event.preventDefault();
                        openExtensionStore(primaryDataService.getChromeWebstoreItemUrl());
                    };

                    $scope.installFirefox = function (event) {
                        event.preventDefault();
                        openExtensionStore(primaryDataService.getFirefoxWebstoreItemUrl());
                    };

                    function openExtensionStore(url) {
                        $timeout(function () {
                            $scope.showFallback = true;
                        }, 2000);
                        window.open(url, '_blank');
                    }

                }]
        };

    };

});

