define('tagpacker-angular/controllers/AccountController',['classes/User'], function(User){

	var AccountController = function($scope, $http, authService) {
		
		$scope.user = null;
		$scope.providers = [];
		
		var loadAccount = function () {
			$http({
				method: 'GET',
				url: '/getAccount'
			}).success(function(data, status, headers, config) {
				var user = new User(data.user);
				authService.setSessionUser(user);
				$scope.user = user;
				$scope.providers = data.providers;
			});
		};
		
		$scope.$on('refreshAccount', function() {
			loadAccount();
		});
		
		loadAccount();
		
	};
	
	AccountController.$inject=['$scope', '$http', 'authService'];
	
	return AccountController;

});
