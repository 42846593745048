define('tagpacker-angular/directives/clickTracker/indexClickTracker',[], function(){

	var indexClickTracker = function (analytics) {
		return {
			link: function(scope, elem, attributes, ngModel) {
	      		elem.click(function(event){
	      			var href = elem.attr('href');
	      			analytics.sendIndexClickEvent(attributes.size + " " + href);
	      		});
	  		}
		};
	};
	
	indexClickTracker.$inject=['analytics'];
	
	return indexClickTracker;
	
});
