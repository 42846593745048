define('tagpacker-angular/directives/validSubmitDirective',[], function(){

	var validSubmit = function($parse, loadingService) {
		return {
			link: function(scope, element, iAttrs, controller) {
				var form = element.controller('form');    
				form.$submitted = false;
				var fn = $parse(iAttrs.validSubmit);
				
				element.on('submit', function(event) {
					scope.$apply(function() {
						form.$submitted = true;
						if (form.$valid) {
							loadingService.showModalLoadingAnimation();
							var result = fn(scope, { $event : event });
							if (result) {
								result.finally(function() {
									loadingService.hideModalLoadingAnimation();
								});
							} else {
								loadingService.hideModalLoadingAnimation();
							}
							form.$submitted = false;
						} else {
							event.preventDefault();
						}
					});
				});
			}
		};
	};
		
	validSubmit.$inject=['$parse','loadingService'];
	
	return validSubmit;
	
});
