define('tagpacker-angular/controllers/activitiesList/LatestLinksController',['classes/Znippet','tagpackerUtils'], function(Znippet, tagpackerUtils) {
    LatestLinksController = function($scope, listLoader) {
		
		$scope.linkList = listLoader.create('/api/latestLinks', {
			chunkSize: 12,
			morphArray: function(oldArray, newArray) {
				newArray = Znippet.constructArray(newArray);
				tagpackerUtils.morphZnippetArray(oldArray, newArray);
			}
		});
		$scope.linkList.load();
		
		$scope.$on('refreshPage', function() {
			$scope.linkList.refresh();
		});

	}
	
	LatestLinksController.$inject=['$scope', 'listLoader'];
	
	return LatestLinksController;
	
});
