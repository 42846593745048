define('tagpacker-angular/directives/formCommitDirective',[], function () {

    var formCommit = function factory() {
        return {
            replace: false,
            link: function postLink(scope, element) {
                var form = element.controller('form');
                form.commit = function () {
                    element[0].submit();
                };
            }
        };
    };
    formCommit.$inject = [];
    return formCommit;

});
