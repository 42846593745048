define('tagpacker-angular/controllers/welcome/WelcomeController',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.controller('WelcomeController', WelcomeController);

	WelcomeController.$inject=['$scope', '$state', 'analytics', 'navigationService', 'primaryDataService'];

	function WelcomeController($scope, $state, analytics, navigationService, primaryDataService) {

		var stepIndex = 0;
		
		// indexes are set automatically
		$scope.steps = [
			{
				id:'welcome.bookmarklet',
				name:'"Pack it"'
			},
			{
				id:'welcome.interests',
				name:'Interests'
			}, 
			{
				id:'welcome.follow',
				name:'Follow'
			},
			{
				id:'welcome.verify',
				name:'Verify'
			}
		];
		
		if(primaryDataService.isApp()) {
			$scope.steps.shift();
		}
		
		for (var i=0 ; i<$scope.steps.length ; i++)
			$scope.steps[i].index = i;

		$scope.getNextButtonText = function () {
			return $scope.steps[stepIndex].nextButtonText ||
				($scope.isLastStep() ? "Finish" : "Next");
		};

		$scope.isSkipButtonVisible = function () {
			return !!($scope.steps[stepIndex].skipButtonText);
		}

		$scope.getSkipButtonText = function () {
			return $scope.steps[stepIndex].skipButtonText;
		}
		
		$scope.isLastStep = function () {
			return stepIndex === $scope.steps.length - 1;
		};

		$scope.isNext = function(step) {
			return step.index == stepIndex + 1;
		};
		
		$scope.currentStepIndex = function () {
			return stepIndex;
		};
		
		$scope.triggerNextViaMenu = function () {
			analytics.sendWelcomeEvent('welcome wizard step skipped via menu: '
										   + $scope.steps[stepIndex].name, 'skip');
			$scope.triggerNext();
		};

		$scope.triggerNext = function () {
			$scope.$broadcast('trigger-next-step');
		};
		
/*		$scope.next = function (step) {
			if (step.index == stepIndex + 1) {
				$scope.$broadcast('next-step');	
			}
			$state.go(step.id);
		};*/
		
		$scope.stepFinished = function (step) {
			return step.index < stepIndex;
		};

		// sync step index with current state
		$scope.$watch(function() { return $state.current.name }, function(name) {
			for (var i=0 ; i<$scope.steps.length ; i++) {
				if ($scope.steps[i].id == name) {
					stepIndex = $scope.steps[i].index;
				}
			}
		});

		$scope.triggerSkip = function () {
			analytics.sendWelcomeEvent('welcome wizard step skipped: '
									   + $scope.steps[stepIndex].name, 'skip');
			$scope.triggerNext();
		};
		
		$scope.$on('next-step', function () {
			if ($scope.isLastStep()) {
				navigationService.goHome();
			}
			else {
				$state.go($scope.steps[stepIndex + 1].id);
			}
		});

	}
	
});

