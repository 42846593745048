define('tagpacker-angular/services/modalService',['tagpackerModule'], function(tagpackerModule){

	/*
	 * This service wraps the bootstrap $uibModal and the znippetOverlay
	 */

	tagpackerModule.factory('modalService', modalService);

	modalService.$inject = ['$uibModal', '$q', '$window', 'keyEventService', '$rootScope'];

	function modalService($uibModal, $q, $window, keyEventService, $rootScope)
	{
		// we assume there is no modal open when this is executed
		var originalWidth = $('body').width();
		
		var modalCounter = 0;
		
		var stylesheetChanged = false;
	
		function changeStylesheet(widthDifference) {
			var style = document.createElement("style");
			style.appendChild(document.createTextNode(".modal-open.padding-when-overlay-open { padding-right: " + widthDifference + "px !important}"));
			style.appendChild(document.createTextNode("body.modal-open .padding-when-overlay-open { padding-right: " + widthDifference + "px !important}"));
			style.appendChild(document.createTextNode(".tp-modal-open.padding-when-overlay-open { padding-right: " + widthDifference + "px !important}"));
			style.appendChild(document.createTextNode("body.tp-modal-open .padding-when-overlay-open { padding-right: " + widthDifference + "px !important}"));
			document.head.appendChild(style);
		}
		
		$($window).bind('resize', function() {
			if (modalCounter == 0) {
				originalWidth = $('body').width();
			}
		});
		
		$rootScope.$watch(function() {return $('body').attr('class') && $('body').attr('class').includes('modal-open'); }, function(newValue, oldValue){
			var keyEventServiceId = "modalService";
			if(newValue === true && oldValue !== newValue) {
				keyEventService.add(keyEventServiceId);
			} else if (newValue === false && oldValue !== newValue) {
				keyEventService.remove(keyEventServiceId);
			}
		});
		
		function modalOpened() {
			
			$('body').addClass('tp-modal-open');
			if (modalCounter == 0 && !stylesheetChanged && $('body').width() > originalWidth) {
				stylesheetChanged = true;
				changeStylesheet($('body').width() - originalWidth);
			}
			modalCounter++;		
		};
		
		function modalClosed() {
			modalCounter--;
			if (modalCounter == 0) {
				$('body').removeClass('tp-modal-open');
			} 
		};

		var service = {};
		
		service.openBootstrapModal = function(parameters) {
			var modalInstance = $uibModal.open(parameters);
			
			modalInstance.result = modalInstance.result['finally'](function() {
				modalClosed();
			});
				
			modalInstance.opened = modalInstance.opened.then (
				function(result) {
					modalOpened();
					return result;
				},
				function(reason) {
					return $q.reject(reason);
				}
			);
			
			return modalInstance;
		};
	
		return service;

	}

});

