define('tagpacker-angular/services/cookieService',['tagpackerModule'], function (tagpackerModule) {

    tagpackerModule.factory('cookieService', cookieService);

    cookieService.$inject = ['$http', 'utilService'];

    function cookieService($http, utilService) {
        var service = {};

        service.setCookie = function (key, value, days) {
            if (typeof value == 'undefined') {
                value = 'true';
            }
            if (typeof days == 'undefined') {
                days = 365 * 100;
            }

            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "expires=" + date.toGMTString();
            var path = "/;"
            if (utilService.isLocalhost()) {
                path = path + "Domain=localhost"
            } else if (utilService.isTagpacker()) {
                path = path + "Domain=.tagpacker.com;SameSite=none;Secure"
            }

            document.cookie = key + "=" + value + "; " + expires + "; path=" + path;
        };

        service.hasCookie = function (key) {
            return service.getCookie(key) != "";
        };

        service.getCookie = function getCookie(key) {
            var name = key + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1);
                if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
            }
            return "";
        }

        return service;

    }

});

