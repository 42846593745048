define('tagpacker-angular/directives/login/hlineWithTextDirective',[], function(){

	return ['loginService', function(loginService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/hlineWithTextDirective.html',
			restrict: 'E',
			replace: true
		};
		
	}];

});
