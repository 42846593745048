define('tagpacker-angular/directives/user/userDirective',[], function(){

	var user = function factory(userListOverlayService) {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/user/userDirective.html',
			replace: true,
			restrict: 'E',
			scope: {
				user: '='
			}, 
			controller: ['$scope', function($scope) {
				$scope.openFollowersOverlay= function(user) {
					userListOverlayService.openFollowersOverlay(user);
				};
			}]
		};
	};
	
	user.$inject=['userListOverlayService'];
	
	return user;

});
