define('tagpacker-angular/directives/login/tosDirective',[], function(){	

	return function factory() {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/tosDirective.html',
			scope: {
				form: '=',
				session: '='
			},
			replace: true,
			restrict: "E"
		};
		
	};

});
