define('tagpacker-angular/services/filterService',['classes/Znippet', 'classes/Filter', 'tagpackerUtils', 'tagpackerModule'],
	   function(Znippet, Filter, tagpackerUtils, tagpackerModule)
{
	tagpackerModule.factory('filterService', filterService);

	filterService.$inject = ['tagNameInUrlEncoder', '$state', '$stateParams', '$rootScope', 'authService'];

	function filterService(tagNameInUrlEncoder, $state, $stateParams, $rootScope, authService) {

		var FILTER_STATE = 'user.main.filter';

		var user;
		var filterTags = [];
		var excludedPack = null;

		// not in url
		var prefixQuery = null;

		function encodeFilterInParams() {
			var excludedTags = filterTags.filter(function(tag) {
				return tag.excluded == true;
			});
			$stateParams.nt = serializeTags(excludedTags);
			
			var tags = filterTags.filter(function(tag) {
				return tag.excluded == false;
			});
			$stateParams.t = serializeTags(tags);
			
			$stateParams.comb = excludedPack == null ? null : excludedPack.id;

			if ($state.current && $state.current.name == FILTER_STATE) {
				$state.go(FILTER_STATE, $stateParams);
			}
		}

		function serializeTags(tags) {
			var encodedNames = [];
			for (var i=0; i<tags.length; i++) {
				encodedNames.push(tagNameInUrlEncoder.encode(tags[i].name));
			}
			return encodedNames.length > 0 ? encodedNames.join(',') : null;
		}

		var service = {};

		service.clear = function() {
			service.setTags([]);
			service.setExcludedPack(null);
			service.setQuery(null);
			service.setPrefixQuery(null);
			service.setVisibility(null);
			service.setReachability(null);
			service.setNumberOfTags(null);
			encodeFilterInParams();
		};

		service.setUser = function(newUser) {
			user = newUser;
		};

		service.getUser = function() {
			return user;
		};

		service.getUsername = function() {
			return $stateParams.username;
		};
		

		service.addTag = function(tag) {
			if (!service.containsFilter(tag)) {
				filterTags.push(tag);
				encodeFilterInParams();
			}
		};

		service.setTags = function(tags, excludedTags) {
			if(!excludedTags) {
				excludedTags = [];
			}
			excludedTags.forEach(function(excludedTag) {
				excludedTag.excluded = true;
			});
			var allTags = excludedTags.concat(tags);
			tagpackerUtils.morphTagArray(filterTags, allTags);
			encodeFilterInParams();
		};
		
		service.toggleExclude = function(tag) {
			var filterTagIds = service.getTagIds();
			var index = filterTagIds.indexOf(tag.id);
			if(index > -1) {
				filterTags[index].excluded = !filterTags[index].excluded;
			}
			encodeFilterInParams();
		};
		
		service.setExcludedPack = function(pack) {
			excludedPack = pack;
			encodeFilterInParams();
		};

		service.getExcludedPack = function() {
			return excludedPack;
		};

		service.getTags = function() {
			return [].concat(filterTags);
		};

		service.getExcludedPackId = function() {
			return $stateParams.comb ? $stateParams.comb : null;
		};

		service.getVisibility = function() {
			return $stateParams.v ? $stateParams.v : null;
		};

		service.getReachability = function() {
			return $stateParams.r ? $stateParams.r === 'true' : null;
		};

		service.getNumberOfTags = function() {
			return $stateParams.c ? $stateParams.c : null;
		};

		removeTag = function(tag) {
			for (var i=0 ; i<filterTags.length ; i++) {
				if (filterTags[i].id == tag.id) {
					filterTags.splice(i, 1);
				}
			}
		};

		service.removeTags = function (tags) {
			for(var i = 0; i < tags.length; i++) {
				removeTag(tags[i]);
			}
			encodeFilterInParams();
		};

		service.removeTag = function(tag) {
			removeTag(tag);
			encodeFilterInParams();
		};

		service.containsFilter = function(tag) {
			return service.getTagIds().indexOf(tag.id) > -1;
		};

		service.getTagIds = function() {
			return filterTags.map(function(x) { return x.id });
		};

		service.getTagNamesFromUrl = function() {
			var encodedTagNames = $stateParams.t ? $stateParams.t.split(',') : [];

			return encodedTagNames.map(function(x) {
				return tagNameInUrlEncoder.decode(x);
			});
		};
		
		service.getExcludedTagNamesFromUrl = function() {
			var encodedTagNames = $stateParams.nt ? $stateParams.nt.split(',') : [];
			return encodedTagNames.map(function(x) {
				return tagNameInUrlEncoder.decode(x);
			});
		};

		service.getFilter = function() {
			var result = new Filter(user);
			filterTags.forEach(function(eachTag) {
				result = result.withTag(eachTag);
			});
			if (excludedPack) {
				result = result.withExcludedPack(excludedPack);
			}
			if (service.getQuery()) {
				result = result.withQuery(service.getQuery());
			}
			if (service.getVisibility()) {
				result = result.withVisibility(service.getVisibility());
			}
			if (service.getReachability() != null) {
				result = result.withReachability(service.getReachability());
			}
			if (service.getNumberOfTags() != null) {
				result = result.withNumberOfTags(service.getNumberOfTags());
			}
			return result;
		};

		var isAContainedInB = function(arrayA, arrayB) {
			result = true;
			for (var i=0 ; i<arrayA.length && result ; i++) {
				result = arrayB.indexOf(arrayA[i]) !== -1;
			}
			return result;
		};

		service.setQuery = function(q) {
			$stateParams.q = q;
		};

		service.setVisibility = function(v) {
			$stateParams.v = v;
			encodeFilterInParams();
		};

		service.setReachability = function(r) {
			$stateParams.r = r;
			encodeFilterInParams();
		};

		service.setNumberOfTags = function(c) {
			$stateParams.c = c;
			encodeFilterInParams();
		};

		service.setPrefixQuery = function(q) {
			prefixQuery = q;
		};

		service.getPrefixQuery = function() {
			return prefixQuery;
		};

		service.getQuery = function() {
			return $stateParams.q || null;
		};

		service.getEffectiveQuery = function() {
			return service.getQuery() || prefixQuery;
		};

		service.isEmpty = function() {
			return 	service.getTags().length == 0 &&
					service.getExcludedPack() == null &&
					!service.getEffectiveQuery() &&
					service.getVisibility() == null &&
					service.getReachability() == null &&
					service.getNumberOfTags() == null;
		};

		// The watch is placed on the passed scope, so it will not execute after the scope has died.
		// This way we don't have to unregister callbacks.
		service.onFilterChange = function(scope, callback) {
			scope.$watch(function() {
				return {
					username: typeof $stateParams.username === 'undefined' ? null : $stateParams.username,
					nt: typeof $stateParams.nt === 'undefined' ? null : $stateParams.nt,
					t: typeof $stateParams.t === 'undefined' ? null : $stateParams.t,
					comb:  typeof $stateParams.comb === 'undefined' ? null : $stateParams.comb,
					q: prefixQuery,
					q1: $stateParams.q === 'undefined' ? null : $stateParams.q,
					v: $stateParams.v === 'undefined' ? null : $stateParams.v,
					r: $stateParams.r === 'undefined' ? null : $stateParams.r,
					c: $stateParams.c === 'undefined' ? null : $stateParams.c
				};
			}, function(newValue, oldValue) {
				if (newValue.username != null) {
					var reason = null;

					var newFilter =  newValue.t ? newValue.t.split(',') : [];
					var oldFilter =  oldValue.t ? oldValue.t.split(',') : [];

					if (newValue.username == oldValue.username) {
						if (newValue.comb != oldValue.comb) {
						    reason = newValue.comb === null ? 'widen' : 'narrow';
						}
						if (newValue.t != oldValue.t) {
							if (isAContainedInB(newFilter, oldFilter)) {
								reason = (reason == 'widen' || reason == null) ? 'widen' : 'jump';
							}
							else if (isAContainedInB(oldFilter, newFilter)) {
								reason = (reason == 'narrow' || reason == null) ? 'narrow' : 'jump';
							}
						}
					}

					if (reason == null) {
						reason = 'jump';
					}

					callback(reason);
				}
			}, true);
		};

		return service;

	}


});

