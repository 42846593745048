define('tagpacker-angular/controllers/activitiesList/PopularUsersController',['classes/User', 'tagpackerUtils'], function(User, tagpackerUtils){

	var	PopularUsersController = function($scope, listLoader) {
		
		$scope.userList = listLoader.create('/api/popularUsers', {
			chunkSize: 8,
			morphArray: function(oldArray, newArray) {
				newArray = User.constructArray(newArray);
				tagpackerUtils.morphUserArray(oldArray, newArray);
			}
		});
		$scope.userList.load();
		
		$scope.$on('refreshPage', function() {
			$scope.userList.refresh();
		});

	};
	
	PopularUsersController.$inject=['$scope', 'listLoader'];
	
	return PopularUsersController;

});
		
