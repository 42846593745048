define('tagpacker-angular/controllers/UntagAllDialogController',[], function(){

	return ['$scope', '$uibModalInstance', 'applier', 'znippetService', 'loadingService', 
	        function($scope, $uibModalInstance, applier, znippetService, loadingService) {
	
		$scope.tags = [];
		$scope.userId = applier.getUserId();
		$scope.isNoTagsErrorVisible = false;
		
		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
		
		$scope.submit = function() {
			if ($scope.tags.length > 0 ) {
				untagAllMatchingLinks();
			}
			else {
				$scope.isNoTagsErrorVisible = true;
			}
		};
		
		function untagAllMatchingLinks() {
			loadingService.showModalLoadingAnimation();
			applier.apply($scope.tags)
				.then(function() {
					$uibModalInstance.close();
				}).finally(function () {
					loadingService.hideModalLoadingAnimation();
	            });
		}
		
		$scope.$watch('tags.length', function(newValue) {
			if (newValue > 0 ) {
				$scope.isNoTagsErrorVisible = false;
			}
		});

	}];
	
});
