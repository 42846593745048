define('tagpacker-angular/directives/sessionDirective',[], function(){	
	
	var session = function(primaryDataService) {
	    return {
	        link: function(scope, element, attrs) {
	        	for (property in scope.form) {
	            	if (property && scope.form[property] && scope.form[property].$dirty !== undefined) {
				  	    var p = primaryDataService.getFormField(property);
				  	    if (p !== null) {
				  	    	scope.form[property].$setViewValue(p.value);
					  	    scope.form[property].$render();
				  	    }
				    }
				}
	        }
	    };
	};
	
	session.$inject=['primaryDataService'];
	
	return session;
	
});
