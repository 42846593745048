define('tagpacker-angular/directives/ad/googleAdDirective',[], function(){	
	
	var googleIns = function factory($window, $compile, $timeout, $location, recommendationService, utilService) {
	    var template = '<div style="position:relative; width:100%; height:100%">' +
	    					'<div ng-show="recommendationService.blocked" ng-class="{localhost: isLocalhost()}" class="blocker" style="position:absolute;width:100%;height:100%;"></div>' + 
	    					'<ins ng-click="handleClick()" class="adsbygoogle" data-ad-client="xxx" data-ad-slot="yyy" data-format="auto"></ins>' + 
	    				'</div>';
	    var timeout = null;
	    return {
	      restrict: 'A',
	      replace: false,
	      link: function postLink(scope, element, iAttrs) {
	      	scope.recommendationService = recommendationService;
	      	
	      	scope.handleClick = function () {
	      		if(scope.isLocalhost()) {
	      			alert("ad clicked");
	      		}
	      	};
	      	
	      	scope.refreshAd = function() {
	      		if (element.hasClass('otherAd')) {
	      			element.removeClass('otherAd');
	      		}
	      		else {
	      			element.addClass('otherAd');
	      		}
	      	
	      		if (!$window.adsbygoogle) {
			        $window.adsbygoogle = [];
			    }
			    $window.adsbygoogle.push({});
			};
	      
	        scope.$watch(function() { return $location.url(); }, function(newValue, oldValue) {
			  if (element.is(':visible')) {
			  	element.html("");
		        element.append(angular.element($compile(template)(scope)));
		        scope.refreshAd();
			  } 
			});
			
			scope.isLocalhost = function () {
				utilService.isLocalhost();
			};
	      }
	    };
	  };
	  
	  googleIns.$inject=['$window', '$compile', '$timeout', '$location', 'recommendationService', 'utilService'];
	  
	  return googleIns;
	  
});

