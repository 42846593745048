define('tagpacker-angular/controllers/kpis/KPIsController',[], function(){

	return ['$scope', '$http', function($scope, $http) {
		
		$scope.kpis = [];
		
		$http.get('/api/kpis').success(function(data) {
			$scope.kpis = [];
			for(var key in data) {
				$scope.kpis.push({
					key: key,
					value: data[key]
				});
			}
		});
	}];
	
});
