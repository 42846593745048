define('tagpacker-angular/services/windowLocation',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('windowLocation', windowLocation);

	windowLocation.$inject = ['$window'];

	function windowLocation ($window) {
		return {
			assign: function(url) {
				$window.location.assign(url);
			},
			reload: function() {
				$window.location.reload();
			}
		};
	}

});

