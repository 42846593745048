define('tagpacker-angular/directives/user/userPreviewDirective',[], function(){
	
	var userPreview = function factory(userListOverlayService) {
	                                        
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/user/userPreviewDirective.html',
			replace: true,
			restrict: 'E', 
			scope: {
				user: '=',
				withFollowButton: '&'
			},
			controller: ['$scope', function($scope) {
				$scope.openFollowersOverlay= function(user) {
					userListOverlayService.openFollowersOverlay(user);
				}
			}]
		};
		
	};
	
	userPreview.$inject=['userListOverlayService'];
	
	return userPreview;
	
});
