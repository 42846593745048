define('tagpacker-angular/controllers/TagAllDialogController',[], function(){

	return ['$scope', '$uibModalInstance', 'applier', 'tagService', '$q', 'loadingService', 
	        function($scope, $uibModalInstance, applier, tagService, $q, loadingService) {
		$scope.tags = [];
		$scope.userId = applier.getUserId();
		$scope.isNoTagsErrorVisible = false;
		
		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
		
		$scope.submit = function() {
			if ($scope.tags.length > 0) {
				submitAndClose();
			}
			else {
				$scope.isNoTagsErrorVisible = true;
			}
		};
		
		function submitAndClose() {
			loadingService.showModalLoadingAnimation();
			createTagsAndTagAllLinks().then(function() {
				$uibModalInstance.close();	
			}).finally(function () {
				loadingService.hideModalLoadingAnimation();
            });
		}
		
		function createTagsAndTagAllLinks() {
			return getCreatedTags($scope.tags.concat([])).then(function(createdTags) {
				return applier.apply(createdTags);
			});
		}
		
		function resolved(value) {
			var deferred = $q.defer();
			deferred.resolve(value);
			return deferred.promise;
		}
		
		function getCreatedTags(inputTags) {
			if (inputTags.length == 0) {
				return resolved([]);
			}
			
			return createTagIfRequired(inputTags.shift()).then(function(createdTag) {
				return getCreatedTags(inputTags).then(function(createdTags) {
					return [createdTag].concat(createdTags);
				});
			});
		}
		
		function createTagIfRequired(tag) {
			if (tag.isNew()) {
				return tagService.saveNew(tag, $scope.userId);
			}
			else {
				return resolved(tag);
			}
		}
		
		$scope.$watch('tags.length', function(newValue) {
			if (newValue > 0 ) {
				$scope.isNoTagsErrorVisible = false;
			}
		});
		 
	}];
	
});

