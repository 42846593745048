define('tagpacker-angular/services/listLoader',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('listLoader', listLoader);

	listLoader.$inject = ['$http','$q'];

	function listLoader($http, $q) {
	
		return {
			create: function(url, options) {
				options = options ? options : {};
				options.morphArray = options.morphArray ? options.morphArray : simpleMorph; 
				options.transformElement = options.transformElement ? options.transformElement : function(e) {return e;}; 
				
				return new RemoteList(url, options);
			}
		};
		
		function RemoteList(url, options) {
			var loading;
			var complete = false;
			var lastLimit;
			var canceler;
			
			var self = this;
			
			this.elements = [];
			
			this.isLoading = function() {
				return loading;
			};
			
			this.isComplete =function() {
				return !loading && (!isLimited() || complete);
			};
			
			this.load = function() {
				return sendRequest(isLimited() ? options.chunkSize : undefined);
			};
			
			this.loadMore = function() {
				if (!loading) {
					sendRequest(self.elements.length + options.chunkSize);
				}
			};
			
			this.hasElements = function() {
				return self.elements.length > 0;
			};
			
			this.refresh = function() {
				if (loading) {
					var deferred = $q.defer();
					deferred.reject('already loading');
					return deferred.promise;
				}
				return sendRequest(lastLimit);
			};
			
			function isLimited() {
				return options.chunkSize > 0;
			}
			
			this.cancel = function() {
				if(canceler) {
					canceler.resolve();
				}
			};
	
			function sendRequest(limit) {
				loading = true;
				lastLimit = limit;
				canceler = $q.defer();
				return $http.get(url, { 
					params: typeof limit != 'undefined' ? { limit: limit + 1} : {},
					timeout: canceler.promise
				}).then(function(response) {
					var answer = response.data;
					
					if (typeof limit != 'undefined') {
						complete = answer.length <= limit;
						if (!complete) {
							answer.pop();
						}
					}
					
					for (var i=0 ; i<answer.length ; i++) {
						answer[i] = options.transformElement(answer[i]);
					}
					
					options.morphArray(self.elements, answer);
				}).finally(function(){
					loading = false;
				});
			}
	
		}
		
		function simpleMorph(array, newElements) {
			while (array.length > 0) {
				array.pop();
			}
			for (var i=0 ; i<newElements.length ; i++) {
				array.push(newElements[i]);
			}
		}
		
	}

});

