define('tagpacker-angular/directives/companyAddress/companyAddressDirective',[], function(){
	
	return function factory() {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/companyAddress/companyAddress.html',
			replace: true,
			restrict: 'E',
			scope: {
			},
			link: function postLink(scope, element, iAttrs) {
			}
		};
		
	};
	
});
