define('tagpacker-angular/services/superTagDialogService',['classes/Tag', 'tagpackerModule'], function(Tag, tagpackerModule){

	// inline template because it seems the bootstrap modal does not use the template cache (problem in tests)
	// TODO: google the problem when internet is available...
	var template =
	   '<div class="dialog super-tag-dialog" ng-class="{loading: loadingTag}">'
		+ '<div class="modal-header">'
		+   '<h3>Select a super tag for tagpack "{{pack.name}}"</h3>'
		+ '</div>'
		+ '<div class="modal-body">'
		//   we have to use $parent because of the isolated scope of ng-if
		+   '<single-tag-input tag="$parent.superTag" user-id="pack.ownerId" input-focus '
		+           'ng-if="!loadingTag" placeholder="\'Enter a tag\'"></single-tag-input>'
		// as soon as this becomes some kind of pattern, these inline-styles should be moved to an appropriate class
		+   '<p style="margin-top: 10px; margin-left: 5px;">If you don\'t select a tag, the tagpack will be always visible.</p>'
	    + '</div>'
	    + '<div class="modal-footer">'
	    +   '<button class="btn btn-link cancel-button" ng-click="cancel()">Cancel</button>'
	    +   '<button class="btn btn-primary save-button" ng-click="save()">Save</button>'
	    + '</div>'
		+ '</div>';

	tagpackerModule.factory('superTagDialogService', superTagDialogService);

	superTagDialogService.$inject = ['modalService'];

	function superTagDialogService(modalService) {
		return {
			open: function(pack) {
				return modalService.openBootstrapModal({
					template: template,
					controller: controller,
					resolve: {
						pack: function() { return pack }
				    }
				}).result;
			}
		}
	}
	
	controller.$inject = ['$scope', '$uibModalInstance', 'pack', 'tagService', 'tagpackService'];
	
    function controller($scope, $uibModalInstance, pack, tagService, tagpackService) {
		$scope.pack = pack;
		$scope.superTag = null;
		$scope.loadingTag = false;
		
		if (pack.superTagId) {
			$scope.loadingTag = true;
			tagService.loadTag(pack.ownerId, pack.superTagId)
				.then(function(superTag) {
					$scope.superTag = superTag;
					$scope.loadingTag = false;
				});	
		}
		
		$scope.save = function() {
			tagpackService.setSuperTag($scope.pack, $scope.superTag)
				.then(function(updatedPack) {
					$uibModalInstance.close(updatedPack);
				});
		};
		
		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancelled');
		};
	}
	
});

