define('tagpacker-angular/directives/user/userPhoto',[], function(){

	return function factory() {
		return {
			restrict: 'A',
			scope: {
				user: '=userPhoto'
			},
			link : function postLink(scope, element, attributes) {
				scope.$watch('user.photoUrl', function(photoUrl) {
					if (photoUrl) {
						element.attr('src', scope.user.getPhotoUrl());
						element.removeClass('default-photo');
					}
					else {
						// default user photo is transparent so we can use different images as background in different places 
						element.attr('src', '/assets/images/transparent.png');
						element.addClass('default-photo');
					}
				});
				element.addClass('user-photo');
			}
		};
	};

});

