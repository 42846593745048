define('urlSemicolonFix',['angular', 'tagpackerUtils'], function(angular, tagpackerUtils) {
	'use strict';
	
	//separate module needed to be able to mock $httpBackend methods in Jasmine tests; don't load this module in test environment
	var urlSemicolonFix = angular.module('urlSemicolonFix', []);
	urlSemicolonFix.config(['$provide', function($provide){
		$provide.decorator('$httpBackend', ['$delegate', function($delegate) {
			return function(method, url, post, callback, headers, timeout, withCredentials, responseType) {
				url = tagpackerUtils.fixSemicolons(url);
				$delegate(method, url, post, callback, headers, timeout, withCredentials, responseType);
			};
		}]);	
	}]);
	return urlSemicolonFix;
});
