define('tagpacker-angular/directives/navbarSearchInput/navbarSearchInput',[], function(){

	var navbarSearchInput = function factory($state) {
		var SEARCH_STATE = 'search.main';
		
		return {
			replace: true,
			restrict: 'E',
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/navbarSearchInput/navbarSearchInput.html',
			link : function postLink(scope, element, iAttrs) {
				var input = element.find('input');
				
				function isOnSearchPage() {
					return $state.is(SEARCH_STATE);
				}
				
				scope.$watch(function() { return $state.params.q }, function(value) {
					if (isOnSearchPage() && $state.params.q) {
						//by setting empty before the cursor is always at the end of the input (http://stackoverflow.com/questions/637287)
						input.focus().val('').val($state.params.q);
					}
				});
				
				element.bind('keypress', function(event) {
					if (event.which === $.ui.keyCode.ENTER) {
						scope.$apply(function() {
							var query = input.val().trim();
							if (query!='') {
								$state.go(SEARCH_STATE, {q: query});
							}
						});
					}
				});
			}
		};
	};
	
	navbarSearchInput.$inject=['$state'];
	
	return navbarSearchInput;

});
