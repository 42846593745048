define('tagpacker-angular/controllers/activitiesList/ActivitiesSidebarController',['classes/User', 'tagpackerUtils'], function (User, tagpackerUtils) {

    return ['$scope', 'authService', 'userListOverlayService', 'listLoader', '$http', 'alertService',
        'inviteFriendsDialogService', 'modalService', 'loadingService', 'dialogService', 'analytics', 'channelService', 'navigationService',
        function ($scope, authService, userListOverlayService, listLoader, $http, alertService,
                  inviteFriendsDialogService, modalService, loadingService, dialogService, analytics, channelService, navigationService) {

            $scope.owner = null;

            loadOwner();

            function loadOwner() {
                $http.get('/api/users/' + authService.getSessionUser().id).success(function (data) {
                    $scope.owner = new User(data);
                });
            };

            $scope.suggestedUsersList = listLoader.create('/api/socialNetworkFriends', {
                chunkSize: 3,
                morphArray: function (oldArray, newArray) {
                    newArray = User.constructArray(newArray);
                    tagpackerUtils.morphUserArray(oldArray, newArray);
                }
            });
            $scope.suggestedUsersList.load();

            $scope.similarUsersList = listLoader.create('/api/userRecommendationsForUser?id=' + authService.getSessionUser().id, {
                chunkSize: 3,
                morphArray: function (oldArray, newArray) {
                    newArray = User.constructArray(newArray);
                    tagpackerUtils.morphUserArray(oldArray, newArray);
                }
            });
            $scope.similarUsersList.load()

            $scope.$watch(function () {
                return channelService.getChannelName();
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    updateChannelFollowSuggestions(newValue);
                }
            });

            function updateChannelFollowSuggestions(channelName) {
                if (channelName != null) {
                    if ($scope.channelFollowSuggestions) {
                        $scope.channelFollowSuggestions.cancel();
                    }
                    $scope.channelFollowSuggestions = listLoader.create('/api/channelFollowSuggestions?channelName=' + encodeURIComponent(channelName), {
                        chunkSize: 3,
                        morphArray: function (oldArray, newArray) {
                            newArray = User.constructArray(newArray);
                            tagpackerUtils.morphUserArray(oldArray, newArray);
                        }
                    });
                    $scope.channelFollowSuggestions.load();
                } else {
                    $scope.channelFollowSuggestions = null;
                }
            }

            $scope.channels = [];
            $scope.channelsInitiallyLoaded = false;
            loadChannels().then(function () {
                $scope.channelsInitiallyLoaded = true;
            });

            function loadChannels() {
                return channelService.getAll().then(function (channels) {
                    $scope.channels = channels;
                    var channelName = channelService.getChannelName();
                    if (channelName) {
                        for (var i = 0; i < $scope.channels.length; i++) {
                            if ($scope.channels[i].name.toLowerCase() == channelName.toLowerCase()) {
                                $scope.channels[i].selected = true;
                                $scope.channels[i].unseen = 0;
                                break;
                            }
                        }
                    }
                });
            };

            $scope.showChannelInfo = function () {
                modalService.openBootstrapModal({
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/channelInfo.html',
                    controller: ['$scope', '$uibModalInstance', function InfoDialogController($scope, $uibModalInstance) {
                        $scope.close = function (result) {
                            $uibModalInstance.close();
                        };
                    }]
                });
            };

            $scope.addChannel = function () {
                modalService.openBootstrapModal({
                    backdrop: 'static',
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/channelAddDialog.html',
                    controller: ['$scope', '$uibModalInstance',
                        function TagEditDialogController($scope, $uibModalInstance) {
                            $scope.channel = null;
                            $scope.saveIfValid = function () {
                                if ($scope.form.$valid) {
                                    $uibModalInstance.close($scope.channel);
                                }
                            };
                            $scope.cancel = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                            $scope.getTags = function (query) {
                                var prefixes = [];
                                query.trim().split(' ').forEach(function (prefix) {
                                    if (prefix.length > 0) {
                                        prefixes.push(prefix);
                                    }
                                });
                                return $http.get('/api/tags', {
                                    params: {prefix: prefixes, limit: 5}
                                }).then(function (response) {
                                    return response.data;
                                });
                            };
                        }]
                }).result.then(function (channelName) {
                    if (!containsChannel(channelName)) {
                        loadingService.showModalLoadingAnimation();
                        $http.post('/api/users/' + authService.getSessionUser().id + '/channels', {name: channelName}).then(function (response) {
                            analytics.sendChannelEvent('channel created', 'create');
                            return loadChannels();
                        }).finally(function () {
                            loadingService.hideModalLoadingAnimation();
                        });
                    }
                });
            };

            //otherwise activity stream and channels badge numbers are not synchronized
            $scope.$on('windowFocus', function () {
                loadChannels();
            });

            function containsChannel(channel) {
                for (var i = 0; i < $scope.channels.length; i++) {
                    if ($scope.channels[i].name.toLowerCase() == channel.toLowerCase()) {
                        return true;
                    }
                }
                return false;
            }

            $scope.removeChannel = function (channel) {
                dialogService.showDeleteDialog('Channel').then(function () {
                    var index = $scope.channels.indexOf(channel);
                    if (index >= 0) {
                        channelService.delete(channel).then(function () {
                            analytics.sendChannelEvent('channel deleted', 'delete');
                            $scope.channels.splice(index, 1);
                            if (channel.selected) {
                                navigationService.goNetwork();
                            }
                        });
                    }
                });
            };

            $scope.$watch(function () {
                return channelService.getChannelName();
            }, function (newValue, oldValue) {
                if (newValue != oldValue) {
                    loadChannels();
                }
            });

            $scope.selectChannel = function (channel) {
                if (channel.selected) {
                    navigationService.goNetwork();
                    analytics.sendChannelEvent('channel deselected', 'deselect');
                } else {
                    channel.lastVisit = new Date().getTime();
                    channelService.update(channel).then(function () {
                        channelService.open(channel);
                        var label = 'channel selected (' + channel.unseen + ')';
                        analytics.sendChannelEvent(label, 'select');
                    });
                }
            };

            $scope.$on('refreshPage', function () {
                $scope.suggestedUsersList.refresh();
                loadOwner();
            });

            $scope.$on('follow', function (event, eventData) {
                alertService.addSuccessAlert('You are now following ' + eventData.user.name + '.');
            });

            $scope.openFollowersOverlay = function (user) {
                userListOverlayService.openFollowersOverlay(user);
            }

            $scope.openFollowingOverlay = function (user) {
                userListOverlayService.openFollowingOverlay(user);
            }

            $scope.openSuggestedUsersOverlay = function () {
                userListOverlayService.openSuggestedUsersOverlay();
            }

            $scope.openChannelFollowSuggestionsOverlay = function () {
                userListOverlayService.openChannelFollowSuggestionsOverlay(channelService.getChannelName());
            };

            $scope.openSimilarUsersOverlay = function (user) {
                userListOverlayService.openSimilarUsersOverlay(user.id);
            };

            $scope.showInviteFriendsDialog = function (owner) {
                inviteFriendsDialogService.open(owner);
            };

        }];

});

