define('tagpacker-angular/directives/login/registerElements',[], function(){

	return ['loginService', '$state', function(loginService, $state) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/registerElements.html',
			scope: {
				onLogin: '&'
			},
			restrict: 'E',
			controller: ['$scope', function($scope) {
				$scope.login = function () {
					$scope.onLogin();
					if ($state.is('register')) {
						$state.go('login');
					} else {
						loginService.openLoginDialog("switch from register");
					}
				};
			}],
			link: function postLink(scope, element, attributes) {
			}
		};
		
	}];

});
