define('tagpacker-angular/services/sortService',['tagpackerModule'],
    function (tagpackerModule) {
        tagpackerModule.factory('sortService', sortService);

        sortService.$inject = ['$state', '$stateParams', 'analytics'];

        function sortService($state, $stateParams, analytics) {

            var FILTER_STATE = 'user.main.filter';

            var SORT_FIELD_DATE = null;
            var SORT_FIELD_CLICKS = 'CLICKS';
            var SORT_FIELD_TITLE = 'TITLE';

            var SORT_ORDER_ASC = 'ASC';
            var SORT_ORDER_DSC = 'DSC';

            var service = {};

            var setSort = function (sort) {
                if ($state.current && $state.current.name == FILTER_STATE) {
                    $stateParams.sf = sort.field;
                    $stateParams.so = sort.order;
                    $state.go(FILTER_STATE, $stateParams);
                }
            };
            
            service.isSortASC = function () {
            	return $stateParams.so == SORT_ORDER_ASC;
            };

            service.getSort = function () {
                return makeSort($stateParams.sf, $stateParams.so);
            };

            service.sortByClicks = function () {
                var SORT_ORDER = null;
                //preview state: sort by clicks --> invert sort
                if (service.isSortByClicks()) {
                    SORT_ORDER = $stateParams.so == SORT_ORDER_ASC ? null : SORT_ORDER_ASC;
                }
                setSort(makeSort(SORT_FIELD_CLICKS, SORT_ORDER));
                analytics.sendLinkEvent('clicks', 'sort');
            };

            service.sortByTitle = function () {
                var SORT_ORDER = SORT_ORDER_ASC;
                //preview state: sort by title --> invert sort
                if (service.isSortByTitle()) {
                    SORT_ORDER = $stateParams.so == SORT_ORDER_ASC ? null : SORT_ORDER_ASC;
                }
                setSort(makeSort(SORT_FIELD_TITLE, SORT_ORDER));
                analytics.sendLinkEvent('title', 'sort');
            };
            service.sortByDate = function () {
                var SORT_ORDER = null;
                //preview state: sort by date --> invert sort
                if (service.isSortByDate()) {
                    SORT_ORDER = $stateParams.so == SORT_ORDER_ASC ? null : SORT_ORDER_ASC;
                }
                setSort(makeSort(SORT_FIELD_DATE, SORT_ORDER));
                analytics.sendLinkEvent('date', 'sort');
            };

            service.isSortByClicks = function () {
                return $stateParams.sf === SORT_FIELD_CLICKS;
            };

            service.isSortByTitle = function () {
                return $stateParams.sf === SORT_FIELD_TITLE;
            };

            service.isSortByDate = function () {
                return !service.isSortByClicks() && !service.isSortByTitle();
            };

            // The watch is placed on the passed scope, so it will not execute after the scope has died.
            // This way we don't have to unregister callbacks.
            service.onSortChange = function (scope, callback) {
                scope.$watch(function () {
                    return {
                        sf: typeof $stateParams.sf === 'undefined' ? null : $stateParams.sf,
                        so: typeof $stateParams.so === 'undefined' ? null : $stateParams.so
                    };
                }, function (newValue, oldValue) {
                    callback();
                }, true);
            };

            function makeSort(field, order) {
                return {
                    field: field,
                    order: order
                }
            }

            return service;

        }


    });

