define('tagpacker-angular/directives/bookmarklet/bookmarkletHelpDirective',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.directive('bookmarkletHelp', bookmarkletHelp);

	bookmarkletHelp.$inject = [];

	var videoUrls = {
	    'Firefox': 'http://youtu.be/B82IreVlU-I',
		'Chrome': 'http://youtu.be/UI0bUzjaywA'
	};
	
	function bookmarkletHelp() {
		
		BookmarkletHelpController.$inject = ['$scope', 'browserDetector'];
		
		function BookmarkletHelpController($scope, browserDetector) {
			var browserName = browserDetector.getBrowserName();
			$scope.videoUrl = videoUrls[browserName];
			$scope.isChrome = browserName == 'Chrome';
			$scope.isFirefox = browserName == 'Firefox';
		};
		
		return {
			scope: true,
			replace: true,
			restrict: 'E', 
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/bookmarklet/bookmarkletHelpDirective.html',
			controller: BookmarkletHelpController
		};
	}
	
});

