define('tagpacker-angular/directives/login/loginElements',[], function(){

	return ['loginService', '$state', function(loginService, $state) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/loginElements.html',
			scope: {
				onRegister: '&', 
				onForgotPassword: '&'
			},
			restrict: 'E',
			controller: ['$scope', function($scope) {
				$scope.register = function () {
					$scope.onRegister();
					if ($state.is('login')) {
						$state.go('register');
					} else {
						loginService.openRegisterDialog("switch from login");
					}
				};
			}],
			link: function postLink(scope, element, attributes) {
			}
		};
		
	}];

});
