define('tagpacker-angular/directives/validUrl',['tagpackerUtils'], function(tagpackerUtils){	

	
	var validUrl = function ($timeout) {
		return {
			require: 'ngModel',
	  		link: function(scope, elem, attr, ngModel) {
	  			var timeout;
	      		//For DOM -> model validation
	      		ngModel.$parsers.unshift(function(value) {	
	         		$timeout.cancel(timeout);
	         		if (value && value.length > 0) {
	         			timeout = $timeout(function() {
	         				ngModel.$setValidity('validUrl', tagpackerUtils.isValidUrl(value));
		         		}, 250);
	         		} else {
	         			ngModel.$setValidity('validUrl', true);
	         		}
	         		return value;
	      		});
	  		}
		};
	};
	
	validUrl.$inject=['$timeout'];

	return validUrl;

});
