define('tagpacker-angular/directives/autocomplete/autocompleteInput',[], function(){

	return ['$timeout', function factory($timeout) {
		return {
			restrict: 'E',
			replace: true,
			scope: {
				value: '=',
				onChange: '&',
				onDown: '&',
				onUp: '&',
				onBackspace: '&',
				onEscape: '&',
				onEnter: '&',
				onFocus: '&',
				onBlur: '&',
				placeholder: '&',
				delay: '='
			},
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/autocomplete/autocompleteInput.html',
			link : function postLink(scope, element, iAttrs) {
	
				element.bind('keydown', function(event) {
					if (event.which == $.ui.keyCode.UP || event.which == $.ui.keyCode.DOWN) {
						$timeout(event.which == $.ui.keyCode.UP ? scope.onUp : scope.onDown);
						// prevent cursor movement in chrome
						event.preventDefault();
					}
					else if (event.which == $.ui.keyCode.BACKSPACE) {
						// we check the real value here because ng-model seems to trim it
						if (element.val().length == 0) {
							$timeout(scope.onBackspace);
						}
					}
				});
				
				element.bind('keyup', function(event) {
					// keyup is used because keypress does not work in FF and keydown seems to early. Apparently
					// the ng-model directive reverts the change in FF when using keydown.
					if (event.which === $.ui.keyCode.ESCAPE) {
						$timeout(scope.handleEscape);
						event.stopPropagation();
					}
				});
				
				element.bind('keypress', function(event) {
					if (event.which === $.ui.keyCode.ENTER) {
						$timeout(function() { scope.onEnter({ $value: scope.value }); });
					}
				});
				
				element.bind('focus', function(event) {
					$timeout(function() {
						scope.onFocus({ $event: event });
					});
				});
				
				element.bind('blur', function(event) {
					$timeout(scope.onBlur);
				});
				
				scope.$watch('placeholder()', function(newValue) {
					element.attr('placeholder', newValue);
				});
				
			},
			controller: ['$scope', '$timeout', function($scope, $timeout) {
				
				var promise = null;
				
				function cancelTimeoutIfScheduled() {
					if (promise != null) {
						$timeout.cancel(promise);
						promise = null;
					}
				}
				
				function sendChangeNotification() {
					$scope.onChange({ $value: $scope.value });
				}
				
				function scheduleChangeNotification() {
					promise = $timeout(sendChangeNotification, $scope.delay);
				}
				
				$scope.$watch('value', function(newValue, oldValue) {
					if (newValue !== oldValue) {
						cancelTimeoutIfScheduled();
						if ($scope.value === '')
							sendChangeNotification()
						else 
							scheduleChangeNotification();
					}
				});
				
				$scope.handleEscape = function() {
					$scope.value = '';
					$scope.onEscape();
				};
				
			}]
		};
	}];
	
});

