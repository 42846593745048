define('tagpacker-angular/directives/overflowDetectorDirective',[], function () {

    var formCommit = function factory() {
        return {
        	restrict: 'A',
        	replace: false,
            link: function postLink(scope, element) {
            	scope.$watch(function() {
            		return element[0].scrollHeight > element[0].clientHeight || element[0].scrollWidth > element[0].clientWidth;
            	}, function(newValue) {
            		if(newValue) {
            			element.addClass('overflowing');
            		} else {
            			element.removeClass('overflowing');
            		}
            	});
            }
        };
    };
    formCommit.$inject = [];
    return formCommit;

});
