define('tagpacker-angular/directives/navbar/navbarEmbeddedDirective',[''], function(){

	var znippetNavbar = function factory() {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/navbar/navbarEmbeddedDirective.html',
			replace: true,
			restrict: 'E',
			scope: {
				owner: '='			
			},
			controller: ['$scope', function($scope) {
				$scope.isSelf = function() {
					return true;
				};
			}],
			link: function postLink(scope, element, attributes) {
				
			}
		};
		
	};
	
	znippetNavbar.$inject=[];
	
	return znippetNavbar;

});

