define('tagpacker-angular/directives/indexEmbeddedVideo/indexEmbeddedVideo',[], function() { return [function() {
	
	return {
		templateUrl: '/assets/javascripts/tagpacker-angular/directives/indexEmbeddedVideo/indexEmbeddedVideo.html',
		replace: true,
		restrict: 'E',
		scope: {
			videoUrl: '@'
		},
		controller: ['$scope', function($scope) {
			$scope.isPlaying = false;
			$scope.play = function() {
				$scope.height = $scope.getHeight() + 'px';
				$scope.isActivated = true;
			}
		}],
		link: function(scope, element, attributes) {
			scope.getHeight = function() {
				return element.height();
			}
		}
	};
	
}]});

