define('classes/Collection',['classes/User', 'classes/Tag', 'classes/Znippet'], function(User, Tag, Znippet){
	
	function Collection(jsObject) {
		this.user = new User(jsObject.user);
		this.tags = jsObject.tags.map(function(x) { return new Tag(x) });
		this.previewLinks = jsObject.previewLinks.map(function(x) { return new Znippet(x) });
		this.url = jsObject.url;
		this.size = jsObject.size;
	}
	
	return Collection;

});
