define('tagpacker-angular/controllers/znippetsList/ZnippetsAdsController',['tagpackerUtils'], function(tagpackerUtils){
	
	var ZnippetsAdsController = function($scope, recommendationService, authService) {
		
		$scope.adZnippets = [];
		$scope.linkRecommendations = [];
		$scope.isDonor = authService.isLoggedIn() && authService.getSessionUser().isDonor;
		
		$scope.$watch(function(){ return recommendationService.getAdZnippets() }, function(newValue, oldValue) {
			if (oldValue !== newValue) {
				updateAds(newValue);
			}
		}, true);
		
		$scope.$watch(function(){ return recommendationService.getLinkRecommendations() }, function(newValue, oldValue) {
			if (oldValue !== newValue) {
				updateLinkRecommendations(newValue);
			}
		}, true);
		
		updateAds(recommendationService.getAdZnippets());
		updateLinkRecommendations(recommendationService.getLinkRecommendations());

		function updateAds(newAds) {
			var newAdZnippets = newAds.slice(0,4);
			tagpackerUtils.morphZnippetArray($scope.adZnippets, newAdZnippets);
		}

		function updateLinkRecommendations(newRecommendations) {
			var newLinkRecommendations = newRecommendations.slice(0,4);
			tagpackerUtils.morphZnippetArray($scope.linkRecommendations, newLinkRecommendations);
		}
		
	};
	
	ZnippetsAdsController.$inject=['$scope', 'recommendationService', 'authService'];
	
	return ZnippetsAdsController;

});

