define('tagpacker-angular/directives/collectionSearchResult/collectionSearchResult',[], function(){

	return function factory() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/collectionSearchResult/collectionSearchResult.html',
			replace: true,
			restrict: 'E', 
			scope: {
				collection: '='
			}
		};
	};

});
