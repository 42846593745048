define('tagpacker-angular/directives/tagInput/uniqueTag',['classes/Tag'], function(Tag){	

	
	//TODO: harmonize with unique directive from usernameDirective file; 
	var uniqueTag = function ($http, $timeout, authService, tagService) {
		return {
			require: 'ngModel',
	  		link: function(scope, elem, attr, ngModel) {
	  			var timeout;
	      		//For DOM -> model validation
	      		ngModel.$parsers.unshift(function(value) {	
	         		$timeout.cancel(timeout);
	         		if (value && value.length > 0) {
	         			timeout = $timeout(function(){
	         				var userId = authService.getSessionUser().id;
		         			tagService.getTagsForName(userId, value).then(function(tagArray) {
		         				if(tagArray && tagArray.length > 0) {
			         				ngModel.$setValidity('unique', tagArray[0].id == scope.tag.id);
			         			} else {
			         				ngModel.$setValidity('unique', true);
			         			}
		         			});
		         		}, 500);
	         		} else {
	         			ngModel.$setValidity('unique', true);
	         		}
	         		return value;
	      		});
	  		}
		};
	};
	
	uniqueTag.$inject=['$http', '$timeout', 'authService', 'tagService'];

	return uniqueTag;

});
