define('tagpacker-angular/services/titleService',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.factory('titleService', titleService);

	titleService.$inject = ['$rootScope'];

	function titleService($rootScope) {
	
		var defaultTitle = "Tagpacker";
		var titleSuffix = " | Tagpacker";
		
		function setTitle(title) {
			$('html head title').text(title);
		}
		
		function getProfileTitle(user, tags, excludedPack) {
			if (tags.length > 0 || excludedPack) {
				return makeGenitive(user.name) + " " + getCollectionName(tags, excludedPack);
			} else {
				return user ? user.name : '';
			}
		}

		function getCollectionName(tags, excludedPack) {
			var tagNames = tags.map(function(x) { return x.name });
			var result = tagNames.join(' - ') + " links";
			if (excludedPack) {
				result += " not tagged with " + excludedPack.name;
			} 
			return result;
		}
		

		function makeGenitive(name) {
			if (endsWith(name, "s") || endsWith(name, "x")) {
				return name + "'";
			} else {
				return name + "'s";
			}
		}
		
		function endsWith(str, suffix) {
		    return str.indexOf(suffix, str.length - suffix.length) !== -1;
		}
		
		return {
			
			init: function() {
				$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
					if (toState.title !== false) {
						setTitle(toState.title ? toState.title + titleSuffix : defaultTitle);	
					}
		        });
			},
			
			setTitle: function(title) {
				setTitle(title + titleSuffix);
			},
			
			getProfileTitleFor: function(filter) {
				return getProfileTitle(filter.getUser(), filter.getTags(),
						filter.getExcludedPack()) + titleSuffix;
			},
			
			setProfileTitleFor: function(filter) {
				setTitle(this.getProfileTitleFor(filter));
			}
			
		};
	
	}

});

