define('tagpacker-angular/directives/api/apiDocLink',['tagpackerUtils'], function(tagpackerUtils){

	var apiDocLink = function factory(primaryDataService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/api/apiDocLink.html',
			replace: true,
			restrict: 'E', 
			link: function postLink(scope, element, attributes) {
				scope.apiDocUrl = primaryDataService.getApiDocUrl();
		    }
		};
	};
	
	apiDocLink.$inject=['primaryDataService'];
	
	return apiDocLink;
	
});
