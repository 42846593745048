define('tagpacker-angular/directives/login/usernameDirective',['classes/Tag'], function(Tag){	

	var username = function factory(primaryDataService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/usernameDirective.html',
			scope: {
				ignoreValidation: '&', 
				form: '=', 
				session: '=', 
				readOnly: '&',
				hint: '&', 
				showLabel: '&'
			},
			replace: true,
			restrict: "E",
			controller: ['$scope', function($scope) {
				$scope.usernamePattern = new RegExp(primaryDataService.getUsernamePattern());
			}],
			link: function postLink(scope, element, attrs) {
				if (scope.ignoreValidation()) {
					scope.$watch('form.username.$viewValue', function(newValue, oldValue) {
					 	if (oldValue !== newValue) {
					 		for (property in scope.form.username.$error) {
								scope.form.username.$setValidity(property,true);
							}
					 	}
					});
				}
			}
		};
		
	};
	
	username.$inject=['primaryDataService'];
	
	var unique = function ($http, $timeout) {
		return {
			require: 'ngModel',
	  		link: function(scope, elem, attr, ngModel) {
	      		
	      		//For DOM -> model validation
	      		ngModel.$parsers.unshift(function(value) {	
	         		$timeout.cancel(scope.timeout);
	         		if (value && value.length > 0) {
	         			scope.timeout = $timeout(function(){
		         			$http({
								method: 'GET',
								url: '/usernameUnique',
								params: {
									username: value
								}
							}).success(function(data, status, headers, config) {
								ngModel.$setValidity('unique', data.unique);
							})
		         		}, 500);
	         		}
	         		return value;
	      		});
	  		}
		};
	};
	
	unique.$inject=['$http', '$timeout'];
	
	return {
		username: username, 
		unique: unique
	}

});
