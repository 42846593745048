define('tagpacker-angular/directives/ad/znippet/adZnippetsDirective',[], function(){

	adZnippets = function factory(analytics) {
		
		return {
			replace: true,
			scope: {
				znippets: '=',
				getUser: '&'
			},
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/ad/znippet/adZnippetsDirective.html',
			link: function postLink(scope, element, attributes) {
			},
			controller: ['$scope', function($scope) {
				$scope.trackClick = function(link) {
					analytics.sendAdEvent(link.sourceUrl);
				};
			}]
		};
		
	};

	adZnippets.$inject=['analytics'];

	return adZnippets;

});
