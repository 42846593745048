define('classes/Znippet',['classes/User', 'classes/Tag'], function(User, Tag){
	
	function Znippet(optionalStateOrId) {
		if (typeof optionalStateOrId === 'undefined' || typeof optionalStateOrId === 'string') {
			this.id = typeof optionalStateOrId === 'string' ? optionalStateOrId : null;
			this.title = '';
			this.tags = [];
			this.thumbnailSource = 'AUTO';
			this.isPrivate = false;
			this.selected = false;
		} else {
			this.update(optionalStateOrId);
		}
	}
	
	function getTagsArray(tags) {
		var result = typeof tags === 'undefined' ? [] : tags;
		if (result.length > 0 && !(result[0] instanceof Tag)) {
			result = Tag.constructArray(result);
		}
		return result;
	}

	Znippet.prototype.update = function(link) {
		this.id = link.id; 
		this.title = link.title; 
		this.description = link.description;
		this.url = link.url;
		this.sourceUrl = link.sourceUrl;
		this.affiliateUrl = link.affiliateUrl;
		this.thumbnailId = link.thumbnailId;
		this.thumbnailUrl = link.thumbnailUrl;
		this.thumbnailSource = link.thumbnailSource;
		this.thumbnailSourceUrl = link.thumbnailSourceUrl;
		this.ownerId = link.ownerId;
		this.tags = getTagsArray(link.tags);
		this.ownLinkId = link.ownLinkId;
		this.sourceZnippetId = link.sourceZnippetId;
		this.liked = link.liked;
		this.isPrivate = link.isPrivate;
		this.selected = link.selected;
		
		this.timeDifference = link.timeDifference;
		this.createdAt = link.createdAt;
		if (link.owner) {
			this.owner = new User(link.owner);
		}
		if (link.sourceZnippetOwner) {
			this.sourceZnippetOwner = new User(link.sourceZnippetOwner);
		}
		this.stats = link.stats ? $.extend({}, link.stats) : undefined;
	}

	Znippet.prototype.clone = function() {
		var clone = new Znippet(this);
		// make a copy of the tags array
		clone.tags = clone.tags.concat([]);
		return clone;
	};

	Znippet.prototype.isNew = function() {
		return this.id == null;
	};

	Znippet.prototype.addTag = function(tag) {
		if (!Array.isArray(this.tags)) {
			this.tags = [];
		}
			
		if (tag.id != null && !this.hasTagWithId(tag.id)) {
			this.tags.push(tag);
		}
	};

	Znippet.prototype.getTagIds = function() {
		var result = [];
		if (Array.isArray(this.tags)) {
			for (var i=0 ; i<this.tags.length ; i++) {
				if (this.tags[i].id != null) {
					result.push(this.tags[i].id);
				}
			}
		}
		return result;
	};

	Znippet.prototype.hasTagWithId = function(tagId) {
		return this.getTagIds().indexOf(tagId) >= 0;
	}

	Znippet.prototype.hasUrl = function () {
		return this.sourceUrl != null;
	};

	//http://stackoverflow.com/questions/2964678/jquery-youtube-url-validation-with-regex
	function isYouTubeVideo(url) {
		var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
	  	return (url.match(p)) ? true : false;
	}

	Znippet.prototype.isYouTubeVideo = function () {
		if(this.sourceUrl) {
			return isYouTubeVideo(this.sourceUrl);
		}
		return false;
	};
	
	Znippet.constructArray = function(znippets) {
		return znippets.map(function(x) { return new Znippet(x); });
	}

	return Znippet;
	
});
