define('tagpacker-angular/services/navigationService',['tagpackerModule'], function(tagpackerModule) {
	tagpackerModule.factory('navigationService', navigationService);

	navigationService.$inject = ['$state', '$stateParams', 'authService', 'filterService', 'viewService'];

	function navigationService($state, $stateParams, authService, filterService, viewService) {

		var FILTER_STATE = 'user.main.filter';

		var service = {};

		service.goIndex = function() {
			$state.go('index');
		};

		service.goNetwork = function() {
			$state.go('network.main');
		};

		service.goEmbed = function(params) {
			params.a = viewService.getEmbeddedViewConfig(true, true, true);
			$state.go(FILTER_STATE, params);
		};

        service.getEmbedUrl = function(username, withImage, withDescription, withTags) {
            var path = $state.href(FILTER_STATE, { username: username, a: viewService.getEmbeddedViewConfig(withImage, withDescription, withTags) });
            return getBaseUrl().concat(path);
        };

		service.goProfile = function() {
			$state.transitionTo(FILTER_STATE, { username: authService.getSessionUserUsername() }, { reload: true, inherit: false, notify: true });
		};

		service.goHome = function() {
			$state.transitionTo(FILTER_STATE, { username: authService.getSessionUserUsername(), a: viewService.getHomeViewConfig()}, { reload: true, inherit: false, notify: true });
		};

		service.getUserUrl = function (username, viewConfig) {
			return $state.href(FILTER_STATE, { username: username, a: viewConfig });
		};

		service.isHome = function() {
			return viewService.isDefaultHomeView() && authService.isSessionUserUsername(filterService.getUsername());
		};

		service.getHomeUrl = function() {
			return service.getUserUrl(authService.getSessionUserUsername(), viewService.getHomeViewConfig());
		};

		service.getUserShareUrl = function() {
			var params = angular.copy($stateParams);
			//router needs value undefined
			var a;
			params.a = a;
			var path = $state.href(FILTER_STATE, params);
			return getBaseUrl().concat(path);
		};

		function getBaseUrl() {
			return window.location.protocol + '//' + location.hostname + (location.port ? ':'+location.port: '');
		};

		return service;

	}

});

