define('tagpacker-angular/services/znippetService',['classes/User', 'classes/Znippet', 'classes/TagPack',
        'classes/Tag', 'tagpackerUtils', 'tagpackerModule'],
    function (User, Znippet, TagPack, Tag, tagpackerUtils, tagpackerModule) {
        tagpackerModule.factory('znippetService', znippetService);

        // too many dependencies
        znippetService.$inject = ['$http', 'modalService', 'authService', '$sce', '$q',
            'loadingService', 'alertService', 'listLoader', 'tagService',
            'recommendationService', 'analytics', 'filterService', 'dialogService', 'navigationService'];

        function znippetService($http, modalService, authService, $sce, $q,
                                loadingService, alertService, listLoader, tagService,
                                recommendationService, analytics, filterService, dialogService, navigationService) {
            var znippetService = {};

            znippetService.getNewZnippet = function (title) {
                var result = new Znippet();
                result.title = title;
                result.ownerId = (authService.getSessionUser() ? authService.getSessionUser().id : null);
                result.frequency = 0;
                return result;
            };

            znippetService.submitEditForm = function (znippetId, fields) {
                var userId = authService.getSessionUser().id;
                if (znippetId == null) {
                    return $http.post('/api/users/' + userId + '/links', fields).then(function (responseObject) {
                        return new Znippet(responseObject.data);
                    });
                } else {
                    return znippetService.updateZnippet(znippetId, fields);
                }
            };

            znippetService.updateZnippet = function (znippetId, fields) {
                var userId = authService.getSessionUser().id;
                return $http.put('/api/users/' + userId + '/links/' + znippetId, fields).then(function (responseObject) {
                    return new Znippet(responseObject.data);
                });
            };

            znippetService.loadLink = function (linkId) {
                return $http.get('/api/links/' + linkId).then(function (response) {
                    return new Znippet(response.data);
                });
            };

            // unfortunately "delete" is a reserved word in Javascript
            znippetService.remove = function (znippet) {
                return $http.delete('/api/users/' + znippet.ownerId + '/links/' + znippet.id);
            };

            znippetService.removeLinks = function (links) {
                var promises = [];
                angular.forEach(links, function (link) {
                    promises.push(znippetService.remove(link));
                });
                return $q.all(promises);
            }

            znippetService.tagAll = function (tags, filter) {
                return $http({
                    method: 'POST',
                    url: '/tagAll',
                    data: {
                        tagIds: tags.map(function (x) {
                            return x.id
                        }),
                        filter: filter.asPlainObject()
                    }
                });
            };

            znippetService.untagAll = function (tags, filter) {
                return $http({
                    method: 'POST',
                    url: '/untagAll',
                    data: {
                        tagIds: tags.map(function (x) {
                            return x.id
                        }),
                        filter: filter.asPlainObject()
                    }
                });
            };

            znippetService.tagLinks = function (owner, links, tags) {
                return $http({
                    method: 'POST',
                    url: '/tagAll',
                    data: {
                        userId: owner.id,
                        linkIds: links.map(function (x) {
                            return x.id
                        }),
                        tagIds: tags.map(function (x) {
                            return x.id
                        })
                    }
                });
            };

            znippetService.untagLinks = function (owner, links, tags) {
                return $http({
                    method: 'POST',
                    url: '/untagAll',
                    data: {
                        userId: owner.id,
                        linkIds: links.map(function (x) {
                            return x.id
                        }),
                        tagIds: tags.map(function (x) {
                            return x.id
                        })
                    }
                });
            };

            function updateVisibility(filter, isPrivate) {
                return $http({
                    method: 'POST',
                    url: '/updateVisibility',
                    data: {
                        filter: filter.asPlainObject(),
                        isPrivate: isPrivate
                    }
                });
            };

            znippetService.makePublic = function (filter) {
                return updateVisibility(filter, false).then(function () {
                    alertService.addSuccessAlert('Selected links are public now.');
                });
            };

            znippetService.makePrivate = function (filter) {
                return updateVisibility(filter, true).then(function () {
                    alertService.addSuccessAlert('Selected links are private now.');
                });
            };

            function updateLinksVisibility(owner, links, isPrivate) {
                return $http({
                    method: 'POST',
                    url: '/updateVisibility',
                    data: {
                        userId: owner.id,
                        linkIds: links.map(function (x) {
                            return x.id
                        }),
                        isPrivate: isPrivate
                    }
                });
            };

            znippetService.makeLinksPublic = function (owner, links) {
                return updateLinksVisibility(owner, links, false).then(function () {
                    alertService.addSuccessAlert('Selected links are public now.');
                });
            };

            znippetService.makeLinksPrivate = function (owner, links) {
                return updateLinksVisibility(owner, links, true).then(function () {
                    alertService.addSuccessAlert('Selected links are private now.');
                });
            };

            znippetService.removeAll = function (filter, totalResultSize) {
                return dialogService.showDeleteDialog(totalResultSize + ' links',
                    'All ' + totalResultSize + ' links will be deleted.').then(function () {
                    loadingService.showModalLoadingAnimation();
                    return $http({
                        method: 'POST',
                        url: '/deleteZnippets',
                        data: {
                            filter: filter.asPlainObject()
                        }
                    }).finally(function () {
                        loadingService.hideModalLoadingAnimation();
                    });
                });
            };

            znippetService.openMoveTagDialog = function (tag, successHandler) {
                return modalService.openBootstrapModal({
                    backdrop: 'static',
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/moveTagDialog.html',
                    controller: ['$scope', '$uibModalInstance', function MoveTagDialogController($scope, $uibModalInstance) {
                        $scope.tag = tag;
                        var tagPacksUrl = '/api/users/' + authService.getSessionUser().id + '/tagpacks';
                        $scope.tagPackList = listLoader.create(tagPacksUrl, {
                            transformElement: function (element) {
                                return new TagPack(element);
                            }
                        });
                        $scope.tagPackList.load();
                        $scope.selectPack = function (pack) {
                            $uibModalInstance.close(pack);
                        };
                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }]
                }).result.then(function (pack) {
                    tag.pack = pack;
                    return tagService.update(tag).then(function () {
                        return pack;
                    });
                });
            };

            znippetService.openBookmarkletDialog = function () {
                modalService.openBootstrapModal({
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/bookmarkletDialog.html',
                    controller: ['$scope', '$uibModalInstance', function BookmarkletDialogController($scope, $uibModalInstance) {
                        $scope.close = function (result) {
                            $uibModalInstance.close();
                        };
                    }]
                });
            };

            znippetService.getYouTubeEmbedUrl = function (znippet) {
                var videoId = tagpackerUtils.getURLParameter('v', znippet.sourceUrl);
                /*
                 * wmmode=opaque is required to allow overlapping html elements:
                 * http://helpx.adobe.com/flash/kb/flash-object-embed-tag-attributes.html#main_Using_Window_Mode__wmode__values_
                 * However, it reduces performance
                 */
                return $sce.trustAsResourceUrl('https://www.youtube.com/embed/' + videoId + '?wmode=opaque');
            };

            znippetService.promptUrl = function () {
                return modalService.openBootstrapModal({
                    backdrop: 'static',
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/urlInputDialog.html',
                    controller: ['$scope', '$uibModalInstance', 'primaryDataService', function ($scope, $uibModalInstance, primaryDataService) {
                        $scope.session = {
                            url: null
                        };
                        $scope.save = function (form) {
                            $uibModalInstance.close($scope.session.url);
                        };
                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
                        $scope.openBookmarkletDialog = znippetService.openBookmarkletDialog;

                        $scope.isApp = function () {
                            return primaryDataService.isApp();
                        }
                    }]
                }).result;
            }

            znippetService.getMetaData = function getMetaData(url) {
                return $http.get('/api/links/metaData', {
                    params: {'url': url}
                }).then(function (response) {
                    return response.data;
                });
            }

            function makeNewLink(url) {
                return znippetService.getMetaData(url).then(function (metaData) {
                    var link = znippetService.getNewZnippet('');
                    if (tagpackerUtils.isValidUrl(url)) {
                        link.sourceUrl = url;
                    }
                    if (metaData) {
                        if (metaData.title) {
                            link.title = metaData.title;
                        } else {
                            link.title = tagpackerUtils.shorten(url, 60);
                        }
                        if (metaData.description) {
                            link.description = metaData.description;
                        }
                    }
                    return link;
                });
            }

            znippetService.getLinks = function (owner, filter) {
                return $http({
                    method: 'GET',
                    url: '/api/users/' + owner.id + '/links',
                    params: {
                        filter: filter.asPlainObject()
                    }
                }).then(function (response) {
                    return Znippet.constructArray(response.data);
                });
            };

            znippetService.getLinkForUrl = function (url) {
                if (url) {
                    url = url.trim();
                    return $http.get('/api/users/' + authService.getSessionUser().id + '/links', {
                        params: {'url': url}
                    }).then(
                        function (response) {
                            return response.data[0] ? new Znippet(response.data[0]) : makeNewLink(url);
                        },
                        function (response) {
                            if (response.status == 404) {
                                response.preventDefault();
                                return makeNewLink(url);
                            }
                            return $q.reject();
                        }
                    );
                } else {
                    var deferred = $q.defer();
                    deferred.resolve(znippetService.getNewZnippet(''));
                    return deferred.promise;
                }
            };

            znippetService.addLink = function (tags) {
                tags = tags ? tags : [];
                return znippetService.promptUrl().then(function (url) {
                    loadingService.showModalLoadingAnimation();
                    return znippetService.getLinkForUrl(url).then(function (link) {
                        for (var i = 0; i < tags.length; i++) {
                            link.addTag(tags[i]);
                        }
                        if (link.isNew()) {
                            var isPrivate = false;
                            if (filterService.getVisibility()) {
                                isPrivate = filterService.getVisibility() == 'Private';
                            } else {
                                isPrivate = authService.getSessionUser().linkPrivateByDefault;
                            }
                            link.isPrivate = isPrivate;
                        }
                        if (!link.title) {
                            link.title = url;
                        }
                        return link;
                    }).finally(function () {
                        loadingService.hideModalLoadingAnimation();
                    });
                }).then(function (znippet) {
                    return znippetService.openEditOverlay(znippet);
                });
            };

            znippetService.loadLinkRecommendationsForLink = function (id, limit) {
                limit = limit || 4;
                return $http.get('/api/linkRecommendationsForLink', {
                    params: {
                        id: id,
                        limit: limit
                    }
                }).then(function (response) {
                    return Znippet.constructArray(response.data);
                }, function (response) {
                    response.preventDefault();
                });
            }

            znippetService.handleClick = function (link, action, context) {
                znippetService.loadLinkRecommendationsForLink(link.id).then(function (recommendedLinks) {
                    if (recommendedLinks.length > 0) {
                        recommendationService.updateLinkRecommendations(recommendedLinks);
                    }
                });
                context = context ? context + ' ' : '';
                analytics.sendLinkEvent('clicked ' + context + link.sourceUrl, action);
                if (link.id) {
                    $http.post('/trackClickThrough', {id: link.id});
                }
            }

            znippetService.openEditOverlay = function (link) {
                //option "keyboard: false" required as the edit view handles ESCAPE itself; otherwise we would need to use event.preventDefault
                return modalService.openBootstrapModal({
                    backdrop: 'static',
                    keyboard: false,
                    size: 'm',
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/editDialog.html',
                    controller: ['$scope', '$uibModalInstance', function TestController($scope, $uibModalInstance) {
                        $scope.link = link;

                        $scope.handleCancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };

                        $scope.handleDelete = function () {
                            $uibModalInstance.close(null);
                        };

                        $scope.handleSave = function (link) {
                            $uibModalInstance.close(link);
                        };
                    }]
                }).result;
            };

            function loadSessionUser() {
                if (authService.isLoggedIn()) {
                    return $http.get('/api/users/' + authService.getSessionUser().id).then(function (response) {
                        return response.data;
                    });
                }
                var deferred = $q.defer();
                deferred.resolve(null);
                return deferred.promise;
            };

            znippetService.openLinkTipOverlay = function (link) {
                return loadSessionUser().then(function (sessionUser) {
                    return modalService.openBootstrapModal({
                        backdrop: 'static',
                        templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/sendLinkTipDialog.html',
                        controller: ['$scope', '$uibModalInstance', 'authService', 'shareService',
                            function LinkTipDialogController($scope, $uibModalInstance, authService, shareService) {
                                $scope.messageMaxLength = 140;
                                $scope.message = null;
                                $scope.selectedUser = null;

                                $scope.sessionUser = sessionUser;
                                $scope.link = link;
                                $scope.receivingUsers = [];

                                $scope.isLoggedIn = function () {
                                    return authService.isLoggedIn();
                                };

                                $scope.trackShareClick = function (service) {
                                    analytics.sendLinkEvent(service + ' share button clicked ' + $scope.link.sourceUrl, 'share');
                                };

                                $scope.twitterShare = function () {
                                    return shareService.twitterShare($scope.link.sourceUrl, $scope.link.title);
                                };

                                $scope.facebookShare = function () {
                                    return shareService.facebookShare($scope.link.sourceUrl);
                                };

                                $scope.googleShare = function () {
                                    return shareService.googleShare($scope.link.sourceUrl);
                                };

                                $scope.tumblrShare = function () {
                                    return shareService.tumblrShare($scope.link.sourceUrl);
                                };

                                $scope.emailShare = function () {
                                    return shareService.emailShare($scope.link.sourceUrl, $scope.link.title);
                                };

                                $scope.getUsers = function (query) {
                                    return $http.get('/api/users/' + $scope.sessionUser.id + '/searchFollowers?query=' + encodeURIComponent(query) + '&limit=5').then(function (result) {
                                        $scope.filteredUser = null;
                                        var users = User.constructArray(result.data);
                                        //TODO: prevent sending link tips to link owner; should be handled by the server as the sender might think the autocomplete is broken;
                                        // var filteredUser could be used to give the sending user a hint why this user is not available
                                        for (var i = 0; i < users.length; i++) {
                                            if (users[i].id == link.ownerId) {
                                                $scope.filteredUser = users[i];
                                                users.splice(i, 1);
                                                break;
                                            }
                                        }
                                        return users;
                                    });
                                };

                                function makeLinkTip(link, receivingUserId, message) {
                                    return {
                                        sourceLinkId: link.id,
                                        title: link.title,
                                        url: link.sourceUrl,
                                        thumbnailId: link.thumbnailId,
                                        receivingUserId: receivingUserId,
                                        message: message
                                    };
                                };

                                $scope.send = function () {
                                    if ($scope.receivingUsers.length > 0) {
                                        var linkTips = [];
                                        for (var i = 0; i < $scope.receivingUsers.length; i++) {
                                            linkTips.push(makeLinkTip($scope.link, $scope.receivingUsers[i].id, $scope.message));
                                        }
                                        $http.post('/api/linkTips', linkTips).then(function () {
                                            alertService.addSuccessAlert('Successfully sent!');
                                            $uibModalInstance.close($scope.receivingUsers);
                                        });
                                    }
                                };

                                $scope.removeReceivingUser = function (receivingUser) {
                                    var index = getIndex(receivingUser);
                                    $scope.receivingUsers.splice(index, 1);
                                };

                                $scope.addReceivingUser = function (receivingUser) {
                                    var index = getIndex(receivingUser);
                                    if (index == -1) {
                                        $scope.receivingUsers.push(receivingUser);
                                    }
                                    $scope.selectedUser = null;
                                };

                                $scope.cancel = function () {
                                    $uibModalInstance.dismiss('cancel');
                                };

                                var getIndex = function (receivingUser) {
                                    for (var i = 0; i < $scope.receivingUsers.length; i++) {
                                        if (receivingUser.id == $scope.receivingUsers[i].id) {
                                            return i;
                                        }
                                    }
                                    return -1;
                                };
                            }]
                    });
                });
            };

            znippetService.openImportDialog = function () {
                return modalService.openBootstrapModal({
                    backdrop: 'static',
                    templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/importDialog.html',
                    controller: ['$scope', '$uibModalInstance', function ImportDialogController($scope, $uibModalInstance) {
                        $scope.file = null;
                        $scope.session = {
                            allLinksPrivate: authService.getSessionUser().linkPrivateByDefault
                        }

                        $scope.tags = [];
                        $scope.userId = authService.getSessionUser().id;
                        $scope.fileValidators = [
                            tagpackerUtils.createFileTypeFilter(['html', 'htm', 'json']),
                            tagpackerUtils.createFileSizeFilter(10 * 1000 * 1000)
                        ];
                        $scope.uploading = false;
                        $scope.uploadFinished = function (file) {
                            $scope.file = file;
                            $scope.uploading = false;
                        };
                        $scope.uploadStarted = function () {
                            $scope.uploading = true;
                        };
                        $scope.failedHandler = function () {
                            $scope.uploading = false;
                        };

                        $scope.importing = false;
                        $scope.startImport = function () {
                            //prevent import is started multiple times
                            if (!$scope.importing) {
                                $scope.importing = true;
                                $http({
                                    method: 'POST',
                                    url: '/importBookmarks',
                                    data: {
                                        tags: $scope.tags.map(function (x) {
                                            return x.name
                                        }),
                                        allLinksPrivate: $scope.session.allLinksPrivate,
                                        id: $scope.file.id
                                    }
                                }).then(function (response) {
                                    analytics.sendLinkEvent('links imported', 'import');
                                    var numberOfImportedBookmarks = response.data.numberOfImportedBookmarks;
                                    if (numberOfImportedBookmarks == 0) {
                                        alertService.addInfoAlert("No links imported! Either all links of this file are already in your collection or the file contains invalid data!");
                                    } else {
                                        alertService.addSuccessAlert('Successfully imported ' + numberOfImportedBookmarks + ' links to ' +
                                            '<a href="' + navigationService.getHomeUrl() + '">your collection</a>.' +
                                            '<br><b>It may take a while until all links show an image.</b>');
                                    }
                                    $uibModalInstance.close();
                                }).finally(function () {
                                    $scope.importing = false;
                                });
                            }
                        };

                        $scope.close = function (result) {
                            $uibModalInstance.close();
                        };
                    }]
                }).result;
            }

            znippetService.openAll = function (links) {
                for (var i = 0; i < links.length; i++) {
                    var url = links[i].sourceUrl;
                    if (url) {
                        window.open(url, '_blank');
                    }
                }
            };

            znippetService.openLinksWithWarning = function (numberOfLinks, callback) {
                if (numberOfLinks > 25) {
                    return dialogService.showContinueDialog("This will open " + numberOfLinks + " new tabs/windows and might significantly slow down your browser. Really open?").then(function () {
                        callback();
                    });
                } else {
                    callback();
                }
            };

            return znippetService;

        }

    });

