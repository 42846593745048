define('tagpacker-angular/services/recommendationService',['tagpackerUtils', 'classes/Znippet', 'tagpackerModule'], function (tagpackerUtils, Znippet, tagpackerModule) {
    'use strict';

    // The name was changed from 'adService' so it's not blocked by Adblock plus
    tagpackerModule.factory('recommendationService', recommendationService);

    recommendationService.$inject = ['$http', 'filterService'];

    function recommendationService($http, filterService) {

        var service = {
            adZnippets: [],
            linkRecommendations: [],
            blocked: false
        };

        service.getAdZnippets = function () {
            return service.adZnippets;
        };

        service.getLinkRecommendations = function () {
            return service.linkRecommendations;
        };

        service.block = function () {
            service.blocked = true;
        };

        service.unblock = function () {
            service.blocked = false;
        };

        service.update = function (newAdZnippets) {
            tagpackerUtils.morphZnippetArray(service.adZnippets, newAdZnippets);
        };

        service.updateLinkRecommendations = function (linkRecommendations) {
            tagpackerUtils.morphZnippetArray(service.linkRecommendations, linkRecommendations);
        };

        function getAd(title, url, imageUrl) {
            return new Znippet({
                id: null,
                title: title,
                description: '',
                url: '',
                sourceUrl: url,
                affiliateUrl: null,
                thumbnailId: null,
                thumbnailUrl: imageUrl,
                thumbnailSource: 'auto',
                thumbnailSourceUrl: '',
                ownerId: null,
                tags: [],
                ownLinkId: null,
                sourceZnippetId: null,
                liked: false,
                isPrivate: false
            });
        }

        return service;

    }

});

