define('tagpacker-angular/directives/tagInput/simpleTagInput',[], function () {

    return [function () {
        return {
            templateUrl: '/assets/javascripts/tagpacker-angular/directives/tagInput/simpleTagInput.html',
            replace: true,
            restrict: 'E',
            scope: {
                tags: '=',
                userId: '&',
                placeholder: '&',
                onEmptySubmit: '&',
                onFocus: '&',
                withFacets: '&',
                onFacetToggle: '&'
            },
            controller: ['$scope', 'tagService', '$timeout', 'analytics',
                function ($scope, tagService, $timeout, analytics) {
                    var LIMIT = 5;
                    var LOADING_ANIMATION_DELAY = 300;

                    var loadingAnimationTimeout = null;

                    $scope.facetsExpanded = false;
                    $scope.showsLoadingAnimation = false;
                    $scope.inputValue = '';
                    $scope.results = null;
                    $scope.pendingRequestId = null;
                    $scope.lastRequestId = 0;
                    $scope.active = false;

                    function isBlank(value) {
                        return value == null || /^\s*$/.test(value);
                    }

                    function setAndReturnNewPendingRequestId() {
                        var requestId = $scope.lastRequestId + 1;
                        $scope.lastRequestId = requestId;
                        $scope.pendingRequestId = requestId;
                        return requestId;
                    }

                    function adaptLoadingStatusForNewRequest() {
                        cancelLoadingTimeoutIfSet();
                        loadingAnimationTimeout = $timeout(function () {
                            $scope.showsLoadingAnimation = true;
                        }, LOADING_ANIMATION_DELAY);
                    }

                    function cancelLoadingTimeoutIfSet() {
                        if (loadingAnimationTimeout != null) {
                            $timeout.cancel(loadingAnimationTimeout);
                            loadingAnimationTimeout = null;
                        }
                    }

                    function clear() {
                        $scope.inputValue = '';
                        $scope.results = null;
                    }

                    function reset() {
                        clear();
                        $scope.pendingRequestId = null;
                        $scope.showsLoadingAnimation = false;
                        cancelLoadingTimeoutIfSet();
                    }

                    function onAfterRequest(requestId) {
                        if ($scope.pendingRequestId == requestId) {
                            $scope.pendingRequestId = null;
                            $scope.showsLoadingAnimation = false;
                            cancelLoadingTimeoutIfSet();
                        }
                    }

                    function getTag(results, value) {
                        for (var i = 0; i < results.length; i++) {
                            if (results[i].name.toUpperCase() === value.toUpperCase()) {
                                return results[i];
                            }
                        }
                        return null;
                    }

                    function handleValueWithoutComma(value) {
                        var requestId = setAndReturnNewPendingRequestId();
                        tagService.getTagsForPrefix(value, $scope.userId(), LIMIT).then(function (results) {
                            if ($scope.pendingRequestId == requestId) {
                                $scope.results = results;
                                $scope.selectedIndex = results.length > 0 ? 0 : null;
                            }
                        }).finally(function () {
                            onAfterRequest(requestId);
                        });
                        adaptLoadingStatusForNewRequest();
                    }

                    function handleValueWithComma(value) {
                        var requestId = setAndReturnNewPendingRequestId();
                        var valueWithoutComma = value.substring(0, value.length - 1);
                        tagService.getTagsForPrefix(valueWithoutComma, $scope.userId(), LIMIT).then(function (results) {
                            if ($scope.pendingRequestId == requestId) {
                                var tag = getTag(results, valueWithoutComma);
                                if (tag != null) {
                                    addTag(tag);
                                    analytics.sendTagEvent('existing tag with comma assigned', 'assign');
                                } else {
                                    addNewTag(valueWithoutComma);
                                    analytics.sendTagEvent('new tag with comma assigned', 'assign');
                                }
                            }
                        }).finally(function () {
                            onAfterRequest(requestId);
                        });
                        adaptLoadingStatusForNewRequest();
                    }

                    function issueRequest(value) {
                        if (value.endsWith(',') && value.length > 1) {
                            handleValueWithComma(value);
                            return;
                        }
                        handleValueWithoutComma(value);
                    }

                    $scope.onInputChange = function (value) {
                        if (isBlank(value))
                            reset();
                        else
                            issueRequest(value);
                    };

                    $scope.onDown = function () {
                        $scope.$broadcast('autocomplete-key-down');
                    };

                    $scope.onUp = function () {
                        $scope.$broadcast('autocomplete-key-up');
                    };

                    $scope.containsTag = function (tag) {
                        var result = false;
                        $scope.tags.forEach(function (eachTag) {
                            result |= (tag.id != null && eachTag.id === tag.id) || (tag.id == null && tag.name == eachTag.name);
                        })
                        return result;
                    };

                    function addTag(tag) {
                        if (!$scope.containsTag(tag)) {
                            $scope.tags.push(tag);
                        }
                        clear();
                    }

                    $scope.toggleFacets = function () {
                        $scope.facetsExpanded = !$scope.facetsExpanded;
                        if ($scope.onFacetToggle) {
                            $scope.onFacetToggle({facetsExpanded: $scope.facetsExpanded})
                        }
                    };


                    $scope.hasFacets = false;

                    $scope.onFacetsLoaded = function (facets) {
                        $scope.hasFacets = facets.length > 1;
                    }

                    $scope.addTag = function (tag) {
                        addTag(tag);
                        analytics.sendTagEvent('existing tag without comma assigned', 'assign');
                    };

                    function addNewTag(value) {
                        tagService.getOrCreateTag($scope.userId(), value).then(function (tag) {
                            $scope.addTag(tag);
                        });
                    }

                    $scope.addNewTag = function () {
                        addNewTag($scope.inputValue);
                        analytics.sendTagEvent('new tag without comma assigned', 'assign');
                    };

                    //e.g., to clear the inputValue when a tag suggestion is selected
                    $scope.$watch('tags.length', function (newValue, oldValue) {
                        if (oldValue != newValue) {
                            clear();
                        }
                    });

                    $scope.onEnter = function () {
                        if ($scope.pendingRequestId !== null) {
                            $scope.showsLoadingAnimation = true;
                        } else if (isBlank($scope.inputValue)) {
                            $scope.onEmptySubmit();
                        } else {
                            $scope.$broadcast('autocomplete-key-enter');
                        }
                    };

                    $scope.handleFocus = function ($event) {
                        $scope.active = true;
                        $timeout(function () {
                            $scope.onFocus({$event: $event});
                        });
                    }

                    $scope.onEscape = function () {
                        if ($scope.results == null) {
                            // not tested because it involves a (real) timeout
                            $scope.triggerEscape();
                        }
                        reset();
                    };

                    $scope.handleClick = function (tag) {
                        $scope.$emit('tagClick', {tag: tag});
                    };
                }],
            link: function (scope, element, attributes) {
                element.bind('click', function (event) {
                    element.find('input').focus();
                });

                // not tested because it involves a (real) timeout
                scope.triggerEscape = function () {
                    var e = $.Event('keyup');
                    e.which = $.ui.keyCode.ESCAPE;
                    setTimeout(function () {
                        element.trigger(e);
                    });
                };
            }
        };
    }];

});

