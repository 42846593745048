define('tagpacker-angular/services/tagSuggestionService',['classes/Tag', 'tagpackerModule'], function (Tag, tagpackerModule) {

    tagpackerModule.factory('tagSuggestionService', tagSuggestionService);

    tagSuggestionService.$inject = ['$http'];

    function tagSuggestionService($http) {

        return {
            getSuggestions: function (userId, params) {
                var submittedParams = {};
                if (typeof params.title !== 'undefined')
                    submittedParams.title = params.title;
                if (typeof params.limit !== 'undefined')
                    submittedParams.limit = params.limit;
                if (typeof params.url !== 'undefined')
                    submittedParams.url = params.url;
                if (typeof params.description !== 'undefined')
                    submittedParams.description = params.description;
                // tagId instead of tagIds to have a nicer url.
                // Don't know if this is best practice but tagId=123&tagId=456
                // looks better in my opinion.
                if (typeof params.tagIds !== 'undefined')
                    submittedParams.tagId = params.tagIds;
                if (typeof params.excludedTagIds !== 'undefined')
                    submittedParams.excludedTagId = params.excludedTagIds;

                return $http.post('/api/users/' + userId + '/tagSuggestions', submittedParams).then(function (response) {
                    return Tag.constructArray(response.data);
                });
            }
        }
    }

});

