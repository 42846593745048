define('tagpacker-angular/directives/bookmarklet/bookmarkletDirective',[], function(){

	return ['primaryDataService', '$timeout', 'browserDetector', function(primaryDataService, $timeout, browserDetector) {

		var bookmarkletTemplate = "javascript:(function(w) {"
			+ "var d=w.document;"
			+ "var title=d.title;"
			+ "var description=d.getSelection().toString();"
			
            + "var url=\"__URL__?version=__VERSION__"
		    +     "&url=\"+encodeURIComponent(location.href) + \""
			+     "&title=\"+encodeURIComponent(title) + \""
			+     "&description=\"+encodeURIComponent(description);"
            + "w.Tagpacker_PackIt_Script_Loaded = false;"

		    + "if (__FALLBACK__ || w.Tagpacker_blocked_script) { "
		    +     "window.open(url + '&fallback=true','_blank');"
			+ "}"
		    + "else { "
			+     "setTimeout(function() { "
		    +         "if (!w.Tagpacker_PackIt_Script_Loaded) {"
		    +             "w.Tagpacker_blocked_script = true;"
		    +             "alert('This page prevents Tagpacker from opening a dialog. Please click the \"Pack it\" button again to open a new window.');"
			+         "}"
			+     "},2500);"
			+     "loadScript();"
			+ "}"

			+ "function loadScript() {"
			+     "var tps = document.createElement('script');"
			+     "tps.setAttribute('type', 'text/javascript');"
			+     "tps.setAttribute('id', 'tagpacker-packit-script');"
			+     "tps.setAttribute('src', '__SCRIPT_URL__');"
			+     "(top.document.body || top.document.getElementsByTagName('head')[0]).appendChild(tps);"
			+ "}"
			
			+ "})(window)";
		
		var compileBookmarklet = function() {
			return bookmarkletTemplate
			    .replace('__URL__', primaryDataService.getBookmarkUrl())
			    .replace('__SCRIPT_URL__', primaryDataService.getBookmarkletScriptUrl())
			    .replace('__VERSION__', primaryDataService.getLatestBookmarkletVersion())
			    .replace('__FALLBACK__', browserDetector.isSafari());
		};
		
		return {
			scope: true,
			replace: true,
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/bookmarklet/bookmarkletDirective.html',
			link: function postLink(scope, element, attributes) {
				// not using ng-href because it always adds "unsafe: " and the link doesn't work.
				// Don't want to disable link sanitization for the complete page
				// see http://stackoverflow.com/questions/15637133/unsafe-link-in-angular
				element.find('*').addBack().filter('a.bookmarklet-button').attr('href', scope.bookmarkletCode);
				
				element.find('.bookmarklet-code-input').on('click', function () {
					$timeout(function(){
						element.find('.bookmarklet-code-input').select();
					});
				});
			},
			controller: ['$scope', 'dialogService', '$window', 'analytics', '$state', 'primaryDataService', 'osDetector',
						 function($scope, dialogService, $window, analytics, $state, primaryDataService, osDetector) {
				
				$scope.isAndroid = osDetector.isAndroid();
				$scope.bookmarkletCode = compileBookmarklet();
				
				$scope.showHint = function() {
					var info = 'Please drag this button to your browser bookmarks toolbar.';
					dialogService.showInfoDialog(info);
				};

				var buttonClickHandler = function() {
					var info = 'Great! You have successfully installed the "Pack it" button. ' +
						'Just click it when you are on an interesting page you want to save.';
					dialogService.showInfoDialog(info);
					analytics.sendEvent('packit', 'clickAfterInstallation', $state.current.name);
				};
				
				$window.tagpackerPackitButtonVisible = true;
				$(window).on('packitButtonClick', buttonClickHandler);
				
				$scope.$on('$destroy', function() {
					$window.tagpackerPackitButtonVisible = false;
					$(window).off('packitButtonClick', buttonClickHandler);
				});
				
			}]
		};
		
	}];

});

