define('tagpacker-angular/services/mainListService',['tagpackerModule'], function (tagpackerModule) {

    tagpackerModule.factory('mainListService', mainListService);

    mainListService.$inject = ['filterService', 'pageService', 'scrollService', 'recommendationService', 'loadingService', 'sortService', 'userService', 'authService', 'analytics'];

    function mainListService(filterService, pageService, scrollService, recommendationService, loadingService, sortService, userService, authService, analytics) {
        var CONTENT_LENGTH_INCREMENT = 16;

        var contentLength = 0;
        var totalContentLength = 0;
        var privateContentLength = 0;
        var brokenContentLength = 0;
        var notTaggedContentLength = 0;
        var isComplete = false;
        var numberOfRequiredZnippets = CONTENT_LENGTH_INCREMENT;
        var loadPageCallback;

        var mainListService = {
            expandOtherTags: false,
            otherTagsFacetSort: null
        };

        var facetModes = [];
        var facetSorts = [];
        var facetsExpanded = true;
        
        var facetsSort = null;

        var keepState = false;

        var focusedPackId = null;
        var focusedTagId = null;

        // there may only be one such callback
        mainListService.registerLoadPageCallback = function (callback) {
            loadPageCallback = callback;
        }
        
        function isNavigationReason(reason) {
            return (reason == 'jump' || reason == 'widen' || reason == 'narrow' || reason == 'comb');
        }

        function getFacetStates() {
            return facetModes.concat(facetSorts);
        }

        // possible values for reason:
        // - 'comb'
        // - 'uncomb'
        // - 'refresh'
        // - 'loadMore'
        // - 'jump'
        // - 'widen'
        // - 'narrow'
        var loadPage = function (reason) {
            if (isNavigationReason(reason)) {
                mainListService.expandOtherTags = false;
                if (!keepState) {
                    facetModes = [];
                }
            }
            keepState = false;
            var contentParameters = {
                reason: reason,
                contentLength: numberOfRequiredZnippets,
                numberOfAdZnippets: 4,
                sort: sortService.getSort(),

                facetStates: getFacetStates(),
                expandOtherTags: mainListService.expandOtherTags,
                otherTagsFacetSort: mainListService.otherTagsFacetSort,

                query: filterService.getEffectiveQuery() || '',
                visibility: filterService.getVisibility(),
                reachability: filterService.getReachability(),
                numberOfTags: filterService.getNumberOfTags(),
                excludedPackId: filterService.getExcludedPackId(),
                serializedExcludedTagNames: filterService.getExcludedTagNamesFromUrl(),
                
                facetsExpanded: facetsExpanded,
                facetsSort: facetsSort
            };

            var loadingTask = loadingService.startNewTask();
            pageService.loadPage(filterService.getUsername(), filterService.getTagNamesFromUrl(), contentParameters).then(function (data) {
            	facetsSort = data.facetList.sort;
            	
            	filterService.setUser(data.page.filter.user);
                filterService.setTags(data.page.filter.tags, data.page.filter.excludedTags);
                filterService.setExcludedPack(data.page.filter.combPack);

                contentLength = data.page.znippets.length;
                isComplete = data.page.isComplete;

                recommendationService.update(data.page.adZnippets);

                totalContentLength = data.page.totalContentLength
                privateContentLength = data.page.privateContentLength;
                brokenContentLength = data.page.brokenContentLength;
                notTaggedContentLength = data.page.notTaggedContentLength;

                if (loadPageCallback) {
                    loadPageCallback(data, reason);
                }

                if (focusedPackId != null || focusedTagId != null) {
                    if (focusedTagId != null) {
                        scrollService.scrollToTagInFixedSidebar(focusedTagId);
                    }
                    else {
                        scrollService.scrollToPackInSidebar(focusedPackId);
                    }
                    focusedPackId = null;
                    focusedTagId = null;
                }
            }).finally(function () {
                loadingService.stopTask(loadingTask);
            });

        };
        
        function mayEdit() {
			return authService.isSessionUserUsername(filterService.getUsername());	
		};
          
        //0 == frequency, 1 == abc , 2 == custom
        mainListService.toggleFacetsSort = function(owner) {
        	var newFacetsSort = 0;
        	var owner = filterService.getUser();
        	if(facetsSort == 0) {
        		newFacetsSort = 1;
        	} else if(facetsSort == 1) {
        		if(mayEdit() || owner.usesCustomTagPackOrder == true) {
        			newFacetsSort = 2;
				} else {
					newFacetsSort = 0;
				}
        	}
        	if(mayEdit()) {
        		userService.update(owner.id, {tagPackSort: newFacetsSort}).then(function(){
        			setFacetsSort(newFacetsSort);
            	});
        	} else {
        		setFacetsSort(newFacetsSort);
        	}
        };
        
        function setFacetsSort(newFacetsSort) {
        	facetsSort = newFacetsSort;
        	mainListService.refresh();
        	analytics.sendTagPackEvent('facets sort ' + newFacetsSort, 'sort');
        };

        mainListService.refresh = function () {
        	loadPage('refresh');
        };

        mainListService.handleFilterChange = function (reason) {
            numberOfRequiredZnippets = CONTENT_LENGTH_INCREMENT;
            loadPage(reason);
        };

        mainListService.handleSortChange = function () {
            loadPage('sort');
        };

        mainListService.isComplete = function () {
            return isComplete;
        };

        mainListService.loadMore = function () {
            numberOfRequiredZnippets = contentLength + CONTENT_LENGTH_INCREMENT;
            loadPage('loadMore');
        };

        mainListService.getTotalContentLength = function () {
            return totalContentLength;
        };

        mainListService.getPrivateContentLength = function () {
            return privateContentLength;
        };

        mainListService.getBrokenContentLength = function () {
            return brokenContentLength;
        };

        mainListService.getNotTaggedContentLength = function () {
            return notTaggedContentLength;
        };

        function getFacetSort(packId) {
            for (var i = 0; i < facetSorts.length; i++) {
                if (facetSorts[i].packId == packId) {
                    return facetSorts[i];
                }
            }
            return null;
        };

        function requireSort(packId, sort) {
            var facetSort = getFacetSort(packId);
            if (facetSort != null) {
                facetSort.sort = sort;
            } else {
                facetSorts.push({packId: packId, sort: sort});
            }
        };

        mainListService.requireFrequencySort = function (packId) {
            requireSort(packId, 'frequency');
        };

        mainListService.requireAlphapeticallySort = function (packId) {
            requireSort(packId, 'alphabetically');
        };
        
        mainListService.isFacetsFrequencySort = function () {
        	return facetsSort == 0;
        };
        
        mainListService.isFacetsAlphapeticallySort = function () {
        	return facetsSort == 1;
        };
        
        mainListService.isFacetsCustomSort = function () {
        	return facetsSort == 2;
        };
        
        mainListService.requirePackMode = function (packId, mode) {
            mainListService.resetPackMode(packId);
            keepState = true;
            if (mode !== 'collapsed') {
                while (facetModes.length > 0) {
                    facetModes.pop();
                }
            }

            facetModes.push({packId: packId, mode: mode});

            if (mode == 'collapsed' && filterService.getExcludedPackId() == packId) {
                filterService.setExcludedPack(null);
            }
        };

        mainListService.resetPackMode = function (packId) {
            for (var i = 0; i < facetModes.length; i++) {
                if (facetModes[i].packId == packId) {
                    facetModes.splice(i, 1);
                    i--;
                }
            }
        };

        mainListService.setFocusedPackId = function (packId) {
            focusedPackId = packId;
        };

        mainListService.setFocusedTagId = function (tagId) {
            focusedTagId = tagId;
        };
        
        mainListService.requireAllFacets = function() {
        	facetsExpanded = true;
        };

        mainListService.requireLessFacets = function() {
        	facetsExpanded = false;
        };
        
        mainListService.facetsExpanded = function() {
        	return facetsExpanded;
        };
        
        return mainListService;

    }

});

