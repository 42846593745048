define('tagpacker-angular/services/adminService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('adminService', adminService);

	adminService.$inject = ['modalService', 'znippetService'];

	function adminService(modalService, znippetService) {
		
		var service = {
				
			openTagAllDialog: function(filter) {
				return modalService.openBootstrapModal({
					templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/addTagDialog.html',
					controller: 'TagAllDialogController',
					resolve: { 
						applier: {
							getUserId: function() {
								return filter.getUserId();
							},
							apply: function(tags){
								return znippetService.tagAll(tags, filter);
							}
						} 
					}
				}).result;
			},
			
			openUntagAllDialog: function(filter) {
				return modalService.openBootstrapModal({
					templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/removeTagDialog.html',
					controller: 'UntagAllDialogController',
					resolve: { 
						applier: {
							getUserId: function() {
								return filter.getUserId();
							},
							apply: function(tags){
								return znippetService.untagAll(tags, filter)
							}
						} 
					}
				}).result;
			},
			
			openTagLinksDialog: function(owner, links) {
				return modalService.openBootstrapModal({
					templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/addTagDialog.html',
					controller: 'TagAllDialogController',
					resolve: { 
						applier: {
							getUserId: function() {
								return owner.id;
							},
							apply: function(tags){
								return znippetService.tagLinks(owner, links, tags);
							}
						}
					}
				}).result;
			},
			
			openUntagLinksDialog: function(owner, links) {
				return modalService.openBootstrapModal({
					templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/removeTagDialog.html',
					controller: 'TagAllDialogController',
					resolve: { 
						applier: {
							getUserId: function() {
								return owner.id;
							},
							apply: function(tags){
								return znippetService.untagLinks(owner, links, tags);
							}
						} 
					}
				}).result;
			}
		
		};
		
		return service;
		
	}
	
});

