define('tagpacker-angular/directives/linkList/linkList',[], function () {

    return ['keyEventService', 'dialogService', 'znippetService', '$q', '$rootScope', 'analytics',
        function factory(keyEventService, dialogService, znippetService, $q, $rootScope, analytics) {

            var keyEventServiceId = 'linkList';

            return {
                templateUrl: '/assets/javascripts/tagpacker-angular/directives/linkList/linkList.html',
                scope: true,
                replace: true,
                restrict: 'E',
                controller: ['$scope', 'filterService', 'authService', 'alertService', 'scrollService', 'keyEventService', 'sortService', 'navigationService',
                    function ($scope, filterService, authService, alertService, scrollService, keyEventService, sortService, navigationService) {

                        keyEventService.add(keyEventServiceId);

                        $scope.query = null;
                        $scope.indexOfLastSelectedLink = null;

                        $scope.$on('refreshPage', function (event, args) {
                            if (args) {
                                if (!(args.reason == 'windowFocused' || args.reason == 'linkPrivacyToggled' || args.reason == 'linkEdited' || args.reason == 'bulkTagging')) {
                                    $scope.resetSelectedLinks();
                                    var resetLastSeletedLinkIndex = !(args.reason == 'linkDeleted');
                                    if (resetLastSeletedLinkIndex) {
                                        $scope.indexOfLastSelectedLink = null;
                                    }
                                }
                            } else {
                                $scope.resetSelectedLinks();
                                $scope.indexOfLastSelectedLink = null;
                            }
                        });

                        $scope.handleTagDropped = function (link, tag) {
                            if ($scope.selectedLinks.length > 1) {
                                znippetService.tagLinks($scope.owner, $scope.selectedLinks, [tag]).then(function () {
                                    $rootScope.$broadcast('refreshPage', {reason: 'bulkTagging'});
                                });
                            } else {
                                znippetService.updateZnippet(link.id, {tags: link.tags.concat(tag)}).then(function () {
                                    $rootScope.$broadcast('refreshPage', {reason: 'bulkTagging'});
                                });
                            }
                            analytics.sendLinkEvent('facet option dropped on link', 'edit');
                        };

                        $scope.handleSelect = function (link, $event) {
                            if (!$event.metaKey && !$event.ctrlKey && $event.target.type !== "checkbox") {
                                $scope.resetSelectedLinks();
                            }
                            $scope.indexOfLastSelectedLink = $scope.links.indexOf(link);
                            $scope.addToSelected(link);
                        };

                        $scope.handleDeselect = function (link, $event) {
                            if ($scope.selectedLinks.length > 1) {
                                if ($event.metaKey || $event.ctrlKey || $event.target.type === "checkbox") {
                                    $scope.removeFromSelected(link);
                                } else {
                                    $scope.selectLink($scope.links.indexOf(link));
                                }
                            } else {
                                $scope.resetSelectedLinks();
                            }
                        };

                        $scope.addToSelected = function (link) {
                            var index = $scope.getIndexOfSelected(link);
                            if (index < 0) {
                                $scope.selectedLinks.push(link);
                            } else {
                                $scope.selectedLinks.splice(index, 1);
                                $scope.selectedLinks.push(link);
                            }
                        }

                        $scope.removeFromSelected = function (link) {
                            var index = $scope.getIndexOfSelected(link);
                            if (index >= 0) {
                                $scope.selectedLinks.splice(index, 1);
                            }
                        };

                        $scope.selectLink = function (index) {
                            $scope.resetSelectedLinks();
                            var link = $scope.links[index];
                            link.selected = true;
                            $scope.addToSelected(link);
                            $scope.indexOfLastSelectedLink = index;
                        };

                        $scope.getIndexOfSelected = function (link) {
                            for (var i = 0; i < $scope.selectedLinks.length; i++) {
                                if ($scope.selectedLinks[i].id == link.id) {
                                    return i;
                                }
                            }
                            return -1;
                        };

                        $scope.mayEdit = function () {
                            return authService.isSessionUserUsername(filterService.getUsername());
                        };

                        $scope.handleChange = function (link) {
                            $rootScope.$broadcast('refreshPage', {reason: 'linkEdited'});
                            analytics.sendLinkEvent('link edited in user profile', 'edit');
                        };

                        $scope.handleRepack = function (link) {
                            $rootScope.$broadcast('refreshPage');
                            analytics.sendLinkEvent('repacked link from user profile', 'repack');
                            alertService.addSuccessAlert('Link was successfully repacked to ' +
                                '<a href="' + navigationService.getHomeUrl() + '">your collection</a>.');
                        };

                        $scope.handleDelete = function () {
                            $rootScope.$broadcast('refreshPage', {reason: 'linkDeleted'});
                            analytics.sendLinkEvent('link deleted in user profile', 'delete');
                        };

                        $scope.linksSelected = function () {
                            return $scope.selectedLinks.length > 0;
                        };

                        $scope.$watch(
                            function () {
                                return filterService.getEffectiveQuery()
                            },
                            function (newValue) {
                                $scope.query = newValue;
                                $scope.resetSelectedLinks();
                                $scope.indexOfLastSelectedLink = null;
                            }
                        );

                        filterService.onFilterChange($scope, function (reason) {
                            $scope.resetSelectedLinks();
                            $scope.indexOfLastSelectedLink = null;
                        });

                        sortService.onSortChange($scope, function (reason) {
                            $scope.resetSelectedLinks();
                            $scope.indexOfLastSelectedLink = null;
                        });

                        $scope.resetSelectedLinks = function () {
                            for (var i = 0; i < $scope.selectedLinks.length; i++) {
                                $scope.selectedLinks[i].selected = false;
                            }
                            //do not do $scope.selectedLinks = [] as there might be references to this object
                            $scope.selectedLinks.length = 0;
                        };
                    }
                ],
                link: function postLink(scope, element, attributes) {

                    function getNextLinkIndex(currentLinkIndex) {
                        for (var i = currentLinkIndex + 1; i < scope.links.length; i++) {
                            return i;
                        }
                        return -1;
                    };

                    function getPrevLinkIndex(currentLinkIndex) {
                        for (var i = currentLinkIndex - 1; i > -1; i--) {
                            return i;
                        }
                        return -1;
                    };

                    scope.$on('$destroy', function () {
                        $('body').unbind('keydown', keydownHandler);
                    });

                    $('body').bind('keydown', keydownHandler);

                    function keydownHandler(event) {
                        var key = event.which;
                        //46 == DELETE, 113 == F2
                        if (keyEventService.isActive(keyEventServiceId) && $.inArray(key, [46, 113, $.ui.keyCode.UP, $.ui.keyCode.DOWN, $.ui.keyCode.ESCAPE, $.ui.keyCode.ENTER]) > -1) {
                            if (scope.selectedLinks.length > 0) {
                                event.preventDefault();

                                if (key === 113) {
                                    if (scope.selectedLinks.length == 1) {
                                        znippetService.openEditOverlay(scope.selectedLinks[0].clone()).then(function (changedLink) {
                                            if (changedLink != null) {
                                                $rootScope.$broadcast('refreshPage', {reason: 'linkEdited'});
                                            } else {
                                                $rootScope.$broadcast('refreshPage', {reason: 'linkDeleted'});
                                            }
                                        });
                                    }
                                } else if (key === 46) {
                                    dialogService.showDeleteDialog(scope.selectedLinks.length + ' Selected Links').then(function () {
                                        znippetService.removeLinks(scope.selectedLinks).then(function () {
                                            $rootScope.$broadcast('refreshPage', {reason: 'linkDeleted'});
                                        });
                                    });
                                } else if (key === $.ui.keyCode.ENTER) {
                                    znippetService.openLinksWithWarning(scope.selectedLinks.length, function () {
                                        znippetService.openAll(scope.selectedLinks);
                                    });
                                } else if (key === $.ui.keyCode.UP) {
                                    scope.$apply(function () {
                                        var lastSelectedLink = scope.selectedLinks[scope.selectedLinks.length - 1];
                                        var prevLinkIndex = getPrevLinkIndex(scope.links.indexOf(lastSelectedLink));
                                        if (prevLinkIndex > -1) {
                                            var prevLink = scope.links[prevLinkIndex];
                                            if (event.shiftKey) {
                                                if (prevLink.selected && scope.indexOfLastSelectedLink <= prevLinkIndex) {
                                                    lastSelectedLink.selected = false;
                                                    scope.removeFromSelected(lastSelectedLink);
                                                } else {
                                                    prevLink.selected = true;
                                                    scope.addToSelected(prevLink);
                                                }
                                            } else {
                                                if (scope.selectedLinks.length == 1) {
                                                    scope.selectLink(prevLinkIndex);
                                                } else if (scope.selectedLinks.length > 1) {
                                                    scope.selectLink(getPrevLinkIndex(scope.indexOfLastSelectedLink) > -1 ? getPrevLinkIndex(scope.indexOfLastSelectedLink) : 0);
                                                }
                                            }
                                        } else {
                                            if (!event.shiftKey) {
                                                scope.selectLink(getPrevLinkIndex(scope.indexOfLastSelectedLink) > -1 ? getPrevLinkIndex(scope.indexOfLastSelectedLink) : 0);
                                            }
                                        }
                                    });

                                } else if (key === $.ui.keyCode.DOWN) {
                                    scope.$apply(function () {
                                        var lastSelectedLink = scope.selectedLinks[scope.selectedLinks.length - 1];
                                        var nextLinkIndex = getNextLinkIndex(scope.links.indexOf(lastSelectedLink));
                                        if (nextLinkIndex > -1) {
                                            var nextLink = scope.links[nextLinkIndex];
                                            if (event.shiftKey) {
                                                if (nextLink.selected && scope.indexOfLastSelectedLink >= nextLinkIndex) {
                                                    lastSelectedLink.selected = false;
                                                    scope.removeFromSelected(lastSelectedLink);
                                                } else {
                                                    nextLink.selected = true;
                                                    scope.addToSelected(nextLink);
                                                }
                                            } else {
                                                if (scope.selectedLinks.length == 1) {
                                                    scope.selectLink(nextLinkIndex);
                                                } else if (scope.selectedLinks.length > 1) {
                                                    scope.selectLink(getNextLinkIndex(scope.indexOfLastSelectedLink) > -1 ? getNextLinkIndex(scope.indexOfLastSelectedLink) : scope.links.length - 1);
                                                }
                                            }
                                        } else if (!event.shiftKey) {
                                            scope.selectLink(getNextLinkIndex(scope.indexOfLastSelectedLink) > -1 ? getNextLinkIndex(scope.indexOfLastSelectedLink) : scope.links.length - 1);
                                        }
                                    });
                                } else if (key === $.ui.keyCode.ESCAPE) {
                                    scope.$apply(function () {
                                        scope.resetSelectedLinks();
                                        scope.indexOfLastSelectedLink = null;
                                    });
                                }
                                return false;
                            } else if (scope.selectedLinks.length == 0 && scope.indexOfLastSelectedLink != null && scope.links.length > 0) {
                                event.preventDefault();
                                if (key === $.ui.keyCode.UP) {
                                    var prevLinkIndex = getPrevLinkIndex(scope.indexOfLastSelectedLink);
                                    if (prevLinkIndex == -1) {
                                        prevLinkIndex = 0;
                                    } else if (prevLinkIndex > scope.links.length - 1) {
                                        prevLinkIndex = scope.links.length - 1;
                                    }
                                    scope.$apply(function () {
                                        scope.links[prevLinkIndex].selected = true;
                                        scope.handleSelect(scope.links[prevLinkIndex], event);
                                    });
                                } else if (key === $.ui.keyCode.DOWN) {
                                    var nextLinkIndex = getNextLinkIndex(scope.indexOfLastSelectedLink - 1);
                                    if (nextLinkIndex == -1) {
                                        nextLinkIndex = scope.links.length - 1;
                                    }
                                    scope.$apply(function () {
                                        scope.links[nextLinkIndex].selected = true;
                                        scope.handleSelect(scope.links[nextLinkIndex], event);
                                    });
                                }
                                return false;
                            }
                            analytics.sendLinkEvent('key ' + key, 'keyboard');
                        }
                        return true;
                    };
                }
            };

        }];

});

