define('tagpacker-angular/directives/smartFooter/smartFooter',[], function () {

    var smartFooter = function factory(navigationService, footerService, viewService) {

        return {
            templateUrl: '/assets/javascripts/tagpacker-angular/directives/smartFooter/smartFooter.html',
            restrict: 'E',
            replace: true,
            link: function postLink(scope, element, attributes) {
                scope.footerFixed = false;
                scope.viewService = viewService;
                scope.tagpackerProfileURL = navigationService.getUserUrl('tagpacker');

                footerService.register(function (fixed) {
                    scope.$apply(function () {
                        scope.footerFixed = fixed;
                    });
                });
            }
        }

    };

    smartFooter.$inject = ['navigationService', 'footerService', 'viewService'];

    return smartFooter;

});
