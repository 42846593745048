define('tagpacker-angular/services/pageLoader',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('pageLoader', pageLoader);

	pageLoader.$inject = ['$http', '$q'];

	function pageLoader($http, $q) {
		
		var currentRequest = null;
		
		return {
			get: function(url, params) {
				if (currentRequest == null) {
					return createNewRequestAndGetPromise(url, params);	
				} 
				else if (currentRequest.isSameAs(url, params)) {
					return $q.reject('redundant');
				}
				else {
					currentRequest.deferred.reject('interrupted');
					return createNewRequestAndGetPromise(url, params);	
				}
			}
		};
	
		function Request(url, params) {
			var self = this;
			this.deferred = $q.defer();
			
			this.isSameAs = function(otherUrl, otherParams) {
				return url == otherUrl
					&& angular.equals(params, otherParams);
			}
			
			$http.get(url, {
				params : params
			}).success(function(data) {
				self.deferred.resolve(data);
			}).error(function() {
				self.deferred.reject("error");
			});
		}
	
		function createNewRequestAndGetPromise(url, params) {
			var request = new Request(url, params);
			
			currentRequest = request;
			
			return request.deferred.promise.finally(function() {
				if (currentRequest == request) {
					currentRequest = null;
				}
			});
		}
		
	}
	
});

