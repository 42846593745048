define('tagpacker-angular/services/recaptcha',['tagpackerModule'], function (tagpackerModule) {

    tagpackerModule.factory('recaptcha', recaptcha);

    recaptcha.$inject = ['primaryDataService'];

    function recaptcha(primaryDataService) {

        var key = primaryDataService.getRecaptchaKey();

        var service = {};

        var initialized = false;

        var init = function (callback) {
            if (initialized == false) {
                initialized = true;
                var scriptTag = document.createElement('script');
                scriptTag.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + key);
                scriptTag.onload = callback;
                document.head.appendChild(scriptTag);
            } else {
                callback();
            }
        };

        service.execute = function (action) {
            return new Promise((resolve) => {
                init(function () {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(key, {action: action})
                            .then(function (token) {
                                resolve(token);
                            });
                    });
                });
            });
        };

        return service;
    }

});

