define('classes/Facet',['classes/TagPack', 'classes/FacetOption', 'tagpackerUtils'],
	   function(TagPack, FacetOption, tagpackerUtils)
{
	
	function Facet(facet) {
		if (facet.pack && !(facet.pack instanceof TagPack)) {
			facet.pack = new TagPack(facet.pack);
		}
		this.update(facet);
	}

	Facet.prototype.isPack = function() {
		return this.type == 'pack';
	}
	
	Facet.prototype.isOther = function() {
		return this.type == 'other'
	}
	
	Facet.prototype.hasOptions = function() {
		return this.options.length > 0;
	}

	Facet.prototype.update = function (otherFacet) {
		this.type = otherFacet.type; // type is either 'pack' or 'other'
		if (this.isPack()) {
			this.pack = otherFacet.pack;
		}
		else {
			this.pack = null;
		}
		if (otherFacet.options.length > 0 || !(otherFacet.options[0] instanceof FacetOption)) {
			this.options = FacetOption.constructArray(otherFacet.options);	
		}
		else {
			tagpackerUtils.morphFacetOptionArray(this.options, otherFacet.options);
		} 
		this.coverage = otherFacet.coverage;
		this.totalSize = otherFacet.totalSize;
		this.mode = otherFacet.mode;
		this.sort = otherFacet.sort;
		this.isComplete = otherFacet.isComplete;
		this.numberOfUsedOptions = otherFacet.numberOfUsedOptions;
	};

	Facet.prototype.getColor = function () {
		return this.type == "pack" ? this.pack.color : 0;
	};

	Facet.prototype.equals = function (otherFacet) {
		if (this.isPack() && otherFacet.isPack()
			&& this.pack.equals(otherFacet.pack)) {
			return true;
		} else if (this.isOther() && otherFacet.isOther()) {
			return true;
		}
		return false;
	}

	Facet.prototype.containsSelectedOption = function () {
		for (var i=0 ; i<this.options.length ; i++) {
			if (this.options[i].selected) {
				return true;
			}
		}
		return false;
	}

	Facet.prototype.getZeroOptions = function() {
		return this.options.filter(function(option) {
			return option.frequency == 0
		});
	}
	
	Facet.prototype.containsZeroOption = function () {
		return this.getZeroOptions().length > 0;
	}

	Facet.prototype.containsAnyNotExcludedAndNotSelectedZeroOption = function () {
		var zeroOptions = this.getZeroOptions();
		for (var i=0 ; i<zeroOptions.length; i++) {
			if (zeroOptions[i].excluded == false && zeroOptions[i].selected == false) {
				return true;
			}
		}
		return false;
	}
	
	Facet.constructArray = function (facets) {
		return facets.map(function (x) { return new Facet(x); });
	};
	
	return Facet;
	
});

