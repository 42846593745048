'use strict';
define('tagpacker-angular/services/challengeService',['tagpackerModule'], function(tagpackerModule) {

	tagpackerModule.factory('challengeService', challengeService);

	challengeService.$inject = ['hintService', 'analytics'];

	function challengeService(hintService, analytics) {
		var service = {};
				
		service.complete = function(challenge) {
			analytics.sendChallengeEvent(challenge.key, 'complete');
			hintService.confirm(challenge);
		};
		
		service.completed = function(challenge){
			return hintService.confirmed(challenge.key)
		};
		
		return service;
	}

});

