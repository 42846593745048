define('tagpacker-angular/directives/link/link',['tagpackerUtils'], function(tagpackerUtils){

	var linkElement = function factory($timeout, znippetService, authService,
			draggingService, userListOverlayService, $rootScope, analytics, 
			loadingService, $http, loginService, shareService, viewService, 
			filterService, $state, scrollService, dialogService, primaryDataService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/link/link.html',
			replace: true,
			restrict: 'E',
			scope: {
				link: '=',
				isDraggable: '&',
				onSelect: '&',
				onDeselect: '&',
				onChange: '&',
				onRepack: '&',
				onDelete: '&',
				onTagDropped: '&',
				query: '=',
				context: '&',
				checkboxVisible: '&'
			},
			link: function postLink(scope, element, attributes) {
				scope.playingMode = false;
				scope.playerVars = {
				    autoplay: 1
				};
				
				scope.isPlayable = function() {
					return scope.link.isYouTubeVideo() && !primaryDataService.isApp();
				};
				
				scope.play = function() {
					scope.playingMode = true;
					znippetService.handleClick(scope.link, 'playVideo', scope.getContext());
				};
				
				scope.isOwnLink = function() {
					return authService.isSessionUserId(scope.link.ownerId);
				};
				
				scope.isSelectable = function() {
					return scope.isOwnLink() && authService.isSessionUserUsername(filterService.getUsername());
				};
				
				scope.isPacked = function() {
					return scope.link.ownLinkId!= null;
				};
				
				scope.isLiked = function() {
					return scope.link.liked;
				};
				
				scope.isLoggedIn = function() {
					return authService.isLoggedIn();
				};
				
				scope.edit = function() {
					znippetService.openEditOverlay(scope.link.clone()).then(function(changedLink) {
						if(changedLink != null){
							scope.onChange({ $changedLink: changedLink });
						} else {
							scope.onDelete();
						}
					});
				};
	      	
		    	scope.repack = function() {
		    		if(scope.isLoggedIn()) {
		    			if (scope.link.ownLinkId == null) {
			    			var derivedLink = znippetService.getNewZnippet();
			    			derivedLink.title = scope.link.title;
			    			derivedLink.sourceUrl = scope.link.sourceUrl;
			    			derivedLink.sourceZnippetId = scope.link.id;
			    			derivedLink.description = scope.link.description;
			    			znippetService.openEditOverlay(derivedLink).then(function() {
								scope.onRepack();
							});
			    		}
			    		else {
			    			znippetService.loadLink(scope.link.ownLinkId).then(function(ownLink) {
								znippetService.openEditOverlay(ownLink).then(function(changedLink) {
									scope.onChange({ $changedLink: changedLink });
								});						
							}).finally(function() {
								loadingService.hideModalLoadingAnimation();
							});
			    		}	
		    		} else {
		    			loginService.openRegisterDialog('link repack button');
		    		}
		    	};
		    	
		    	scope.handleCheckboxSelect = function($event) {
		    		toggleSelect($event);
		    	};
		    	
		    	scope.toggleSelect = function($event) {
		    		if(scope.isOwnLink()) {
		    			scope.link.selected = !scope.link.selected;
		    			toggleSelect($event);
		    		}
		    	};
		    	
		    	function toggleSelect($event) {
		    		if(scope.link.selected === true){
	    				scope.onSelect({ $link: scope.link, $event: $event});
	    			} else if(scope.link.selected === false) {
	    				scope.onDeselect({ $link: scope.link, $event: $event });
	    			}
		    	};
		    	
		    	scope.$watch('link.selected', function(newValue) {
		    		if(newValue === true) {
		    			scrollService.scrollToElement(element, 0);
		    			element.focus();
		    		}
		    	});
		    	
		    	scope.toggleLike = function () {
		    		if(scope.isLoggedIn()) {
		    			if(!scope.isLiked()) {
			    			$http.post('/like', { id: scope.link.id }).then(function(){
			    				scope.link.liked = true;
			    				scope.link.stats.numberOfLikes++;
		    				        analytics.sendLinkEvent('liked ' + scope.getContext() + ' ' + scope.link.sourceUrl, 'like');
			    			});
			    		} else {
			    			$http.post('/unlike', { id: scope.link.id }).then(function(){
			    				scope.link.liked = false;
			    				scope.link.stats.numberOfLikes--;
		    				        analytics.sendLinkEvent('unliked ' + scope.getContext() + ' ' + scope.link.sourceUrl, 'unlike');
			    			});
			    		}
		    		} else {
		    			loginService.openRegisterDialog('link like button');
		    		}
		    	};
		    	
		    	scope.togglePrivacy = function () {
	    			znippetService.updateZnippet(scope.link.id, {isPrivate: !scope.link.isPrivate}).then(function(responseObject) {
						scope.link.isPrivate = !scope.link.isPrivate;
	    				if(scope.link.isPrivate) {
	    					analytics.sendLinkEvent('made private ' + scope.link.sourceUrl, 'makePrivate');
	    				} else {
	    					analytics.sendLinkEvent('made public ' + scope.link.sourceUrl, 'makePublic');
	    				}
	    				$rootScope.$broadcast('refreshPage', {reason: 'linkPrivacyToggled'});
					});
		    	};
		    	
		    	scope.showRepacks = function() {
					userListOverlayService.openRepackersOverlay(scope.link);
				}
	
				scope.showLikes = function() {
					userListOverlayService.openLikersOverlay(scope.link);
				}
				
				var isDraggable = false;
				element.bind('mouseenter', function() {
					scope.$apply(function() {
						if (!ResponsiveBootstrapToolkit.is('xs') && !isDraggable && scope.isDraggable()) {
							element.data('znippet', scope.link);
			    			element.draggable({
			    		  		revert: 'invalid',
			    		  		distance: 10,
			    		  		start: function() {
	    		  					draggingService.startDragging();
	    		  				},
								stop: function() {
									draggingService.stopDragging();
								},
								helper: function() {
			    		  	  		draggingHelper = $(this).clone();
			    		  	  		draggingHelper.addClass('dragging-helper');
			    		  	  		draggingHelper.css('width', $(this).outerWidth());
			    		  	  		draggingHelper.css('height', $(this).outerHeight());
			    		      		return draggingHelper;
			    		    	}
			    		  	});
			    			isDraggable = true;
			    		}
		    		});
				});
				
				var droppableInitialized = false;
				scope.$on('dragStart', function() {
					if (!droppableInitialized) {
						element.droppable({
							hoverClass: 'droppable-hover',
							tolerance: 'pointer',
							accept: function(draggable) {
								return draggable.data('tag') ? true : false;
							},
							drop: function(event, ui) {
								scope.$apply(function() {
									scope.onTagDropped({ $link: scope.link, $tag: ui.draggable.data('tag') });
								});
							}
						});
					}
					droppableInitialized = true;
				});
				
				scope.handleTagClick = function($event, tag) {
					if($state.includes('user')){
						$event.preventDefault();
						filterService.clear();
						filterService.setTags([tag]);
					}
					analytics.sendTagEvent('tag selected in link view', 'select');
				};
				
				scope.handleDescriptionClick = function($event) {
					var $description = angular.element($event.target);
					if($description.hasClass('overflowing')) {
						$description.addClass('expanded');
						$description.removeClass('overflowing');
						$event.stopPropagation();
						analytics.sendLinkEvent('description expanded', 'expand');
					}
				};
				
				scope.openShareDialog = function() {
					if(primaryDataService.isApp()) {
						window.plugins.socialsharing.share(null, null, null, scope.link.sourceUrl);
					} else {
						znippetService.openLinkTipOverlay(scope.link);
					}
				};
				
				scope.getContext = function() {
					var context = scope.context();
					if(context == 'activity') {
						return context;
					}
					else if(context == 'profile') {
						var fullContext = scope.isOwnLink() ? 'own ' : 'other ';
						return fullContext.concat(context, scope.isLoggedIn() ? '' : ' anonymous');
					}
				};
				
				scope.withImage = function() {
					return viewService.isLinkWithImage();
				};

				scope.withDescription = function() {
					return viewService.isLinkWithDescription();
				};

				scope.withTags = function() {
					return viewService.isLinkWithTags();
				};

		    }
		};
	};
	
	linkElement.$inject=['$timeout', 'znippetService', 'authService',
	    'draggingService', 'userListOverlayService', '$rootScope',
	    'analytics', 'loadingService', '$http', 'loginService', 'shareService', 
	    'viewService', 'filterService', '$state', 'scrollService', 'dialogService', 'primaryDataService'];
	
	return linkElement;
	
});

