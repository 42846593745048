define('tagpacker-angular/services/draggingService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('draggingService', draggingService);

	draggingService.$inject = ['$rootScope'];

	function draggingService($rootScope) {
		var service = {};
		
		service.startDragging = function() {
			$rootScope.$broadcast('dragStart');
		};

		service.stopDragging = function() {
			$rootScope.$broadcast('dragStop');
		};


		return service;
	}
	
});


