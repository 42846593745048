define('tagpacker-angular/directives/consent/consent',[], function () {

    var consent = function (cookieService) {
        return {
            scope: {},
            templateUrl: '/assets/javascripts/tagpacker-angular/directives/consent/consent.html',
            controller: ['$scope', function ($scope) {
                var _consent = cookieService.hasCookie('consent');
                $scope.consent = function (consent) {
                    if (consent === undefined) {
                        return _consent;
                    } else if (consent) {
                        cookieService.setCookie('consent');
                        _consent = true;
                    }
                };
            }]
        };
    };

    consent.$inject = ['cookieService'];

    return consent;

});
