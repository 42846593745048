define('tagpacker-angular/directives/tagInput/tagAutocompleteResults',[], function() { return [function() {
	
	return {
		templateUrl: '/assets/javascripts/tagpacker-angular/directives/tagInput/tagAutocompleteResults.html',
		restrict: 'E',
		scope: {
			tags: '=',
			checkedTags: '=',
			onSelect: '&',
			onSubmitNew: '&',
			inputValue: '&'
		},
		controller: ['$scope', function($scope) {
			$scope.highlightedIndex = null;
			
			$scope.isChecked = function(tag) {
				var result = false;
				$scope.checkedTags.forEach(function(eachTag) {
					result |= tag.id != null && eachTag.id === tag.id;
				});
				return result;
			};

			$scope.$on('autocomplete-key-down', function() {
				if (angular.isArray($scope.tags)) {
					if ($scope.highlightedIndex === null)
						$scope.highlightedIndex = 0;
					else 
					    $scope.highlightedIndex++;
					if ($scope.highlightedIndex === $scope.tags.length)
						$scope.highlightedIndex = null;
				}
			});

			$scope.$on('autocomplete-key-up', function() {
				if (angular.isArray($scope.tags)) {
					if ($scope.highlightedIndex === null)
						$scope.highlightedIndex = $scope.tags.length - 1;
					else
						$scope.highlightedIndex--;
	
					if ($scope.highlightedIndex === -1)
						$scope.highlightedIndex = null;
				}
			});

			$scope.$on('autocomplete-key-enter', function() {
				if (angular.isArray($scope.tags)) {
					if ($scope.highlightedIndex !== null)
						$scope.onSelect({ '$tag': $scope.tags[$scope.highlightedIndex] });
					else
						$scope.onSubmitNew({ '$tagName': $scope.inputValue() });
				}
			});

			$scope.$watch('tags', function() {
				if ($scope.tags && $scope.tags.length > 0)
					$scope.highlightedIndex = 0
				else
					$scope.highlightedIndex = null;
			}, true);

			$scope.handleTagHover = function(index) {
				$scope.highlightedIndex = index;
			};

			$scope.handleTagClick = function(index) {
				$scope.onSelect({ '$tag': $scope.tags[index] });
			};

			$scope.handleNewTagHover = function() {
				$scope.highlightedIndex = null;
			};

			$scope.handleNewTagClick = function() {
				$scope.onSubmitNew({ '$tagName': $scope.inputValue() });
			};
			
		}]
	};
	
}]});

