define('tagpacker-angular/directives/login/emailDirective',[], function(){	
	
	var email = function factory(primaryDataService) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/emailDirective.html',
			scope: {
				form: '=', 
				session: '=', 
				hint: '&', 
				showLabel: '&',
				placeholder: '&'
			},
			replace: true,
			restrict: "E",
			controller: ['$scope', function($scope) {
				$scope.emailPattern = new RegExp(primaryDataService.getEmailPattern());
				
				$scope.getPlaceholder = function () {
					if(!$scope.showLabel() && $scope.inputFocused) {
						return "";
					}
					if ($scope.placeholder()) {
						return $scope.placeholder();
					} else {
						return "Email";
					}
				};
			}]
		};
		
	};
	
	email.$inject=['primaryDataService'];
	
	return email;

});
