define('classes/FacetOption',['./Tag'], function(Tag){

	function FacetOption(data) {
		this.tag = new Tag(data.tag);
		this.update(data);
	}

	FacetOption.prototype.update = function(data) {
		this.tag.update(data.tag);
		this.frequency = data.frequency;
		this.selected = data.selected;
		this.excluded = data.excluded;
		this.url = data.url;
	}

	FacetOption.constructArray = function(dataArray) {
		return dataArray.map(function(x) { return new FacetOption(x); });
	}
	
	return FacetOption;

});
