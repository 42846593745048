define('tagpacker-angular/services/imageService',['tagpackerModule'], function(tagpackerModule){
	
	tagpackerModule.factory('imageService', imageService);
	
	imageService.$inject = ['$http'];
	
	function imageService($http) {

		var service = {
			load: load,
			create: create
		};

		function load(url, limit, excludedUrls) {
			return $http.post('/api/images/search?url=' + encodeURIComponent(url) + '&limit=' + limit, {excludedUrls: excludedUrls}).then(function(response){
				return response.data;
			});
		};
		
		function create(imageUrl) {
			return $http.post('/api/images', {
					'imageUrl': imageUrl
			}).then(function(response){
				return response.data;
			});
		};
		
		return service;
	}
	
});

