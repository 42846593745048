define('tagpacker-angular/directives/loadTrigger/loadTriggerDirective',[], function(){

	var znippetLoadTrigger = function factory($window, $timeout) {
		
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/loadTrigger/loadTriggerDirective.html',
			replace: true,
			scope: {
				isReloadPossible: '&',
				triggerReload: '&',
				watchExpression: '&'
			},
			controller: ['$scope', function($scope) {
				$scope.triggerReloadIfNearBottom = function() {
					var bottomLoadingDiv = $('.bottom-loading-div');
					if ($scope.isReloadPossible() && bottomLoadingDiv.length !== 0) {
						var elementTop = bottomLoadingDiv.offset().top - $(window).scrollTop();
						if (elementTop < ($($window).height() + 500)) {
							$scope.triggerReload();
						}
					}
				};
				
				$scope.$watch($scope.watchExpression, function(newValue, oldValue) {
					if (newValue != oldValue) {
						// wait for the page to render
						$timeout($scope.triggerReloadIfNearBottom);
					}
				});
				
			}],
			link: function postLink(scope, element, attributes) {
				$($window).bind('resize', scope.triggerReloadIfNearBottom);
				
				$($window).bind('scroll', scope.triggerReloadIfNearBottom);
				
				scope.$on('$destroy', function() {
					$($window).unbind('resize', scope.triggerReloadIfNearBottom);
					
					$($window).unbind('scroll', scope.triggerReloadIfNearBottom);
				});
			}
		};
		
	};
	
	znippetLoadTrigger.$inject=['$window', '$timeout'];
	
	return znippetLoadTrigger;
	
});
