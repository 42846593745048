define('tagpacker-angular/controllers/FeaturesController',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.controller('FeaturesController', FeaturesController);

	FeaturesController.$inject=['$scope', 'primaryDataService', 'loginService'];

	function FeaturesController($scope, primaryDataService, loginService) {
		$scope.chromeWebstoreItemUrl = primaryDataService.getChromeWebstoreItemUrl();
		$scope.firefoxWebstoreItemUrl = primaryDataService.getFirefoxWebstoreItemUrl();
		$scope.edgeWebstoreItemUrl = primaryDataService.getEdgeWebstoreItemUrl();
		
		$scope.openRegisterDialog = function() {
	      	loginService.openRegisterDialog('FeatureController');
	    };
	}
	
});

