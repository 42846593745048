define('tagpacker-angular/services/notificationService',['tagpackerModule'], function(tagpackerModule) {
	tagpackerModule.factory('notificationService', notificationService);

	notificationService.$inject = ['$rootScope', '$http', '$state', '$location', 'authService'];

	function notificationService($rootScope, $http, $state, $location, authService) {

		var service = {};
		
		var badgeNumbers = {
			numberOfUnseenLinks: 0,
			numberOfUnseenLinkTips: 0	
		};
		
		function updateNotificationBadge() {
	    	if(authService.isLoggedIn()){
            	if ($state.current.name == 'network.main') {
            		badgeNumbers.numberOfUnseenLinks = 0;
			 		badgeNumbers.numberOfUnseenLinkTips = 0;
			 	} else {
			 	 	$http({
						method: 'GET',
						url: '/api/activities/count'
					}).success(function(data, status, headers, config) {
						badgeNumbers.numberOfUnseenLinks = data;
					});
					$http.get('/api/linkTips/count').success(function(data) {
						badgeNumbers.numberOfUnseenLinkTips = data;
					});
			 	}
            }
		};

		$rootScope.$watch(function() { return $location.url() }, function() {
		 	updateNotificationBadge();
		});
		
		$rootScope.$on('refreshPage', function() {
			updateNotificationBadge();
        });
		
		service.getBadgeNumbers = function() {
			return badgeNumbers;
		};

		return service;

	}

});

