define('tagpacker-angular/directives/autocomplete/filterAutocompleteDirective',['tagpackerUtils'], function(tagpackerUtils){

	var filterAutocomplete = function factory($timeout, $compile, loaderService) {
		return {
			scope: {
				placeholder: '=',
				container: '=',
				query:'=ngModel',
				// like an extension of the element. A click inside focuses the input.
				inputWrapper: '=',
				onSubmit: '&',
				onSelect: '&',
				onBackspace: '&',
				search: '&'
			},
			controller: ['$scope', function($scope) {
				var loader = loaderService.getMostRecentRequestLoader();
				
				$scope.hasFocus = false;
				$scope.noSearchResults = false;
				$scope.suggestions = [];
				$scope.selectedSuggestion = null;
				$scope.query = '';
				
				$scope.state = 'idle';
				
				$scope.loadingTimeout = null;
				
				$scope.cancelTimeouts = function() {
					if ($scope.suggestionTimeout) {
						$timeout.cancel($scope.suggestionTimeout);
					}
					if ($scope.loadingTimeout) {
						$timeout.cancel($scope.loadingTimeout);
					}
				};
				
				$scope.clear = function () {
					$scope.suggestions = [];
					$scope.selectedSuggestion = null;
					$scope.cancelTimeouts();
					$scope.query = '';
					$scope.state = 'idle';
					$scope.hideLoading();
					loader.cancel();
				};
				
				$scope.$on('$destroy', function() {
					$scope.cancelTimeouts();
				});
				
				$scope.scheduleSearch = function (delay) {
					delay = typeof delay !== 'undefined' ? delay : 100;
					$scope.cancelTimeouts();
					$scope.loadingTimeout = $timeout(function() {
						$scope.showLoading();								
					}, 200);
					$scope.state = 'loading';
					loader.cancel();
					$scope.suggestionTimeout = $timeout(function() {
						loader.load($scope.search({ $query: $scope.query }))
							.then(function(data) {
								$scope.suggestions = data;
								$scope.selectedSuggestion = null;
								if ($scope.suggestions && $scope.suggestions.length > 0
									    && $scope.query.length > 0) {
									$scope.selectedSuggestion = $scope.suggestions[0];	
								}
								
								$scope.state = 'idle';
								$scope.hideLoading();
								$scope.cancelTimeouts();
								$scope.noSearchResults = !$scope.suggestions || $scope.suggestions.length == 0;
							});
					}, delay);
				};
			
				
				$scope.select = function(entry) {
					$scope.onSelect({ $item: entry.item});
					$scope.clear();
				};
				
				$scope.submit = function(query) {
					$scope.onSubmit({ $query: query });
					$scope.clear();
				};
				
				$scope.hoverSuggestion = function(suggestion) {
					$scope.selectedSuggestion = suggestion;
				};
				
				$scope.isSubmitPossible = function() {
					return !tagpackerUtils.isBlank($scope.query);
				}
				
				// update suggestions when query changes
				$scope.$watch('query', function(newValue, oldValue) {
					if (tagpackerUtils.isBlank(newValue)) {
						$scope.clear(); 
					}
					else {
						$scope.scheduleSearch();
					}
				});
			}],
			link: function postLink(scope, element, attributes) {
				
				scope.showLoading = function() {
					if (scope.resultContainer) {
						scope.resultContainer.addClass('loading');
					}
				};
				
				scope.hideLoading = function() {
					if (scope.resultContainer) {
						scope.resultContainer.removeClass('loading');
					}
				};
				
				scope.$watch("isSubmitPossible() || suggestions.length > 0 || showNoResultsHint()",
						function(newValue, oldValue) {
					if (scope.resultContainer) {
						if (newValue == true) {
							scope.resultContainer.addClass('not-empty');
						}
						else {
							scope.resultContainer.removeClass('not-empty');
						}
					}
				});
								
				// Lex: why timeout? Chris: because container/inputWrapper are set in the post-link method of the calling directive, otherwise these elements are undefined
				$timeout(function() {
					//wrap input for autocomplete loading animation
					var inputWrapper = element;
					if (typeof scope.inputWrapper !== 'undefined') {
						inputWrapper = scope.inputWrapper;
					}
					
					// prevent blur when a click occurs inside the input wrapper
					inputWrapper.mousedown(function(event) {
						if (!$(event.target).is(element)) {
							event.preventDefault();											
						}
					});
					
					scope.resultContainer = scope.container;
					scope.resultContainer.append('<div class="loading-div"></div>');
					scope.resultContainer.mousedown(function(event) {
						event.preventDefault();
					});
					
					// focus the element, when a click occurs inside the input wrapper
					inputWrapper.click(function(event) {
						if (!$(event.target).is(element)) {
							element.focus();
						}
					});			
					
					scope.resultContainer.append($compile('<autocomplete-results></autocomplete-results>')(scope));	
				}, 0);
				
				element.attr('placeholder', scope.placeholder);
		    	
		    	element.bind('keydown', function(event) {
					scope.$apply(function() {
						if(event.which === $.ui.keyCode.DOWN) {
					        if (scope.selectedSuggestion == null && scope.suggestions.length > 0) {
					        	scope.selectedSuggestion = scope.suggestions[0];
					        }
					        else if (scope.selectedSuggestion != null) {
					        	var index = $.inArray(scope.selectedSuggestion, scope.suggestions);
					        	if (index + 1 < scope.suggestions.length) {
					        		scope.selectedSuggestion = scope.suggestions[index + 1]; 
					        	}
					        	else {
					        		scope.selectedSuggestion = null;
					        	}
					        }
					    } else if (event.which === $.ui.keyCode.UP) {
					    	if (scope.selectedSuggestion == null && scope.suggestions.length > 0) {
					        	scope.selectedSuggestion = scope.suggestions[scope.suggestions.length - 1];
					        }
					        else if (scope.selectedSuggestion != null) {
					        	var index = $.inArray(scope.selectedSuggestion, scope.suggestions);
					        	if (index - 1 >= 0) {
					        		scope.selectedSuggestion = scope.suggestions[index - 1]; 
					        	}
					        	else {
					        		scope.selectedSuggestion = null;
					        	}
					        }
					    }
					    else if (event.which === $.ui.keyCode.TAB) {
							if (scope.selectedSuggestion != null) {
					    		scope.select(scope.selectedSuggestion);
					    		event.preventDefault();
					    	}
						}
						else if (event.which === $.ui.keyCode.BACKSPACE) {
							if (scope.query.length == 0) {
								scope.onBackspace();
							}
						}
						
						if (event.which === $.ui.keyCode.UP || event.which === $.ui.keyCode.DOWN) {
							// prevent cursor movement in chrome
							event.preventDefault();
							
							function scrollToSelectedElement(container) {
								var selected = container.find('.selected');
								if (selected.length > 0) {
									var selectedTop = selected.position().top;
									var selectedBottom = selectedTop + selected.outerHeight(); 
	
									if (selectedBottom > container.height()) {
										var delta = selectedBottom - container.height();
										container.scrollTop(container.scrollTop() + delta);
									}
									else if (selectedTop < 0) {
										container.scrollTop(container.scrollTop() + selectedTop);
									}
								}
							}
							
							setTimeout(function(){
								scrollToSelectedElement(scope.resultContainer);
							}, 0);
						}
					});
				});			
				
				element.bind('keyup', function(event) { 
					scope.$apply(function() {
						// ESCAPE
						// keyup is used because keypress does not work in FF and keydown seems to early. Apparently
						// the ng-model directive reverts the change in FF when using keydown.
						if (event.which === $.ui.keyCode.ESCAPE) {
							scope.clear();
						}
					});
				});
				
				// ENTER
				element.bind('keypress', function(event) {
					scope.$apply(function() {
						if (event.which === $.ui.keyCode.ENTER) {
							if (scope.state === 'idle') {
								if (scope.selectedSuggestion != null) {
						    		scope.select(scope.selectedSuggestion);
						    	} else {
						    		scope.submit(scope.query);
						    	}
							}
							else {
								scope.showLoading();
							}
						}
					});
				});
						
				element.bind('focus', function(event) {
					scope.$apply(function() {
						scope.hasFocus = true;
					});
				});
				
				element.bind('blur', function(event) {
		    		scope.$apply(function() {
		    			//do not clear input / query; otherwise link title and link url cannot be clicked anymore; ask Chris for details
			    		scope.hasFocus = false;
					});
		    	});
			}
		};
		
	};
	
	filterAutocomplete.$inject=['$timeout', '$compile', 'loaderService'];
	
	return filterAutocomplete;
	
});

