define('tagpacker-angular/directives/login/nameDirective',[], function(){	

	return function factory() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/login/nameDirective.html',
			scope: {
				form: '=',
				session: '=', 
				showLabel: '&'
			},
			replace: true,
			restrict: "E",
			controller: ['$scope', function($scope) {		
				$scope.getPlaceholder = function () {
					if(!$scope.showLabel() && $scope.inputFocused) {
						return "";
					}
					return "Name";
				};
			}]
		};
	};

});
