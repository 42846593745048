define('tagpacker-angular/services/utilService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('utilService', utilService);

	utilService.$inject = ['$timeout', '$state', '$window', '$rootScope'];

	function utilService($timeout, $state, $window, $rootScope)
	{
		var service = {};
	
		service.focusElement = function(element) {
			// apparently the extra setTimeout is required in modals (example: addTagDialog.html)
			setTimeout(function() {
				$timeout(function() {
					if (element.is('input')) {
						focusInput(element);
					}
					else {
						focusInput(element.find('input').first());
					}
				}, 250);
			});		
		};
		
		var focusInput = function (input){
			//set cursor to the end of the input
			var val = input.val();
			input.focus().val('').val(val);
		};
		
		
		// this function could certainly reside in another service
		service.getStateNameForHttpStatus = function(httpStatus) {
			var result = null;
			if (httpStatus == 400) {
				result = 'badRequest';
			}
			else if (httpStatus == 404) {
				result = 'notFound';
			}
			else if (httpStatus == 500) {
				result = 'error';
			}
			return result;
		};
		
		service.initWindowFocusEvent = function() {
			var windowHasFocus = true;
			
			$($window).focus(function(event) {
				if (!windowHasFocus) {
					$rootScope.$broadcast('windowFocus');
				}
			});
			
			$(window).blur(function(event) {
				windowHasFocus = false;
			});
		};

		service.isLocalhost = function () {
			return window.location.hostname === 'localhost';
		};

		service.isTagpacker = function () {
			return window.location.hostname === 'tagpacker.com';
		};

		return service;
		
	}
	
});

