define('tagpacker-angular/directives/user/userHeaderDirective',[], function(){

	var userHeader = function factory(modalService, $window) {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/user/userHeaderDirective.html',
			replace: true,
			restrict: 'E', 
			scope: {
				user: '='
			},
			controller: ['$scope', 'authService', '$http', 'userListOverlayService', 'blockService', 'loadingService', 'analytics', 'userService',
				function($scope, authService, $http, userListOverlayService, blockService, loadingService, analytics, userService) {

					$scope.areAdminFeaturesEnabled = function() {
						return authService.areAdminFeaturesEnabled();
					};
					
					$scope.lock = function() {
						$http.post('/api/users/'+ $scope.user.id +'/lock').then(function() {
							window.location.reload();
						});
					};

					$scope.flagQuality = function() {
						$http.post('/api/users/'+ $scope.user.id +'/flagQuality').then(function() {
							window.location.reload();
						});
					};

					$scope.unflagQuality = function() {
						$http.post('/api/users/'+ $scope.user.id +'/unflagQuality').then(function() {
							window.location.reload();
						});
					};

					$scope.isSelf = function() {
						return authService.isSessionUserId($scope.user.id);
					};
					
					$scope.photoChangeFinished = function() {
						$window.location.reload(true);
				    };
					
					$scope.shortenNumberOfProfileViews = function () {
						var totalViewCount = $scope.user.numberOfProfileViews;
						if(totalViewCount < 100) {
							return '<100';
						} else if(totalViewCount >= 100 && totalViewCount < 250) {
							return '+100';
						} else if(totalViewCount >= 250 && totalViewCount < 500) {
							return '+250';
						} else if(totalViewCount >= 500 && totalViewCount < 1000) {
							return '+500';
						} else if(totalViewCount >= 1000 && totalViewCount < 1000000) {
							return '+' + parseInt(totalViewCount / 1000) + 'K';
						} else if(totalViewCount >= 1000000) {
							return '+' + ( Math.round( totalViewCount / 1000000 * 100) / 100) + 'M';
						} 
						return totalViewCount;
					};
					
					$scope.changeDescription = function() {
						var description = $scope.user.description;
						modalService.openBootstrapModal({
					    	backdrop: 'static',
					    	templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/userEditDialog.html',
					    	controller: ['$scope', '$uibModalInstance', function UserDescriptionEditDialogController($scope, $uibModalInstance) {
					    		// this wrapping is required in order to use ng-model. $scope is a parent of the actual scope.
					    		$scope.fields = { description: description };
					    		$scope.save = function () {
					    			$uibModalInstance.close($scope.fields.description);
								};
								$scope.cancel = function () {
									$uibModalInstance.dismiss('cancel');
								};
					    	}]
						}).result.then(function(description) {
							userService.update(authService.getSessionUser().id, {description: description}).then(function(){
								$scope.$emit('refreshPage');
			                });
					    });
					};
					
					$scope.openFollowersOverlay = function() {
						userListOverlayService.openFollowersOverlay($scope.user)
					};
					
					$scope.openFollowingOverlay = function() {
						userListOverlayService.openFollowingOverlay($scope.user);
					};
					
					$scope.mayBlock = function() {
						return authService.isLoggedIn() && !$scope.isSelf() && authService.getSessionUser().blockedIds.indexOf($scope.user.id) == -1;
					};
					
					$scope.mayUnblock = function() {
						return authService.isLoggedIn() && !$scope.isSelf() && authService.getSessionUser().blockedIds.indexOf($scope.user.id) >= 0;
					};
					
					$scope.block = function() {
						modalService.openBootstrapModal({
							templateUrl: '/assets/javascripts/tagpacker-angular/dialogs/blockConfirmation.html',
							controller: ['$scope', '$uibModalInstance', function InfoDialogController($scope, $uibModalInstance) {
								$scope.close = function () {
									$uibModalInstance.dismiss('cancel');
								};
								$scope.confirm = function () {
									$uibModalInstance.close();
								};
								$scope.showBlockInfo = function() {
									showBlockInfo();
								};
							}]
						}).result.then(function () {
							loadingService.showModalLoadingAnimation();
							blockService.block(authService.getSessionUserId(), $scope.user.id).then(function() {
								$scope.$emit('refreshPage');
							}).finally(function(){
								loadingService.hideModalLoadingAnimation();
								analytics.sendUserEvent('user blocked', 'block');
							});
						});
					};
					
					$scope.unblock = function() {
						loadingService.showModalLoadingAnimation();
						blockService.unblock(authService.getSessionUserId(), $scope.user.id).then(function() {
							$scope.$emit('refreshPage');
						}).finally(function(){
							loadingService.hideModalLoadingAnimation();
							analytics.sendUserEvent('user unblocked', 'unblock');
						});
					};
					
					function showBlockInfo() {
						return modalService.openBootstrapModal({
							templateUrl:  '/assets/javascripts/tagpacker-angular/dialogs/blockInfo.html',
							controller: ['$scope', '$uibModalInstance', function InfoDialogController($scope, $uibModalInstance){
				  				$scope.close = function(result){
									$uibModalInstance.close();
								};
							}]
						});
					};
					
					$scope.showBlockInfo = function() {
						showBlockInfo();
					};
				}
			]
		};
	};
	
	userHeader.$inject=['modalService', '$window'];

	return userHeader;

})
;
