define('tagpacker-angular/controllers/activitiesList/ActivitiesListController',['classes/Znippet', 'classes/User', 'tagpackerModule', 'tagpackerUtils'], function(Znippet, User, tagpackerModule, tagpackerUtils){
	'use strict';
	
	tagpackerModule.controller('ActivitiesListController', ActivitiesListController);

	ActivitiesListController.$inject = ['$scope', 'recommendationService', 'scrollService',
										'listLoader', 'loadingService', '$http', 'authService', '$state', 'channelService', 'filterService'];
	
	function ActivitiesListController($scope, recommendationService, scrollService, listLoader,
									  loadingService, $http, authService, $state, channelService, filterService) {	
		$scope.linkTips = [];
		
		function loadActivities(channelName) {
			var url = '/api/activities';
			if(channelName) {
				url = url + '?tag=' + encodeURIComponent(channelName)
			}
			if($scope.activitiesList){
				$scope.activitiesList.cancel();
			}
			$scope.activitiesList = listLoader.create(url, {
				chunkSize: 12,
				transformElement: function(element) {
					return new Znippet(element);
				}
			});
			$scope.activitiesList.load();
		}
		
		$scope.$watch(function() {
			return $scope.activitiesList.isComplete(); 
		}, function(newValue, oldValue) {
			if(newValue === true){
				var channelName = channelService.getChannelName();
				if(channelName != null){
					loadChannelFollowSuggestions(channelName);
				} else {
					loadFollowSuggestions();
				}
			}
		});
		
		function loadFollowSuggestions() {
			if($scope.userList){
				$scope.userList.cancel();
			}
			$scope.userList = listLoader.create('/api/followSuggestions', {
				chunkSize: 5,
				morphArray: function(oldArray, newArray) {
					newArray = User.constructArray(newArray);
					tagpackerUtils.morphUserArray(oldArray, newArray);
				}
			});
			$scope.userList.load();
		}
		
		function loadChannelFollowSuggestions(channelName) {
			if($scope.userList){
				$scope.userList.cancel();
			}
			$scope.userList = listLoader.create('/api/channelFollowSuggestions?channelName=' + encodeURIComponent(channelName), {
				chunkSize: 5,
				morphArray: function(oldArray, newArray) {
					newArray = User.constructArray(newArray);
					tagpackerUtils.morphUserArray(oldArray, newArray);
				}
			});
			$scope.userList.load();
		}
		
		$scope.$on('windowFocus', function() {
			refreshWithLoadingAnimation();
		});
		
		$scope.$on('refreshPage', function() {
			refreshWithLoadingAnimation();
		});
	
		$scope.$on('forceActivitiesRefresh', function() {
			scrollService.scrollToTop();
			refreshWithLoadingAnimation(true);
		});
		
		$scope.$watch(function() {
				return channelService.getChannelName();
			}, function(newValue, oldValue) {
				scrollService.scrollToTop();
				loadActivities(newValue);
			});
		
		$scope.channelSelected = function() {
			return channelService.channelSelected();
		};
		
		$scope.getChannelName = function() {
			return channelService.getChannelName();
		};
		
		function loadAds() {
			$http.get('/api/activities/ads?limit=4').success(function(data) {
				data = Znippet.constructArray(data);
				recommendationService.update(data);
			});
		}
		
		function loadLinkTips() {
			$http.get('/api/linkTips').success(function(data) {
				for(var i=0;i<data.length;i++) {
					if(data[i].sendingUser) {
						data[i].sendingUser = new User(data[i].sendingUser);
					}
					if(data[i].sourceLinkUser) {
						data[i].sourceLinkUser = new User(data[i].sourceLinkUser);
					}
				}
				$scope.linkTips = data;
			});
		};
		
		function refreshWithLoadingAnimation(startAnimationImmediately) {
			var loadingTask =
					loadingService.startNewTask(startAnimationImmediately ? true : false);
			
			$scope.activitiesList.refresh().finally(function() {
				loadingService.stopTask(loadingTask);
			});
			loadAds();
			loadLinkTips();
		}
		
		loadActivities();
		recommendationService.update([]);
		loadAds();
		loadLinkTips();
	}
	
});

