define('tagpacker-angular/services/osDetector',['tagpackerModule'], function(tagpackerModule){
	
	tagpackerModule.factory('osDetector', osDetector);
	
	osDetector.$inject = ['$window'];
	
	var userAgent = navigator.userAgent.toLowerCase();
	
	function osDetector($window) {
		var android = isAndroid();
		var ios = isIOS();
		
		return {
			isAndroid: function() {
				return android;
			},
			isIOS: function(){
				return ios;
			}
		};
		
		function isAndroid() {
			return userAgent.indexOf('android') > -1 && userAgent.indexOf('mobile') > -1;
		};
		
		//http://stackoverflow.com/questions/9038625/detect-if-device-is-ios
		function isIOS() {
			var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
			return isIOS;
		};
	}

});

