define('tagpacker-angular/services/alertService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('alertService', alertService);

	alertService.$inject = ['$document', '$rootScope', '$compile', '$timeout', '$sce'];

	function alertService($document, $rootScope, $compile, $timeout, $sce) {
		var FADEOUT_DELAY = 4000;
		var FADEOUT_DURATION= 500; // must match transition duration in stylesheet
		
		var alertScope = $rootScope.$new();
	  	alertScope.alerts = [];
		
		var alertTemplate = 
			'<div id="global-alert-container">' + 
				'<div ng-repeat="alert in alerts" class="alert-wrapper" ng-class="{ fadeOut: alert.isHidden }" ng-mouseenter="alert.onEnter()" ng-mouseleave="alert.onLeave()">' +
				    '<uib-alert type="{{alert.type}}" close="alert.close()"><div ng-bind-html="alert.message"></div></uib-alert>' +
				'</div>' +
			'</div>';
		
		var addAlert = function (type, message, forceShowing, noFadeout) {
			if ($document.find('#global-alert-container').length == 0) {
				$document.find('body').prepend($compile(alertTemplate)(alertScope));	
			}
			var alreadyDisplayed = false;
			for(var i=0; i<alertScope.alerts.length; i++) {
				var alert = alertScope.alerts[i];
				if (alert.type == type && alert.message == message) {
					alreadyDisplayed = true;
					break;
				}
			};
			if (!alreadyDisplayed || forceShowing === true) {
				var alert = {
		  			type: type,
		  			message:  $sce.trustAsHtml(message), 
		  			isHovered: false,
		  			isHidden: false,
		  			close: function() {
		  				closeAlert(this);
		  			}, 
		  			onEnter: function () {
		  				this.isHovered = true;
		  			},
		  			onLeave: function () {
		  				this.isHovered = false;
		  			}
		  		};
				alertScope.alerts.push(alert);
				if (!noFadeout) {
					createFadeoutTimeout(alert)
				}
			}
	  	};
	  	
	  	var createFadeoutTimeout = function (alert) {
	  		$timeout(function(){
				if(alert.isHovered) {
					createFadeoutTimeout(alert);
				} else {
					closeAlert(alert);	
				}
			}, FADEOUT_DELAY);
	  	}
	  
	  	var closeAlert = function (alert) {
	  		alert.isHidden = true;
	  		$timeout(function() {
	  			// remove element after animation
	  			alertScope.alerts.splice(alertScope.alerts.indexOf(alert), 1);
	  		}, FADEOUT_DURATION);
	  	}
		
		
		var service = {};
		
		service.addErrorAlert = function(message, forceShowing, noFadeout) {
			addAlert('danger', message, forceShowing, noFadeout);
		};
		
		service.addInfoAlert = function(message, forceShowing, noFadeout) {
			addAlert('info', message, forceShowing, noFadeout);
		};
		
		service.addWarningAlert = function(message, forceShowing, noFadeout) {
			addAlert('warning', message, forceShowing, noFadeout);
		};
		
		service.addSuccessAlert = function(message, forceShowing, noFadeout) {
			addAlert('success', message, forceShowing, noFadeout);
		};
		
		return service;
	}

});

