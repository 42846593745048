define('tagpacker',[
	'tagpackerModule',

	'tagpackerUtils',

	'tagpacker-angular/controllers/MainController',
	'tagpacker-angular/controllers/register/RegisterController',
	'tagpacker-angular/controllers/login/LoginController',
	'tagpacker-angular/controllers/activitiesList/ActivitiesSidebarController',
	'tagpacker-angular/controllers/activitiesList/PopularUsersController',
	'tagpacker-angular/controllers/activitiesList/LatestLinksController',
	'tagpacker-angular/controllers/znippetsList/ZnippetsAdsController',
	'tagpacker-angular/controllers/znippetsList/LinkListController',

	'tagpacker-angular/controllers/UserController',
	'tagpacker-angular/controllers/AccountController',
	'tagpacker-angular/controllers/account/ProfileTabController',
	'tagpacker-angular/controllers/account/AccountTabController',
	'tagpacker-angular/controllers/account/NotificationTabController',
	'tagpacker-angular/controllers/login/ResetPasswordController',
	'tagpacker-angular/controllers/znippetPage/ZnippetPageController',
	'tagpacker-angular/controllers/SignupController',
	'tagpacker-angular/controllers/SearchController',
	'tagpacker-angular/controllers/PluginController',
	'tagpacker-angular/controllers/TagAllDialogController',
	'tagpacker-angular/controllers/UntagAllDialogController',
	'tagpacker-angular/controllers/kpis/KPIsController',
	'tagpacker-angular/controllers/lockedUsers/LockedUsersController',

	'tagpacker-angular/directives/imageChooser/imageChooser',
	'tagpacker-angular/directives/smartFooter/smartFooter',
	'tagpacker-angular/directives/login/loginElements',
	'tagpacker-angular/directives/login/registerElements',
	'tagpacker-angular/directives/login/hlineWithTextDirective',
	'tagpacker-angular/directives/login/emailDirective',
	'tagpacker-angular/directives/login/nameDirective',
	'tagpacker-angular/directives/login/passwordDirective',
	'tagpacker-angular/directives/login/tosDirective',
	'tagpacker-angular/directives/login/usernameDirective',
	'tagpacker-angular/directives/inputFocusDirective',
	'tagpacker-angular/directives/formCommitDirective',
	'tagpacker-angular/directives/validSubmitDirective',
	'tagpacker-angular/directives/navbar/navbarDirective',
	'tagpacker-angular/directives/navbar/changelogDirective',
	'tagpacker-angular/directives/navbar/navbarEmbeddedDirective',
	'tagpacker-angular/directives/ad/blockAdsWhenActive',
	'tagpacker-angular/directives/bookmarklet/bookmarkletDirective',
	'tagpacker-angular/directives/bookmarklet/packItDirective',
	'tagpacker-angular/directives/user/userDirective',
	'tagpacker-angular/directives/user/userPhoto',
	'tagpacker-angular/directives/user/userHeaderDirective',
	'tagpacker-angular/directives/user/userPreviewDirective',
	'tagpacker-angular/directives/followButton/followButton',
	'tagpacker-angular/directives/tagpackerTextareaDirective',
	'tagpacker-angular/directives/onEnterPressDirective',
	'tagpacker-angular/directives/overflowDetectorDirective',
	'tagpacker-angular/directives/activity/activity',
	'tagpacker-angular/directives/activity/stub',
	'tagpacker-angular/directives/link/link',
	'tagpacker-angular/directives/linkTip/linkTip',
	'tagpacker-angular/directives/link/linkThumbnail',
	'tagpacker-angular/directives/affiliateAnchor/affiliateAnchor',
	'tagpacker-angular/directives/filter/filterDirective',
	'tagpacker-angular/directives/sidebar/sidebarDirective',
	'tagpacker-angular/directives/sidebar/sidebarToggleDirective',
	'tagpacker-angular/directives/facet/facetDirective',
	'tagpacker-angular/directives/challenge/challengeDirective',
	'tagpacker-angular/directives/facetOption/facetOption',
	'tagpacker-angular/directives/autocomplete/filterAutocompleteDirective',
	'tagpacker-angular/directives/autocomplete/autocompleteResults',
	'tagpacker-angular/directives/editView/editView',
	'tagpacker-angular/directives/linkList/linkList',
	'tagpacker-angular/directives/navbarSearchInput/navbarSearchInput',
	'tagpacker-angular/directives/collectionSearchResult/collectionSearchResult',
	'tagpacker-angular/directives/tagInput/simpleTagInput',
	'tagpacker-angular/directives/tagInput/singleTagInput',
	'tagpacker-angular/directives/tagInput/tagSuggestions',
	'tagpacker-angular/directives/tagInput/tagAutocompleteResults',
	'tagpacker-angular/directives/tagInput/uniqueTag',
	'tagpacker-angular/directives/validUrl',
	'tagpacker-angular/directives/autocomplete/autocompleteInput',
	'tagpacker-angular/directives/ad/znippet/adZnippetsDirective',
	'tagpacker-angular/directives/recommendation/linkRecommendationDirective',
	'tagpacker-angular/directives/ad/adSenseDirective',
	'tagpacker-angular/directives/ad/googleAdDirective',
	'tagpacker-angular/directives/analytics/optOutDirective',
	'tagpacker-angular/directives/clickTracker/clickTracker',
	'tagpacker-angular/directives/clickTracker/indexClickTracker',
	'tagpacker-angular/directives/companyAddress/companyAddressDirective',
	'tagpacker-angular/directives/debug/debug',
	'tagpacker-angular/directives/api/apiDocLink',
	'tagpacker-angular/directives/jumpToTopOnInit/jumpToTopOnInit',
	'tagpacker-angular/directives/loadTrigger/loadTriggerDirective',
	'tagpacker-angular/directives/otherTagsFacet/otherTagsFacet',
	'tagpacker-angular/directives/scrollToTop/scrollToTopDirective',
	'tagpacker-angular/directives/uploadFile/uploadFileDirective',
	'tagpacker-angular/directives/user/changeUserPhotoDirective',
	'tagpacker-angular/directives/sessionDirective',
	'tagpacker-angular/directives/embeddedVideo/embeddedVideo',
	'tagpacker-angular/directives/indexEmbeddedVideo/indexEmbeddedVideo',
    'tagpacker-angular/directives/consent/consent',

	'tagpacker-angular/filters/highlightQueryFilter',
	'tagpacker-angular/filters/linebreakFilter',

	'routing',

	// directives and services registering themselves in the module

	'fbLoginFix',

	'tagpacker-angular/controllers/activitiesList/ActivitiesListController',
	'tagpacker-angular/controllers/IndexController',
	'tagpacker-angular/controllers/FeaturesController',
	'tagpacker-angular/controllers/welcome/FollowController',
	'tagpacker-angular/controllers/welcome/InterestsController',
	'tagpacker-angular/controllers/welcome/VerifyController',
	'tagpacker-angular/controllers/welcome/WelcomeController',
	'tagpacker-angular/controllers/welcome/WelcomePackitController',

	'tagpacker-angular/directives/bookmarklet/bookmarkletHelpDirective',
	'tagpacker-angular/directives/facet/editModeFacet',
	'tagpacker-angular/directives/facetList/facetList',
	'tagpacker-angular/directives/tagInput/facetBasedTagInput',
	'tagpacker-angular/directives/thumbnail/thumbnailSourceSelector',
    'tagpacker-angular/directives/userVoice/userVoice',

	'tagpacker-angular/services/adminService',
	'tagpacker-angular/services/alertService',
	'tagpacker-angular/services/analytics',
	'tagpacker-angular/services/pullToRefresh',
	'tagpacker-angular/services/authService',
	'tagpacker-angular/services/blockService',
	'tagpacker-angular/services/challengeService',
	'tagpacker-angular/services/browserDetector',
	'tagpacker-angular/services/channelService',
	'tagpacker-angular/services/cookieService',
	'tagpacker-angular/services/dialogService',
	'tagpacker-angular/services/draggingService',
	'tagpacker-angular/services/facetService',
	'tagpacker-angular/services/filterService',
	'tagpacker-angular/services/followService',
	'tagpacker-angular/services/footerService',
	'tagpacker-angular/services/hintService',
	'tagpacker-angular/services/inviteFriendsDialogService',
	'tagpacker-angular/services/viewService',
	'tagpacker-angular/services/listLoader',
	'tagpacker-angular/services/loaderService',
	'tagpacker-angular/services/loadingService',
	'tagpacker-angular/services/loginService',
	'tagpacker-angular/services/mainListService',
	'tagpacker-angular/services/modalService',
	'tagpacker-angular/services/notificationService',
	'tagpacker-angular/services/keyEventService',
	'tagpacker-angular/services/imageService',
	'tagpacker-angular/services/mouseService',
	'tagpacker-angular/services/navigationService',
	'tagpacker-angular/services/osDetector',
	'tagpacker-angular/services/pageLoader',
	'tagpacker-angular/services/pageService',
	'tagpacker-angular/services/primaryDataService',
	'tagpacker-angular/services/recommendationService',
	'tagpacker-angular/services/shareService',
	'tagpacker-angular/services/scrollService',
	'tagpacker-angular/services/sidebarService',
	'tagpacker-angular/services/sortService',
	'tagpacker-angular/services/superTagDialogService',
	'tagpacker-angular/services/tagNameInUrlEncoder',
	'tagpacker-angular/services/tagpackService',
	'tagpacker-angular/services/tagService',
	'tagpacker-angular/services/tagSuggestionService',
	'tagpacker-angular/services/titleService',
	'tagpacker-angular/services/userService',
	'tagpacker-angular/services/userListOverlayService',
	'tagpacker-angular/services/utilService',
	'tagpacker-angular/services/windowLocation',
	'tagpacker-angular/services/znippetService',
	'tagpacker-angular/services/recaptcha'

	], function(
		tagpackerModule,

		tagpackerUtils,

		MainController,
		RegisterController,
		LoginController,
		ActivitiesSidebarController,
		PopularUsersController,
		LatestLinksController,
		ZnippetsAdsController,
		LinkListController,
		UserController,
		AccountController,
		ProfileTabController,
		AccountTabController,
		NotificationTabController,
		ResetPasswordController,
		ZnippetPageController,
		SignupController,
		SearchController,
		PluginController,
		TagAllDialogController,
		UntagAllDialogController,
		KPIsController,
		LockedUsersController,

		imageChooser,
		smartFooter,
		loginElements,
		registerElements,
		hlineWithTextDirective,
		emailDirective,
		nameDirective,
		passwordDirective,
		tosDirective,
		usernameDirective,
		inputFocusDirective,
		formCommitDirective,
		validSubmitDirective,
		navbarDirective,
		changelogDirective,
		navbarEmbeddedDirective,
		blockAdsWhenActive,
		bookmarkletDirective,
		packItDirective,
		userDirective,
		userPhoto,
		userHeaderDirective,
		userPreviewDirective,
		followButton,
		tagpackerTextareaDirective,
		onEnterPressDirective,
		overflowDetectorDirective,
		activity,
		stub,
		link,
		linkTip,
		linkThumbnail,
		affiliateAnchor,
		filterDirective,
		sidebarDirective,
		sidebarToggleDirective,
		facetDirective,
		challengeDirective,
		facetOption,
		filterAutocompleteDirective,
		autocompleteResults,
		editView,
		linkList,
		navbarSearchInput,
		collectionSearchResult,
		simpleTagInput,
		singleTagInput,
		tagSuggestions,
		tagAutocompleteResults,
		uniqueTag,
		validUrl,
		autocompleteInput,
		adZnippetsDirective,
		linkRecommendationDirective,
		adSenseDirective,
		googleAdDirective,
		optOutDirective,
		clickTracker,
		indexClickTracker,
		companyAddressDirective,
		debug,
		apiDocLink,
		jumpToTopOnInit,
		loadTriggerDirective,
		otherTagsFacet,
		scrollToTopDirective,
		uploadFileDirective,
		changeUserPhotoDirective,
		sessionDirective,
		embeddedVideo,
		indexEmbeddedVideo,
		consent,

		highlightQueryFilter,
		linebreakFilter,

		routing
	) {
  'use strict';

  var tagpacker = tagpackerModule;

  tagpacker.controller('MainController', MainController);
  tagpacker.controller('RegisterController', RegisterController);
  tagpacker.controller('LoginController', LoginController);
  tagpacker.controller('ActivitiesSidebarController', ActivitiesSidebarController);
  tagpacker.controller('ZnippetsAdsController', ZnippetsAdsController);
  tagpacker.controller('UserController', UserController);
  tagpacker.controller('LinkListController', LinkListController);
  tagpacker.controller('AccountController', AccountController);
  tagpacker.controller('ProfileTabController', ProfileTabController);
  tagpacker.controller('AccountTabController', AccountTabController);
  tagpacker.controller('NotificationTabController', NotificationTabController);
  tagpacker.controller('PopularUsersController', PopularUsersController);
  tagpacker.controller('LatestLinksController', LatestLinksController);
  tagpacker.controller('ResetPasswordController', ResetPasswordController);
  tagpacker.controller('ZnippetPageController', ZnippetPageController);
  tagpacker.controller('SignupController', SignupController);
  tagpacker.controller('SearchController', SearchController);
  tagpacker.controller('PluginController', PluginController);
  tagpacker.controller('TagAllDialogController', TagAllDialogController);
  tagpacker.controller('UntagAllDialogController', UntagAllDialogController);
  tagpacker.controller('KPIsController', KPIsController);
  tagpacker.controller('LockedUsersController', LockedUsersController);

  tagpacker.directive('imageChooser', imageChooser);
  tagpacker.directive('smartFooter', smartFooter);
  tagpacker.directive('loginElements', loginElements);
  tagpacker.directive('registerElements', registerElements);
  tagpacker.directive('hlineWithText', hlineWithTextDirective);
  tagpacker.directive('email', emailDirective);
  tagpacker.directive('name', nameDirective);
  tagpacker.directive('password', passwordDirective);
  tagpacker.directive('tos', tosDirective);
  tagpacker.directive('username', usernameDirective.username);
  tagpacker.directive('unique', usernameDirective.unique);
  tagpacker.directive('inputFocus', inputFocusDirective);
  tagpacker.directive('formCommit', formCommitDirective);
  tagpacker.directive('validSubmit', validSubmitDirective);
  tagpacker.directive('znippetNavbar', navbarDirective);
  tagpacker.directive('tpChangelog', changelogDirective);
  tagpacker.directive('tpNavbarEmbedded', navbarEmbeddedDirective);
  tagpacker.directive('blockAdsWhenActive', blockAdsWhenActive);
  tagpacker.directive('tpBookmarklet', bookmarkletDirective);
  tagpacker.directive('tpPackIt', packItDirective);
  tagpacker.directive('user', userDirective);
  tagpacker.directive('userPhoto', userPhoto);
  tagpacker.directive('userHeader', userHeaderDirective);
  tagpacker.directive('followButton', followButton);
  tagpacker.directive('tagpackerTextarea', tagpackerTextareaDirective);
  tagpacker.directive('onEnterPress', onEnterPressDirective);
  tagpacker.directive('overflowDetector', overflowDetectorDirective);
  tagpacker.directive('activity', activity);
  tagpacker.directive('stub', stub);
  tagpacker.directive('linkElement', link);
  tagpacker.directive('linkTip', linkTip);
  tagpacker.directive('linkThumbnail', linkThumbnail);
  tagpacker.directive('affiliateAnchor', affiliateAnchor);
  tagpacker.directive('tagpackerFilter', filterDirective);
  tagpacker.directive('sidebar', sidebarDirective);
  tagpacker.directive('sidebarToggle', sidebarToggleDirective);
  tagpacker.directive('tagpackFacet', facetDirective);
  tagpacker.directive('challenge', challengeDirective);
  tagpacker.directive('facetOption', facetOption);
  tagpacker.directive('userPreview', userPreviewDirective);
  tagpacker.directive('filterAutocomplete', filterAutocompleteDirective);
  tagpacker.directive('autocompleteResults', autocompleteResults);
  tagpacker.directive('editView', editView);
  tagpacker.directive('linkList', linkList);
  tagpacker.directive('navbarSearchInput', navbarSearchInput);
  tagpacker.directive('collectionSearchResult', collectionSearchResult);
  tagpacker.directive('simpleTagInput', simpleTagInput);
  tagpacker.directive('singleTagInput', singleTagInput);
  tagpacker.directive('tagSuggestions', tagSuggestions);
  tagpacker.directive('tagAutocompleteResults', tagAutocompleteResults);
  tagpacker.directive('uniqueTag', uniqueTag);
  tagpacker.directive('validUrl', validUrl);
  tagpacker.directive('autocompleteInput', autocompleteInput);
  tagpacker.directive('adZnippets', adZnippetsDirective);
  tagpacker.directive('linkRecommendations', linkRecommendationDirective);
  tagpacker.directive('adSense', adSenseDirective);
  tagpacker.directive('googleIns', googleAdDirective);
  tagpacker.directive('optOut', optOutDirective);
  tagpacker.directive('clickTracker', clickTracker);
  tagpacker.directive('indexClickTracker', indexClickTracker);
  tagpacker.directive('companyAddress', companyAddressDirective);
  tagpacker.directive('debug', debug);
  tagpacker.directive('apiDocLink', apiDocLink);
  tagpacker.directive('jumpToTopOnInit', jumpToTopOnInit);
  tagpacker.directive('znippetLoadTrigger', loadTriggerDirective);
  tagpacker.directive('otherTagsFacet', otherTagsFacet);
  tagpacker.directive('scrollToTop', scrollToTopDirective);
  tagpacker.directive('uploadFile', uploadFileDirective);
  tagpacker.directive('changeUserPhoto', changeUserPhotoDirective);
  tagpacker.directive('session', sessionDirective);
  tagpacker.directive('embeddedVideo', embeddedVideo);
  tagpacker.directive('indexEmbeddedVideo', indexEmbeddedVideo);
  tagpacker.directive('consent', consent);

  tagpacker.filter('highlightQuery', highlightQueryFilter);
  tagpacker.filter('linebreakFilter', linebreakFilter);

  tagpacker.config(routing);

  tagpacker.run(['alertService', 'analytics', 'pullToRefresh', 'primaryDataService', 'utilService', '$rootScope', '$state', '$stateParams', 'authService', 'titleService', 'hintService', 'navigationService', 'znippetService', '$window',
		function(alertService, analytics, pullToRefresh, primaryDataService, utilService, $rootScope, $state, $stateParams, authService, titleService, hintService, navigationService, znippetService, $window) {

		analytics.init();

		var INTENT_KEY = 'tp-intent';
	  	function showLinkEditDialog(intent) {
	  		var subject = intent.extras["android.intent.extra.SUBJECT"];
			var text = intent.extras["android.intent.extra.TEXT"];
	  		znippetService.getLinkForUrl(text).then(function(link) {
				if(!link.title && subject) {
					link.title = subject;
				}
				if(!link.description && !link.sourceUrl && text) {
					link.description = text;
				}
				if(link.isNew()) {
	  				link.isPrivate = authService.getSessionUser().linkPrivateByDefault;
				}
				return znippetService.openEditOverlay(link);
			}).then(function() {
				$rootScope.$broadcast('refreshPage');
				analytics.sendLinkEvent('link created in app', 'create');
			});
	  	};

	  	if(primaryDataService.isApp()) {
	  		document.addEventListener('deviceready', function(){
	  			var handleIntent = function(intent) {
					if(intent.extras && !intent.extras.used && intent.action == 'android.intent.action.SEND' && intent.type == 'text/plain') {
						if(authService.isLoggedIn()) {
							showLinkEditDialog(intent);
						} else {
							$window.localStorage.setItem(INTENT_KEY, JSON.stringify(intent));
							if (!$state.is('login')) {
								$state.go('login');
							}
						}
					}
				};
				//app is already open
				window.plugins.intent.setNewIntentHandler(handleIntent);
				//app opened
				window.plugins.intent.getCordovaIntent(handleIntent);
			}, false);

		  	document.addEventListener('pause', function() {

			}, false);

			document.addEventListener('resume', function() {
				$rootScope.$broadcast('refreshPage');
			}, false);

			pullToRefresh.init({
				onRefresh: function() {
					$rootScope.$broadcast('refreshPage');
				}
			});

			if(authService.isLoggedIn() && $window.localStorage.getItem(INTENT_KEY) !== null) {
		  		var intent = JSON.parse($window.localStorage.getItem(INTENT_KEY));
		  		$window.localStorage.removeItem(INTENT_KEY);
		  		showLinkEditDialog(intent);
		  	}
	  	}

		titleService.init();
		hintService.run();

		if(primaryDataService.getError()) {
			alertService.addErrorAlert(primaryDataService.getError());
		}
		if (primaryDataService.getMessage()) {
			alertService.addSuccessAlert(primaryDataService.getMessage());
		}
		if(primaryDataService.getWarning()) {
			alertService.addWarningAlert(primaryDataService.getWarning(), true, true);
		}
		if(primaryDataService.getInfo()) {
			alertService.addInfoAlert(primaryDataService.getInfo());
		}

		var firstStateChange = true;
		$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
	    	if (firstStateChange) {
	          	firstStateChange = false;
	          	var errorState = utilService.getStateNameForHttpStatus(primaryDataService.getHttpStatus());
	          	if (errorState != null) {
	          		event.preventDefault();
	          		$state.go(errorState, {}, {location: false});
	          	}
	        }
	    	if (authService.isLoggedIn() && toState.anonymousOnly) {
	    		event.preventDefault();
	    		navigationService.goHome();
	    	} else if(toState.name == 'user.main.filter' && toParams.e && toParams.e == '1') { // legacy embedded link list
	    		event.preventDefault();
	    		delete toParams.e;
	    		navigationService.goEmbed(toParams);
	    	}
	    });

	    utilService.initWindowFocusEvent();

	}]);

	tagpacker.factory('tagpackerHttpInterceptor', ['$q', '$injector', '$timeout',
			function($q, $injector, $timeout) {
		var unload = false;
		$(window).bind('beforeunload', function() {
			unload = true;
		});

		return {
			response: function(response) {
		      // success
		      return response;
		    },
		    responseError: function(response) {
		    	var reason = response;
		    	if (!unload) {
		    	  	//needs to be injected on demand because otherwise a circular dependency is detected (cf., https://github.com/angular/angular.js/issues/2367)
					var alertService = $injector.get('alertService');
					var $window = $injector.get('$window');
					var httpStatus = response.status;
					if (httpStatus == 0) {
						alertService.addErrorAlert("No internet connection");
					} else if (httpStatus == 401) {
						if (response.data.url) {
							$window.location = response.data.url;
						} else {
							alertService.addErrorAlert("Please log in first!");
						}
					} else if (httpStatus >= 400 && httpStatus <= 500) {
						// 400 ignored as it is often caused by unevaluated Angular expressions (e.g., on quick browser backwards/forwards navigation)
						if(httpStatus > 400) {
							var message = response.data.message;
							if(!message) {
								if(httpStatus == 404) {
									message = "Resource not found.";
								} else if(httpStatus == 414) {
									message = "URL too long.";
								} else {
									message = "An error occurred. We'll fix this as soon as possible.";
								}
							}
							var timeout = $timeout(function() {
								alertService.addErrorAlert(message);
							});
						}
						reason = $.extend(response, {
							preventDefault: function() {
								if(timeout){
									$timeout.cancel(timeout);
								}
							}
						});
					}
				}

		    	return $q.reject(reason);
		    }
		};
	}]);

	tagpacker.filter('shorten', function() {
	    return function(input, length){
	        return tagpackerUtils.shorten(input, length);
	    };
	});

	tagpacker.filter('prefix', function() {
	    return function(input, length){
	        return input.substring(0, length);
	    };
	});

	// turns "http://www.spiegel.de/some-article" into "spiegel.de"
	tagpacker.filter('shortenUrl', function() {
	    return function(input){
	        var result = input == null ? "" : $.trim(input);

	        if (result.indexOf('http://') == 0) {
				result = result.substring('http://'.length);
	        }
	        if (result.indexOf('https://') == 0) {
				result = result.substring('https://'.length);
	        }

	        if (result.indexOf('/') > 0) {
	        	result = result.substring(0, result.indexOf('/'));
	        }

	        if (result.indexOf('www.') == 0) {
	        	result = result.substring(4);
	        }

	        return result;
	    };
	});

	tagpacker.filter('toString', function() {
	    return function(input){
	        return input.toString();
	    };
	});

	return tagpacker;

});

