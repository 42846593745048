'use strict';
define('tagpacker-angular/services/primaryDataService',['classes/User', 'classes/Znippet', 'tagpackerModule'],
	   function(User, Znippet, tagpackerModule)
{
    //using 'provider' we can also use it at configuration time
	tagpackerModule.provider('primaryDataService', PrimaryDataService);

	PrimaryDataService.$inject = [];

	function PrimaryDataService() {
		var httpStatus;
		var googleAnalyticsKey;
		var sessionUser;
		var googleAnalyticsDisableString;
		var logline;
		var firefoxHash;
		var recaptchaKey;

		var tagpackerInitialData = window.tagpackerInitialData;

		// should only be falsy in tests (which is admittedly bad, too)
		if (typeof tagpackerInitialData == 'undefined') {
			tagpackerInitialData = {};
		}

		updateFields(tagpackerInitialData);

		function updateFields(initialData) {
			httpStatus = initialData.httpStatus;
			googleAnalyticsKey = initialData.googleAnalyticsKey;
			sessionUser = initialData.sessionUser ? new User(initialData.sessionUser) : null;
			googleAnalyticsDisableString = initialData.googleAnalyticsDisableString;
			logline = initialData.logline;
			firefoxHash = initialData.firefoxHash;
			recaptchaKey = initialData.recaptchaKey;
		}

		this.getEdgeWebstoreItemUrl = function() {
			return 'https://microsoftedge.microsoft.com/addons/detail/efhnfodpjoapfgfjhlknkbkojeppekni';
		};

		this.getChromeWebstoreItemId = function() {
			//development
			//return 'iogapnepjfkjfdmihkjajganiliagdop';

			//runtime
			return 'nohjijligchgbabiimogbigfkjmhekdm';
		};

		this.getChromeWebstoreItemUrl = function() {
			return 'https://chrome.google.com/webstore/detail/' + this.getChromeWebstoreItemId();
		};

		this.getFirefoxWebstoreItemUrl = function() {
			return 'https://addons.mozilla.org/en-US/firefox/addon/tagpacker';
		};

		this.getLogline = function() {
			return logline;
		};

		this.getHttpStatus = function() {
			return httpStatus;
		};

		this.getGoogleAnalyticsKey = function() {
			return googleAnalyticsKey;
		};

		this.getGoogleAnalyticsDisableString = function() {
			return googleAnalyticsDisableString;
		};

		this.getFirefoxHash = function() {
			return firefoxHash;
		};

		this.getRecaptchaKey = function() {
			return recaptchaKey;
		};

		this.getSessionUser = function() {
			return sessionUser;
		};

		this.setSessionUser = function(user) {
			sessionUser = user;
		};

		this.getProviders = function() {
			return tagpackerInitialData.providers;
		};

		this.getBookmarkUrl = function() {
			return tagpackerInitialData.bookmarkUrl;
		};

		this.getBookmarkletScriptUrl = function() {
			return tagpackerInitialData.bookmarkletScriptUrl;
		};

		this.getLatestBookmarkletVersion = function() {
			return tagpackerInitialData.latestBookmarkletVersion;
		};

		this.getActualBookmarkletVersion = function() {
			return tagpackerInitialData.actualBookmarkletVersion;
		};

		this.isPlugin = function() {
			return tagpackerInitialData.isPlugin;
		};

		this.isApp = function() {
			return !!window.cordova;
		};

		this.getEmailPattern = function() {
			return tagpackerInitialData.emailPattern;
		};

		this.getApiDocUrl = function() {
			return tagpackerInitialData.apiDocUrl;
		};

		this.getUsernamePattern = function() {
			return tagpackerInitialData.usernamePattern;
		};

		this.getZnippet = function() {
			return tagpackerInitialData.znippet ? new Znippet(tagpackerInitialData.znippet) : null;
		};

		this.getFormField = function(propertyName) {
			var result = null;
			if (tagpackerInitialData.hasOwnProperty('formData')) {
				for	(property in tagpackerInitialData.formData) {
					if(property === propertyName) {
						return tagpackerInitialData.formData[property];
					}
				}
			}
			return result;
		};

		this.update = function(initialData) {
			tagpackerInitialData = initialData;
			updateFields(initialData);
		};

		this.getError = function() {
			return tagpackerInitialData.error;
		};

		this.getMessage = function() {
			return tagpackerInitialData.message;
		};

		this.getWarning = function() {
			return tagpackerInitialData.warning;
		};

		this.getInfo = function() {
			return tagpackerInitialData.info;
		};

		this.$get = [function launcherFactory() {
			return new PrimaryDataService();
		}];
	}

});

