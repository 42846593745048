define('tagpacker-angular/directives/tagInput/tagSuggestions',[], function(){
	return [function() {
		return {
			templateUrl: '/assets/javascripts/tagpacker-angular/directives/tagInput/tagSuggestions.html',
			replace: true,
			restrict: 'E',
			scope: {
				tags: '=',
				userId: '&',
				url: '&',
				description: '&',
				linkTitle: '&' // should not be 'title' because that would show a tooltip on hover
			},
			controller: ['$scope', 'tagSuggestionService', 'loaderService', 'analytics',
			             function($scope, tagSuggestionService, loaderService, analytics) {

				$scope.suggestions = [];
				$scope.skippedSuggestions = [];

				function getCurrentTagIds() {
					return $scope.tags.map(function(x) { return x.id })
						.filter(function(x) { return x !== null });
				}

				function getSkippedTagIds() {
					return $scope.skippedSuggestions.map(function(x) { return x.id })
						.filter(function(x) { return x !== null });
				}

				function isAlreadyAssigned(tag) {
					var matchId = function(x) { return x.id === tag.id };
					return $scope.tags.filter(matchId).length > 0;
				}

				function empty(someArray) {
					while (someArray.length > 0) someArray.pop();
				}

				function updateSuggesions(newSuggestions) {
					empty($scope.suggestions);
					newSuggestions.forEach(function(eachSuggestion) {
						if (!isAlreadyAssigned(eachSuggestion))
							$scope.suggestions.push(eachSuggestion);
					});
				}

				var suggestionLoader = loaderService.getMostRecentRequestLoader();

				function loadSuggestions() {
					$scope.loadingSuggestions = true;
					suggestionLoader.load(tagSuggestionService.getSuggestions($scope.userId(), {
						title: $scope.linkTitle(),
						description: $scope.description(),
						url: $scope.url(),
						limit: 1,
						tagIds: getCurrentTagIds(),
						excludedTagIds: getSkippedTagIds()
					})).then(function(newSuggestions) { updateSuggesions(newSuggestions) }).finally(function(){
						$scope.loadingSuggestions = false;
					});
				};

				$scope.$watch('tags', function() {
					loadSuggestions();
				}, true);

				$scope.getVisibleSuggestions = function() {
					var result = [];
					$scope.suggestions.forEach(function(eachSuggestion) {
						if (!isAlreadyAssigned(eachSuggestion))
							result.push(eachSuggestion);
					});
					return result;
				};

				$scope.acceptSuggestion = function(tag) {
					$scope.tags.push(tag);
					analytics.sendTagEvent('suggestion selected in edit view', 'select');
				};

				$scope.refreshSuggestions = function() {
					$scope.getVisibleSuggestions().forEach(function(eachSuggestion) {
						if($scope.skippedSuggestions.indexOf(eachSuggestion) == -1) {
							$scope.skippedSuggestions.push(eachSuggestion);
						}
					});
					loadSuggestions();
					analytics.sendTagEvent('suggestions skipped in edit view', 'skip');
				}

			}]
		}
	}];
});

