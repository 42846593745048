define('tagpacker-angular/directives/ad/adSenseDirective',[], function(){	
	
	return function factory() {
	  return {
	    restrict: 'A',
	    transclude: true,
	    replace: true,
	    template: '<div ng-transclude></div>'
	  }
	};
	
});
