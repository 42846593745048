define('tagpacker-angular/services/followService',['tagpackerModule'], function(tagpackerModule){

	tagpackerModule.factory('followService', followService);

	followService.$inject = ['$http', '$q'];

	function followService($http, $q) {
	
		return {
			
			follow: function(userId) {
				var promise = $http.post('/follow', { userId: userId });
				return wrapPromise(promise);
			},
			
			unfollow: function(userId) {
				var promise = $http.post('/unfollow', { userId: userId });
				return wrapPromise(promise);
			},
			
			followRequest: function(userId) {
				return $http.post('/followRequest?userId=' + userId);
			},
			
		};
		
		// Lex: What was the (my?) idea here? Maybe to hide the special .success() and .error()
		// functions on the returned promise?
		function wrapPromise(promise) {
			var deferred = $q.defer();
			promise.then(function() {
				deferred.resolve(); 
			}, function() { 
				deferred.reject(); 
			});
			return deferred.promise;
		}
	
	}
	
});

